import State, { ToggleModeLibras } from './state';

/**
 * Controla a ativação/desativação do modo libras na aplicação
 */
export default class ModeLibrasController {
  element: HTMLElement & {
    ToggleModeLibras?: ToggleModeLibras;
    // TODO: substituir o tipo pela interface do LoadingStatus quando for implementada
    LoadingStatus?: {
      start: () => void;
      stop: () => void;
      isBusy: () => boolean;
    };
  };

  constructor(element: HTMLElement) {
    this.element = element;
    if (this.element.ToggleModeLibras) return; // já inicializado

    this.element.ToggleModeLibras = State;
    this.init();
  }

  async enable() {
    State.enable();
  }

  async disable() {
    State.disable();
  }

  async toggle() {
    State.toggle();
  }

  private async update() {
    this.element.dataset.mode = State.value().mode;
    this.adjustA11y();

    if (this.element.LoadingStatus && this.element.LoadingStatus?.isBusy())
      return;

    if (this.element.dataset.post) {
      await this.handlePostLibraMode();
    }
  }

  private init() {
    if (this.element.dataset.mode) {
      this.element.dataset.mode === 'on' ? this.enable() : this.disable();
    } else {
      // recupera o valor a partir do menu libras (retrocompatibilidade)
      if (
        this.element.dataset.toggleLibras &&
        this.element.dataset.toggleLibras !== ''
      ) {
        const menuLibras = document.querySelector<HTMLElement>(
          this.element.dataset.toggleLibras
        );
        menuLibras?.dataset.libras === 'true' ? this.enable() : this.disable();
      }
    }

    State.subscribe(async () => {
      await this.update();
    });

    this.element.addEventListener('click', () => {
      if (this.element.LoadingStatus && this.element.LoadingStatus?.isBusy())
        return;
      this.toggle();
    });
  }

  private adjustA11y() {
    const detail = {
      active: State.isEnabled(),
      ...State.value(),
    };

    this.element.ariaLabel = detail.label;
    this.element.title = detail.title;

    // usado no menu lateral direito (modo mobile)
    if (this.element.classList.contains('nav-link')) {
      this.element.title = '';
      const titleEl = this.element.querySelector('span');
      if (titleEl) {
        titleEl.innerText = detail.title;
      }
    }
  }

  private async handlePostLibraMode() {
    const urlAPI = this.element.dataset.post;
    if (urlAPI) {
      this.element.LoadingStatus?.start();
      try {
        await fetch(urlAPI, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            preference:
              this.element.dataset.preference || 'acessibilidade_libras',
            value: State.isEnabled(),
          }),
        });
      } catch (error) {
        console.error('Não foi possível salvar a configuração do modo libras');
      } finally {
        this.element.LoadingStatus?.stop();
      }
    }
  }
}
