/* ----- ROTACIONA ICONE NO ACCORDION ----------------------------
|  Esse trecho de código realiza a adição da classe rotate-arrow no icone do
|  accordion, para produzir o giro do icone, informando que está aberto ou fechado.
|  Como também, estiliza a abertura do quadro.
*-------------------------------------------------------------------*/
(window => {
  function openAccordion(parametro) {
    const icon = parametro.lastElementChild;
    if (!icon) return;
    icon.classList.add('rotate-arrow'); // Necessário para manter compatibilidade com versão anterior a 1.10
  }

  window.openAccordion = openAccordion;
})(window);

// ************************* FIM DO accordion.js *************************
