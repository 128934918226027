import Popper from 'popper.js';
import * as utils from './datepicker.utils.js';
import * as factory from './datepicker.factory.js';
import { COMPONENT_NAME } from './constants';

import observeElement from '../../commons/observerElement';
import { DSGlobal } from '../../global';

const SELECTOR_INPUT_EDITABLE = 'div[contenteditable]';
const SELECTOR_INPUT_BUTTON = '.input-group-addon';
const SELECTOR_MODAL_VALID_DAYS = 'td[data-date]';
const SELECTOR_MODAL_DAYS = '.datepicker__dateTable__tableBody tr td';
const SELECTOR_MODAL_ITEMS = 'td.datepicker__monthsPeriodTable__item';
const SELECTOR_MODAL_HEADER = '.datepicker__header';
const SELECTOR_MODAL_HEADER_BUTTONS = '.datepicker__header button';
const SELECTOR_MODAL_HEADER_PREV_BUTTON = 'button.header__previous';
const SELECTOR_MODAL_HEADER_NEXT_BUTTON = 'button.header__next';
const SELECTOR_MODAL_HEADER_MAIN_BUTTON = '.header__currentMonthYear';
const SELECTOR_MODAL_HEADER_PERIOD_TITLE = '.header__currentPeriod';

const NEXT_MONTH_EVENT = 'nextMonth';

const defaultDatepickerOptions = {
  outputFormat: 'yyyy-mm-dd',
};

const optionsSanitized = utils.objectSanitizer(
  defaultDatepickerOptions,
  DSGlobal[COMPONENT_NAME] || {}
);

export const options = Object.assign(
  defaultDatepickerOptions,
  optionsSanitized
);

export class DatepickerCombobox {
  constructor(el) {
    if (el.Datepicker === this) return;
    el.Datepicker = this;
    this.el = el;
    this.el.style.display = 'none';
    this.datepickerModal = null;
    this.outputFormat =
      this.el.dataset.outputFormat || DSGlobal[COMPONENT_NAME].outputFormat;
    this.selectedDate = null;
    this.currentDate = new Date();
    this.inputEditableWrapper = factory.createInputDatepicker();
    this.el.parentElement.appendChild(this.inputEditableWrapper);
    this.inputEditable = this.inputEditableWrapper.querySelector(
      SELECTOR_INPUT_EDITABLE
    );
    this.isDisabled = this.el.hasAttribute('disabled');
    if (this.isDisabled) {
      this.setDisabled();
    }
    if (this.outputFormat === 'dd/mm/yyyy') {
      this.el.setAttribute('type', 'text');
    } else {
      this.el.setAttribute('type', 'date');
    }
    this.handleInputButtonEvents();
    this.addContentEditableEvents();
    this.addInputEvents();
    this.el.addEventListener(NEXT_MONTH_EVENT, (e) => {
      this.renderCalendarModal(e.detail.date, e.detail.arrowbutton);
    });
    if (this.el.value) {
      if (this.outputFormat === 'dd/mm/yyyy') {
        const dateDMYFormat = /^\d{2}\/\d{2}\/\d{4}$/;
        if (
          dateDMYFormat.test(this.el.value) &&
          utils.dateInputIsValid(this.el.value)
        ) {
          const ISODate = utils.formatObjectDateToIsoTextDate(
            utils.formatTextDateToObjectDate(this.el.value)
          );
          this.selectDate(ISODate, false);
        } else {
          console.warn(
            `O valor especificado ${this.el.value} não está de acordo com o padrão esperado: "dd/mm/yyyy" ou não é um dia válido.`
          );
        }
      } else {
        this.selectDate(this.el.value, false);
      }
    }

    if (this.el.classList.contains('is-invalid')) {
      this.inputEditable.classList.add('is-invalid');
    }

    const label = document.querySelector(`label[for="${this.el.id}"]`);
    if (label) {
      label.addEventListener('click', (event) => {
        event.preventDefault();

        if (!this.el.disabled) {
          this.inputEditable.focus();
        }
      });
    }

    this.el.activateError = () => this.activateError();
    this.el.deactivateError = () => this.deactivateError();

    document.addEventListener('click', (event) => {
      this.clickAway(event);
    });

    observeElement(this.el, 'value', (oldValue, newValue) => {
      const wasChanged = oldValue !== newValue;
      if (!wasChanged) return;
      this.syncToContentEditableText();
    });
  }

  toggleModal() {
    if (this.datepickerModal) {
      this.closeModal();
    } else {
      this.openModal();
    }
  }

  closeModal() {
    if (this.datepickerModal) {
      this.datepickerModal.remove();
      this.datepickerModal = null;
      this.inputEditable.setAttribute('aria-expanded', 'false');
    }
    return;
  }

  openModal() {
    this.renderCalendarModal(this.getInputDateValue() || this.currentDate);
    this.inputEditable.setAttribute('aria-expanded', 'true');
    this.setModalPosition();
  }

  clickAway(event) {
    const inputButton = this.inputEditableWrapper.querySelector(
      SELECTOR_INPUT_BUTTON
    );
    if (this.datepickerModal) {
      if (
        !event.composedPath().includes(this.datepickerModal) &&
        !event.composedPath().includes(inputButton)
      ) {
        this.closeModal();
      }
    }
  }

  markSelectedDateOnModal(date) {
    if (!date) return;

    const isSelectedMonth = utils.isSameMonth(date, this.selectedDate);
    if (isSelectedMonth) {
      const dateIso = utils.formatObjectDateToIsoTextDate(this.selectedDate);
      const dayEl = this.datepickerModal.querySelector(
        `[data-date="${dateIso}"]`
      );
      if (dayEl) {
        dayEl.setAttribute('aria-selected', 'true');

        const currentAriaLabel = dayEl.getAttribute('aria-label') || '';
        dayEl.setAttribute('aria-label', `Selecionado: ${currentAriaLabel}`);
      }
    }
  }

  setCursorPositionToEnd() {
    let range = document.createRange();
    let sel = window.getSelection();
    range.setStart(
      this.inputEditable.childNodes[0],
      this.inputEditable.textContent.length
    );
    range.collapse(true);

    sel.removeAllRanges();
    sel.addRange(range);
  }

  setModalPosition() {
    new Popper(this.inputEditableWrapper, this.datepickerModal, {
      placement: 'bottom-start',
      modifiers: {
        preventOverflow: {
          enabled: false,
          padding: 1,
        },
        hide: {
          enabled: false,
        },
        offset: {
          enabled: true,
          offset: '0, 10',
        },
      },
    });
  }

  setDisabled() {
    const inputButton = this.inputEditableWrapper.querySelector(
      SELECTOR_INPUT_BUTTON
    );

    this.inputEditable.setAttribute('aria-disabled', 'true');
    this.inputEditable.setAttribute('tabindex', '-1');
    this.inputEditable.classList.add('disabled');
    inputButton.setAttribute('disabled', 'true');
  }

  clearsModal() {
    this.datepickerModal.innerHTML = '';
  }

  selectDate(dateIso, focus = true) {
    if (this.el.classList.contains('is-invalid')) {
      window.deactivateError(this.el);
      this.inputEditable.removeAttribute('aria-invalid');
      this.inputEditable.removeAttribute('aria-describedby');
    }
    this.closeModal();
    this.el.value =
      this.outputFormat === 'dd/mm/yyyy'
        ? utils.formatISODateToCommonFormat(dateIso)
        : dateIso;
    this.selectedDate = utils.formatTextDateToObjectDate(dateIso);
    this.inputEditable.innerText = utils.formatISODateToCommonFormat(dateIso);
    if (focus) {
      this.inputEditable.focus();
    }
  }

  getInputDateValue() {
    const value = this.inputEditable.textContent;
    if (value) {
      if (utils.dateInputIsValid(value)) {
        return utils.formatTextDateToObjectDate(value);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  activateError() {
    this.inputEditable.classList.add('is-invalid');
  }

  deactivateError() {
    this.inputEditable.classList.remove('is-invalid');
  }

  syncToInputValue() {
    this.el.value = null;
    const date = this.getInputDateValue();
    if (date) {
      if (this.outputFormat === 'dd/mm/yyyy') {
        this.el.value = this.inputEditable.innerText;
      } else {
        this.el.value = utils.formatObjectDateToIsoTextDate(date);
      }
    }
  }

  syncToContentEditableText() {
    const inputDate =
      this.outputFormat === 'dd/mm/yyyy'
        ? this.el?.value
        : utils.formatISODateToCommonFormat(this.el?.value);

    if (utils.dateInputIsValid(inputDate)) {
      if (this.outputFormat === 'dd/mm/yyyy') {
        this.el.value = utils.insertDateMask(this.el.value);
        this.inputEditable.textContent = this.el.value;
      } else {
        this.inputEditable.textContent = utils.formatISODateToCommonFormat(
          this.el.value
        );
      }
    } else {
      this.el.value = '';
      this.inputEditable.textContent = '';
    }
  }

  focusOnDay(dayEl, button) {
    const tdArrays = this.datepickerModal.querySelectorAll(
      SELECTOR_MODAL_VALID_DAYS
    );
    const dayIndex = Array.from(tdArrays).indexOf(dayEl);
    const isoDate = dayEl.getAttribute('data-date');
    const date = utils.formatTextDateToObjectDate(isoDate);
    const month = date.getMonth();
    const year = date.getFullYear();
    const currentMonthDays = utils.getCurrentMonthDays(year, month);
    const currentDay = dayIndex + 1;
    const previousMonthDays = utils.getPreviousMonthDays(year, month);
    const nextMonthDays = utils.getNextMonthDays(year, month);
    const remainingDaysCurrentMonth = currentMonthDays - currentDay;
    const totalStepDays = remainingDaysCurrentMonth + nextMonthDays;
    const weekdayText = dayEl.getAttribute('aria-label').split(',')[0];
    const weekday = utils.weekdaysArray.indexOf(weekdayText);

    const LAST_WEEK_INDEX = 6;

    let stepDays;
    switch (button) {
      case 'right':
        stepDays = 1;
        break;
      case 'left':
        stepDays = -1;
        break;
      case 'up':
        stepDays = -7;
        break;
      case 'down':
        stepDays = 7;
        break;
      case 'home':
        stepDays = -weekday;
        break;
      case 'end':
        stepDays = LAST_WEEK_INDEX - weekday;
        break;
      case 'pageUp':
        stepDays =
          previousMonthDays < currentDay ? -currentDay : -previousMonthDays;
        break;
      case 'pageDown':
        stepDays =
          currentDay > nextMonthDays ? totalStepDays : currentMonthDays;
        break;
    }
    const nextValidDay = tdArrays[dayIndex + stepDays];
    if (nextValidDay) {
      dayEl.setAttribute('tabindex', '-1');
      nextValidDay.setAttribute('tabindex', '0');
      nextValidDay.focus();
    } else {
      const dataDateObject = utils.formatTextDateToObjectDate(
        dayEl.getAttribute('data-date')
      );
      dataDateObject.setDate(dataDateObject.getDate() + stepDays);
      this.dispatchNextMonthEvent(dataDateObject);
    }
  }

  focusOnTd(tdEl, button) {
    const tdArrays =
      this.datepickerModal.querySelectorAll(SELECTOR_MODAL_ITEMS);
    const tdIndex = Array.from(tdArrays).indexOf(tdEl);

    let nextElement;
    switch (button) {
      case 'right':
        nextElement = tdArrays[tdIndex + 1] || tdArrays[0];
        break;
      case 'left':
        nextElement = tdArrays[tdIndex - 1] || tdArrays[tdArrays.length - 1];
        break;
      case 'up':
        nextElement = tdArrays[tdIndex - 4] || tdArrays[tdIndex + 8];
        break;
      case 'down':
        nextElement = tdArrays[tdIndex + 4] || tdArrays[tdIndex - 8];
        break;
    }
    tdEl.setAttribute('tabindex', '-1');
    nextElement.setAttribute('tabindex', '0');
    nextElement.focus();
  }

  keepFocusOnHeaderArrowButton(isNext) {
    const firstTd = this.datepickerModal.querySelector(SELECTOR_MODAL_ITEMS);
    const prevButton = this.datepickerModal.querySelector(
      SELECTOR_MODAL_HEADER_PREV_BUTTON
    );

    const nextButton = this.datepickerModal.querySelector(
      SELECTOR_MODAL_HEADER_NEXT_BUTTON
    );

    firstTd?.setAttribute('tabindex', '0');

    isNext ? nextButton.focus() : prevButton.focus();
  }

  focusOnCurrentDay(date) {
    const currentDataDate = utils.formatObjectDateToIsoTextDate(date);
    const day = this.datepickerModal.querySelector(
      `td[data-date="${currentDataDate}"]`
    );
    day.setAttribute('tabindex', '0');
    day.focus();
  }

  focusOncurrentYear(year) {
    const yearElement = this.datepickerModal.querySelector(
      `td[data-year="${year}"]`
    );
    yearElement.setAttribute('tabindex', '0');
    yearElement.focus();
  }

  dispatchNextMonthEvent(date, arrowButton) {
    const nextMonthEvent = new CustomEvent(NEXT_MONTH_EVENT, {
      detail: {
        date: date,
        arrowbutton: arrowButton,
      },
    });
    this.el.dispatchEvent(nextMonthEvent);
  }

  handleInputButtonEvents() {
    const inputButton = this.inputEditableWrapper.querySelector(
      SELECTOR_INPUT_BUTTON
    );

    inputButton.addEventListener('click', (event) => {
      event.preventDefault();
      this.inputEditable.textContent = utils.insertDateMask(
        this.inputEditable.textContent
      );
      this.toggleModal();
    });
  }

  addInputEvents() {
    this.el.addEventListener('change', () => {
      this.syncToContentEditableText();
    });
  }

  addContentEditableEvents() {
    const dayFormatRegex = /^\d{1,2}$/;
    const monthFormatRegex = /^\d{1,2}\/\d{1,2}$/;
    this.inputEditable.addEventListener('keydown', (event) => {
      // abre o datepicker
      if (event.key === ' ') {
        event.preventDefault();
        this.inputEditable.textContent = utils.insertDateMask(
          this.inputEditable.textContent
        );
        this.openModal();
      }

      // fecha o datepicker
      if (event.key === 'Escape') {
        this.closeModal();
      }

      if (event.key === 'Backspace') {
        if (this.el.value) {
          this.el.value = null;
        }
      }

      if (
        event.key === '/' &&
        (dayFormatRegex.test(this.inputEditable.textContent) ||
          monthFormatRegex.test(this.inputEditable.textContent))
      ) {
        return;
      }

      const keysPermited = [
        'Backspace',
        'Delete',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Tab',
      ];

      if (keysPermited.indexOf(event.key) != -1) return;

      if (
        utils.isNumberTyped(event) &&
        this.inputEditable.textContent.length < 10
      ) {
        return;
      }

      event.preventDefault();
    });

    this.inputEditable.addEventListener('blur', () => {
      if (this.el.parentElement.classList.contains('focused')) {
        this.el.parentElement.classList.remove('focused');
      }
      if (this.datepickerModal) {
        return;
      }
      this.syncToInputValue();
      if (utils.dateInputIsValid(this.inputEditable.textContent)) {
        const date = utils.formatTextDateToObjectDate(
          this.inputEditable.textContent
        );
        if (this.inputEditable.textContent) {
          this.selectDate(utils.formatObjectDateToIsoTextDate(date), false);
        }
        this.inputEditable.textContent = utils.insertDateMask(
          this.inputEditable.textContent
        );
      } else {
        window.activateError(this.el, 'Data Inválida');
        this.inputEditable.setAttribute('aria-invalid', 'true');
        this.inputEditable.setAttribute(
          'aria-describedby',
          `${this.el.getAttribute('aria-describedby')}`
        );
      }
    });

    this.inputEditable.addEventListener('focus', () => {
      if (this.inputEditable.textContent) {
        this.setCursorPositionToEnd();
      }
      this.el.parentElement.classList.add('focused');
    });

    this.inputEditable.addEventListener('input', () => {
      if (utils.dateInputIsValid(this.inputEditable.textContent)) {
        this.selectedDate = utils.formatTextDateToObjectDate(
          this.inputEditable.textContent
        );
      }
      if (this.el.classList.contains('is-invalid')) {
        window.deactivateError(this.el);
        this.inputEditable.removeAttribute('aria-invalid');
        this.inputEditable.removeAttribute('aria-describedby');
      }
    });
  }

  handleDaysEvents(day) {
    day.addEventListener('keydown', (event) => {
      switch (event.key) {
        case 'ArrowRight':
          event.preventDefault();
          this.focusOnDay(day, 'right');
          break;
        case 'ArrowLeft':
          event.preventDefault();
          this.focusOnDay(day, 'left');
          break;
        case 'ArrowDown':
          event.preventDefault();
          this.focusOnDay(day, 'down');
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.focusOnDay(day, 'up');
          break;
        case 'Home':
          event.preventDefault();
          this.focusOnDay(day, 'home');
          break;
        case 'End':
          event.preventDefault();
          this.focusOnDay(day, 'end');
          break;
        case 'PageUp':
          event.preventDefault();
          this.focusOnDay(day, 'pageUp');
          break;
        case 'PageDown':
          event.preventDefault();
          this.focusOnDay(day, 'pageDown');
          break;
        case 'Enter':
        case ' ':
          event.preventDefault();
          this.selectDate(day.getAttribute('data-date'));
          break;
        case 'Tab':
          event.preventDefault();
          this.datepickerModal
            .querySelector(SELECTOR_MODAL_HEADER_PREV_BUTTON)
            .focus();
          break;
        case 'Escape':
          this.closeModal();
          this.inputEditable.focus();
          break;
        default:
          break;
      }
    });

    day.addEventListener('click', (event) => {
      event.preventDefault();
      this.selectDate(day.getAttribute('data-date'));
    });
  }

  handleKeyDownEventHeader() {
    const buttons = this.datepickerModal.querySelectorAll(
      SELECTOR_MODAL_HEADER_BUTTONS
    );
    const headerDatepicker = this.datepickerModal.querySelector(
      SELECTOR_MODAL_HEADER
    );

    const goToIndex = (i, n) => ((i % n) + n) % n;

    headerDatepicker.addEventListener('keydown', (event) => {
      const index = Array.from(buttons).indexOf(event.target);
      switch (event.key) {
        case 'ArrowRight':
        case 'ArrowDown':
          event.preventDefault();
          buttons[goToIndex(index + 1, buttons.length)].focus();
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
          event.preventDefault();
          buttons[goToIndex(index - 1, buttons.length)].focus();
          break;
        case 'Escape':
          this.closeModal();
          this.inputEditable.focus();
          break;
        default:
          break;
      }
    });
  }

  handleCalendarHeaderEvents(month, year) {
    const [prevButton, monthYearButton, nextButton] =
      this.datepickerModal.querySelectorAll(SELECTOR_MODAL_HEADER_BUTTONS);
    this.handleKeyDownEventHeader();

    prevButton.addEventListener('click', () => {
      const currentDate = new Date(year, month);
      currentDate.setMonth(currentDate.getMonth() - 1);
      this.dispatchNextMonthEvent(currentDate, 'isPrev');
    });

    nextButton.addEventListener('click', () => {
      const currentDate = new Date(year, month);
      currentDate.setMonth(currentDate.getMonth() + 1);
      this.dispatchNextMonthEvent(currentDate, 'isNext');
    });

    monthYearButton.addEventListener('click', () => {
      this.renderMonthsModal(year);
    });
  }

  handleMonthsHeaderEvents(year) {
    const [prevButton, yearButton, nextButton] =
      this.datepickerModal.querySelectorAll(SELECTOR_MODAL_HEADER_BUTTONS);

    this.handleKeyDownEventHeader();

    prevButton.addEventListener('click', () => {
      const decrementedYear = (year -= 1);
      this.renderMonthsModal(decrementedYear);
    });

    nextButton.addEventListener('click', () => {
      const incrementedYear = (year += 1);
      this.renderMonthsModal(incrementedYear, true);
    });

    yearButton.addEventListener('click', () => {
      this.renderPeriodModal(year, utils.generateYearsArray(year));
    });
  }

  handlePeriodHeaderEvents(year) {
    const [prevButton, nextButton] = this.datepickerModal.querySelectorAll(
      SELECTOR_MODAL_HEADER_BUTTONS
    );

    this.handleKeyDownEventHeader();

    const [initialYear, finalYear] = utils.getFirstAndLastPeriodYear(year);

    prevButton.addEventListener('click', () => {
      this.renderPeriodModal(
        initialYear,
        utils.generateYearsArray(initialYear)
      );
    });

    nextButton.addEventListener('click', () => {
      this.renderPeriodModal(
        finalYear,
        utils.generateYearsArray(finalYear),
        true
      );
    });
  }

  handleTableMonthsPeriodEvents(td) {
    const yearButton = this.datepickerModal.querySelector(
      SELECTOR_MODAL_HEADER_MAIN_BUTTON
    );
    td.addEventListener('keydown', (event) => {
      switch (event.key) {
        case 'ArrowRight':
          event.preventDefault();
          this.focusOnTd(td, 'right');
          break;
        case 'ArrowLeft':
          event.preventDefault();
          this.focusOnTd(td, 'left');
          break;
        case 'ArrowDown':
          event.preventDefault();
          this.focusOnTd(td, 'down');
          break;
        case 'ArrowUp':
          event.preventDefault();
          this.focusOnTd(td, 'up');
          break;
        case 'Enter':
        case ' ':
          event.preventDefault();
          if (td.hasAttribute('data-month')) {
            this.dispatchNextMonthEvent(
              new Date(
                yearButton.textContent,
                utils.monthsArray.indexOf(td.getAttribute('data-month')),
                1
              )
            );
          } else if (td.hasAttribute('data-year')) {
            this.renderMonthsModal(td.getAttribute('data-year'));
          }
          break;
        case 'Tab':
          event.preventDefault();
          this.datepickerModal
            .querySelector(SELECTOR_MODAL_HEADER_PREV_BUTTON)
            .focus();
          break;
        case 'Escape':
          event.preventDefault();
          this.closeModal();
          this.inputEditable.focus();
          break;
        default:
          break;
      }
    });
    td.addEventListener('click', () => {
      if (td.hasAttribute('data-month')) {
        this.dispatchNextMonthEvent(
          new Date(
            yearButton.textContent,
            utils.monthsArray.indexOf(td.getAttribute('data-month')),
            1
          )
        );
      } else if (td.hasAttribute('data-year')) {
        this.renderMonthsModal(td.getAttribute('data-year'));
      }
    });
  }

  renderCalendarModal(date, arrowButton) {
    if (!this.datepickerModal) {
      this.datepickerModal = factory.createModalWrapper();
      this.el.parentElement.appendChild(this.datepickerModal);
    }
    this.clearsModal();
    this.fillCalendarModalHeader(date);
    this.fillCalendarTable(date);
    this.fillModalFooter();
    if (date) {
      this.markSelectedDateOnModal(date);
    }
    this.focusOnCurrentDay(date);
    if (arrowButton === 'isNext') {
      this.keepFocusOnHeaderArrowButton(true);
    } else if (arrowButton === 'isPrev') {
      this.keepFocusOnHeaderArrowButton(false);
    }
  }

  renderMonthsModal(year, isNext) {
    this.clearsModal();
    this.fillMonthsModalHeader(year);
    this.fillMonthsTable();
    this.keepFocusOnHeaderArrowButton(isNext);
  }

  renderPeriodModal(year, yearArray, isNext) {
    this.clearsModal();
    this.fillPeriodModalHeader(year);
    this.fillPeriodTable(yearArray);
    this.focusOncurrentYear(year);
    this.keepFocusOnHeaderArrowButton(isNext);
  }

  fillCalendarModalHeader(date) {
    const month = date.getMonth();
    const year = date.getFullYear();
    const header = factory.createCalendarHeaderModal();

    const monthYearButton = header.querySelector(
      SELECTOR_MODAL_HEADER_MAIN_BUTTON
    );
    monthYearButton.textContent = `${utils.monthsArray[month]} ${year}`;

    this.datepickerModal.appendChild(header);
    this.handleCalendarHeaderEvents(month, year);
  }

  fillMonthsModalHeader(year) {
    const header = factory.createMonthsHeaderModal();

    const currentYear = header.querySelector(SELECTOR_MODAL_HEADER_MAIN_BUTTON);
    currentYear.textContent = `${year}`;

    this.datepickerModal.appendChild(header);
    this.handleMonthsHeaderEvents(+year);
  }

  fillPeriodModalHeader(year) {
    const header = factory.createPeriodHeaderModal();

    const currentYear = header.querySelector(
      SELECTOR_MODAL_HEADER_PERIOD_TITLE
    );
    currentYear.textContent = `${year - 6} - ${year + 5}`;

    this.datepickerModal.appendChild(header);
    this.handlePeriodHeaderEvents(year);
  }

  fillMonthsTable() {
    const table = factory.createMonthsPeriodTable();
    const year = this.datepickerModal.querySelector(
      '.header__currentMonthYear'
    ).textContent;
    table.setAttribute('aria-label', `ano ${year}`);

    const tds = table.querySelectorAll(SELECTOR_MODAL_ITEMS);
    const months = utils.monthsArray;

    tds.forEach((td, index) => {
      td.textContent = months[index].substring(0, 3);
      td.setAttribute('data-month', months[index]);
      td.setAttribute('aria-label', months[index]);
      td.setAttribute('title', months[index]);
      this.handleTableMonthsPeriodEvents(td);
    });

    this.datepickerModal.appendChild(table);
  }

  fillPeriodTable(array) {
    const table = factory.createMonthsPeriodTable();
    table.setAttribute('aria-label', `ano ${array[0]} a ano ${array[11]}`);

    const tds = table.querySelectorAll(SELECTOR_MODAL_ITEMS);

    tds.forEach((td, index) => {
      td.textContent = array[index];
      td.setAttribute('data-year', array[index]);
      td.setAttribute('aria-label', array[index]);

      td.addEventListener('click', () => {
        this.renderMonthsModal(array[index]);
      });

      this.handleTableMonthsPeriodEvents(td);
    });
    this.datepickerModal.appendChild(table);
  }

  fillModalFooter() {
    const message = factory.createFooterModal();

    this.datepickerModal.appendChild(message);
  }

  fillCalendarTable(date) {
    const table = factory.createCalendarTable();
    this.fillCalendarTableBody(table, date);
    this.datepickerModal.appendChild(table);
  }

  fillCalendarTableBody(table, date) {
    const currentDate = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const monthDays = utils.getCurrentMonthDays(year, month);

    let currentDay = 1;
    let nextMonthCount = 1;

    const previousMonthDaysArray = utils
      .generateCalendarPreviousMonthDaysArray(year, month)
      .reverse();

    const tds = table.querySelectorAll(SELECTOR_MODAL_DAYS);

    tds.forEach((td, index) => {
      if (index < previousMonthDaysArray.length) {
        td.textContent = previousMonthDaysArray[index];
        td.classList.add('disabled');
      } else if (currentDay <= monthDays) {
        const isCurrentDay =
          year === currentDate.getFullYear() &&
          month === currentDate.getMonth() &&
          currentDay === currentDate.getDate();

        const currentWeekday = utils.weekdaysArray[index % 7];
        const currentMonth = utils.monthsArray[month];
        const ariaLabel = `${currentWeekday}, ${currentDay} de ${currentMonth} de ${year}`;
        const dataDate = `${year}-${utils.getMonthFormated(month)}-${
          currentDay < 10 ? '0' + currentDay : currentDay
        }`;

        td.textContent = currentDay;
        td.setAttribute('aria-label', ariaLabel);
        td.setAttribute('data-date', dataDate);
        if (isCurrentDay) {
          td.classList.add('currentDay');
        }
        currentDay++;
        this.handleDaysEvents(td);
      } else {
        td.textContent = nextMonthCount;
        td.classList.add('disabled');
        nextMonthCount++;
      }
    });
  }
}
