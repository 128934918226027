/* ----- GERAÇÃO ALEATÓRIA DE ROBÔS NA PÁGINA DE VIXE --------------------------
|  Esse trecho de código realiza a geração de forma aleatória de um robô que está
|  entre os presentes no array abaixo e o usa para substituir pela imagem
|  originalmente colocada, como também adiciona a funcionalidade de voltar
|  na história do navegador a partir do clique do botão.
*-------------------------------------------------------------------*/
(function() {
  const robotsLabels = [
    'RobozinhoEITA',
    'RobozinhoHEY',
    'RobozinhoOPA',
    'RobozinhoVISH',
  ];
  const errorContainers = document.querySelectorAll([
    '.tela-erro--vixe',
    '[class^="tela-erro--vixe--contraste"]',
  ]);

  const randomNumber = (min, max) => Math.floor(Math.random() * max + min);

  errorContainers.forEach(errorContainer => {
    const imgs = errorContainer.querySelectorAll('img');
    const randomRobot = robotsLabels[randomNumber(0, robotsLabels.length)];

    imgs.forEach((img, index) => {
      const robotInImg = img.src.slice(
        img.src.indexOf('Robozinho'),
        img.src.lastIndexOf('-')
      ); // Essa função retorna o nome do robô presente no src da imagem

      img.style.display = 'block'; // Display block porque inicialmente a imagem está escondida
      img.src = img.src.replace(robotInImg, randomRobot);

      // No primeiro robô um acréscimo de tamanho é gerado baseado na altura da
      // imagem na tela subtraido da altura original da imagem.
      if (index === 0) {
        $(img).on('load', () => {
          const offset = img.height - img.naturalHeight;
          let scale = 1;

          if (offset > 10 && offset <= 15) {
            scale = 1.1;
          } else if (offset > 15 && offset <= 20) {
            scale = 1.2;
          } else if (offset > 20 && offset <= 35) {
            scale = 1.3;
          } else if (offset > 35) {
            scale = 1.4;
          }

          img.style.transform = `scale(${scale})`;
        });
      }
    });
  });

  const backButton = document.getElementById('go-back');
  if (backButton) {
    backButton.addEventListener('click', () => window.history.back());
  }
})();

// ************************* FIM DO vixe.js *************************
