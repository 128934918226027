// Essa função é responsável por adicionar a seta na tabela para ordenção
async function addArrow() {
  const bootstrapTables = document.querySelectorAll(
    'table[data-toggle="table"]'
  );
  bootstrapTables.forEach(bootstrapTable => {
    $(bootstrapTable).one('post-body.bs.table', () => {
      const tableHeaders = bootstrapTable.querySelectorAll('thead th');

      const addIcon = (iconElement, icon) => {
        iconElement.className = iconElement.className.replace(/fa-.*$/, '');
        iconElement.classList.add(icon);
      };

      const resetHeaders = clickedElement => {
        tableHeaders.forEach(tableHeader => {
          const sortableElement = tableHeader.querySelector('.sortable');

          if (sortableElement && sortableElement !== clickedElement) {
            sortableElement.sortState = null;
            const sortIcon = sortableElement.querySelector('i');
            addIcon(sortIcon, 'fa-sort');
          }
        });
      };

      tableHeaders.forEach(tableHeader => {
        const sortableElement = tableHeader.querySelector('.sortable');

        if (
          sortableElement &&
          !sortableElement.classList.contains('sortable-active')
        ) {
          sortableElement.classList.add('sortable-active');

          const buttonIcon = document.createElement('button');
          const sortIcon = document.createElement('i');
          sortIcon.classList.add('fas', 'fa-sort');

          const thTitle = tableHeader.getAttribute('title');
          buttonIcon.setAttribute('aria-label', thTitle);

          buttonIcon.append(sortIcon);
          sortableElement.append(buttonIcon);

          sortableElement.addEventListener('click', () => {
            resetHeaders(sortableElement);

            if (
              !sortableElement.sortState ||
              sortableElement.sortState === 'desc'
            ) {
              sortableElement.sortState = 'asc';
              addIcon(sortIcon, 'fa-sort-up');
            } else if (sortableElement.sortState === 'asc') {
              sortableElement.sortState = 'desc';
              addIcon(sortIcon, 'fa-sort-down');
            }
          });
        }
        tableHeader.setAttribute('scope', 'col');
      });
    });
  });
  setTimeout(addArrow, 200);
}
addArrow();

(function() {
  const bootstrapTables = document.querySelectorAll(
    'table[data-toggle="table"]'
  );

  // Script responsavel pela paginação da tabela
  bootstrapTables.forEach((bootstrapTable, tableIndex) => {
    const removePaginationClass = bsTable => {
      const [bootstrapTableContainer] = $(bsTable).parents('.bootstrap-table');

      const paginationContainer = bootstrapTableContainer.querySelector(
        '.fixed-table-pagination div.pagination'
      );
      if (paginationContainer) {
        paginationContainer.classList.remove('pagination');
        paginationContainer.classList.add('pagination-container');
      }
    };

    $(bootstrapTable).one('post-body.bs.table', () => {
      const [bootstrapTableContainer] = $(bootstrapTable).parents(
        '.fixed-table-body'
      );
      bootstrapTableContainer.classList.add('custom-scroll');

      bootstrapTableContainer.style.overflowX = 'unset';
      bootstrapTableContainer.style.overflowY = 'unset';

      setTimeout(() => {
        bootstrapTableContainer.style.cssText = '';
      }, 200);
    });

    if (bootstrapTable.dataset.pagination === 'true') {
      bootstrapTable.dataset.paginationPreText =
        '<i class="fas fa-chevron-left"></i>';
      bootstrapTable.dataset.paginationNextText =
        '<i class="fas fa-chevron-right"></i>';

      const handleTableChange = () => {
        removePaginationClass(bootstrapTable);
      };

      $(bootstrapTable).on('post-body.bs.table', handleTableChange);
    }

    if (bootstrapTable.dataset.clickToSelect === 'true') {
      const tableHeaderCheck = bootstrapTable.querySelector(
        'th[data-checkbox="true"]'
      );

      if (tableHeaderCheck) {
        tableHeaderCheck.dataset.clickToSelect = 'false';
      }
    }

    if (bootstrapTable.dataset.search === 'true') {
      $(bootstrapTable).one('post-body.bs.table', () => {
        const [bootstrapTableContainer] = $(bootstrapTable).parents(
          '.bootstrap-table'
        );

        const textInputContainer = bootstrapTableContainer.querySelector(
          '.search.btn-group'
        );
        const textInput = bootstrapTableContainer.querySelector(
          'input.search-input'
        );

        const formGroup = document.createElement('div');
        formGroup.classList.add('form-group', 'm-0');

        textInput.setAttribute('name', 'searchInput');

        formGroup.append(textInput);
        textInputContainer.append(formGroup);
      });
    }

    if (bootstrapTable.dataset.showColumns === 'true') {
      $(bootstrapTable).one('post-body.bs.table', () => {
        const [bootstrapTableContainer] = $(bootstrapTable).parents(
          '.bootstrap-table'
        );
        const openDropdownButton = bootstrapTableContainer.querySelector(
          '.fixed-table-toolbar .columns button.btn'
        );
        const dropdownMenu = bootstrapTableContainer.querySelector(
          '.fixed-table-toolbar .columns .dropdown-menu'
        );

        if (openDropdownButton) {
          openDropdownButton.classList.add('btn-secondary');
        }

        if (dropdownMenu) {
          const dropdownItems = dropdownMenu.querySelectorAll('.dropdown-item');

          dropdownItems.forEach((dropdownItem, index) => {
            const checkbox = dropdownItem.querySelector(
              'input[type="checkbox"]'
            );
            checkbox.id = `column-checkbox-${tableIndex}-${index}`;
            checkbox.classList.add('form-check-input');

            const formCheck = document.createElement('div');
            formCheck.classList.add('form-check');

            const label = document.createElement('label');
            label.classList.add('form-check-label', 'label-checkbox');
            label.setAttribute('for', `column-checkbox-${tableIndex}-${index}`);

            formCheck.append(checkbox, label);
            dropdownItem.append(formCheck);

            const columnLabel = dropdownItem.querySelector('span');
            dropdownItem.append(columnLabel);
          });
        }
      });
    }
    $(bootstrapTable).one('post-body.bs.table', () => {
      const updateChecks = () => {
        const tableHeaders = Array.from(
          bootstrapTable.querySelectorAll('thead th')
        );

        const headerCheck = tableHeaders.find(tableHeader =>
          tableHeader.querySelector('input[type="checkbox"]')
        );

        if (headerCheck) {
          const checksOnTable = bootstrapTable.querySelectorAll('.bs-checkbox');
          bootstrapTable.classList.add('table-icon');

          checksOnTable.forEach((checkOnTable, index) => {
            const checkbox = checkOnTable.querySelector(
              'input[type="checkbox"]'
            );

            if (checkbox) {
              checkbox.id = `checkbox-${tableIndex}-${index}`;
              checkbox.classList.add('form-check-input');
              checkbox.setAttribute(
                'aria-label',
                `checkbox-${tableIndex}-${index}`
              );
              checkbox.parentElement.remove();

              const formCheck = document.createElement('div');
              formCheck.classList.add('form-check');

              const checkLabel = document.createElement('label');
              checkLabel.classList.add('form-check-label', 'label-checkbox');
              checkLabel.setAttribute('for', `checkbox-${tableIndex}-${index}`);

              formCheck.append(checkbox, checkLabel);

              if (checkOnTable.tagName === 'TH') {
                const thInner = checkOnTable.querySelector('.th-inner');

                thInner.append(formCheck);
              } else if (checkOnTable.tagName === 'TD') {
                checkOnTable.append(formCheck);
              }
            }
          });
        }
      };

      updateChecks();

      $(bootstrapTable).on('post-body.bs.table', updateChecks);
    });

    $(bootstrapTable).one('post-body.bs.table', () => {
      const updateRadios = () => {
        const tableHeaders = Array.from(
          bootstrapTable.querySelectorAll('thead th.bs-checkbox')
        );

        const headerRadio = tableHeaders.find(
          tableHeader => !tableHeader.querySelector('input[type="checkbox"]')
        );

        if (headerRadio) {
          $(bootstrapTable).one('post-body.bs.table', () => {
            const radiosOnTable = bootstrapTable.querySelectorAll(
              '.bs-checkbox'
            );

            radiosOnTable.forEach((radioOnTable, index) => {
              const radio = radioOnTable.querySelector('input[type="radio"]');

              if (radio) {
                radio.id = `radio-${tableIndex}-${index}`;
                radio.classList.add('form-check-input');
                radio.parentElement.remove();

                const formCheck = document.createElement('div');
                formCheck.classList.add('form-check');

                const radioLabel = document.createElement('label');
                radioLabel.classList.add('form-check-label', 'label-radio');
                radioLabel.setAttribute('for', `radio-${tableIndex}-${index}`);

                formCheck.append(radio, radioLabel);
                radioOnTable.append(formCheck);
              }
            });
          });
        }
      };

      updateRadios();

      $(bootstrapTable).on('post-body.bs.table', updateRadios);
    });

    $(bootstrapTable).one('post-body.bs.table', () => {
      $(bootstrapTable).on('all.bs.table', () => {
        if (bootstrapTable.dataset.pagination === 'true') {
          removePaginationClass(bootstrapTable);
        }

        window.emitChangePalette();
      });
    });
  });

  const linkFormatter = value =>
    `<a class="link" href="${value}" target="_blank" rel="noopener noreferrer">${value}</a>`;

  window.linkFormatter = linkFormatter;
})();

// ************************* FIM DO table.js *************************
