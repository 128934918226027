/* Classe para instanciar um objeto */
export default class InputField {
  inputField: HTMLInputElement;

  /**
   * Instancia do objeto
   * @param {HTMLInputElement} inputField - Objeto referenciando a raiz do componente DOM
   */
  constructor(inputField: HTMLInputElement) {
    this.inputField = inputField;
    if (!this.inputField) {
      console.error(`Não foi possível encontrar o campo de senha ou se a estrutura HTML atende ao padrão estabelecido.`);
      return;
    }
    this.init();
  }

  init(): void {
    this._setBehavior();
  }

  /**
   * Define comportamentos do componente
   * @private
   */
  private _setBehavior(): void {
    this._setFocusBehavior();
  }

  /**
   * Define comportamentos do componente
   * @private
   */
  private _setFocusBehavior(): void {
    if (!this.inputField.disabled) {
      const inputFieldHandler = (event: Event): void => {
        this._handleFocus(event);
      }
      this.inputField.addEventListener('focus', inputFieldHandler, false);
      this.inputField.addEventListener('blur', inputFieldHandler, false);
    }
  }

  /**
   * Define comportamentos do componente
   * @private
   * @param {Event} event - referência ao elemento que dispara a ação
   */
  private _handleFocus(event: Event): void {
    const target = event.currentTarget as HTMLElement;
    const formInputGroup = target.closest('.form-input-group') as HTMLElement;
    if (formInputGroup?.classList.contains('focused')) {
      formInputGroup?.classList.remove('focused');
    } else {
      formInputGroup?.classList.add('focused');
    }
  }
}
