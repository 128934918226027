/* eslint-disable no-unused-vars */
(function() {
  /* ----- Adiciona check ao input -------------------------
|  Esse trecho de código verifica o input clicado e adiciona a classe
|  checked ao input selecionado.
*-------------------------------------------------------------------*/
  function getCheck(e) {
    const card = e.closest('.card-retangular');

    if (!card) return;

    if (e.checked) {
      card.classList.toggle('checked');
    } else {
      card.classList.toggle('checked');
    }
  }
  window.getCheck = getCheck;

  /* ----- Altera a hora dos cards -------------------------
  |  Adiciona a hora a cada 60seg nos cards que necessitam de hora
  *-------------------------------------------------------------------*/
  let data = new Date();
  const horas = document.querySelectorAll('p.timer');
  let foo2;
  horas.forEach(hora => {
    if (data.getHours() < 10) {
      foo2 =
        data.getMinutes() < 10
          ? (hora.innerHTML = `0${data
              .getHours()
              .toString()}:0${data.getMinutes().toString()}`)
          : (hora.innerHTML = `0${data
              .getHours()
              .toString()}:${data.getMinutes().toString()}`);
    } else {
      foo2 =
        data.getMinutes() < 10
          ? (hora.innerHTML = `${data
              .getHours()
              .toString()}:0${data.getMinutes().toString()}`)
          : (hora.innerHTML = `${data
              .getHours()
              .toString()}:${data.getMinutes().toString()}`);
    }
  });
  setInterval(() => {
    data = new Date();
    horas.forEach(hora => {
      if (data.getHours() < 10) {
        foo2 =
          data.getMinutes() < 10
            ? (hora.innerHTML = `0${data
                .getHours()
                .toString()}:0${data.getMinutes().toString()}`)
            : (hora.innerHTML = `0${data
                .getHours()
                .toString()}:${data.getMinutes().toString()}`);
      } else {
        foo2 =
          data.getMinutes() < 10
            ? (hora.innerHTML = `${data
                .getHours()
                .toString()}:0${data.getMinutes().toString()}`)
            : (hora.innerHTML = `${data
                .getHours()
                .toString()}:${data.getMinutes().toString()}`);
      }
    });
  }, 60000);
})();
// ************************* FIM DO card.js *************************
