/* ------------------------------- ROLAR A PÁGINA PARA O TOPO ------------------------------ *
| Esse trecho de código realiza o controle do componente de rolagem automática para o topo   |
| da página. Um botão com o ícone de 'seta para cima' é exibido no canto inferior direito    |
| da tela para melhorar a experiência do usuário.                                            |
* ----------------------------------------------------------------------------------------- */

(() => {
  const scrollUpButton = document.querySelector('.scroll-up');

  if (!scrollUpButton) {
    return;
  }

  const scroller =
    document.querySelector(scrollUpButton.dataset.scroller) || document.body;

  const scrollUp = () => {
    const OFFSET_TOP = scrollUpButton.dataset.distance || 100;
    const showButton = scroller.scrollTop > OFFSET_TOP;
    scrollUpButton.style.display = showButton ? 'flex' : 'none';
    if (showButton) {
      scrollUpButton.classList.add('show');
    } else {
      scrollUpButton.classList.remove('show');
    }
  };

  const moveUp = event => {
    event.preventDefault();
    scroller.scrollTo({ top: 0, behavior: 'smooth' }); // Realiza a rolagem suave para o topo do documento
  };

  scroller.addEventListener('scroll', scrollUp, { capture: true });
  scrollUpButton.addEventListener('click', moveUp);
})();

// ************************* FIM DO scroll-up.js *************************
