(function () {
  /* ----- ADIÇÃO AUTOMÁTICA DO ACTIVE BASEADO NA URL ----------------------------
  |  Esse trecho de código realiza a adição da classe active nos <li> que abrigam
  |  os links que referenciam uma URL qualquer. Se a URL atual for igual à URL
  |  que o <a> referencia, então o <li> recebe a classe active.
  *-------------------------------------------------------------------*/
  const currentUrl = window.location.href;
  const navContainer = document.querySelector('nav.header-mobile');

  if (
    navContainer &&
    navContainer.parentElement.classList.contains(
      'container-conteudo__nav_right'
    )
  ) {
    const links = navContainer.querySelectorAll('a.nav-link');
    links.forEach((link) => {
      if (link.href === currentUrl) {
        let sair = false;
        let li = link.parentElement;
        let ul = li.parentElement;

        // Remove todos as classes activas dentro do menu lateral
        const activos = navContainer.querySelectorAll('.active');
        activos.forEach((activo) => {
          activo.classList.remove('active');
        });

        while (!sair) {
          li.children[0].classList.add('active');
          if (ul.classList.contains('header-mobile__item')) {
            sair = true;
          } else {
            li = ul.parentElement.parentElement;
            ul = li.parentElement;
          }
        }
      }
    });

    // Caminha até o submenu desejado
    const activos = navContainer.querySelectorAll('.active');
    activos.forEach((activo, indice) => {
      if (indice < activos.length - 1) {
        activo.click();
      } else {
        setTimeout(() => {
          const targetOffset = $(activo).offset().top;
          const scrollContainer = navContainer.getElementsByClassName(
            'header-mobile__content'
          )[0];
          const subTarget = targetOffset - $(scrollContainer).offset().top;
          $(scrollContainer).animate({ scrollTop: subTarget }, 1000);
        }, 500);
      }
    });
  }
})();

// ************************* FIM DO header_mobile.js *************************
