(function () {
  const userContainer = document.getElementById('user-container');

  if (userContainer) {
    const userImg = userContainer.querySelector('.header__user img');

    const hideImg = () => {
      userImg.style.display = 'none';
    };

    const showImg = () => {
      userImg.style.display = '';
    };

    if (userImg) {
      if (!userImg.complete || userImg.naturalWidth === 0) {
        hideImg();
      }

      userImg.addEventListener('load', () => {
        if (userImg.naturalWidth === 0) {
          hideImg();
          return;
        }

        showImg();
      });
    }
  }
})();

(function () {
  const headers = document.querySelectorAll('header.header');

  if (!headers) return;

  headers.forEach((header) => {
    const headerRight = header.querySelector('.header__right');
    if (!headerRight) return; // TODO: Criar a estrurura em vez de não fazer nada.

    const items = Array.from(headerRight.querySelectorAll(['.header-mob-out']));
    if (!items) return;

    const itemsMetaData = items.map((item) => {
      const isUserContainer = item.id === 'user-container';

      if (isUserContainer) {
        const userName = item.querySelector(
          '.header__user__info__title'
        ).innerText;
        const userDetail =
          item.querySelector('.header__user__info__detail') ||
          item.querySelector('.header__user__info .p3');
        let userRole = 'Nenhum cargo informado';

        if (userDetail !== null) {
          userRole = userDetail.innerText;
        }

        const dropdownItems = Array.from(
          item.querySelectorAll('.dropdown-item')
        );
        const parsedDropdownItems = dropdownItems
          .filter(
            (dropdownItem) =>
              !dropdownItem.innerText.toLowerCase().includes('sair')
          )
          .map((dropdownItem) => {
            const isLink = !!dropdownItem.href;

            return {
              label: dropdownItem.innerText,
              listeners: isLink ? [] : dropdownItem.getEventListeners(),
              ...(isLink
                ? {
                    link: {
                      href: dropdownItem.href,
                    },
                  }
                : {}),
            };
          });
        const logoutButton = dropdownItems.find((dropdownItem) =>
          dropdownItem.innerText.toLowerCase().includes('sair')
        );
        return {
          user: {
            name: userName,
            role: userRole,
            title: item.title,
            dropdownSize: dropdownItems.length,
            items: parsedDropdownItems,
            ...(logoutButton
              ? {
                  logout: {
                    label: logoutButton.innerText,
                    listeners: logoutButton.getEventListeners
                      ? logoutButton.getEventListeners()
                      : [],
                    href: logoutButton.href || null,
                  },
                }
              : {}),
          },
        };
      }

      const icon = item.querySelector('i[class^="fa"]');
      const isModalOpener = item.dataset.toggle === 'modal';
      const isLink = !!item.href;
      const isNotification = item.id === 'notification-dropdown';

      if (isNotification) {
        const notificationButton = item.querySelector('button.icon');
        const notificationQuantity = item.querySelector('span')
          ? item.querySelector('span').innerText
          : null;
        const notificationLink = item.querySelector('a.link').href;

        const objNotification = {
          label: notificationButton.title,
          icon: icon.className,
          link: notificationLink,
          listeners: item.getEventListeners ? item.getEventListeners() : [],
        };

        if (notificationQuantity !== null) {
          objNotification.notification = {
            quantity: notificationQuantity,
          };
        }
        return objNotification;
      }

      const isToggleModeLibras = !!item.dataset.toggleLibras;

      if (isToggleModeLibras) {
        const objToggleModeLibras = {
          type: 'toggleModeLibras',
          dataset: item.dataset,
          label: item.title,
          icon: icon.className,
          link: null,
          listeners: [],
        };

        return objToggleModeLibras;
      }

      return {
        label: item.title || item.innerText,
        icon: icon.className,
        tag: item.tagName,
        listeners: item.getEventListeners ? item.getEventListeners() : [],
        ...(isModalOpener
          ? {
              modal: {
                target: item.dataset.target,
              },
            }
          : {}),
        ...(isLink
          ? {
              link: {
                href: item.href,
              },
            }
          : {}),
      };
    });

    const listaHeaderMobile = header.parentElement.querySelector(
      '.header-mobile__item'
    );
    if (!listaHeaderMobile) return;

    if (listaHeaderMobile) {
      listaHeaderMobile.setAttribute('role', 'menu');
      if (itemsMetaData.length) {
        itemsMetaData.forEach((itemMetaData) => {
          const listItem = document.createElement('li');
          listItem.classList.add('nav-item');
          listItem.setAttribute('role', 'none');

          const icon = document.createElement('i');
          icon.className = itemMetaData.icon;

          if (itemMetaData.user) {
            icon.className = 'fas fa-user';

            const button = document.createElement('button');
            button.classList.add('nav-link', 'submenu');
            button.setAttribute('aria-expanded', 'false');
            button.setAttribute('aria-haspopup', 'menu');
            button.setAttribute('tabindex', '0');
            button.setAttribute('role', 'menuitem');

            const buttonText = document.createElement('span');
            buttonText.innerText = 'Perfil do(a) usuário(a)';

            const submenuIcon = document.createElement('i');
            submenuIcon.classList.add('fas', 'fa-chevron-right');

            button.append(icon, buttonText, submenuIcon);

            const submenuContent = document.createElement('div');
            submenuContent.classList.add('submenu__content');

            const submenuItems = document.createElement('ul');
            submenuItems.classList.add('nav', 'flex-column', 'submenu_itens');
            submenuItems.setAttribute('aria-describedby', 'submenu-title');
            submenuItems.setAttribute('aria-label', 'perfil');
            submenuItems.setAttribute('role', 'menu');

            const backButton = document.createElement('button');
            backButton.type = 'button';
            backButton.classList.add('link-normal', 'back-menu');
            backButton.setAttribute('role', 'menuitem');
            const backButtonLi = document.createElement('li');
            backButtonLi.classList.add('nav-item');
            backButtonLi.setAttribute('role', 'none');

            const backIcon = document.createElement('i');
            backIcon.classList.add('fas', 'fa-chevron-left');

            const backButtonText = document.createElement('span');
            backButtonText.innerText = 'Voltar ao menu';

            backButton.append(backIcon, backButtonText);
            backButtonLi.append(backButton);

            const submenuTitle = document.createElement('h3');
            submenuTitle.setAttribute('id', 'submenu-title');
            submenuTitle.setAttribute('role', 'none');
            submenuTitle.setAttribute('aria-hidden', 'true');
            submenuTitle.innerText = 'Perfil do(a) usuário(a)';

            const items = itemMetaData.user.items.map((item) => {
              const li = document.createElement('li');
              li.classList.add('nav-item');
              li.setAttribute('role', 'none');

              const navLink = document.createElement('a');
              navLink.classList.add('nav-link');
              navLink.setAttribute('tabindex', '0');
              navLink.setAttribute('role', 'menuitem');

              if (item.link) {
                navLink.href = item.link.href;
              }

              const navLinkText = document.createElement('span');
              navLinkText.innerText = item.label;

              Object.keys(item.listeners).forEach((listenerType) => {
                item.listeners[listenerType].forEach(({ listener }) => {
                  navLink.addEventListener(listenerType, listener);
                });
              });

              navLink.append(navLinkText);
              li.append(navLink);

              return li;
            });

            submenuItems.append(backButtonLi, submenuTitle, ...items);

            if (itemMetaData.user.dropdownSize <= 1) {
              const username = document.createElement('p');
              username.innerText = itemMetaData.user.name;

              submenuItems.append(username);
            }

            submenuContent.append(submenuItems);

            listItem.append(button, submenuContent);

            listaHeaderMobile.append(listItem);

            return;
          }

          const navLink = document.createElement('a');
          navLink.classList.add('nav-link');
          navLink.setAttribute('tabindex', '0');
          navLink.setAttribute('role', 'menuitem');
          if (itemMetaData.tag === 'A') {
            navLink.setAttribute('target', 'blank');
          }

          Object.keys(itemMetaData.listeners).forEach((listenerType) => {
            itemMetaData.listeners[listenerType].forEach(({ listener }) => {
              navLink.addEventListener(listenerType, listener);
            });
          });

          if (itemMetaData.modal) {
            navLink.dataset.toggle = 'modal';
            navLink.dataset.target = itemMetaData.modal.target;
          }

          if (itemMetaData.link) {
            navLink.href = itemMetaData.link;
          }

          const navLinkText = document.createElement('span');
          navLinkText.innerText = itemMetaData.label;
          navLink.append(icon, navLinkText);

          if (itemMetaData.type === 'toggleModeLibras') {
            navLink.dataset.toggleLibras = itemMetaData.dataset.toggleLibras;
            navLink.dataset.post = itemMetaData.dataset.post;
          }

          if (itemMetaData.notification) {
            const notification = document.createElement('span');
            notification.classList.add(
              'tag-rotulo',
              'dark',
              'notification-span'
            );
            notification.innerText = itemMetaData.notification.quantity;
            navLink.href = itemMetaData.link;

            navLink.append(notification);
          }

          listItem.append(navLink);

          listaHeaderMobile.append(listItem);
        });
      }
    }
    const userMetaData = itemsMetaData.find(
      (itemMetaData) => itemMetaData.user
    );

    if (userMetaData && userMetaData.user.logout) {
      const listItem = document.createElement('li');
      listItem.classList.add('nav-item');
      listItem.setAttribute('role', 'none');

      const icon = document.createElement('i');
      icon.className = 'fas fa-sign-out-alt';

      const navLink = document.createElement('a');
      navLink.classList.add('nav-link');
      navLink.setAttribute('tabindex', '0');
      navLink.setAttribute('role', 'menuitem');

      if (userMetaData.user.logout.href) {
        navLink.href = userMetaData.user.logout.href;
      }

      Object.keys(userMetaData.user.logout.listeners).forEach(
        (listenerType) => {
          userMetaData.user.logout.listeners[listenerType].forEach(
            ({ listener }) => {
              navLink.addEventListener(listenerType, listener);
            }
          );
        }
      );

      const navLinkText = document.createElement('span');
      navLinkText.innerText = userMetaData.user.logout.label;

      navLink.append(icon, navLinkText);
      listItem.append(navLink);
      listaHeaderMobile.append(listItem);
    }
  });
})();

(function () {
  const headers = document.querySelectorAll('header.header');

  headers.forEach((header) => {
    const headerTitle = header.querySelector('.header__title');
    const headerMenu = header.querySelector('.header__right .header-mob-in');

    if (headerTitle) {
      let TitleMetaData = {};
      const isDropdownTitle = headerTitle.id === 'dropdownTitle';
      const titleText = headerTitle.querySelector('h1').innerText;

      TitleMetaData = {
        text: titleText,
      };

      if (isDropdownTitle) {
        const titleLink =
          headerTitle.nextElementSibling.querySelector('a.link').href;

        TitleMetaData = {
          text: titleText,
          link: titleLink,
        };
      }

      const listaHeaderMobile = document.querySelector('.header-mobile__item');
      const listItem = document.createElement('li');
      listItem.classList.add('area-modulo');
      listItem.setAttribute('role', 'none');

      if (isDropdownTitle) {
        const linkNormal = document.createElement('a');
        linkNormal.classList.add('link-normal');
        linkNormal.setAttribute('title', 'Alterar módulo');
        linkNormal.innerText = TitleMetaData.text;
        linkNormal.href = TitleMetaData.link;

        listItem.append(linkNormal);
      } else {
        const moduleH1 = document.createElement('p');
        moduleH1.innerText = TitleMetaData.text;

        listItem.append(moduleH1);
      }

      if (listaHeaderMobile !== null) listaHeaderMobile.prepend(listItem);
    }
    // else if (headerMenu) {
    //   const listaHeaderMobile = document.querySelector('.header-mobile__item');

    //   const listItem = document.createElement('li');
    //   listItem.classList.add('area-modulo');

    //   const noModule = document.createElement('p');
    //   noModule.innerText = 'Nenhum módulo no momento';

    //   listItem.append(noModule);
    //   if (listaHeaderMobile) {
    //     listaHeaderMobile.prepend(listItem);
    //   }
    // }
  });
})();

// ************************* FIM DO header.js *************************
