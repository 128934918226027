(function () {
  const stepNav = document.querySelector('.step');
  if (!stepNav) return;
  const stepProgress = stepNav.querySelector('.step-progress');

  const LARGURA_TELA_MAX = 576;

  function verificarLarguraTela() {
    const larguraTela = window.innerWidth;

    if (larguraTela < LARGURA_TELA_MAX) {
      stepNav.classList.add('step-vertical');
      stepProgress.setAttribute('aria-orientation', 'vertical');
    } else {
      stepNav.classList.remove('step-vertical');
      stepProgress.setAttribute('aria-orientation', 'horizontal');
    }
  }

  verificarLarguraTela();

  window.addEventListener('resize', verificarLarguraTela);
})();
