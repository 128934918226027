import InputField from './InputField';
import PasswordInputField from './PasswordInputField';

function initInputField(): void {
  const formInputGroups = document.querySelectorAll('.form-input-group input') as NodeListOf<HTMLInputElement>;
  if (!formInputGroups) {
    return;
  }
  formInputGroups.forEach((inputElement: HTMLInputElement): void => {
    new InputField(inputElement);
  });
}

function initPasswordInputField(): void {
  const inputAddonWrappers = document.querySelectorAll('.input-addon-wrapper') as NodeListOf<HTMLElement>;
  if (!inputAddonWrappers) {
    console.error(`Não foi possível identificar se a estrutura HTML atende ao padrão estabelecido para um campo de senha.`);
    return;
  }
  inputAddonWrappers.forEach(inputAddonWrapper => {
    const passwordInputField = inputAddonWrapper.querySelector('input[type="password"]');
    const actionInputField = inputAddonWrapper.querySelector('button[data-toggle-show-password]');
    if (actionInputField) {
      new PasswordInputField(passwordInputField as HTMLInputElement, actionInputField as HTMLButtonElement);
    }
  });
}

export function init(): void {
  initInputField();
  initPasswordInputField();
}

const methods = {
  init, InputField, PasswordInputField,
};

export default methods;
