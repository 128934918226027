(function () {
  if (Element.prototype.newAddEventListener) return;

  Element.prototype.newAddEventListener = Element.prototype.addEventListener;
  Element.prototype.addEventListener = function (a, b, c) {
    if (!c) c = false;
    this.newAddEventListener(a, b, c);
    if (!this.eventListenerList) this.eventListenerList = {};
    if (!this.eventListenerList[a]) this.eventListenerList[a] = [];
    this.eventListenerList[a].push({ listener: b, useCapture: c });
  };

  Element.prototype.getEventListeners = function (a) {
    if (!this.eventListenerList) this.eventListenerList = {};
    if (!a) return this.eventListenerList;
    return this.eventListenerList[a];
  };

  Element.prototype.clearEventListeners = function (a) {
    if (!this.eventListenerList) this.eventListenerList = {};
    if (!a) {
      Object.keys(this.getEventListeners()).forEach((key) => {
        this.clearEventListeners(key);
      });
      return;
    }
    const el = this.getEventListeners(a);
    if (!el) return;
    for (let i = el.length - 1; i >= 0; i -= 1) {
      const ev = el[i];
      this.removeEventListener(a, ev.listener, ev.useCapture);
    }
  };

  Element.prototype.newRemoveEventListener =
    Element.prototype.removeEventListener;
  Element.prototype.removeEventListener = function (a, b, c) {
    if (!c) c = false;
    this.newRemoveEventListener(a, b, c);
    if (!this.eventListenerList) this.eventListenerList = {};
    if (!this.eventListenerList[a]) this.eventListenerList[a] = [];

    for (let i = 0; i < this.eventListenerList[a].length; i += 1) {
      if (
        (this.eventListenerList[a][i].listener === b,
        this.eventListenerList[a][i].useCapture === c)
      ) {
        this.eventListenerList[a].splice(i, 1);
        break;
      }
    }
    if (this.eventListenerList[a].length === 0) {
      delete this.eventListenerList[a];
    }
  };
})();

// ************************* FIM DO overwrite-add-event-listeners.js *************************
