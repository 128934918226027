(function(Cosmos) {
  const initLoading = element => {
    const html = element instanceof HTMLElement ? element : document;

    const dataLoadingSelector = html.querySelectorAll(
      'button[data-loading], a[data-loading]'
    );

    const dataLoadingTrue = button => {
      const iconButtonElement = button.querySelector('[data-icon]');
      const textButtonElement = button.querySelector('[data-text]');
      const customLoadingText = button.dataset.loadingText;

      if (textButtonElement && customLoadingText) {
        textButtonElement.dataset.originalText = textButtonElement.textContent;
        textButtonElement.textContent = customLoadingText;
      }

      button.classList.add('disabled');
      button.setAttribute('aria-disabled', 'true');

      if (iconButtonElement) {
        iconButtonElement.classList.add('d-none');
      }

      const loadingIcon = document.createElement('i');
      loadingIcon.classList.add(
        'fas',
        'fa-circle-notch',
        'fa-spin',
        'loading-icon'
      );

      if (!button.querySelector('.loading-icon')) {
        button.prepend(loadingIcon);
      }
    };

    const dataLoadingFalse = (button, ignoreDisabled = false) => {
      const iconButtonElement = button.querySelector('[data-icon]');
      const iconLoading = button.querySelector('i.loading-icon');
      const textButtonElement = button.querySelector('[data-text]');

      if (textButtonElement && textButtonElement.dataset.originalText) {
        textButtonElement.textContent = textButtonElement.dataset.originalText;
        delete textButtonElement.dataset.originalText;
      }

      if (iconButtonElement) {
        iconButtonElement.classList.remove('d-none');
      }

      if (!ignoreDisabled) {
        button.classList.remove('disabled');
        button.removeAttribute('aria-disabled');
      }

      if (iconLoading) {
        iconLoading.remove();
      }
    };

    const handlerLoading = (button, ignoreDisabled) => {
      if (button.getAttribute('data-loading') === 'true') {
        dataLoadingTrue(button);
      }

      if (button.getAttribute('data-loading') === 'false') {
        dataLoadingFalse(button, ignoreDisabled);
      }
    };

    const loadingButtonMutations = mutations => {
      mutations.forEach(item => {
        if (
          item.type === 'attributes' &&
          item.attributeName === 'data-loading'
        ) {
          handlerLoading(item.target);
        }
      });
    };

    const init = () => {
      dataLoadingSelector.forEach(button => {
        if (button.LoadingStatus) return; // Já instanciado

        button.LoadingStatus = {
          start: () => button.setAttribute('data-loading', 'true'),
          stop: () => button.setAttribute('data-loading', 'false'),
          isBusy: () => button.getAttribute('data-loading') === 'true',
        };
        const ignoreDisabled = true;
        handlerLoading(button, ignoreDisabled);
        const options = {
          attributes: true,
          attributeOldValue: true,
        };

        button.addEventListener('click', e => {
          if (e.currentTarget.dataset.trigger !== 'manual') {
            if (
              e.currentTarget.LoadingStatus.isBusy() ||
              e.currentTarget.ariaDisabled === 'true'
            ) {
              e.preventDefault();
              e.stopImmediatePropagation();
              return;
            }
            e.currentTarget.LoadingStatus.start();
          }
        });

        const observer = new MutationObserver(loadingButtonMutations);
        observer.observe(button, options);
      });
    };

    init();
  };

  const stopAll = () => {
    const buttons = document.querySelectorAll('button[data-loading]');
    buttons.forEach(button => {
      button.LoadingStatus.stop();
    });
  };

  const COMPONENT_NAME = 'buttonLoading';
  const methods = {
    init: initLoading,
    stopAll,
  };

  Cosmos[COMPONENT_NAME] = methods;
  Cosmos[COMPONENT_NAME].init();

  // Ao carregar uma página do cache, desabilita os botões em loading.
  // evita que o botão esteja ocupado ao precionar o back button do navegador
  window.addEventListener('pageshow', function(event) {
    if (event.persisted) {
      Cosmos[COMPONENT_NAME].stopAll();
    }
  });
})(window.Cosmos);

// ************************* FIM DO button.js *************************
