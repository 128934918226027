/* ----- ROTACIONA ICONE E ADD CLASS NO CARD-HEADER DO ACCORDION ----------------------------
|  Esse trecho de código realiza a adição da classe rotate-arrow no icone do
|  accordion, para produzir o giro do icone, informando que está aberto ou fechado.
|  Como também, adiciona classe open-accordion no card-header para estilização do mesmo.
*-------------------------------------------------------------------*/
// eslint-disable-next-line no-unused-vars
(window => {
  function openAccordionAssinadorUfrn(parametro) {
    const i = parametro.lastElementChild;
    const divPai = parametro.parentElement;
    if (i.classList.contains('rotate-arrow')) {
      i.classList.remove('rotate-arrow');
      setTimeout(function() {
        divPai.classList.remove('open-accordion');
      }, 250);
    } else {
      i.classList.add('rotate-arrow');
      setTimeout(function() {
        divPai.classList.add('open-accordion');
      }, 10);
    }
  }
  window.openAccordionAssinadorUfrn = openAccordionAssinadorUfrn;
})(window);

// ************************* FIM DO assinador_ufrn_page.js *************************
