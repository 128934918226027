(function() {
  // Seleciona todas os elementos que possuem uma classe que começa com "tela-erro"
  // e não possui nenhum underline em seu nome.
  const errorContainers = document.querySelectorAll(
    '[class^="tela-erro"]:not([class*="_"])'
  );

  errorContainers.forEach(errorContainer => {
    const originalImage = errorContainer.querySelector(
      '.tela-erro__robot > img'
    );
    const referenceElement = errorContainer.querySelector(
      '.tela-erro__content__info > p'
    );

    const cloneImage = originalImage.cloneNode();

    const imageContainer = document.createElement('div');
    imageContainer.classList.add('tela-erro__content__info__robot');
    imageContainer.appendChild(cloneImage);

    referenceElement.insertAdjacentElement('beforebegin', imageContainer);
  });
})();

// ************************* FIM DO error_page.js *************************
