(function() {
  const paginations = document.querySelectorAll('[data-js-pagination]');
  paginations.forEach(pagination => {
    const pageLinks = pagination.querySelectorAll(`[data-page]`);

    pageLinks.forEach(pageLink => {
      const paginationPageEvent = new CustomEvent(`pagination:page`, {
        detail: {
          page: pageLink.getAttribute('data-page'),
        },
      });

      pageLink.addEventListener('click', e => {
        e.preventDefault();
        pagination.dispatchEvent(paginationPageEvent);
      });
    });
  });
})();
// ************************* FIM DO pagination-numeral.js *************************
