(function() {
  const selector = '[data-pagination-slide]';

  class Observable {
    constructor(value) {
      this._value = value;
      this.observers = [];
    }

    get value() {
      return this._value;
    }

    set value(v) {
      if (this._value !== v) {
        this._value = v;
        this.notify(v);
      }
    }

    subscribe(f) {
      this.observers.push(f);
    }

    unsubscribe(f) {
      this.observers = this.observers.filter(subscriber => subscriber !== f);
    }

    notify(data) {
      this.observers.forEach(observer => observer(data));
    }
  }

  const infoSelector = '.page-info .page-item';

  const PaginationSliderUtils = (function() {
    const templateStepLabel = document.createElement('template');
    templateStepLabel.innerHTML =
      '<li class="page-item"><span class="page-link"></span></li>';

    const templateStepIndicator = document.createElement('template');
    templateStepIndicator.innerHTML =
      '<li class="page-item"><a class="page-link" href="#"></a></li>';

    const templateStepIndicatorControlDisabled = document.createElement(
      'template'
    );
    templateStepIndicator.innerHTML =
      '<li class="page-item"><span class="page-link"></span></li>';

    function cloneTemplateLabel(className) {
      const element = templateStepLabel.content.cloneNode(true).firstChild;
      element.querySelector('.page-link').classList.add(className);
      return element;
    }

    function createStepLabel(el, position) {
      const [prevPageInfo, nextPageInfo] = el.querySelectorAll('.page-info');

      if (position === 'prev' && prevPageInfo) {
        prevPageInfo.firstChild.before(cloneTemplateLabel('prev-step'));
      }
      if (position === 'next' && nextPageInfo) {
        nextPageInfo.appendChild(cloneTemplateLabel('next-step'));
      }
    }

    function createStepIndicator(el, hasControl, active) {
      const container = el.querySelector('.page-position');
      if (!container) return;

      const template = hasControl
        ? templateStepIndicator
        : templateStepIndicatorControlDisabled;
      const element = template.content.cloneNode(true).firstChild;
      if (active) element.classList.add('active');

      container.appendChild(element);
    }

    function clearStepIndicators(el) {
      const container = el.querySelector('.page-position');
      if (!container) return;
      container.innerHTML = '';
    }

    return {
      createStepLabel,
      createStepIndicator,
      clearStepIndicators,
    };
  })();

  class PaginationSlider {
    constructor(el, options) {
      const defaultOptions = {
        stepNames: undefined, // ex: ['Etapa 1', 'Etapa 2', 'Etapa 3']
        finish: undefined, // ex: 'Enviar', se preenchido indica que após os steps haverá etapa de finalização.
        controlIndicator: true, // ex: false, se 'false', não permite trocar de step ao clicar no item
        initialStepIndex: 0,
      };

      this.el = el;
      this.options = { ...defaultOptions, ...options };
      const stepsAttribute = this.el.dataset.steps; // atributo data-steps possui os labels separados por ','
      this.options.stepNames =
        (stepsAttribute && stepsAttribute.split(',')) ||
        defaultOptions.stepNames;
      this.options.finish = this.el.dataset.submit; // atributo data-submit com valor do label;
      this.options.controlIndicator =
        this.el.dataset.controlIndicator || defaultOptions.controlIndicator; // atributo data-control-indicator;

      this.pagePositionItems = this.el.querySelectorAll(
        '.page-position .page-item'
      );

      this.stepIndexObservable = new Observable(this.options.initialStepIndex);

      this.prepareLabelsAndIndicators();

      this.prevStepLabel = this.el.querySelector(`${infoSelector} .prev-step`);
      this.nextStepLabel = this.el.querySelector(`${infoSelector} .next-step`);

      this.btnPrev = this.el.querySelector('.button-prev');
      this.btnNext = this.el.querySelector('.button-next');

      this.lastItemIndex = this.pagePositionItems.length - 1;

      this.init();
    }

    /**
     * Prepara a estrutura do componente caso nem todos elementos existam no html
     * - Espaço para os labels
     * - Indicadores de step ativo
     */
    prepareLabelsAndIndicators() {
      const self = this;
      const componentEl = this.el;

      function checkLabelElement(type) {
        return componentEl.querySelector(`.page-info .page-item .${type}-step`);
      }

      function prepareLabels() {
        if (!checkLabelElement('prev'))
          PaginationSliderUtils.createStepLabel(componentEl, 'prev');
        if (!checkLabelElement('next'))
          PaginationSliderUtils.createStepLabel(componentEl, 'next');
      }

      function checkIndicatorsElements() {
        const pagePositionEl = componentEl.querySelector(`.page-position`);
        if (!pagePositionEl) return null;
        return (
          self.options.stepNames &&
          self.pagePositionItems.length === self.options.stepNames.length
        );
      }

      function prepareIndicators() {
        if (checkIndicatorsElements() === false) {
          PaginationSliderUtils.clearStepIndicators(componentEl);
          const { stepNames, controlIndicator } = self.options;
          stepNames.forEach((step, index) => {
            PaginationSliderUtils.createStepIndicator(
              componentEl,
              controlIndicator,
              index === self.stepIndexObservable.value
            );
          });
          self.pagePositionItems = componentEl.querySelectorAll(
            '.page-position .page-item'
          );
        }
      }

      prepareLabels();
      prepareIndicators();
    }

    updateWidget(stepIndex) {
      this.updateStepIndicator(stepIndex);
      this.updateStateButtons(stepIndex);
      this.updateLabels(stepIndex);
    }

    /* Atualiza labels de anterior / próximo */
    updateLabels(stepIndex) {
      if (!this.options.stepNames) return;
      /* Se a paginação tiver finalização / submissão, o label .next-step será
        o valor do atributo data-submit, e o .prev-step será o label da última etapa */
      if (stepIndex === 0) {
        this.prevStepLabel.classList.add('d-none');
      }
      if (stepIndex === this.lastItemIndex) {
        if (this.options.finish != null) {
          this.nextStepLabel.classList.add('d-none');
          this.btnNext.classList.remove('btn-primary-icon');
          this.btnNext.classList.add('btn-primary');
          this.btnNext.innerText = this.options.finish;
        } else {
          this.nextStepLabel.classList.add('d-none');
        }
      } else {
        if (this.btnNext.classList.contains('btn-primary')) {
          this.btnNext.classList.remove('btn-primary');
          this.btnNext.innerText = '';
          this.btnNext.classList.add('btn-primary-icon');

          const icone = document.createElement('i');
          icone.classList.add('fas', 'fa-chevron-right');

          this.btnNext.appendChild(icone);
        }
        this.nextStepLabel.textContent = this.options.stepNames[stepIndex + 1];
        this.nextStepLabel.classList.remove('d-none');
      }
      this.prevStepLabel.textContent = this.options.stepNames[stepIndex - 1];
      this.prevStepLabel.classList.remove('d-none');
    }

    /* Atualiza posição da etapa atual nos indicadores */
    updateStepIndicator(stepIndex) {
      Array.from(this.pagePositionItems).forEach(function(el, index) {
        if (index === stepIndex) {
          el.classList.add('active');
        } else {
          el.classList.remove('active');
        }
      });
    }

    /* Atualiza botões e exibe ou esconde labels.  */
    updateStateButtons(stepIndex) {
      const enableButton = button => {
        button.removeAttribute('disabled');
        button.classList.remove('disabled');
      };
      const disableButton = button => {
        button.classList.add('disabled');
        button.setAttribute('disabled', 'disabled');
      };
      const isFirstStep = stepIndex === 0;
      if (isFirstStep) {
        disableButton(this.btnPrev);
      } else {
        enableButton(this.btnPrev);
      }

      const isLastStep = stepIndex === this.lastItemIndex;
      const hasSubmitAction = this.options.finish != null;
      // eslint-disable-next-line no-unused-expressions
      isLastStep && !hasSubmitAction
        ? disableButton(this.btnNext)
        : enableButton(this.btnNext);
    }

    init() {
      const self = this;
      this.stepIndexObservable.subscribe(index => self.updateWidget(index));

      this.updateLabels(this.stepIndexObservable.value);

      /* Cria customEvents */
      function createEvent(stepIndex, type) {
        return new CustomEvent(`pagination-slide:${type}`, {
          detail: {
            index: stepIndex,
          },
          cancelable: true,
        });
      }
      const paginationSubmitEvent = new CustomEvent('pagination-slide:submit');

      const { el } = this;
      Array.from(this.pagePositionItems).forEach(function(pageEl, index) {
        const link = pageEl.querySelector('.page-link');
        if (el.dataset.controlIndicator === 'false') return;

        link.addEventListener('click', e => {
          e.preventDefault();
          if (el.dispatchEvent(createEvent(index, 'page'))) {
            self.stepIndexObservable.value = index;
          }
        });
      });

      this.btnPrev.addEventListener('click', e => {
        e.preventDefault();
        const stepIndex = Math.max(0, this.stepIndexObservable.value - 1);

        if (this.el.dispatchEvent(createEvent(stepIndex, 'previous'))) {
          this.stepIndexObservable.value = stepIndex;
        }
      });

      this.btnNext.addEventListener('click', e => {
        e.preventDefault();

        if (
          this.stepIndexObservable.value === this.lastItemIndex &&
          this.options.finish != null
        ) {
          this.el.dispatchEvent(paginationSubmitEvent);
        } else {
          const stepIndex = Math.min(
            this.lastItemIndex,
            this.stepIndexObservable.value + 1
          );
          if (this.el.dispatchEvent(createEvent(stepIndex, 'next'))) {
            this.stepIndexObservable.value = stepIndex;
          }
        }
      });
    }
  }

  function init() {
    document.querySelectorAll(selector).forEach(pagination => {
      new PaginationSlider(pagination);
    });
  }
  init();
})();

// ************************* FIM DO pagination-slide.js *************************
