(function() {
  const buttonsTagsConteudo = document.querySelectorAll(
    'button.tag-conteudo, div.tag-conteudo'
  );

  if (!buttonsTagsConteudo) return;

  const handlerTagClick = button => {
    const inputCheckboxElement = button.querySelector('input[type="checkbox"]');
    const i = button.getElementsByClassName('tag-conteudo__footer')[0]
      .children[0];
    if (inputCheckboxElement) {
      const data = button.getAttribute('data-tag-checkbox');
      if (data === 'false') {
        button.setAttribute('data-tag-checkbox', 'true');
        inputCheckboxElement.checked = true;
      } else {
        button.setAttribute('data-tag-checkbox', 'false');
        inputCheckboxElement.checked = false;
      }
    } else if (button.getAttribute('data-activeted') === 'true') {
      button.setAttribute('data-activeted', 'false');
      i.classList.remove('fa-times');
      i.classList.add('fa-check');
    } else {
      button.setAttribute('data-activeted', 'true');
      i.classList.remove('fa-times');
      i.classList.add('fa-check');
    }
  };

  const handlerMouseOuver = button => {
    if (button.getAttribute('data-activeted') === 'true') {
      const i = button.getElementsByClassName('tag-conteudo__footer')[0]
        .children[0];
      if (!i.classList.contains('fa-times')) {
        i.classList.remove('fa-check');
        i.classList.add('fa-times');
      }
    }
  };

  const handlerMouseLeave = button => {
    if (button.getAttribute('data-activeted') === 'true') {
      const i = button.getElementsByClassName('tag-conteudo__footer')[0]
        .children[0];
      if (!i.classList.contains('fa-check')) {
        i.classList.remove('fa-times');
        i.classList.add('fa-check');
      }
    }
  };

  const tagConteudoIconChangeFeatures = tagConteudoButton => {
    const inputCheckboxElement = tagConteudoButton.querySelector(
      'input[type="checkbox"]:checked'
    );
    if (inputCheckboxElement) {
      tagConteudoButton.setAttribute('data-tag-checkbox', 'true');
    }

    tagConteudoButton.addEventListener('click', e => {
      if (e.target.tagName.toLowerCase() === 'label') {
        e.preventDefault();
      }
      handlerTagClick(tagConteudoButton);
    });

    tagConteudoButton.addEventListener('mouseover', e => {
      e.preventDefault();
      handlerMouseOuver(tagConteudoButton);
    });

    tagConteudoButton.addEventListener('mouseleave', e => {
      e.preventDefault();
      handlerMouseLeave(tagConteudoButton);
    });

    tagConteudoButton.addEventListener('keypress', e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handlerTagClick(tagConteudoButton);
      }
    });
  };

  buttonsTagsConteudo.forEach(buttonTagConteudo => {
    tagConteudoIconChangeFeatures(buttonTagConteudo);
  });
  window.tagConteudoIconChangeFeatures = tagConteudoIconChangeFeatures;
})();

(function() {
  const filterTags = Array.from(
    document.querySelectorAll([
      'button.tag-filtro',
      '.card-item .card-simple .card-body',
    ])
  );
  const filterTagsWithTooltip = filterTags.filter(filterTag =>
    filterTag.querySelector('span.title')
  );

  filterTagsWithTooltip.forEach(filterTagWithTooltip => {
    const tooltip = filterTagWithTooltip.querySelector('span.title');

    filterTagWithTooltip.addEventListener('mousemove', e => {
      const tagRect = filterTagWithTooltip.getBoundingClientRect();

      tooltip.style.left = `${e.pageX - tagRect.left}px`;
      tooltip.style.top = `${e.pageY - tagRect.top}px`;
    });
  });

  const tooltipMouseMove = tagWithTooltip => {
    const tooltip = tagWithTooltip.querySelector('span.title');

    tagWithTooltip.addEventListener('mousemove', e => {
      const tagRect = tagWithTooltip.getBoundingClientRect();

      tooltip.style.left = `${e.pageX - tagRect.left}px`;
      tooltip.style.top = `${e.pageY - tagRect.top}px`;
    });
  };

  window.tooltipMouseMove = tooltipMouseMove;
})();

// ************************* FIM DO tags.js *************************
