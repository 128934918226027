import './global';
import * as Commons from './commons';
import './utils';
import Overlay from './componentes/overlay';
import './custom-select';
import './overwrite-add-event-listeners';
import './nav_menu';
import './copyright-current-year';
import './scroll-up';
import './error_page';
import './paleta';
import './dropdown';
import './form';
import './vixe';
import './table';
import './barragoverno-hack';
import './accordion';
import './vlibras-hack';
import './card';
import './calendar';
import './header';
import './header_mobile';
import './galeria-imagem';
import './galeria-video';
import './tooltip';
import './navegacao_lateral';
import './navegacao_responsiva';
import './tinymce';
import './tags';
import './siedi_page';
import './chart';
import './progress';
import './assinador_ufrn_page';
import './eligere_page';
import './memorial_eternos_page';
import './timeline';
import './pagination-numeral';
import './pagination-slide';
import './button';
import './popover';
import './toast';
import './cookies-consent-dialog';
import './overlay_loading';
import './skip-menu';
import './step';
import './wizard';

import { DSGlobal, installGlobal } from './global';
import Datepicker from './componentes/datepicker';
import ToggleModeLibras from './componentes/toggle-mode-libras';
import MenuLibras from './componentes/menu-libras';
import InputField from './componentes/input';

const init = (element?: HTMLElement | Document) => {
  const root = element || document;
  ToggleModeLibras.init();
  MenuLibras.init();
  InputField.init();
  Datepicker.init(root);
  DSGlobal.initPopover(root);
  DSGlobal.initToast(root);
  DSGlobal.initTooltip(root);
  window.updateComponents();
  DSGlobal.buttonLoading.init(root);
};

installGlobal(null, { init });
DSGlobal.init();

export default {
  Commons,
  Overlay,
  ToggleModeLibras,
  MenuLibras,
  InputField,
  Datepicker,
  init,
};
