import { installGlobal } from '../../global.js';
import { COMPONENT_NAME } from './constants.js';
import './datepicker.factory.js';
import './datepicker.utils.js';
import './datepickerCombobox.js';
// @ts-ignore
import { DatepickerCombobox, options } from './datepickerCombobox.js';

export interface Datepicker {
  init: (element?: HTMLElement | Document) => void;
  outputFormat: string;
}

export function init(element?: HTMLElement | Document) {
  const root = element || document;
  root.querySelectorAll('[data-js-datepicker]').forEach((element) => {
    new DatepickerCombobox(element);
  });
}

const methods = {
  init: init,
  ...options,
};

installGlobal<Datepicker>(COMPONENT_NAME, methods);

export default methods;
