function hexToRgba(hex: string, rgba = 1) {
  let c: string[];
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    const hexValue: any = `0x${c.join('')}`;
    return `rgba(${[
      (hexValue >> 16) & 255,
      (hexValue >> 8) & 255,
      hexValue & 255,
    ].join(',')},${rgba})`;
  }
  throw new Error('Bad Hex');
}

export default hexToRgba;
