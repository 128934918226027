(async function() {
  /* ----- VERIFICAÇÃO DO MÊS -------------------------
  | Esse trecho de código verifica se o mês é menor que o mes 10 do ano,
  | caso for, adiciona um zero a mais na string.
  *-------------------------------------------------------------------*/
  const verificaMesMenor10 = (variavelQueIraMudar, dataExpecifica) => {
    const li = variavelQueIraMudar;
    if (dataExpecifica.mes < 10) {
      li.innerHTML = `${dataExpecifica.dia.toString()}/0${dataExpecifica.mes.toString()} ${
        dataExpecifica.evento
      }`;
    } else {
      li.innerHTML = `${dataExpecifica.dia.toString()}/${dataExpecifica.mes.toString()} ${
        dataExpecifica.evento
      }`;
    }
  };
  const addZeroIntoMontheOrDay = ev => {
    let data = '';
    if (ev.date.getMonth() + 1 < 10) {
      if (ev.date.getDate() < 10) {
        data = `0${ev.date.getDate()}/0${ev.date.getMonth() +
          1}/${ev.date.getFullYear()}`;
      } else {
        data = `${ev.date.getDate()}/0${ev.date.getMonth() +
          1}/${ev.date.getFullYear()}`;
      }
    } else if (ev.date.getDate() < 10) {
      data = `0${ev.date.getDate()}/${ev.date.getMonth() +
        1}/${ev.date.getFullYear()}`;
    } else {
      data = `${ev.date.getDate()}/${ev.date.getMonth() +
        1}/${ev.date.getFullYear()}`;
    }
    return data;
  };
  /* ----- CRIA LI -------------------------
  | Esse trecho de código cria li de acordo com a defição do tipo vinda
  | das datas da API, adicindo ao Ul pai, como também, .
  *-------------------------------------------------------------------*/
  const criaLi = (dataDaApi, ulPai) => {
    const dataTipo = dataDaApi.tipo.toLowerCase();
    const liFilho = document.createElement('li');
    const ul = ulPai;
    liFilho.classList.add(dataTipo);
    verificaMesMenor10(liFilho, dataDaApi);
    ul.append(liFilho);
    return dataTipo;
  };

  const initEvents = (datasDaApi, dadoDaData, listaPai) => {
    let classeQueVolta = '';
    listaPai.innerHTML = '';
    datasDaApi.forEach(data => {
      const isSameDate =
        data.mes === dadoDaData.getMonth() + 1 &&
        data.ano === dadoDaData.getFullYear();
      if (isSameDate) {
        classeQueVolta = criaLi(data, listaPai);
      }
    });
    return classeQueVolta;
  };

  // retorna o nome que será usada como classe para alterar a cor do dia no calendário
  const addClassColorDay = dataDaApi => {
    const dataTipo = dataDaApi.tipo.toLowerCase();
    return dataTipo;
  };

  /* ----- MUDA COR DA DATA -------------------------
  | Esse trecho de código percorre por todas as datas vinda da API,
  | e retorna a classe que será usada como cor na data do calendário.
  *-------------------------------------------------------------------*/
  const changeColorDay = (datasDaApi, dadoDaData) => {
    let classeQueVolta = '';
    datasDaApi.forEach(data => {
      const isSameDate =
        data.ano === dadoDaData.getFullYear() &&
        data.mes === dadoDaData.getMonth() + 1 &&
        data.dia === dadoDaData.getDate();
      if (isSameDate) {
        classeQueVolta = addClassColorDay(data);
      }
    });
    return classeQueVolta;
  };

  /* ----- Calendários -------------------------
  | Esse trecho de código cria os tipos de calendarios diferentes
  | existentes no projeto: Calendário Componente e Calendário Input
  *-------------------------------------------------------------------*/

  const calendarios = document.querySelectorAll('.calendar-builds');
  const calendariosInputDeprecated = document.querySelectorAll(
    '.calendar-input'
  );
  const calendariosInput = document.querySelectorAll('.input-datepicker.date');
  let retorno = '';

  async function loadData(apiUrl) {
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data;
  }

  /* ----- Calendários Componente -------------------------
  | Esse trecho de código cria o calendário que será utilizado como
  | componente no projeto.
  *-------------------------------------------------------------------*/
  calendarios.forEach(async calendario => {
    /* ----- Pega datas da API-------------------------
    |  Esse trecho de código gera as datas da api encontradas no caminho
    |  https://static.info.ufrn.br/current/json/data-api.json.
    |  Caso, no componente haja o data-api, ele fará a busca, caso não,
    |  ele criará o componente sem dados vindos de alguma api.
    *-------------------------------------------------------------------*/
    /**
     * Renomear os dados das funções para ficarem de acordo com os dados da
     * API a ser utilizada.
     *
     * Modelo da API utilizada no exemplo: https://static.info.ufrn.br/current/json/data-api.json
     */
    const apiUrl = calendario.dataset.api;
    let datasApi = [];
    if (apiUrl) {
      datasApi = await loadData(apiUrl);
    }
    const ul = document.createElement('ul');
    const hr = document.createElement('hr');
    hr.setAttribute('aria-hidden', true);

    /* O atributo [data-show-list] determina se a lista deve ser exibida ou não.
      Por questão de retrocompatibilidade, a ausência do atributo implica que a lista será mostrada. */
    const showsList =
      calendario.getAttribute('data-show-list') === 'true' ||
      !calendario.hasAttribute('data-show-list');
    let selectedDate = new Date();
    $(calendario)
      .datepicker({
        language: 'pt-BR',
        todayHighlight: true,
        keyboardNavigation: true,

        templates: {
          leftArrow:
            '<i class="fas fa-chevron-left" aria-label="Anterior"></i>',
          rightArrow:
            '<i class="fas fa-chevron-right" aria-label="Próximo"></i>',
        },
        beforeShowDay(date) {
          const divDosDias = calendario.getElementsByClassName(
            'datepicker-days'
          );
          retorno = changeColorDay(datasApi, date, ul);
          const result = {
            classes: retorno,
          };

          // Este trecho de código trata da acessibilidade do componente
          const diaSemanaEl = calendario.querySelectorAll(
            '.datepicker-days thead .dow'
          );

          Array.from(diaSemanaEl).forEach((el, indice) => {
            if (el.querySelector('.day__weekend__min') === null) {
              const span = document.createElement('span');
              span.setAttribute('aria-hidden', true);
              span.classList.add('day__weekend__min');
              span.textContent = el.textContent;
              el.textContent = '';
              el.appendChild(span.cloneNode(true));
            }
            if (el.querySelector('.day__wekend__hid') === null) {
              const { days } = $.fn.datepicker.dates['pt-BR'];
              const span = document.createElement('span');
              span.classList.add('day__wekend__hid');
              span.setAttribute('aria-label', days[indice]);
              el.appendChild(span.cloneNode(true));
            }
          });

          if (divDosDias[0].style.display !== 'none') {
            const isToday = date.getDate() === new Date().getDate();
            if (isToday) {
              result.tooltip = 'hoje';
            }
          } else {
            ul.innerHTML = '';
          }
          return result;
        },
        beforeShowMonth(date) {
          // Verifica se o mês selecionado é igual ao mês atual que está no calendário
          // se sim ele cahama a função para criar os li referente aquele mês
          if (date.getMonth() === selectedDate.getMonth()) {
            retorno = initEvents(datasApi, date, ul);
          }
          const result = {
            classes: retorno,
          };
          return result;
        },
      })
      .on('changeDate', function(ev) {
        const dateEvent = new CustomEvent('datePickerChangeDate', {
          detail: ev.date,
        });
        calendario.dispatchEvent(dateEvent);
        selectedDate = ev.date;

        $(calendario).datepicker('update', addZeroIntoMontheOrDay(ev));
      })
      .on('changeMonth', function(ev) {
        selectedDate = ev.date;
      });

    /* A lista e a horizontal line são adicionadas ao calendário somente se showList === true */

    if (showsList === true) {
      const apagaUl = () => {
        ul.innerHTML = '';
      };
      calendario.append(hr);
      calendario.append(ul);
      Array.from(calendario.querySelectorAll('th.next')).forEach(item => {
        item.addEventListener('click', apagaUl);
      });
      Array.from(calendario.querySelectorAll('th.prev')).forEach(item => {
        item.addEventListener('click', apagaUl);
      });
      Array.from(calendario.querySelectorAll('th.datepicker-switch')).forEach(
        item => {
          item.addEventListener('click', apagaUl);
        }
      );
    }
  });

  /* ----- Calendários Input -------------------------
  |  Esse trecho de código cria o calendário que será utilizado junto do
  |  input para o tipo data.
  *-------------------------------------------------------------------*/
  calendariosInput.forEach(calendarioInput => {
    const containerDatepicker =
      document.querySelector(calendarioInput.dataset.container) || undefined;
    if (containerDatepicker) {
      containerDatepicker.style.position = 'relative';
    }
    calendarioInput.parentElement.style.position = 'relative';

    $(calendarioInput)
      .datepicker({
        language: 'pt-BR',
        todayHighlight: true,
        autoclose: true,
        orientation: 'bottom',

        container: containerDatepicker,

        templates: {
          leftArrow:
            '<i class="fas fa-chevron-left" aria-label="anterior"></i>',
          rightArrow:
            '<i class="fas fa-chevron-right" aria-label="próximo"></i>',
        },
      })

      .on('changeDate', e => {
        let data = '';
        if (e.date.getMonth() + 1 < 10) {
          if (e.date.getDate() < 10) {
            data = `0${e.date.getDate()}/0${e.date.getMonth() +
              1}/${e.date.getFullYear()}`;
          } else {
            data = `${e.date.getDate()}/0${e.date.getMonth() +
              1}/${e.date.getFullYear()}`;
          }
        } else if (e.date.getDate() < 10) {
          data = `0${e.date.getDate()}/${e.date.getMonth() +
            1}/${e.date.getFullYear()}`;
        } else {
          data = `${e.date.getDate()}/${e.date.getMonth() +
            1}/${e.date.getFullYear()}`;
        }
        const input = e.currentTarget.querySelector('input');
        input.value = data;
        input.classList.add('filled');
        input.focus();
      });

    const diaSemanaEl = calendarioInput.querySelectorAll(
      '.datepicker-days thead .dow'
    );
    if (!diaSemanaEl) {
      return;
    }
    Array.from(diaSemanaEl).forEach((el, indice) => {
      if (el.querySelector('.day__weekend__min') === null) {
        const span = document.createElement('span');
        span.setAttribute('aria-hidden', true);
        span.classList.add('day__weekend__min');
        span.textContent = el.textContent;
        el.textContent = '';
        el.appendChild(span.cloneNode(true));
      }
      if (el.querySelector('.day__wekend__hid') === null) {
        const { days } = $.fn.datepicker.dates['pt-BR'];
        const span = document.createElement('span');
        span.classList.add('day__wekend__hid');
        span.setAttribute('aria-label', days[indice]);
        el.appendChild(span.cloneNode(true));
      }
    });
  });

  calendariosInputDeprecated.forEach(calendarioInput => {
    $(calendarioInput)
      .datepicker({
        language: 'pt-BR',
        todayHighlight: true,
        templates: {
          leftArrow:
            '<i class="fas fa-chevron-left" aria-label="anterior"></i>',
          rightArrow:
            '<i class="fas fa-chevron-right" aria-label="próximo"></i>',
        },
      })
      .on('changeDate', e => {
        let data = '';
        if (e.date.getMonth() + 1 < 10) {
          if (e.date.getDate() < 10) {
            data = `0${e.date.getDate()}/0${e.date.getMonth() +
              1}/${e.date.getFullYear()}`;
          } else {
            data = `${e.date.getDate()}/0${e.date.getMonth() +
              1}/${e.date.getFullYear()}`;
          }
        } else if (e.date.getDate() < 10) {
          data = `0${e.date.getDate()}/${e.date.getMonth() +
            1}/${e.date.getFullYear()}`;
        } else {
          data = `${e.date.getDate()}/${e.date.getMonth() +
            1}/${e.date.getFullYear()}`;
        }

        const formGroup =
          e.currentTarget.parentElement.parentElement.parentElement;

        const input = formGroup.querySelector('input');
        input.value = data;
        input.classList.add('filled');
        const button = formGroup.querySelector('button');
        if (button) {
          button.setAttribute('aria-expanded', 'false');
        }
        formGroup.classList.remove('show');
        const calendario = formGroup.querySelector('.dropdown-menu');
        calendario.classList.remove('show');
      });

    const diaSemanaEl = calendarioInput.querySelectorAll(
      '.datepicker-days thead .dow'
    );
    if (!diaSemanaEl) {
      return;
    }
    Array.from(diaSemanaEl).forEach((el, indice) => {
      if (el.querySelector('.day__weekend__min') === null) {
        const span = document.createElement('span');
        span.setAttribute('aria-hidden', true);
        span.classList.add('day__weekend__min');
        span.textContent = el.textContent;
        el.textContent = '';
        el.appendChild(span.cloneNode(true));
      }
      if (el.querySelector('.day__wekend__hid') === null) {
        const { days } = $.fn.datepicker.dates['pt-BR'];
        const span = document.createElement('span');
        span.classList.add('day__wekend__hid');
        span.setAttribute('aria-label', days[indice]);
        el.appendChild(span.cloneNode(true));
      }
    });
  });
})();
// ************************* FIM DO calendar.js *************************
