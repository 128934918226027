(function(Cosmos) {
  const SMALL_DEVICE_BREAKPOINT = 767.98;
  const isSmallDevice = () => $(window).width() < SMALL_DEVICE_BREAKPOINT;

  let storeWindowSize = isSmallDevice();

  function createPopover(html) {
    $(html)
      .find('[data-toggle="popover"]')
      .popover({
        placement: (_, element) => {
          const htmlPopoverPlacement = element.dataset.placement;

          const defaultPlacement = 'right';
          const smallDevicePlacement = 'bottom';

          // forma em small devices abrir sempre para baixo
          return isSmallDevice()
            ? smallDevicePlacement
            : htmlPopoverPlacement || defaultPlacement;
        },
      });
  }

  function destroyPopover(popover) {
    popover.popover('dispose');
  }

  function handleKeypress(e) {
    $(e.target).popover('toggle');
  }

  function initPopover(el) {
    const html = el || document;
    createPopover(html);

    const popover = $(html).find('[data-toggle="popover"]');
    popover.on('keypress', handleKeypress);

    window.addEventListener('resize', function() {
      const hasBreakpointChanged = isSmallDevice() !== storeWindowSize;
      if (hasBreakpointChanged) {
        destroyPopover(popover);
        createPopover(html);
        storeWindowSize = isSmallDevice();
      }
    });
  }

  Cosmos.initPopover = initPopover;

  Cosmos.initPopover();
})(window.Cosmos);

// ************************* FIM DO popover.js *************************
