function createHeaderPrevButton() {
  const prevButtonIcon = document.createElement('i');
  prevButtonIcon.classList.add('fa-solid');
  prevButtonIcon.classList.add('fa-angle-left');

  const prevButton = document.createElement('button');
  prevButton.classList.add('header__buttons');
  prevButton.classList.add('header__previous');
  prevButton.setAttribute('type', 'button');
  prevButton.appendChild(prevButtonIcon);

  return prevButton;
}

function createHeaderNextButton() {
  const nextButtonIcon = document.createElement('i');
  nextButtonIcon.classList.add('fa-solid');
  nextButtonIcon.classList.add('fa-angle-right');

  const nextButton = document.createElement('button');
  nextButton.classList.add('header__buttons');
  nextButton.classList.add('header__next');
  nextButton.setAttribute('type', 'button');
  nextButton.appendChild(nextButtonIcon);

  return nextButton;
}

function createHeaderMainButton() {
  const monthYearButton = document.createElement('button');
  monthYearButton.classList.add('header__currentMonthYear');
  monthYearButton.setAttribute('type', 'button');

  return monthYearButton;
}

function createPeriodTitle() {
  const currentPeriod = document.createElement('h3');
  currentPeriod.classList.add('header__currentPeriod');
  currentPeriod.setAttribute('aria-live', 'polite');

  return currentPeriod;
}

export function createHeaderModal(isPeriod = false) {
  const header = document.createElement('div');
  header.classList.add('datepicker__header');

  const prevButton = createHeaderPrevButton();
  const monthYearButton = isPeriod
    ? createPeriodTitle()
    : createHeaderMainButton();

  const nextButton = createHeaderNextButton();

  header.appendChild(prevButton);
  header.appendChild(monthYearButton);
  header.appendChild(nextButton);
  return header;
}

export function createCalendarHeaderModal() {
  const header = createHeaderModal();

  const [prevButton, mainButton, nextButton] =
    header.querySelectorAll('button');
  mainButton.setAttribute('aria-label', 'escolha um mês');
  mainButton.setAttribute('title', 'escolha um mês');
  prevButton.setAttribute('aria-label', 'mês anterior');
  prevButton.setAttribute('title', 'mês anterior');
  nextButton.setAttribute('aria-label', 'mês posterior');
  nextButton.setAttribute('title', 'mês posterior');

  return header;
}

export function createMonthsHeaderModal() {
  const header = createHeaderModal();

  const [prevButton, mainButton, nextButton] =
    header.querySelectorAll('button');
  mainButton.setAttribute('aria-label', 'escolha um período');
  mainButton.setAttribute('title', 'escolha um período');
  prevButton.setAttribute('aria-label', 'ano anterior');
  prevButton.setAttribute('title', 'ano anterior');
  nextButton.setAttribute('aria-label', 'ano posterior');
  nextButton.setAttribute('title', 'ano posterior');

  return header;
}

export function createPeriodHeaderModal() {
  const header = createHeaderModal(true);

  const [prevButton, nextButton] = header.querySelectorAll('button');
  prevButton.setAttribute('aria-label', 'período anterior');
  prevButton.setAttribute('title', 'período anterior');
  nextButton.setAttribute('aria-label', 'período posterior');
  nextButton.setAttribute('title', 'período posterior');

  return header;
}

export function createFooterModal() {
  // const footer = document.createElement("div");
  // footer.classList.add("datepicker__footer");

  // const cancelButton = document.createElement("button");
  // cancelButton.classList.add("footer__buttons");
  // cancelButton.classList.add("footer__cancelButton");
  // cancelButton.textContent = "Cancelar";

  // const confirmButton = document.createElement("button");
  // confirmButton.classList.add("footer__buttons");
  // confirmButton.classList.add("footer__confirmButton");
  // confirmButton.textContent = "Confirmar";

  // footer.appendChild(cancelButton);
  // footer.appendChild(confirmButton);

  const message = document.createElement('div');
  message.classList.add('modal__messages');
  message.classList.add('sr-only');
  message.setAttribute('aria-live', 'polite');
  message.textContent = 'Navegue entre as datas usando as setas';

  return message;
}

function createCalendarTableHeader() {
  const weekdaysArray = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ];

  const thead = document.createElement('thead');
  thead.classList.add('datepicker__dateTable__tableHeader');
  const theadTr = document.createElement('tr');
  theadTr.setAttribute('role', 'presentation');

  weekdaysArray.forEach((weekday) => {
    const th = document.createElement('th');
    th.setAttribute('scope', 'col');
    th.setAttribute('abbr', `${weekday}`);
    th.setAttribute('title', `${weekday}`);
    th.textContent = `${weekday.substring(0, 3)}`;
    theadTr.appendChild(th);
  });

  thead.appendChild(theadTr);
  return thead;
}

function createCalendarTableBody() {
  const tbody = document.createElement('tbody');
  tbody.classList.add('datepicker__dateTable__tableBody');

  const MAX_COLS = 7;
  const MAX_LINES = 6;

  for (let line = 0; line < MAX_LINES; line++) {
    const tr = document.createElement('tr');
    for (let col = 0; col < MAX_COLS; col++) {
      const td = document.createElement('td');
      td.setAttribute('tabindex', '-1');
      tr.appendChild(td);
    }
    tbody.appendChild(tr);
  }

  return tbody;
}

export function createCalendarTable() {
  const table = document.createElement('table');
  table.classList.add('datepicker__dateTable');
  table.setAttribute('role', 'grid');

  const thead = createCalendarTableHeader();
  const tbody = createCalendarTableBody();

  table.appendChild(thead);
  table.appendChild(tbody);
  return table;
}

export function createMonthsPeriodTable() {
  const table = document.createElement('table');
  table.classList.add('datepicker__monthsPeriodTable');
  table.setAttribute('role', 'grid');

  const tbody = document.createElement('tbody');
  tbody.classList.add('datepicker__monthsPeriodTable__tableBody');

  const LINES = 3;
  const COLUMS = 4;

  for (let line = 0; line < LINES; line++) {
    const tr = document.createElement('tr');
    for (let col = 0; col < COLUMS; col++) {
      const td = document.createElement('td');
      td.classList.add('datepicker__monthsPeriodTable__item');
      td.setAttribute('tabindex', '-1');
      tr.appendChild(td);
    }
    tbody.appendChild(tr);
  }

  table.appendChild(tbody);
  return table;
}

export function createModalWrapper() {
  const modalWrapper = document.createElement('div');
  modalWrapper.classList.add('datepicker__modal__wrapper', 'open');
  modalWrapper.setAttribute('role', 'dialog');
  modalWrapper.setAttribute('aria-modal', 'true');
  modalWrapper.setAttribute('aria-label', 'selecione a data');

  return modalWrapper;
}

export function createInputDatepicker() {
  const datepickerInputWrapper = document.createElement('div');
  datepickerInputWrapper.classList.add('datepicker__input__wrapper');
  datepickerInputWrapper.classList.add('input-datepicker');
  datepickerInputWrapper.classList.add('date');

  const inputEditable = document.createElement('div');
  inputEditable.setAttribute('contenteditable', 'true');
  inputEditable.setAttribute('placeholder', 'dia/mês/ano');
  inputEditable.setAttribute('aria-autocomplete', 'none');
  inputEditable.setAttribute('role', 'combobox');
  inputEditable.setAttribute('aria-expanded', 'false');
  inputEditable.setAttribute('aria-haspopup', 'dialog');
  inputEditable.setAttribute('aria-controls', 'datepicker__modal');
  inputEditable.setAttribute('maxlength', '10');
  inputEditable.classList.add('form-control');
  inputEditable.classList.add('form-datepicker');

  const calendarIcon = document.createElement('i');
  calendarIcon.classList.add('far');
  calendarIcon.classList.add('fa-calendar-alt');

  const inputButton = document.createElement('button');
  inputButton.setAttribute('class', 'input-group-addon');
  inputButton.setAttribute('type', 'button');
  inputButton.setAttribute('tabindex', '-1');
  inputButton.setAttribute('aria-label', 'Escolha uma data');
  inputButton.setAttribute('title', 'Escolha uma data');
  inputButton.appendChild(calendarIcon);

  datepickerInputWrapper.appendChild(inputEditable);
  datepickerInputWrapper.appendChild(inputButton);
  return datepickerInputWrapper;
}
