(function() {
  /* ----- Impede o fechamento do Dropdown -----------------------------------------------
  |  Esse trecho de codigo gera uma evento de click nos dropdows
  |  que impedem os mesmos de fecharem caso recebam um click dentro deles.
  *-------------------------------------------------------------------*/
  const menusDropdowns = document.querySelectorAll(
    '.dropdown-tag-selection .dropdown-menu'
  );

  menusDropdowns.forEach(dropdown => {
    dropdown.addEventListener('click', e => {
      e.stopPropagation();
    });
  });

  /** ATUALIZA LOCALIZAÇÃO DE ABERTURA DO DROPDOWN
   *
   * Função para atualização do local de abertura da posição do dropdown
   *
   * 1ª paramentro => botão que abre o dropdown
   */
  const updateDropdownPosition = dropdown => {
    $(dropdown).dropdown('update');
  };

  window.updateDropdownPosition = updateDropdownPosition;

  /** FECHA DROPDOWN
   *
   * Caso deseje fechar o dropdown apos algun evento dentro do dropdown chame essa função
   *
   * 1ª paramentro => botão que abre o dropdown
   */
  const closeDropdown = dropdown => {
    $(dropdown).click();
  };

  window.closeDropdown = closeDropdown;

  /** ADICIONA DROPDOWN TO MUTATION
   *
   * Centraliza dropdown.
   *
   * 1ª Paramentro -> div que contem os itens do dropdown;
   */
  const addDropdownToMutation = dropdown => {
    /* ----- ALINHAMENTO DO DROPDOWN -----------------------------------------------
    |  Esse trecho de código observa por mudanças no atributo style.
    |  Caso ele perceba que o style mudou, então ele seta o left do dropdown
    |  para ser a distância do canto esquerdo somado com a metade da largura, ou seja,
    |  o dropdown ficará situado no meio do botão que lhe foi acionado.
    *-------------------------------------------------------------------*/
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutationRecord => {
        const dropdownMenu = mutationRecord.target;
        const dropdownButton = dropdownMenu.previousElementSibling;
        dropdownMenu.style.left = `${dropdownButton.offsetWidth / 2 -
          dropdownMenu.offsetWidth / 2}px`;
      });
    });

    observer.observe(dropdown, {
      attributes: true,
      attributeFilter: ['style'],
    });

    dropdown.observer = observer;
  };

  window.addDropdownToMutation = addDropdownToMutation;

  /** REMOVE DROPDOWN TO MUTATION
   *
   * remove a centralização do dropdown.
   */
  const removeDropdownToMutation = dropdown => {
    dropdown.observer.disconnect();
  };

  window.removeDropdownToMutation = removeDropdownToMutation;

  /** RETIRA ALINHAMENTRO CENTRALIZADO EM UMA LARGURA DEFINIDA
   *
   * Caso deseje remover o alinhamentro centralizado em telas pequenas e por alinhamento na direita ou esquerda,
   * chame essa função.
   *
   * 1ª Paramentro -> div com a classe .dropdown;
   *
   * 2ª Paramentro -> true para alinhamento a direita, false para alinhamento e esquerda;
   *
   * 3ª Paramentro -> largura deseja para que a mudança ocorrar;
   */

  const removeAlignmentCentered = (
    divDropdown,
    alinhamentoAhDireita,
    larguraDesejada
  ) => {
    const mudaClasses = (buttonDropdown, menuDropdown, ahDireita, largura) => {
      const windowWidth = window.innerWidth;
      const screenWidth = window.screen.width;

      if (windowWidth < largura || screenWidth < largura) {
        if (ahDireita) {
          menuDropdown.classList.remove('dropdown-menu-center');
          menuDropdown.classList.add('dropdown-menu-right');
          removeDropdownToMutation(menuDropdown);
          updateDropdownPosition(buttonDropdown);
        } else {
          menuDropdown.classList.remove('dropdown-menu-center');
          removeDropdownToMutation(menuDropdown);
          updateDropdownPosition(buttonDropdown);
        }
      } else if (ahDireita) {
        menuDropdown.classList.add('dropdown-menu-center');
        menuDropdown.classList.remove('dropdown-menu-right');
        addDropdownToMutation(menuDropdown);
        updateDropdownPosition(buttonDropdown);
      } else {
        menuDropdown.classList.add('dropdown-menu-center');
        addDropdownToMutation(menuDropdown);
        updateDropdownPosition(buttonDropdown);
      }
    };

    const dropdownMenu = divDropdown.getElementsByClassName('dropdown-menu')[0];
    const dropdownButton = divDropdown.getElementsByClassName(
      'dropdown-toggle'
    )[0];

    window.addEventListener('resize', () => {
      mudaClasses(
        dropdownButton,
        dropdownMenu,
        alinhamentoAhDireita,
        larguraDesejada
      );
    });

    mudaClasses(
      dropdownButton,
      dropdownMenu,
      alinhamentoAhDireita,
      larguraDesejada
    );
  };

  window.removeAlignmentCentered = removeAlignmentCentered;

  const dropdownsCenters = document.querySelectorAll('.dropdown-menu-center');
  dropdownsCenters.forEach(dropdownCenter => {
    addDropdownToMutation(dropdownCenter);
  });
})();

// ************************* FIM DO dropdown.js *************************
