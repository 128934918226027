(function() {
  const navegacoesLaterais = document.querySelectorAll('.navegacao-lateral');

  /* ----- ADICIONA FUNÇÃO DE CLICK AOS BOTÕES ----
  |  Esse trecho de código pecorre por cada navegação lateral e adiciona a cada
  |  button um função de click, no qual irá desativar e ativar as div desejadas.
  *-------------------------------------------------------------------*/
  navegacoesLaterais.forEach(navegacaoLateral => {
    /* ----- RESTRINGE TAMANHO DE TELA PARA COMPONENTE ----------------------*
    |  Esse trecho de código delimita um tamanho especifico para o componete,|
    |  em telas menores que tablet ele redireciona para a pagina principal.  |
    |  Se não for passado nenhum valor para o tamanho da tela será utilizado |
    |  um default e caso não seja passada uma url de redirecionamento,       |
    |  o destino será a página inicial;                                      |
    *-----------------------------------------------------------------------*/
    if (navegacaoLateral.dataset.breakpoint !== 'false') {
      window.attachResizeRedirect(
        navegacaoLateral.dataset.breakpoint
          ? parseFloat(navegacaoLateral.dataset.breakpoint)
          : 767.98,
        navegacaoLateral.dataset.url
      );
    }

    const buttonsDaNavecacaoLateral = navegacaoLateral.querySelectorAll(
      '.list-group .menu-item button'
    );
    buttonsDaNavecacaoLateral.forEach(button => {
      button.addEventListener('click', () => {
        // Define toda a lista como "não ativa"
        navegacaoLateral
          .querySelector('.menu-item.active')
          .classList.remove('active');

        // Define o botão clicado como ativo
        button.parentElement.parentElement.classList.add('active');

        const conteudoId = button.getAttribute('href');
        const menuConteudo = navegacaoLateral.querySelector('.menu-conteudo');
        // Desabilita o conteudo atual
        menuConteudo
          .querySelector(':scope > .tab-pane.show.active')
          .classList.remove('show', 'active');
        // Habilita o conteudo referente ao botão clicado
        menuConteudo.querySelector(conteudoId).classList.add('show', 'active');

        navegacaoLateral.dispatchEvent(
          new CustomEvent('navigate', {
            bubbles: true,
            detail: conteudoId,
          })
        );
      });
    });
  });
})();

// ************************* FIM DO navegacao_lateral.js *************************
