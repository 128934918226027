export const monthsArray = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const weekdaysArray = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

export function formatTextDateToObjectDate(date) {
  const isISOFormat = date?.charAt(4) === '-';
  let year = '0000';
  let month = '00';
  let day = '00';
  if (isISOFormat) {
    [year, month, day] = date.split('-');
  } else {
    [day, month, year] = getDateNumbersWithOrWithoutSlash(date);
  }
  const monthIndex = month - 1;
  return new Date(year, monthIndex, day);
}

export function formatObjectDateToIsoTextDate(date) {
  return `${date.getFullYear()}-${getMonthFormated(date.getMonth())}-${
    date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  }`;
}

export function formatISODateToCommonFormat(date) {
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
}

export function generateYearsArray(currentYear) {
  const [initialYear, finalYear] = getFirstAndLastPeriodYear(currentYear);
  const yearsArray = [];
  while (yearsArray.length <= finalYear - initialYear) {
    yearsArray.push(initialYear + yearsArray.length);
  }
  return yearsArray;
}

export function getFirstAndLastPeriodYear(year) {
  return [year - 6, year + 5];
}

export function getMonthFormated(month) {
  const monthIndex = isNaN(+month) ? monthsArray.indexOf(month) : month;
  return monthIndex <= 8 ? `0${monthIndex + 1}` : `${monthIndex + 1}`;
}

export function getWeekday(year, month, day = 1) {
  return new Date(year, month, day).getDay();
}

export function getCurrentMonthDays(year, month) {
  switch (monthsArray[month]) {
    case 'Janeiro':
    case 'Março':
    case 'Maio':
    case 'Julho':
    case 'Agosto':
    case 'Outubro':
    case 'Dezembro':
      return 31;
    case 'Fevereiro':
      return isLeapYear(year) ? 29 : 28;
    case 'Abril':
    case 'Junho':
    case 'Setembro':
    case 'Novembro':
      return 30;
    default:
      break;
  }
}

export function getPreviousMonthDays(year, month) {
  const firstMonthIndex = 0;
  const lastMonthIndex = 11;
  const previousMonthIndex = month - 1;
  const previousYearIndex = year - 1;

  const previousMonth =
    month === firstMonthIndex ? lastMonthIndex : previousMonthIndex;
  const correctYear = month === firstMonthIndex ? previousYearIndex : year;
  return getCurrentMonthDays(correctYear, previousMonth);
}

export function getNextMonthDays(year, month) {
  const firstMonthIndex = 0;
  const lastMonthIndex = 11;
  const nextMonthIndex = month + 1;
  const nextYearIndex = year + 1;

  const nextMonth = month === lastMonthIndex ? firstMonthIndex : nextMonthIndex;
  const correctYear = month === lastMonthIndex ? nextYearIndex : year;
  return getCurrentMonthDays(correctYear, nextMonth);
}

export function getDateNumbersWithOrWithoutSlash(date) {
  let day = null;
  let month = null;
  let year = null;
  const hasSlash = date.includes('/');
  if (hasSlash) {
    [day, month, year] = date.split('/');
  } else if (date.length === 6) {
    day = date.substr(0, 1);
    month = date.substr(1, 1);
    year = date.substr(2, 4);
  } else if (date.length === 8) {
    day = date.substr(0, 2);
    month = date.substr(2, 2);
    year = date.substr(4, 4);
  }
  return [day, month, year];
}

export function generateCalendarPreviousMonthDaysArray(year, month) {
  let previousMonthArray = [];
  let previousMonthNumberOfDays = getPreviousMonthDays(+year, month);

  const currentMonthWeekdayIndex = getWeekday(year, month);

  for (let x = 0; x < currentMonthWeekdayIndex; x++) {
    previousMonthArray.push(previousMonthNumberOfDays--);
  }
  return previousMonthArray;
}

export function dateInputIsValid(date) {
  if (date.trim() === '') {
    return true;
  }
  const [day, month, year] = getDateNumbersWithOrWithoutSlash(date);
  const monthIndex = +month - 1;
  const dayIsValid =
    !isNaN(day) && day <= getCurrentMonthDays(year, monthIndex) && day > 0;
  const monthIsValid = !isNaN(month) && month > 0 && month <= 12;
  const yearIsValid =
    !isNaN(year) && year > 0 && year < 2200 && year.length === 4;

  return dayIsValid && monthIsValid && yearIsValid;
}

export function insertDateMask(textDate) {
  let dateFormated = textDate;
  if (!textDate.includes('/')) {
    if (textDate.length === 6) {
      const day = textDate.substr(0, 1);
      const month = textDate.substr(1, 1);
      const year = textDate.substr(2, 4);
      dateFormated = `0${day}/0${month}/${year}`;
    } else if (textDate.length === 8) {
      const day = textDate.substr(0, 2);
      const month = textDate.substr(2, 2);
      const year = textDate.substr(4, 4);
      dateFormated = `${day}/${month}/${year}`;
    }
  }
  return dateFormated;
}

function isLeapYear(year) {
  if (year % 400 === 0) {
    return true;
  } else if (year % 4 === 0 && year % 100 !== 0) {
    return true;
  } else {
    return false;
  }
}

export function isNumberTyped(evt) {
  evt = evt ? evt : window.event;
  var k = evt.which ? evt.which : evt.keyCode;
  if ((k < 48 || k > 57) && (k < 96 || k > 105)) {
    return false;
  }
  return true;
}

export function objectSanitizer(objectDefault, objectReceived) {
  const optionsDefault = Object.keys(objectDefault).filter((prop) => prop);
  const optionsReceived = Object.keys(objectReceived).filter((prop) => prop);

  optionsReceived.map((option) => {
    if (!optionsDefault.includes(option)) {
      delete objectReceived[option];
    }
  });
  return objectReceived;
}

export function isSameMonth(date, selectedDate) {
  return (
    date.getFullYear() === selectedDate?.getFullYear() &&
    date.getMonth() === selectedDate?.getMonth()
  );
}
