export default class ElementMenuFactory {
  static createVideo(urlSource = '') {
    const wrapper = document.createElement('div');
    wrapper.classList.add('menu__video');

    const videoEl = document.createElement('video');
    const videoAttributes = {
      with: '100%',
      height: '100%',
      muted: 'muted',
    } as {
      [key: string]: string;
    };
    Object.keys(videoAttributes).forEach((key) => {
      videoEl.setAttribute(key, videoAttributes[key]);
    });

    const sourceEl = document.createElement('source');
    const sourceAttributes = {
      src: urlSource,
      type: 'video/mp4',
    } as {
      [key: string]: string;
    };
    sourceEl.innerText = 'Seu navegador não suporta a tag video.';

    Object.keys(sourceAttributes).forEach((key) => {
      videoEl.setAttribute(key, sourceAttributes[key]);
    });

    wrapper.appendChild(videoEl);
    videoEl.appendChild(sourceEl);

    return wrapper;
  }

  static createOverlay() {
    const overlayNav = document.createElement('div');
    overlayNav.classList.add(
      'container-conteudo__overlay',
      'overlay-nav-libras',
      'd-block'
    );
    return overlayNav;
  }
}
