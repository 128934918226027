(async (Cosmos) => {
  /* ----- Altera paleta HTML dentro do Iframe --------------------------
  |  Esse trecho de código realiza as alterações necessarias dentro do iframe
  |  criado pelo tinyMCE para trocar as paletas dele.
  *-------------------------------------------------------------------*/

  const getTinyMceCustomOptions = (textArea) => {
    const tinymceCustomOptions = textArea.getAttribute('data-tinymce-options');
    if (!tinymceCustomOptions) {
      return {};
    }
    try {
      const fixedJSON = tinymceCustomOptions
        // Replace ":" with "@colon@" if it's between double-quotes
        .replace(/:\s*"([^"]*)"/g, function (match, p1) {
          return `: "${p1.replace(/:/g, '@colon@')}"`;
        })

        // Replace ":" with "@colon@" if it's between single-quotes
        .replace(/:\s*'([^']*)'/g, function (match, p1) {
          return `: "${p1.replace(/:/g, '@colon@')}"`;
        })

        // Add double-quotes around any tokens before the remaining ":"
        .replace(/(['"])?([a-z0-9A-Z_]+)(['"])?\s*:/g, '"$2": ')

        // Turn "@colon@" back into ":"
        .replace(/@colon@/g, ':');

      return JSON.parse(fixedJSON);
    } catch (error) {
      console.error('data-tinymce-options com formato inválido');
    }
    return {};
  };

  /* ----- Altera paleta Body dentro do Iframe --------------------------
  |  Esse trecho de código realiza as alterações necessarias dentro do iframe
  |  criado pelo tinyMCE para trocar as paletas dele.
  *-------------------------------------------------------------------*/
  const alteraPaletaBody = (body, html) => {
    const paletteAcInfo = {
      'branco-azul': {
        bodyBg: 'white',
      },
      'azul-amarelo': {
        bodyBg: 'blue',
      },
      'preto-amarelo': {
        bodyBg: 'black',
      },
      'preto-azul': {
        bodyBg: 'black',
      },
    };

    // Remove classes de background do body e html
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const palette in paletteAcInfo) {
      const { bodyBg } = paletteAcInfo[palette];
      body.classList.remove(`bg-${bodyBg}`);
      html.classList.remove(`bg-${bodyBg}`);
    }

    const paleta = document
      .querySelector('body')
      .getAttribute('data-ac-activated');

    if (paleta) {
      const { bodyBg } = paletteAcInfo[paleta];

      // Adiciona classes de background ao body e html
      html.classList.add(`bg-${bodyBg}`);
      body.classList.add('mce-content-body', `bg-${bodyBg}`);
      body.setAttribute('data-ac-activated', paleta);

      // Apresenta inconsistência na documentação
      // if (isComponentBox) {
      //   const tinyTextAreas = Array.from(
      //     document.querySelectorAll('textarea[data-tinymce]')
      //   );

      //   const textAreaIndex = tinyTextAreas.findIndex(
      //     tinyTextArea => tinyTextArea === textarea
      //   );

      //   const toxContainer = document.querySelectorAll('.tox.tox-tinymce-aux')[
      //     textAreaIndex
      //   ];

      //   toxContainer.classList.add('component__box', paleta);
      // }
    } else {
      // Define o background padrão como branco caso não haja paleta definida
      body.classList.add('mce-content-body', 'bg-white');
      body.removeAttribute('data-ac-activated');
      html.classList.add('bg-white');
    }
  };

  const observarMudancasAcActivated = (bodyIframe, htmlIframe) => {
    const bodyElemento = document.querySelector('body');
    if (bodyElemento) {
      const observer = new MutationObserver((mutationsList) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const mutation of mutationsList) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'data-ac-activated'
          ) {
            alteraPaletaBody(bodyIframe, htmlIframe);
          }
        }
      });
      observer.observe(bodyElemento, { attributes: true });
    }
  };

  const subSubDropdownMutation = new MutationObserver((mutations) => {
    mutations.forEach((mutationRecord) => {
      const { target } = mutationRecord;

      if (target.classList.contains('tox-collection__item--active')) {
        const [targetContainer] = $(target).parents('.tox-menu');
        const selectedMenu = targetContainer.nextElementSibling;

        if (selectedMenu && !selectedMenu.style.bottom) {
          const [selectedMenuContainer] = $(selectedMenu).parents(
            '.tox.tox-tinymce-aux'
          );

          const selectedMenuContainerTop =
            selectedMenuContainer.getBoundingClientRect().top;
          const targetTop = target.getBoundingClientRect().top;

          const selectedMenuTop = (selectedMenuContainerTop - targetTop) * -1;

          selectedMenu.style.top = `${selectedMenuTop}px`;
        }
      }
    });
  });

  const subDropdownMutation = new MutationObserver((mutations) => {
    mutations.forEach((mutationRecord) => {
      const { target } = mutationRecord;
      const expanded = target.getAttribute('aria-expanded');

      if (expanded === 'true') {
        const [targetContainer] = $(target).parents('.tox-menu');
        const selectedMenu = targetContainer.nextElementSibling;

        if (selectedMenu && !selectedMenu.style.bottom) {
          const [selectedMenuContainer] = $(selectedMenu).parents(
            '.tox.tox-tinymce-aux'
          );

          const selectedMenuContainerTop =
            selectedMenuContainer.getBoundingClientRect().top;
          const targetTop = target.getBoundingClientRect().top;

          const selectedMenuTop = (selectedMenuContainerTop - targetTop) * -1;

          selectedMenu.style.top = `${selectedMenuTop}px`;
        }

        if (selectedMenu) {
          const dropdownTriggers =
            selectedMenu.querySelectorAll('[aria-expanded]');
          dropdownTriggers.forEach((dropdownTrigger) => {
            subSubDropdownMutation.observe(dropdownTrigger, {
              attributes: true,
              attributeFilter: ['class'],
            });
          });
        }
      }
    });
  });

  const dropdownMutation = new MutationObserver((mutations) => {
    mutations.forEach((mutationRecord) => {
      const { target } = mutationRecord;
      const expanded = target.getAttribute('aria-expanded');

      if (expanded === 'true') {
        const selectedMenu = document.querySelector('.tox-menu');

        if (selectedMenu && !selectedMenu.style.bottom) {
          const [selectedMenuContainer] = $(selectedMenu).parents(
            '.tox.tox-tinymce-aux'
          );

          const selectedMenuContainerTop =
            selectedMenuContainer.getBoundingClientRect().top;

          const targetRect = target.getBoundingClientRect();
          const targetTop = targetRect.top;
          const targetHeight = targetRect.height;

          const selectedMenuTop =
            (selectedMenuContainerTop - targetTop - targetHeight) * -1;

          selectedMenu.style.top = `${selectedMenuTop}px`;
        }

        if (selectedMenu) {
          const dropdownTriggers =
            selectedMenu.querySelectorAll('[aria-expanded]');
          dropdownTriggers.forEach((dropdownTrigger) => {
            subDropdownMutation.observe(dropdownTrigger, {
              attributes: true,
              attributeFilter: ['aria-expanded'],
            });
          });
        }
      }
    });
  });

  const htmlNavKeyboard = `<div role="document" data-alloy-tabstop="true" tabindex="-1" class="tox-form__group">
  <h1>Navegação por teclado na interface do editor</h1>

  <h2>Ativando a navegação por teclado</h2>

  <p>As seções da interface externa do editor - a barra de menus, a barra de ferramentas, a barra lateral e o rodapé - são todas navegáveis por teclado. Como tal, existem várias maneiras de ativar a navegação por teclado:</p>
  <ul>
    <li>Focar na barra de menus: Alt + F9 (Windows) ou ⌥F9 (MacOS)</li>
    <li>Focar na barra de ferramentas: Alt + F10 (Windows) ou ⌥F10 (MacOS)</li>
    <li>Focar no rodapé: Alt + F11 (Windows) ou ⌥F11 (MacOS)</li>
  </ul>

  <p>Ao focar na barra de menus ou na barra de ferramentas, a navegação por teclado começará no primeiro item da barra de menus ou da barra de ferramentas, que será destacado com um fundo cinza. Ao focar no rodapé, a navegação por teclado começará no primeiro item no caminho do elemento, que será destacado com um sublinhado.</p>

  <h2>Movendo-se entre as seções da interface</h2>

  <p>Quando a navegação por teclado estiver ativa, pressionar a tecla Tab moverá o foco para a próxima seção principal da interface, quando aplicável. Essas seções são:</p>
  <ul>
    <li>a barra de menus</li>
    <li>cada grupo da barra de ferramentas</li>
    <li>a barra lateral</li>
    <li>o caminho do elemento no rodapé</li>
    <li>o botão de alternância de contagem de palavras no rodapé</li>
    <li>o link de marca no rodapé</li>
    <li>a alça de redimensionamento do editor no rodapé</li>
  </ul>

  <p>Ao pressionar Shift + Tab, você se moverá para trás nas mesmas seções, exceto ao passar do rodapé para a barra de ferramentas. Ao focar no caminho do elemento e, em seguida, pressionar Shift + Tab, o foco será movido para o primeiro grupo da barra de ferramentas, não para o último.</p>

  <h2>Movendo-se dentro das seções da interface</h2>

  <p>A navegação por teclado dentro das seções da interface geralmente pode ser feita usando as teclas de seta esquerda e direita. Isso inclui:</p>
  <ul>
    <li>mover-se entre os menus na barra de menus</li>
    <li>mover-se entre os botões em um grupo da barra de ferramentas</li>
    <li>mover-se entre os itens no caminho do elemento</li>
  </ul>

  <p>Em todas essas seções da interface, a navegação por teclado ciclará dentro da seção. Por exemplo, ao focar no último botão em um grupo da barra de ferramentas e, em seguida, pressionar a tecla de seta para a direita, o foco será movido para o primeiro item no mesmo grupo da barra de ferramentas.</p>

  <h1>Executando botões</h1>

  <p>Para executar um botão, navegue até o botão desejado e pressione a tecla de espaço ou enter.</p>

  <h1>Abrindo, navegando e fechando menus</h1>

  <p>Ao focar em um botão da barra de menus ou um botão da barra de ferramentas com um menu, pressionar espaço, enter ou a seta para baixo abrirá o menu. Quando o menu é aberto, o primeiro item será selecionado. Para se mover para cima ou para baixo no menu, pressione as teclas de seta para cima ou para baixo, respectivamente. O mesmo vale para submenus, que também podem ser abertos e fechados usando as teclas de seta esquerda e direita.</p>

  <p>Para fechar qualquer menu ativo, pressione a tecla de escape. Quando um menu é fechado, a seleção será restaurada para a seleção anterior. Isso também funciona para fechar submenus.</p>

  <h1>Barras de ferramentas e menus de contexto</h1>

  <p>Para focar uma barra de ferramentas de contexto aberta, como a barra de ferramentas de tabela, pressione Ctrl + F9 (Windows) ou ⌃F9 (MacOS).</p>

  <p>A navegação na barra de ferramentas de contexto é a mesma da navegação na barra de ferramentas padrão, e a navegação no menu de contexto é a mesma da navegação em menus normais.</p>

  <h1>Navegação em diálogos</h1>

  <p>Existem dois tipos de interfaces de diálogo no TinyMCE: diálogos com guias e diálogos sem guias.</p>

  <p>Quando um diálogo sem guias é aberto, o primeiro componente interativo no diálogo será focado. Os usuários podem navegar entre os componentes interativos pressionando a tecla Tab. Isso inclui quaisquer botões no rodapé. A navegação ciclará de volta para o primeiro componente do diálogo se a tecla Tab for pressionada enquanto o último componente do diálogo estiver em foco. Pressionar Shift + Tab navegará em ordem inversa.</p>

  <p>Quando um diálogo com guias é aberto, o primeiro botão no menu de guias é focado. Pressionar a tecla Tab navegará para o primeiro componente interativo naquela guia e ciclará pelos componentes da guia, pelos botões do rodapé e, em seguida, de volta para o botão da guia. Para alternar para outra guia, focalize o botão da guia para a guia atual e, em seguida, use as teclas de seta para navegar pelos botões das guias.</p>
</div>
`;

  function handleContentFilled(editor) {
    if (editor.getContent() === '') {
      editor.editorContainer.classList.remove('filled');
    } else {
      editor.editorContainer.classList.add('filled');
    }
  }

  function handleEditorFocus(event) {
    const editor = event.target;
    const formGroup = editor.editorContainer.closest('.form-group');
    if (formGroup) {
      formGroup.classList.add('focused');
    }
  }

  function handleEditorBlur(event) {
    const editor = event.target;
    const formGroup = editor.editorContainer.closest('.form-group');
    if (formGroup) {
      formGroup.classList.remove('focused');
    }
  }

  /* ----- Cria TinyMCE Basic --------------------------
  |  Esse trecho de código constroe o editor do tinymce com as funções basicas de um editor
  *-------------------------------------------------------------------*/
  const textAreasTinyBasics = document.querySelectorAll(
    'textarea[data-tinymce="basic"]'
  );

  textAreasTinyBasics.forEach(async (textAreaTinyBasic, index) => {
    textAreaTinyBasic.setAttribute(
      'id',
      `${textAreaTinyBasic.getAttribute('id')}-${index + 1}`
    );

    if (typeof tinymce === 'undefined') {
      // eslint-disable-next-line no-console
      console.error('O TinyMCE não está definido');
      return;
    }

    const customOptions = getTinyMceCustomOptions(textAreaTinyBasic);

    const tinymceSmallConfig = {
      selector: `textarea#${textAreaTinyBasic.getAttribute(
        'id'
      )}[data-tinymce="basic"]`,
      height: 200,
      branding: false,
      skin: false,
      min_height: 200,
      language: 'pt_BR',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      menubar: false,
      toolbar:
        'undo redo |  bold italic underline removeformat | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | link | help',
      link_class_list: [
        { title: 'Padrão', value: 'link-text' },
        { title: 'Sem Sublinhado', value: 'link-normal' },
        { title: 'Fundo Destacado', value: 'link' },
      ],
      content_css: `${Cosmos.urlBase}css/base.css, ${Cosmos.urlBase}css/alto_contraste.css`,
      content_style:
        'body { padding: 0 10px; font-size: 14px } ul {margin-left: 20px;}',
      toolbar_mode: 'sliding',
      fontsize_formats: '12px 14px 16px 18px 20px 24px 36px',

      setup(editor) {
        editor.on('focus', handleEditorFocus);
        editor.on('blur', handleEditorBlur);

        editor.on('keyup', () => handleContentFilled(editor));
        editor.on('change', () => handleContentFilled(editor));

        editor.on('init', () => {
          const dropdownTriggers =
            textAreaTinyBasic.nextElementSibling.querySelectorAll(
              '[aria-expanded]'
            );

          dropdownTriggers.forEach((dropdownTrigger) => {
            dropdownMutation.observe(dropdownTrigger, {
              attributes: true,
              attributeFilter: ['aria-expanded'],
            });
          });

          const bodyIframe = $(textAreaTinyBasic)
            .next('.tox-tinymce')
            .contents()
            .find('iframe')
            .contents()
            .find('body')[0];
          const htmlIframe = $(textAreaTinyBasic)
            .next('.tox-tinymce')
            .contents()
            .find('iframe')
            .contents()
            .find('html')[0];
          htmlIframe.setAttribute(
            'paleta',
            document.querySelector('html').getAttribute('paleta')
          );
          htmlIframe.classList.add('bg-white');
          bodyIframe.classList.add('bg-white');
          bodyIframe.classList.add('fix-ul-li');

          alteraPaletaBody(bodyIframe, htmlIframe);
          observarMudancasAcActivated(bodyIframe, htmlIframe);
          editor.plugins.help.addTab({
            name: 'keyboardnav',
            title: 'Navegação por teclado',
            items: [
              {
                type: 'htmlpanel',
                html: htmlNavKeyboard,
              },
            ],
          });

          handleContentFilled(editor);
        });
      },
    };
    const config = { ...tinymceSmallConfig, ...customOptions };
    tinymce.init(config);
  });

  /* ----- Cria TinyMCE Intermediário --------------------------
  |  Esse trecho de código constroe o editor do tinymce com as funções intermediárias de um editor
  *-------------------------------------------------------------------*/
  const textAreasTinyIntermediate = document.querySelectorAll(
    'textarea[data-tinymce="intermediate"]'
  );
  textAreasTinyIntermediate.forEach(async (textAreaTinyIntermediate, index) => {
    textAreaTinyIntermediate.setAttribute(
      'id',
      `${textAreaTinyIntermediate.getAttribute('id')}-${index + 1}`
    );

    if (typeof tinymce === 'undefined') {
      // eslint-disable-next-line no-console
      console.error('O TinyMCE não está definido');
      return;
    }

    const customOptions = getTinyMceCustomOptions(textAreaTinyIntermediate);

    const tinymceIntermediateConfig = {
      selector: `textarea#${textAreaTinyIntermediate.getAttribute(
        'id'
      )}[data-tinymce="intermediate"]`,
      height: 200,
      min_height: 200,
      branding: false,
      skin: false,
      language: 'pt_BR',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      menubar: 'false',
      toolbar:
        'undo redo |  bold italic underline removeformat | ' +
        'strikethrough superscript subscript | ' +
        'fontsizeselect forecolor | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'bullist numlist outdent indent | ' +
        'table | media image link | code | help ',
      link_class_list: [
        { title: 'Padrão', value: 'link-text' },
        { title: 'Sem Sublinhado', value: 'link-normal' },
        { title: 'Fundo Destacado', value: 'link' },
      ],
      content_css: `${Cosmos.urlBase}css/base.css, ${Cosmos.urlBase}css/alto_contraste.css`,
      content_style:
        'body { padding: 0 10px; font-size: 14px } ul {margin-left: 20px;}',
      toolbar_mode: 'sliding',
      fontsize_formats: '12px 14px 16px 18px 20px 24px 36px',
      file_picker_callback(cb) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function () {
          const file = this.files[0];

          const reader = new FileReader();
          reader.onload = function () {
            const id = `blobid${new Date().getTime()}`;
            const { blobCache } = tinymce.activeEditor.editorUpload;
            const base64 = reader.result.split(',')[1];
            const blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      },
      setup(editor) {
        editor.on('focus', handleEditorFocus);
        editor.on('blur', handleEditorBlur);

        editor.on('keyup', () => handleContentFilled(editor));
        editor.on('change', () => handleContentFilled(editor));

        editor.on('init', () => {
          const dropdownTriggers =
            textAreaTinyIntermediate.nextElementSibling.querySelectorAll(
              '[aria-expanded]'
            );

          dropdownTriggers.forEach((dropdownTrigger) => {
            dropdownMutation.observe(dropdownTrigger, {
              attributes: true,
              attributeFilter: ['aria-expanded'],
            });
          });

          const bodyIframe = $(textAreaTinyIntermediate)
            .next('.tox-tinymce')
            .contents()
            .find('iframe')
            .contents()
            .find('body')[0];
          const htmlIframe = $(textAreaTinyIntermediate)
            .next('.tox-tinymce')
            .contents()
            .find('iframe')
            .contents()
            .find('html')[0];
          htmlIframe.setAttribute(
            'paleta',
            document.querySelector('html').getAttribute('paleta')
          );

          htmlIframe.classList.add('bg-white');
          bodyIframe.classList.add('bg-white');
          bodyIframe.classList.add('fix-ul-li');

          alteraPaletaBody(bodyIframe, htmlIframe);
          observarMudancasAcActivated(bodyIframe, htmlIframe);
          editor.plugins.help.addTab({
            name: 'keyboardnav',
            title: 'Navegação por teclado',
            items: [
              {
                type: 'htmlpanel',
                html: htmlNavKeyboard,
              },
            ],
          });

          handleContentFilled(editor);
        });
      },
      images_upload_handler: (blobInfo, success /* failure */) => {
        const dataImageBase64Attribute =
          textAreaTinyIntermediate.getAttribute('data-image-base64');
        const useBase64 =
          dataImageBase64Attribute !== '' &&
          dataImageBase64Attribute !== null &&
          dataImageBase64Attribute !== 'false';

        if (useBase64) {
          success(`data:${blobInfo.blob().type};base64,${blobInfo.base64()}`);
        }
      },
    };

    const config = { ...tinymceIntermediateConfig, ...customOptions };
    tinymce.init(config);
  });

  /* ----- Cria TinyMCE Math --------------------------
  |  Esse trecho de código constroe o editor do tinymce com funções matemáticas
  *-------------------------------------------------------------------*/
  const textAreasTinyMaths = document.querySelectorAll(
    'textarea[data-tinymce="math"]'
  );

  textAreasTinyMaths.forEach(async (textAreaTinyMath, index) => {
    textAreaTinyMath.setAttribute(
      'id',
      `${textAreaTinyMath.getAttribute('id')}-${index + 1}`
    );

    if (typeof tinymce === 'undefined') {
      // eslint-disable-next-line no-console
      console.error('O TinyMCE não está definido');
      return;
    }

    tinymce.init({
      selector: `textarea#${textAreaTinyMath.getAttribute(
        'id'
      )}[data-tinymce="math"]`,
      branding: false,
      skin: false,
      language: 'pt_BR',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks ',
        'insertdatetime media table paste code help wordcount',
      ],
      external_plugins: {
        tiny_mce_wiris: `${Cosmos.urlBase}vendors/mathtype-tinymce5/plugin.min.js`,
      },
      menubar: false,
      toolbar: `
          fontsizeselect formatselect |
          forecolor backcolor |
          tiny_mce_wiris_formulaEditor
        `,
      toolbar_mode: 'sliding',
      content_css: `${Cosmos.urlBase}css/base.css, ${Cosmos.urlBase}css/alto_contraste.css`,
      content_style: 'body { padding: 0 10px; font-size: 14px }',
      fontsize_formats: '12px 14px 16px 18px 20px 24px 36px',
      setup(editor) {
        editor.on('focus', handleEditorFocus);
        editor.on('blur', handleEditorBlur);

        editor.on('keyup', () => handleContentFilled(editor));
        editor.on('change', () => handleContentFilled(editor));

        editor.on('init', () => {
          const dropdownTriggers =
            textAreaTinyMath.nextElementSibling.querySelectorAll(
              '[aria-expanded]'
            );

          dropdownTriggers.forEach((dropdownTrigger) => {
            dropdownMutation.observe(dropdownTrigger, {
              attributes: true,
              attributeFilter: ['aria-expanded'],
            });
          });

          const bodyIframe = $(textAreaTinyMath)
            .next('.tox-tinymce')
            .contents()
            .find('iframe')
            .contents()
            .find('body')[0];
          const htmlIframe = $(textAreaTinyMath)
            .next('.tox-tinymce')
            .contents()
            .find('iframe')
            .contents()
            .find('html')[0];
          htmlIframe.setAttribute(
            'paleta',
            document.querySelector('html').getAttribute('paleta')
          );

          htmlIframe.classList.add('bg-white');
          bodyIframe.classList.add('bg-white');

          alteraPaletaBody(bodyIframe, htmlIframe);
          observarMudancasAcActivated(bodyIframe, htmlIframe);

          handleContentFilled(editor);
        });
      },
    });
  });

  /* ----- Cria TinyMCE Full --------------------------
  |  Esse trecho de código constroe o editor do tinymce com as funções totais de um editor
  *-------------------------------------------------------------------*/
  const textAreasTinyFulls = document.querySelectorAll(
    'textarea[data-tinymce="full"]'
  );

  textAreasTinyFulls.forEach(async (textAreaTinyFull, index) => {
    textAreaTinyFull.setAttribute(
      'id',
      `${textAreaTinyFull.getAttribute('id')}-${index + 1}`
    );

    if (typeof tinymce === 'undefined') {
      // eslint-disable-next-line no-console
      console.error('O TinyMCE não está definido');
      return;
    }

    const customOptions = getTinyMceCustomOptions(textAreaTinyFull);

    const tinymceFullConfig = {
      selector: `textarea#${textAreaTinyFull.getAttribute(
        'id'
      )}[data-tinymce="full"]`,
      branding: false,
      skin: false,
      language: 'pt_BR',
      plugins: [
        'print preview paste importcss searchreplace autolink autosave save',
        'directionality code visualblocks visualchars fullscreen image link',
        'media codesample table charmap hr pagebreak nonbreaking ',
        'anchor insertdatetime advlist lists wordcount',
        'textpattern noneditable help charmap quickbars emoticons',
      ],
      menubar: 'file edit view insert format tools table help',
      toolbar:
        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media template link anchor codesample | ltr rtl',
      toolbar_sticky: false,
      autosave_ask_before_unload: true,
      autosave_interval: '30s',
      autosave_prefix: '{path}{query}-{id}-',
      autosave_restore_when_empty: false,
      autosave_retention: '2m',
      image_advtab: true,
      link_class_list: [
        { title: 'Padrão', value: 'link-text' },
        { title: 'Sem Sublinhado', value: 'link-normal' },
        { title: 'Fundo Destacado', value: 'link' },
      ],
      content_css: `${Cosmos.urlBase}css/base.css, ${Cosmos.urlBase}css/alto_contraste.css`,
      content_style:
        'body { padding: 0 10px; font-size: 14px } ul {margin-left: 20px;}',
      fontsize_formats: '12px 14px 16px 18px 20px 24px 36px',
      file_picker_callback(cb) {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function () {
          const file = this.files[0];

          const reader = new FileReader();
          reader.onload = function () {
            const id = `blobid${new Date().getTime()}`;
            const { blobCache } = tinymce.activeEditor.editorUpload;
            const base64 = reader.result.split(',')[1];
            const blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      },
      image_caption: true,
      quickbars_selection_toolbar:
        'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
      noneditable_noneditable_class: 'mceNonEditable',
      toolbar_mode: 'sliding',
      contextmenu: 'link image table',
      setup(editor) {
        editor.on('focus', handleEditorFocus);
        editor.on('blur', handleEditorBlur);

        editor.on('keyup', () => handleContentFilled(editor));
        editor.on('change', () => handleContentFilled(editor));

        editor.on('init', () => {
          const dropdownTriggers =
            textAreaTinyFull.nextElementSibling.querySelectorAll(
              '[aria-expanded]'
            );

          dropdownTriggers.forEach((dropdownTrigger) => {
            dropdownMutation.observe(dropdownTrigger, {
              attributes: true,
              attributeFilter: ['aria-expanded'],
            });
          });

          const bodyIframe = $(textAreaTinyFull)
            .next('.tox-tinymce')
            .contents()
            .find('iframe')
            .contents()
            .find('body')[0];
          const htmlIframe = $(textAreaTinyFull)
            .next('.tox-tinymce')
            .contents()
            .find('iframe')
            .contents()
            .find('html')[0];
          htmlIframe.setAttribute(
            'paleta',
            document.querySelector('html').getAttribute('paleta')
          );

          htmlIframe.classList.add('bg-white');
          bodyIframe.classList.add('bg-white');
          bodyIframe.classList.add('fix-ul-li');

          alteraPaletaBody(bodyIframe, htmlIframe);
          observarMudancasAcActivated(bodyIframe, htmlIframe);

          editor.plugins.help.addTab({
            name: 'keyboardnav',
            title: 'Navegação por teclado',
            items: [
              {
                type: 'htmlpanel',
                html: htmlNavKeyboard,
              },
            ],
          });

          handleContentFilled(editor);
        });
      },
      images_upload_handler: (blobInfo, success /* failure */) => {
        const dataImageBase64Attribute =
          textAreaTinyFull.getAttribute('data-image-base64');
        const useBase64 =
          dataImageBase64Attribute !== '' &&
          dataImageBase64Attribute !== null &&
          dataImageBase64Attribute !== 'false';

        if (useBase64) {
          success(`data:${blobInfo.blob().type};base64,${blobInfo.base64()}`);
        }
      },
    };
    const config = { ...tinymceFullConfig, ...customOptions };
    tinymce.init(config);
  });
})(window.Cosmos);

// ************************* FIM DO tinymce.js *************************
