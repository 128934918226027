import { installGlobal } from '../../global';
import {
  COMPONENT_NAME,
  CONTAINER_CONTENT_SELECTOR,
  OVERLAY_SELECTOR,
} from './constants';
import { createOverlay } from './overlayFactory';

const getOverlay = () => document.querySelector<HTMLElement>(OVERLAY_SELECTOR);

const getContainer = () =>
  document.querySelector<HTMLElement>(CONTAINER_CONTENT_SELECTOR);

function showOverlay(parent?: HTMLElement) {
  let overlayEl = getOverlay() || createOverlay();
  overlayEl.style.display = 'block';
  if (!overlayEl.parentElement) {
    const parentNode = parent || getContainer() || document.body;
    parentNode.appendChild(overlayEl);
  }
  return overlayEl;
}

function destroyOverlay() {
  getOverlay()?.remove();
}

const methods = {
  show: showOverlay,
  hide: destroyOverlay,
};

installGlobal(COMPONENT_NAME, methods);

export default methods;
