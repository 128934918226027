(function() {
  (function() {
    const form = document.getElementById('autenticacao-form');

    if (!form) return;

    const authInput = form.querySelector('input');

    authInput.addEventListener('input', () => {
      authInput.value = authInput.value.trim();

      if (authInput.value) {
        document.getElementById('btn-autenticacao').disabled = false;
        window.deactivateError(authInput);
        return;
      }

      window.activateError(authInput);
    });
  })();
})();

// ************************* FIM DO eligere_page.js *************************
