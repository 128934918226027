(function (Cosmos) {
  /* ----- ALTERA FUNCIONAMENTO DO NAV ----------------------------
  |  Esse trecho de código altera o funcionamento do nav de acordo com
  |  o tamanho da tela em questão no momento.
  *-------------------------------------------------------------------*/
  const AutoactivateOptions = {
    FALSE: 'false',
    START: 'start',
    EXACT: 'exact',
    PATH: 'path',
  };
  const alteraFuncionamentonNav = () => {
    const windowWidth = window.innerWidth;
    const sideMenus = document.querySelectorAll('nav.menu-lateral');
    const headerMobileToggle = document.querySelector(
      'header button#toggle-menu'
    );
    if (sideMenus) {
      sideMenus.forEach((nav) => {
        const divContainernNav = nav.parentElement;
        const divContainerOverlay = divContainernNav.previousElementSibling;
        const btnOrelhaCollapse = nav.querySelector('.btn-collapsible');
        if (windowWidth > 992) {
          if (btnOrelhaCollapse && headerMobileToggle) {
            headerMobileToggle.classList.add('d-none');
            btnOrelhaCollapse.classList.remove('d-none');
          }

          if (nav.classList.contains('expanded')) {
            nav.classList.remove('expanded');
          }

          if (divContainernNav.classList.contains('nav-open')) {
            divContainernNav.classList.remove('nav-open');
          }

          if (
            divContainerOverlay &&
            divContainerOverlay.classList.contains('d-block')
          ) {
            divContainerOverlay.classList.remove('d-block');
          }
        } else if (btnOrelhaCollapse && headerMobileToggle) {
          btnOrelhaCollapse.classList.add('d-none');
          headerMobileToggle.classList.remove('d-none');
        }
      });
    }
  };

  const closeMenuLateral = () => {
    const containerNavEl = document.querySelector(
      '.container-conteudo__nav.nav-open'
    );
    const overflowEl = document.querySelector('.overlay-nav.d-block');
    if (containerNavEl && overflowEl) {
      containerNavEl.classList.remove('nav-open');
      overflowEl.classList.remove('d-block');
    }
  };

  // Função criada para adicionar o active nos links dos menus que possuem o atributo data-autoactive
  const addActiveInNavLinkMenu = (link) => {
    let sair = false;
    let li = link.parentElement;
    let ul = li.parentElement;

    while (!sair) {
      li.children[0].classList.add('active');

      if (ul.classList.contains('menu-lateral__item')) {
        sair = true;
      } else {
        li = ul.parentElement.parentElement;
        ul = li.parentElement;
      }
    }
  };

  const updateAttributeAutoactivate = (link) => {
    if (link.tagName !== 'A') return;

    let autoActive =
      link.getAttribute('data-autoactive') || AutoactivateOptions.EXACT;
    const currentUrl = window.location.href;

    if (
      autoActive &&
      !Object.values(AutoactivateOptions).includes(autoActive)
    ) {
      console.warn(
        `Valor inválido para data-autoactive: ${autoActive}.Será assumido o valor padrão: 'exact'`
      );
      autoActive = AutoactivateOptions.EXACT;
    }

    if (autoActive === AutoactivateOptions.FALSE) {
      return;
    }

    if (autoActive === AutoactivateOptions.PATH) {
      const { pathname: pathnameLink } = new URL(link.href);
      const { pathname: pathnameCurrentPage } = new URL(currentUrl);

      if (pathnameLink === pathnameCurrentPage) {
        addActiveInNavLinkMenu(link);
      }
      return;
    }

    if (autoActive === AutoactivateOptions.START) {
      const {
        pathname: pathnameLink,
        hash: hashLink,
        searchParams: searchParamsLink,
      } = new URL(link.href);
      const {
        pathname: pathnameCurrentPage,
        hash: hashCurrentPage,
        searchParams: searchParamsCurrentPage,
      } = new URL(currentUrl);

      const includesAll = (arr, values) => arr.every((v) => values.includes(v));

      if (
        pathnameLink === pathnameCurrentPage &&
        (hashLink === '' || hashLink === hashCurrentPage) &&
        includesAll(
          Array.from(searchParamsLink.values()),
          Array.from(searchParamsCurrentPage.values())
        )
      ) {
        addActiveInNavLinkMenu(link);
      }
      return;
    }

    if (autoActive === AutoactivateOptions.EXACT && link.href === currentUrl) {
      addActiveInNavLinkMenu(link);
    }
  };

  window.addEventListener('resize', alteraFuncionamentonNav);
  /* ----- EXPANDE OU CONTRAI O MENU LATERAL ----------------------------
  |  Esse trecho de código realiza a funcionalidade do botão presente no header
  |  para expandir ou contrair o menu lateral, como também, a partir de uma certo tamanho dela
  |  funciona como abertura ou fechamento do menu lateral.
  *-------------------------------------------------------------------*/

  const buttonMenuHeader = document.querySelectorAll(
    'header button#toggle-menu'
  );

  const existeHeaderMobile =
    document.querySelector('header button#toggle-header-mobile') !== null;

  const menuToggle = (nav) => {
    const divContainerNav = nav.parentElement;
    const divContainerOverlay = divContainerNav.previousElementSibling;
    let divContainerOutroMenu = existeHeaderMobile;

    if (nav.classList.contains('expanded')) {
      nav.classList.remove('expanded');
    } else {
      nav.classList.add('expanded');
      nav.focus({ preventScroll: true });
    }

    if (existeHeaderMobile) {
      divContainerOutroMenu =
        divContainerNav.nextElementSibling.nextElementSibling;
    }
    const itensExpanded = nav.querySelectorAll('[aria-expanded]');
    const ulPrincipal = nav.querySelector('.menu-lateral__item');
    // const linkSubMenuActive = nav.querySelectorAll('ul.submenu_itens a.active');

    if (window.innerWidth > 992) {
      if (divContainerNav.classList.contains('.nav-open')) {
        divContainerNav.classList.remove('.nav-open');
      }

      // caso tenha e o usuario esteja em um submenu.
      // ao clicar no icone do header, o menu volta ao menu principal.
      if (
        ulPrincipal.classList.contains('nav-go-right') ||
        ulPrincipal.classList.contains('nav-go-center')
      ) {
        const li = Array.from(ulPrincipal.children).find(
          (item) => !item.classList.contains('d-none')
        );
        let ul = Array.from(li.getElementsByClassName('submenu_itens')).find(
          (item) => !item.classList.contains('nav-go-right')
        );
        let buttonBack = ul.getElementsByClassName('back-menu')[0];
        itensExpanded.forEach((item) => {
          item.setAttribute('aria-expanded', 'false');
        });

        while (ul !== ulPrincipal) {
          buttonBack.click();
          ul = ul.parentElement.parentElement.parentElement;
          [buttonBack] = ul.getElementsByClassName('back-menu');
        }
        // Fecha o menu quando clicar no botão toggle do header quando chegar no primeiro nivel do menu
        if (ul === ulPrincipal) {
          const liButton = ul.querySelectorAll('li button');
          const liLink = ul.querySelectorAll('li a');
          ul.setAttribute('aria-expanded', 'false');
          liButton.forEach((item) =>
            item.setAttribute('aria-expanded', 'false')
          );
          liLink.forEach((item) => item.setAttribute('aria-expanded', 'false'));
        }
      } else {
        // Caso não tenha submenu ativo ou esteja no menu principal, funciona normalmente expandindo ou contraido o menu
        itensExpanded.forEach((item) => {
          const attrExpanded = item.getAttribute('aria-expanded');
          if (attrExpanded === 'true') {
            item.setAttribute('aria-expanded', 'false');
          } else {
            item.setAttribute('aria-expanded', 'true');
          }
        });
      }
    } else {
      itensExpanded.forEach((item) => {
        item.setAttribute('aria-expanded', 'true');
      });

      // Cosmos.closeMenuLateral = closeMenu;

      if (existeHeaderMobile) {
        if (divContainerNav.classList.contains('nav-open')) {
          closeMenuLateral();
        } else if (divContainerOutroMenu.classList.contains('nav-open')) {
          Cosmos.MenuNavRight.closeMenu();
          divContainerNav.classList.add('nav-open');
          divContainerOverlay.classList.add('d-block');
        } else {
          divContainerNav.classList.add('nav-open');
          divContainerOverlay.classList.add('d-block');
        }
      } else if (divContainerNav.classList.contains('nav-open')) {
        closeMenuLateral();
      } else {
        divContainerNav.classList.add('nav-open');
        divContainerOverlay.classList.add('d-block');
      }
    }
  };

  const toggleMenu = (button) => {
    button.addEventListener('click', () => {
      const navsContainers = document.querySelectorAll('.menu-lateral');

      if (navsContainers) {
        navsContainers.forEach((nav) => {
          menuToggle(nav);
        });
      }
    });
  };

  const menusLaterais = document.querySelectorAll('.menu-lateral');
  menusLaterais.forEach((menuLateral) => {
    menuLateral.toggleMenu = () => {
      menuToggle(menuLateral);
    };
  });

  buttonMenuHeader.forEach(toggleMenu);

  /* ----- FECHA O MENU SE CLICAR FORA DELE ----------------------------
  |  Esse trecho de código realiza a funcionalidade de ao clicar fora do menu
  |  em tablet ou mobile, o menu será fechado.
  *-------------------------------------------------------------------*/

  const divContainerOverlay = document.querySelector(
    '.container-conteudo__overlay.overlay-nav'
  );
  if (divContainerOverlay) {
    divContainerOverlay.addEventListener('click', () => {
      const buttonHeader = document.querySelector('header button#toggle-menu');
      buttonHeader.click();
    });
  }
  /* ----- TROCA DE ABAS ENTRE MENUS E SUBMENUS ----------------------------
  |  Esse trecho de código faz as trocas entre as abas de submenus e menus
  *------------------------------------------------------------------------*/

  const menuLateral = (menu) => {
    if (menu.dataset.collapsible !== undefined) {
      menu.parentElement.classList.add('menu-lateral-collapsible');
      const btnOrelhaCollapse = menu.querySelector('.btn-collapsible');
      if (
        btnOrelhaCollapse &&
        btnOrelhaCollapse.getAttribute('aria-expanded') === 'true'
      ) {
        menu.parentElement.classList.add('menu-lateral-collapsible-expanded');
      }
    }
    const menuPrincipal = menu.getElementsByClassName('menu-lateral__item')[0];

    // IDA PARA PROXIMA ABA
    const buttonsSubMenus = menuPrincipal.querySelectorAll('button.submenu');
    buttonsSubMenus.forEach((button) => {
      // Faz a passagem do menu para outros niveis.
      button.addEventListener('click', () => {
        const ul = button.parentElement.parentElement;
        const li = button.parentElement;
        const subMenu = button.nextElementSibling;
        const buttonBack = ul.querySelectorAll('.back-menu');
        const liChildren = subMenu.querySelectorAll('.submenu_itens li');

        liChildren.forEach((item) => {
          if (item.children[0].getAttribute('aria-expanded') === 'false') {
            item.children[0].setAttribute('aria-expanded', 'true');
          }
        });
        buttonBack.forEach((item) => {
          if (item.getAttribute('aria-expanded') === 'false') {
            item.setAttribute('aria-expanded', 'true');
          }
        });

        // Ao clicar em um item de submenu verifica se está falso, caso esteja ele adiciona o aria-expeanded na ul e na li
        if (ul.getAttribute('aria-expanded') === 'false') {
          ul.setAttribute('aria-expanded', 'true');
          li.setAttribute('aria-expanded', 'true');
        }

        // Realiza a transição
        ul.classList.add('nav-go-right');
        subMenu.classList.add('nav-go-center');
        if (!menuPrincipal.classList.contains('w-268px')) {
          menuPrincipal.classList.add('w-268px');
        }

        // Ajusta o posicionamento do submenu para mantê-lo dentro da tela
        setTimeout(() => {
          Array.from(ul.children).forEach((filho) => {
            if (!(filho === li)) {
              filho.classList.add('d-none');
            }
          });
        }, 200);
      });
    });

    // VOLTA PARA ABA ANTERIOR
    const buttonsBackMenus = menuPrincipal.querySelectorAll('button.back-menu');
    buttonsBackMenus.forEach((button) => {
      // Faz a volta do menu para niveis anteriores
      button.addEventListener('click', () => {
        const ul =
          button.parentElement.parentElement.parentElement.parentElement;
        const subMenu = button.parentElement.parentElement;
        const liButton = ul.querySelectorAll('li button');
        const liLink = ul.querySelectorAll('li a');
        // Ao voltar de um submenu expande os itens do menu
        if (
          button.parentElement.parentElement.parentElement.parentElement.parentElement.className.includes(
            'menu-lateral__content'
          )
        ) {
          liButton.forEach((item) =>
            item.setAttribute('aria-expanded', 'true')
          );
          liLink.forEach((item) => item.setAttribute('aria-expanded', 'true'));
          ul.setAttribute('aria-expanded', 'true');
        }

        // Ajusta o posicionamento do submenu para mantê-lo dentro da tela
        Array.from(ul.children).forEach((filho) => {
          filho.classList.remove('d-none');
        });

        // Realiza a transição
        if (ul.classList.contains('menu-lateral__item')) {
          menuPrincipal.classList.remove('w-268px');
        }
        subMenu.classList.add('d-none');
        subMenu.classList.remove('nav-go-center');
        ul.classList.remove('nav-go-right');
        subMenu.classList.remove('d-none');
        // ---------------------
      });
    });

    // ATIVA OS LIS E AS ABAS ANTERIORES;
    const linkClicado = menuPrincipal.querySelectorAll('a.nav-link');
    linkClicado.forEach((link) => {
      link.addEventListener('click', () => {
        const li = link.parentElement;

        if (!link.classList.contains('active')) {
          // Remove todos as classes activas dentro do menu lateral
          const activos = menuPrincipal.querySelectorAll('.active');
          activos.forEach((activo) => {
            activo.classList.remove('active');
          });
          updateAttributeAutoactivate(li.children[0]);
        }
      });
    });
  };

  document.querySelectorAll('.menu-lateral').forEach(menuLateral);

  /* Funcionalidade do botão orelha no menu */

  const menuCollapse = () => {
    const menuPrincipalSelector = '.menu-lateral[data-collapsible] ';
    const navListSelector = '.menu-lateral__item';
    const btnActionSelector = '.btn-action';
    const btnCollapsibleSelector = '.btn-collapsible';
    const btnCollapsibleIconSelector = '.btn-collapsible__icon';
    const btnCollapsibleTextSelector = '.btn-collapsible__text';
    const menusPrincipais = document.querySelectorAll(menuPrincipalSelector);

    function mountCollapseButton() {
      const btnCollapsible = document.createElement('div');
      const btnCollapsibleIcon = document.createElement('i');
      const btnCollapsibleText = document.createElement('span');

      menusPrincipais.forEach((item) => {
        const navUl = item.querySelector(navListSelector);
        if (navUl.getAttribute('aria-expanded') === 'true') {
          btnCollapsible.setAttribute('aria-expanded', 'true');
          btnCollapsibleText.innerHTML = 'Recolher menu';
          btnCollapsible.setAttribute(
            'aria-label',
            'Clique  para recolher o menu'
          );
          btnCollapsibleIcon.classList.add(
            'btn-collapsible__icon',
            'fa-solid',
            'fa-fw',
            'fa-chevron-left'
          );
        } else {
          btnCollapsible.setAttribute('aria-expanded', 'false');
          btnCollapsibleText.innerHTML = 'Expandir menu';
          btnCollapsible.setAttribute(
            'aria-label',
            'Clique  para expandir o menu'
          );
          btnCollapsibleIcon.classList.add(
            'btn-collapsible__icon',
            'fa-solid',
            'fa-fw',
            'fa-chevron-right'
          );
        }
      });

      btnCollapsible.classList.add('btn-collapsible', 'writing-vertical-lr');
      btnCollapsible.setAttribute('role', 'button');

      btnCollapsibleIcon.setAttribute('aria-hidden', 'true');
      btnCollapsible.setAttribute('tabindex', '0');
      btnCollapsibleText.setAttribute('aria-hidden', 'true');
      btnCollapsibleText.classList.add(
        'btn-collapsible__text',
        'writing-sideways-lr'
      );
      btnCollapsible.appendChild(btnCollapsibleIcon);
      btnCollapsible.appendChild(btnCollapsibleText);

      return btnCollapsible;
    }

    const handleMenuState = (menuPrincipal) => {
      const btnAction = menuPrincipal.querySelectorAll(btnActionSelector);
      const navList = menuPrincipal.querySelector(navListSelector);
      const btnCollapsible = menuPrincipal.querySelector(
        btnCollapsibleSelector
      );
      const btnCollapsibleIcon = menuPrincipal.querySelector(
        btnCollapsibleIconSelector
      );
      const btnCollapsibleText = menuPrincipal.querySelector(
        btnCollapsibleTextSelector
      );

      const ulPrincipal = menuPrincipal.querySelector('.menu-lateral__item');
      const linkSubMenuActive = menuPrincipal.querySelectorAll(
        'ul.submenu_itens a.active'
      );

      const menuWrapper = menuPrincipal.parentElement.classList.contains(
        'container-conteudo__nav'
      )
        ? menuPrincipal.parentElement
        : undefined;

      if (navList.getAttribute('aria-expanded') === 'false') {
        if (menuWrapper)
          menuWrapper.classList.remove('menu-lateral-collapsible-expanded');
        btnCollapsible.setAttribute(
          'aria-label',
          'Clique para expandir o menu'
        );
        btnCollapsible.setAttribute('aria-pressed', 'false');
        btnCollapsible.setAttribute('aria-expanded', 'false');
        btnCollapsibleIcon.classList.replace(
          'fa-chevron-left',
          'fa-chevron-right'
        );
        btnCollapsibleText.innerHTML = 'Expandir menu';
        btnAction.forEach((btn) => {
          if (btn) {
            btn.classList.remove('btn-primary');
            btn.classList.remove('rounded-pill');
          }
        });
      }

      if (navList.getAttribute('aria-expanded') === 'true') {
        if (menuWrapper)
          menuWrapper.classList.add('menu-lateral-collapsible-expanded');
        btnCollapsible.setAttribute(
          'aria-label',
          'Clique para recolher o menu'
        );
        btnCollapsible.setAttribute('aria-pressed', 'true');
        btnCollapsible.setAttribute('aria-expanded', 'true');
        btnCollapsibleIcon.classList.replace(
          'fa-chevron-right',
          'fa-chevron-left'
        );
        btnCollapsibleText.innerHTML = 'Recolher menu';
        btnAction.forEach((btn) => {
          if (btn) {
            btn.classList.replace('nav-link', 'btn-primary');
            btn.classList.add('rounded-pill', 'btn-primary');
          }
        });
      }

      if (
        (linkSubMenuActive.length > 0 &&
          ulPrincipal.classList.contains('nav-go-center')) ||
        ulPrincipal.classList.contains('nav-go-right')
      ) {
        btnCollapsible.setAttribute('aria-expanded', 'true');
      }

      if (linkSubMenuActive.length < 0) {
        btnCollapsible.setAttribute('aria-expanded', 'false');
      }
    };

    const mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes') {
          const menuPrincipal = mutation.target.closest(menuPrincipalSelector);
          handleMenuState(menuPrincipal);
        }
      });
    });

    const handleButtonOrelhaState = (navList, menuPrincipal) => {
      menuToggle(menuPrincipal);
      const itensExpanded = menuPrincipal.querySelectorAll('[aria-expanded]');
      const ulPrincipal = menuPrincipal.querySelector('.menu-lateral__item');
      if (
        navList.classList.contains('nav-open') ||
        navList.classList.contains('nav-go-right') ||
        navList.classList.contains('nav-go-center')
      ) {
        const li = Array.from(ulPrincipal.children).find(
          (item) => !item.classList.contains('d-none')
        );
        let ul = Array.from(li.getElementsByClassName('submenu_itens')).find(
          (item) =>
            !item.classList.contains('nav-go-right') ||
            !item.classList.contains('nav-go-center')
        );
        let buttonBack = ul.getElementsByClassName('back-menu')[0];

        itensExpanded.forEach((item) => {
          item.setAttribute('aria-expanded', 'false');
        });

        while (ul !== ulPrincipal) {
          buttonBack.click();
          ul = ul.parentElement.parentElement.parentElement;
          [buttonBack] = ul.getElementsByClassName('back-menu');
        }

        if (ul === ulPrincipal) {
          const liButton = ul.querySelectorAll('li button');
          const liLink = ul.querySelectorAll('li a');
          ul.setAttribute('aria-expanded', 'false');
          liButton.forEach((item) =>
            item.setAttribute('aria-expanded', 'false')
          );
          liLink.forEach((item) => item.setAttribute('aria-expanded', 'false'));
        }
      }
    };

    menusPrincipais.forEach((menuPrincipal) => {
      const navList = menuPrincipal.querySelector(navListSelector);
      const btnCollapsible = mountCollapseButton();
      // Remove toggle somente se o dispositivo possui uma largura maior que 992px
      if (window.innerWidth > 992) {
        const btnToggleHeader = document.querySelector('#toggle-menu');
        if (btnToggleHeader) btnToggleHeader.classList.add('d-none');
      }

      menuPrincipal.prepend(btnCollapsible);

      btnCollapsible.addEventListener('keypress', (event) => {
        if (event.key === 'Enter') {
          handleButtonOrelhaState(navList, menuPrincipal);
        }
      });
      btnCollapsible.addEventListener('click', () => {
        handleButtonOrelhaState(navList, menuPrincipal);
      });

      const mutationObserverConfig = { attributes: true };

      mutationObserver.observe(navList, mutationObserverConfig);
    });
  };
  if (window.innerWidth > 992) {
    menuCollapse();
  }

  /*
   Responsável por ficar "observando" se o menu lateral possui ou não o atributo
   data-js="menu-collapsible" para poder adicionar automaticamente a orelha no menu lateral; */
  const MenuLateralObserver =
    window.MutationObserver ||
    window.WebKitMutationObserver ||
    window.MozMutationObserver;
  const config = { attributes: true };
  const headerMobileToggle = document.querySelector(
    'header button#toggle-menu'
  );
  const checkDataJsMenu = (MenuList) => {
    MenuList.forEach((menu) => {
      if (
        menu.attributeName === 'data-collapsible' &&
        window.innerWidth > 992
      ) {
        if (
          menu.target.dataset.collapsible === 'true' ||
          menu.target.dataset.collapsible === ''
        ) {
          menuCollapse();

          if (existeHeaderMobile) {
            headerMobileToggle.classList.add('d-none');
          }
        } else if (
          menu.target.children[0].className.includes('btn-collapsible')
        ) {
          menu.target.children[0].remove();
          if (existeHeaderMobile) {
            headerMobileToggle.classList.remove('d-none');
          }
        }
      }
    });
  };

  const allMenusSelector = document.querySelectorAll('.menu-lateral');
  const observer = new MenuLateralObserver(checkDataJsMenu);
  allMenusSelector.forEach((item) => observer.observe(item, config));

  const COMPONENT_NAME = 'MenuNavLateral';
  const methods = {
    closeMenu: closeMenuLateral,
  };
  Cosmos[COMPONENT_NAME] = methods;

  (function () {
    /* ----- ADIÇÃO AUTOMÁTICA DO ACTIVE BASEADO NA URL ----------------------------
  |  Esse trecho de código realiza a adição da classe active nos <li> que abrigam
  |  os links que referenciam uma URL qualquer. Se a URL atual for igual à URL
  |  que o <a> referencia, então o <li> recebe a classe active.
  *-------------------------------------------------------------------*/
    const navContainers = document.querySelectorAll('nav.menu-lateral');
    navContainers.forEach((navContainer) => {
      if (navContainer) {
        // Remove todas as classes 'active' dentro do menu lateral
        const linksactive = navContainer.querySelectorAll('.active');
        linksactive.forEach((activo) => {
          activo.classList.remove('active');
        });
        const links = navContainer.querySelectorAll('a.nav-link');

        links.forEach((link) => {
          updateAttributeAutoactivate(link);
        });

        // Caminha até o submenu desejado
        const activos = navContainer.querySelectorAll('.active');
        activos.forEach((activo, indice) => {
          if (
            indice < activos.length - 1 &&
            activo.classList.contains('submenu')
          ) {
            activo.click();
          } else {
            setTimeout(() => {
              const targetOffset = $(activo).offset().top;
              const scrollContainer = navContainer.getElementsByClassName(
                'menu-lateral__content'
              )[0];
              const subTarget = targetOffset - $(scrollContainer).offset().top;
              $(scrollContainer).animate({ scrollTop: subTarget }, 1000);
            }, 500);
          }
        });
      }
    });
  })();
})(window.Cosmos);
// ************************* FIM DO nav_menu.js *************************
