import { hexToRgba } from './commons';

if (typeof Chart !== 'undefined') {
  if (typeof ChartDataLabels !== 'undefined') {
    Chart.plugins.unregister(ChartDataLabels);
  }

  const setDefaultChartColors = () => {
    Chart.defaults.global.defaultFontColor = '#5c6473';

    Chart.defaults.bar.scales.yAxes.forEach((yAxe) => {
      yAxe.gridLines = yAxe.gridLines || {};

      yAxe.gridLines.color = '#d8e1ea';
      yAxe.gridLines.zeroLineColor = '#bdc4cc';
    });

    Chart.defaults.horizontalBar.scales.xAxes.forEach((xAxe) => {
      xAxe.gridLines = xAxe.gridLines || {};

      xAxe.gridLines.color = '#d8e1ea';
      xAxe.gridLines.zeroLineColor = '#bdc4cc';
    });

    Chart.defaults.line.scales.xAxes.forEach((xAxe) => {
      xAxe.gridLines = xAxe.gridLines || {};

      xAxe.gridLines.color = '#d8e1ea';
      xAxe.gridLines.zeroLineColor = '#d8e1ea';
    });

    Chart.defaults.line.scales.yAxes.forEach((yAxe) => {
      yAxe.gridLines = yAxe.gridLines || {};

      yAxe.gridLines.color = '#d8e1ea';
      yAxe.gridLines.zeroLineColor = '#bdc4cc';
    });

    Chart.defaults.radar.scale.gridLines =
      Chart.defaults.radar.scale.gridLines || {};
    Chart.defaults.radar.scale.gridLines.color = '#d8e1ea';

    Chart.defaults.radar.scale.angleLines =
      Chart.defaults.radar.scale.angleLines || {};
    Chart.defaults.radar.scale.angleLines.color = '#d8e1ea';

    Chart.defaults.radar.scale.ticks = Chart.defaults.radar.scale.ticks || {};
    Chart.defaults.radar.scale.ticks.backdropColor = '#fff';
    Chart.defaults.radar.scale.ticks.fontColor = '#5c6473';
  };

  setDefaultChartColors();

  Chart.defaults.global.defaultFontFamily = '"Open Sans", sans-serif';

  Chart.defaults.global.datasets.bar.barPercentage = 1;
  Chart.defaults.global.datasets.horizontalBar.barPercentage = 1;

  Chart.defaults.global.datasets.pie = Chart.defaults.global.datasets.pie || {};
  Chart.defaults.global.datasets.pie.borderWidth = 1;

  Chart.defaults.global.datasets.doughnut =
    Chart.defaults.global.datasets.doughnut || {};
  Chart.defaults.global.datasets.doughnut.borderWidth = 1;

  Chart.defaults.global.datasets.line =
    Chart.defaults.global.datasets.line || {};
  Chart.defaults.global.datasets.line.fill = false;

  Chart.defaults.global.datasets.radar =
    Chart.defaults.global.datasets.radar || {};
  Chart.defaults.global.datasets.radar.pointRadius = 0;

  Chart.defaults.global.datasets.area = {};
  Chart.defaults.global.datasets.area.fill = 'start';

  Chart.defaults.bar.scales.xAxes.forEach((xAxe) => {
    xAxe.gridLines = xAxe.gridLines || {};

    xAxe.gridLines.display = false;
  });

  Chart.defaults.bar.scales.yAxes.forEach((yAxe) => {
    yAxe.gridLines = yAxe.gridLines || {};
    yAxe.ticks = yAxe.ticks || {};

    yAxe.gridLines.zeroLineWidth = 2;

    yAxe.ticks.padding = 5;
  });

  Chart.defaults.horizontalBar.scales.xAxes.forEach((xAxe) => {
    xAxe.gridLines = xAxe.gridLines || {};
    xAxe.ticks = xAxe.ticks || {};

    xAxe.gridLines.zeroLineWidth = 2;

    xAxe.ticks.padding = 5;
  });

  Chart.defaults.horizontalBar.scales.yAxes.forEach((yAxe) => {
    yAxe.gridLines = yAxe.gridLines || {};

    yAxe.gridLines.display = false;
  });

  Chart.defaults.pie.plugins = Chart.defaults.pie.plugins || {};
  Chart.defaults.pie.plugins.datalabels = {
    formatter(value) {
      return `${value}%`;
    },
    backgroundColor: '#fff',
    color: '#5b6372',
    borderRadius: 5,
    font: {
      weight: 600,
      size: 14,
    },
    padding: {
      top: 2,
      bottom: 0,
    },
  };

  Chart.defaults.doughnut.plugins = Chart.defaults.doughnut.plugins || {};
  Chart.defaults.doughnut.plugins.datalabels = {
    formatter(value) {
      return `${value}%`;
    },
    backgroundColor: '#fff',
    color: '#5b6372',
    borderRadius: 5,
    font: {
      weight: 600,
      size: 14,
    },
    padding: {
      top: 2,
      bottom: 0,
    },
  };

  Chart.defaults.line.elements = Chart.defaults.line.elements || {};
  Chart.defaults.line.elements.point = {
    radius: 0,
  };

  Chart.defaults.line.tooltips = {
    mode: 'index',
    intersect: false,
  };

  Chart.defaults.line.hover = {
    mode: 'intersect',
    intersect: true,
  };

  Chart.defaults.line.scales.xAxes.forEach((xAxe) => {
    xAxe.gridLines = xAxe.gridLines || {};

    xAxe.gridLines.drawOnChartArea = false;
  });

  Chart.defaults.line.scales.yAxes.forEach((yAxe) => {
    yAxe.gridLines = yAxe.gridLines || {};
    yAxe.ticks = yAxe.ticks || {};

    yAxe.gridLines.zeroLineWidth = 2;

    yAxe.ticks.padding = 5;
  });

  Chart.defaults.area = { ...Chart.defaults.line };

  Chart.defaults.area.elements.line = Chart.defaults.area.elements.line || {};
  Chart.defaults.area.elements.line.tension = 0.000001;

  Chart.controllers.area = Chart.controllers.line.extend({
    draw(ease) {
      Chart.controllers.line.prototype.draw.call(this, ease);
    },
  });

  Chart.defaults.radar.scale.gridLines =
    Chart.defaults.radar.scale.gridLines || {};
  Chart.defaults.radar.scale.gridLines.z = 1;

  Chart.defaults.radar.scale.ticks = Chart.defaults.radar.scale.ticks || {};
  Chart.defaults.radar.scale.ticks.z = 1;
  Chart.defaults.radar.scale.ticks.fontSize = 14;

  Chart.defaults.radar.tooltips = Chart.defaults.radar.tooltips || {};
  Chart.defaults.radar.tooltips.position = 'nearest';
  Chart.defaults.radar.tooltips.mode = 'nearest';
  Chart.defaults.radar.tooltips.intersect = false;

  // ------------------- Chart.js COSMOS CUSTOM PLUGINS -------------------

  (function () {
    const CHART_COSMOS_PALETTE = [
      '#279cff',
      '#fadd4b',
      '#ff7373',
      '#99ba6e',
      '#b042ff',
      '#ff4c33',
    ];

    const CHART_COSMOS_AC_PALETTE = {
      'preto-amarelo': [
        {
          backgroundColor: '#ff0',
          patternColor: '#000',
        },
        {
          backgroundColor: '#fff',
          patternColor: '#000',
        },
        {
          backgroundColor: '#000',
          patternColor: '#fff',
        },
      ],
      'preto-azul': [
        {
          backgroundColor: '#3737e2',
          patternColor: '#000',
        },
        {
          backgroundColor: '#fff',
          patternColor: '#000',
        },
        {
          backgroundColor: '#000',
          patternColor: '#fff',
        },
      ],
      'azul-amarelo': [
        {
          backgroundColor: '#ff0',
          patternColor: '#043e5f',
        },
        {
          backgroundColor: '#fff',
          patternColor: '#043e5f',
        },
        {
          backgroundColor: '#043e5f',
          patternColor: '#fff',
        },
      ],
      'branco-azul': [
        {
          backgroundColor: '#3737e2',
          patternColor: '#fff',
        },
        {
          backgroundColor: '#000',
          patternColor: '#fff',
        },
        {
          backgroundColor: '#fff',
          patternColor: '#000',
        },
      ],
    };

    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const getRandomPatterns = () => {
      const patterns = [
        'plus',
        'cross',
        'dash',
        'cross-dash',
        'dot',
        'dot-dash',
        'disc',
        'ring',
        'line',
        'line-vertical',
        'weave',
        'zigzag',
        'zigzag-vertical',
        'diagonal',
        'diagonal-right-left',
        'square',
        'box',
        'triangle',
        'triangle-inverted',
        'diamond',
        'diamond-box',
      ];

      shuffleArray(patterns);

      return patterns;
    };

    const setDefaultChartTheme = (chart) => {
      setDefaultChartColors();

      if (
        chart.config.type === 'bar' ||
        chart.config.type === 'horizontalBar' ||
        chart.config.type === 'line' ||
        chart.config.type === 'area'
      ) {
        if (chart.scales && chart.scales['x-axis-0']) {
          chart.scales['x-axis-0'].options.gridLines.color = '#d8e1ea';
          chart.scales['x-axis-0'].options.gridLines.zeroLineColor = '#bdc4cc';
        }
        if (chart.scales && chart.scales['y-axis-0']) {
          chart.scales['y-axis-0'].options.gridLines.color = '#d8e1ea';
          chart.scales['y-axis-0'].options.gridLines.zeroLineColor = '#bdc4cc';
        }
      }

      if (chart.config.type === 'pie' || chart.config.type === 'doughnut') {
        const dataLabelPlugin = chart.$plugins.descriptors.find(
          (descriptor) => descriptor.plugin.id === 'datalabels'
        );
        dataLabelPlugin.options.color = '#5b6372';
        dataLabelPlugin.options.backgroundColor = '#fff';
      }

      if (chart.config.type === 'radar') {
        chart.config.options.scale.angleLines.color = '#d8e1ea';
        chart.config.options.scale.gridLines.color = '#d8e1ea';
        chart.config.options.scale.ticks.backdropColor = '#fff';
        chart.config.options.scale.ticks.fontColor = '#5c6473';
        chart.config.options.scale.ticks.fontSize = 14;
      }
    };

    const setHighContrastChartColors = (chart, highContrast) => {
      Chart.defaults.global.defaultFontColor =
        CHART_COSMOS_AC_PALETTE[highContrast][1].backgroundColor;

      const { datasets } = chart.data;

      const patterns = getRandomPatterns();

      if (
        chart.config.type === 'bar' ||
        chart.config.type === 'horizontalBar' ||
        chart.config.type === 'line' ||
        chart.config.type === 'area'
      ) {
        if (chart.scales && chart.scales['x-axis-0']) {
          chart.scales['x-axis-0'].options.gridLines.color =
            CHART_COSMOS_AC_PALETTE[highContrast][1].backgroundColor;
          chart.scales['x-axis-0'].options.gridLines.zeroLineColor =
            CHART_COSMOS_AC_PALETTE[highContrast][1].backgroundColor;
        }
        if (chart.scales && chart.scales['y-axis-0']) {
          chart.scales['y-axis-0'].options.gridLines.color =
            CHART_COSMOS_AC_PALETTE[highContrast][1].backgroundColor;
          chart.scales['y-axis-0'].options.gridLines.zeroLineColor =
            CHART_COSMOS_AC_PALETTE[highContrast][1].backgroundColor;
        }

        datasets.forEach((dataset, index) => {
          const chartCosmosAcPaletteLength =
            CHART_COSMOS_AC_PALETTE['azul-amarelo'].length;
          let desiredIndex = index % chartCosmosAcPaletteLength;

          // Não permite que as linhas possuam cor de fundo igual a cor de fundo da página
          if (chart.config.type === 'line' && desiredIndex === 2) {
            desiredIndex -= 1;
          }

          dataset.backgroundColor = pattern.draw(
            patterns[index],
            CHART_COSMOS_AC_PALETTE[highContrast][desiredIndex].backgroundColor,
            CHART_COSMOS_AC_PALETTE[highContrast][desiredIndex].patternColor
          );
          dataset.borderColor = pattern.draw(
            patterns[index],
            CHART_COSMOS_AC_PALETTE[highContrast][desiredIndex].backgroundColor,
            CHART_COSMOS_AC_PALETTE[highContrast][desiredIndex].patternColor
          );
        });
      }

      if (chart.config.type === 'pie' || chart.config.type === 'doughnut') {
        const dataLabelPlugin = chart.$plugins.descriptors.find(
          (descriptor) => descriptor.plugin.id === 'datalabels'
        );
        dataLabelPlugin.options.color =
          CHART_COSMOS_AC_PALETTE[highContrast][1].patternColor;
        dataLabelPlugin.options.backgroundColor =
          CHART_COSMOS_AC_PALETTE[highContrast][1].backgroundColor;

        datasets.forEach((dataset) => {
          dataset.backgroundColor = dataset.data.map((_, index) => {
            const chartCosmosAcPaletteLength =
              CHART_COSMOS_AC_PALETTE['azul-amarelo'].length;
            const desiredIndex =
              index === chartCosmosAcPaletteLength
                ? 1
                : index % chartCosmosAcPaletteLength;

            return pattern.draw(
              patterns[index],
              CHART_COSMOS_AC_PALETTE[highContrast][desiredIndex]
                .backgroundColor,
              CHART_COSMOS_AC_PALETTE[highContrast][desiredIndex].patternColor
            );
          });
        });
      }

      if (chart.config.type === 'radar') {
        chart.config.options.scale.angleLines.color = hexToRgba(
          CHART_COSMOS_AC_PALETTE[highContrast][1].backgroundColor,
          0.8
        );
        chart.config.options.scale.gridLines.color = hexToRgba(
          CHART_COSMOS_AC_PALETTE[highContrast][1].backgroundColor,
          0.8
        );
        chart.config.options.scale.ticks.backdropColor =
          CHART_COSMOS_AC_PALETTE[highContrast][1].backgroundColor;
        chart.config.options.scale.ticks.fontColor =
          CHART_COSMOS_AC_PALETTE[highContrast][1].patternColor;
        chart.config.options.scale.ticks.fontSize = 16;

        datasets.forEach((dataset) => {
          dataset.backgroundColor =
            CHART_COSMOS_AC_PALETTE[highContrast][0].backgroundColor;
          dataset.borderColor =
            CHART_COSMOS_AC_PALETTE[highContrast][0].backgroundColor;
        });
      }
    };

    const html = document.documentElement;
    const { body } = document;

    Chart.pluginService.register({
      id: 'tintCosmosCharts',
      afterRender(chart) {
        const setInitialChartColors = () => {
          if (
            chart.config.type === 'bar' ||
            chart.config.type === 'horizontalBar' ||
            chart.config.type === 'line' ||
            chart.config.type === 'area' ||
            chart.config.type === 'radar'
          ) {
            const { datasets } = chart.data;

            datasets.forEach((dataset, index) => {
              if (!dataset.initial) {
                if (!dataset.backgroundColor) {
                  dataset.backgroundColor = CHART_COSMOS_PALETTE[index];
                  dataset.borderColor = CHART_COSMOS_PALETTE[index];
                }
                dataset.backgroundColorInitial = dataset.backgroundColor;
                dataset.initial = true;
              } else {
                dataset.backgroundColor = dataset.backgroundColorInitial;
                dataset.borderColor = dataset.backgroundColorInitial;
              }
            });
          }

          if (chart.config.type === 'pie' || chart.config.type === 'doughnut') {
            const { datasets } = chart.data;

            datasets.forEach((dataset) => {
              dataset.backgroundColor = dataset.data.map(
                (_, index) => CHART_COSMOS_PALETTE[index]
              );
            });
          }
        };

        if (!chart.renderedOnce) {
          setInitialChartColors();
        }

        html.addEventListener('contrastChange', ({ detail }) => {
          if (detail.ac === 'off') {
            setDefaultChartTheme(chart);
            setInitialChartColors();
          } else {
            setHighContrastChartColors(chart, detail.ac);
          }

          // É necessário chamar o update duas vezes, caso contrário a cor da
          // legenda não atualizará
          chart.update();
          chart.update();
        });

        chart.activateHighContrast = (highContrast) => {
          setHighContrastChartColors(chart, highContrast);

          chart.update();
          chart.update();
        };

        const { acActivated } = body.dataset;
        if (!chart.renderedOnce) {
          html.dispatchEvent(
            new CustomEvent('contrastChange', {
              detail: { ac: acActivated || 'off' },
            })
          );
        }

        chart.renderedOnce = true;
      },
    });
  })();
}

// ************************* FIM DO chart.js *************************
