(function(Cosmos) {
  const PROGRESS_THEMES = ['danger', 'warning', 'success'];
  const PROGRESS_STATES = {
    pending: 'Em andamento',
    finished: 'Finalizado',
    not_apply: 'Não se aplica',
  };

  const progressBarsRanged = document.querySelectorAll(
    '.progress-bar[data-range]'
  );

  const progressBarObserver = new MutationObserver(mutations => {
    mutations.forEach(mutationRecord => {
      const { target: progressBar } = mutationRecord;

      const [currentWidth] = progressBar.style.width.split('%');

      progressBar.setProperTheme(Number(currentWidth));
    });
  });

  const progressBarRangedHandler = progressBarRanged => {
    const { range: rawRange } = progressBarRanged.dataset;
    const [tooltip] = $(progressBarRanged).siblings('span.title');
    const rangeArray = JSON.parse(rawRange);
    const rangeWithThemes = rangeArray.map((range, index) => ({
      range,
      theme: PROGRESS_THEMES[index],
    }));

    const [currentWidth] = progressBarRanged.style.width.split('%');

    const removeAllThemes = () => {
      PROGRESS_THEMES.forEach(theme => {
        progressBarRanged.classList.remove(theme);
      });
    };

    const setTooltipText = (width, notApply) => {
      if (!tooltip) return;

      if (!width) {
        tooltip.classList.add('d-none');
        return;
      }

      tooltip.classList.remove('d-none');

      if (notApply || notApply === '') {
        tooltip.innerText = PROGRESS_STATES.not_apply;
        return;
      }

      if (width < 100) {
        tooltip.innerText = PROGRESS_STATES.pending;
        return;
      }

      if (width >= 100) {
        tooltip.innerText = PROGRESS_STATES.finished;
      }
    };

    const setProperTheme = width => {
      removeAllThemes();

      const { notApply } = progressBarRanged.dataset;

      setTooltipText(width, notApply);

      if (notApply || notApply === '') {
        progressBarRanged.classList.add('not-apply');
        return;
      }

      if (!width) {
        return;
      }

      const belongedRange = rangeWithThemes.find(({ range }) => {
        const [min, max] = range;

        if (min <= width && width <= max) {
          return true;
        }

        return false;
      });

      progressBarRanged.classList.add(belongedRange.theme);
    };

    setProperTheme(Number(currentWidth));

    progressBarObserver.observe(progressBarRanged, {
      attributes: true,
      attributeFilter: ['style', 'data-not-apply'],
    });

    progressBarRanged.setProperTheme = setProperTheme;
  };

  progressBarsRanged.forEach(progressBarRangedHandler);

  const COMPONENT_NAME = 'progress';
  const methods = {
    register: {
      progressBarRanged: progressBarRangedHandler,
    },
  };

  Cosmos[COMPONENT_NAME] = methods;
})(window.Cosmos);

// ************************* FIM DO progress.js *************************
