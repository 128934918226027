import { installGlobal } from '../../global';
import ModeLibras from './ToggleModeLibras';
import { COMPONENT_NAME } from './constants';
import State, { ToggleModeLibras } from './state';

function initButtonToggleModeLibras() {
  const buttonsToggleModeLibras = document.querySelectorAll(
    '[data-toggle-libras]'
  );
  buttonsToggleModeLibras.forEach((button) => {
    new ModeLibras(button as HTMLElement);
  });
}

function init() {
  initButtonToggleModeLibras();
}

const methods = {
  init,
  ...State,
};

installGlobal<ToggleModeLibras>(COMPONENT_NAME, methods);

export default methods;
