/* Classe para instanciar um objeto */
export default class PasswordInputField {
  passwordInputField: HTMLInputElement;
  actionInputField: HTMLButtonElement;

  /**
   * Instancia do objeto
   * @param {HTMLInputElement} passwordInputField - Objeto referenciando a raiz do componente DOM
   * @param {HTMLButtonElement} actionInputField - Objeto referenciando a raiz do componente DOM
   */
  constructor(passwordInputField: HTMLInputElement, actionInputField: HTMLButtonElement) {
    this.passwordInputField = passwordInputField;
    this.actionInputField = actionInputField;
    if (!this.passwordInputField) {
      console.error(`Não foi possível encontrar o campo de senha ou se a estrutura HTML atende ao padrão estabelecido.`);
      return;
    }
    if (!this.actionInputField) {
      console.error(`Não foi possível encontrar o botão no elemento fornecido.\nVerifique se o botão de ação possui o atributo data-"${actionInputField.dataset.toggleShowPassword}"\ne/ou se a estrutura HTML atende ao padrão estabelecido.`);
      return;
    }
    this.init();
  }

  init(): void {
    this._setBehavior();
  }

  /**
   * Define comportamentos do componente
   * @private
   */
  private _setBehavior(): void {
    this._setPasswordViewBehavior();
  }

  /**
   * Define comportamentos do componente
   * @private
   */
  private _setPasswordViewBehavior(): void {
    if (!this.passwordInputField.disabled) {
      const inputActionHandler = (event: Event): void => {
        this._toggleShowPassword(event);
      };
      this.actionInputField.addEventListener('click', inputActionHandler, false);
    }
  }

  /**
   * Define comportamentos do componente
   * @private
   * @param {Event} event - referência ao elemento que dispara a ação
   */
  private _toggleShowPassword(event: Event): void {
    const target = event.currentTarget as HTMLElement;
    for (const icon of target?.querySelectorAll('.fas, .fa-solid') as NodeListOf<HTMLElement>) {
      if (icon?.classList.contains('fa-eye')) {
        icon?.classList.replace('fa-eye', 'fa-eye-slash');
        this.passwordInputField.setAttribute('type', 'text');
        this.actionInputField.setAttribute('aria-checked', 'true');
      } else if (icon?.classList.contains('fa-eye-slash')) {
        this.passwordInputField.setAttribute('type', 'password');
        this.actionInputField.setAttribute('aria-checked', 'false');
        icon?.classList.replace('fa-eye-slash', 'fa-eye');
      }
    }
  }
}
