/**
 * Returns a debounced function that delays invoking func until after delay milliseconds have
 * elapsed since the last time the debounced function was invoked.
 *
 * Retirado de: https://amitd.co/code/typescript/debounce
 */
const debounce = <T extends (...args: any[]) => ReturnType<T>>(
  callback: T,
  timeout: number
): ((...args: Parameters<T>) => void) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(...args);
    }, timeout);
  };
};

export default debounce;
