(function() {
  const logoHeader = document.querySelector('.header__logo > a');

  if (logoHeader) {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutationRecord => {
        const [firstAddedNode] = mutationRecord.addedNodes;
        const [firstRemovedNode] = mutationRecord.removedNodes;
        const { target } = mutationRecord;

        if (
          firstAddedNode &&
          firstAddedNode.tagName &&
          firstAddedNode.tagName.toLowerCase() === 'vlibraswidget'
        ) {
          target.replaceChild(firstRemovedNode, firstAddedNode);
        }
      });
    });

    observer.observe(logoHeader, {
      subtree: true,
      childList: true,
    });
  }
})();

// ************************* FIM DO vlibras-hack.js *************************
