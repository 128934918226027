(function() {
  document
    .querySelectorAll('.timeline[data-detail-collapsable]')
    .forEach(timeline => {
      const timelineParent = timeline.parentNode;
      const wrapper = document.createElement('div');
      wrapper.classList.add('timeline-wrapper');

      timelineParent.replaceChild(wrapper, timeline);
      wrapper.appendChild(timeline);

      const buttonDetails = document.createElement('button');
      buttonDetails.classList.add(
        'font-weight-semibold',
        'link-normal',
        'ml-4',
        'mt-2'
      );

      const CLASS_COLLAPSED = 'collapsed';

      const buttonLabel = timeline.dataset.collapseLabel || 'Expandir detalhes';
      const buttonExpandLabel =
        timeline.dataset.collapseExpandLabel || 'Recolher detalhes';

      timeline.parentNode.append(buttonDetails);

      const updateButtonLabel = () => {
        const text = timeline.classList.contains(CLASS_COLLAPSED)
          ? buttonLabel
          : buttonExpandLabel;
        const icon = timeline.classList.contains(CLASS_COLLAPSED)
          ? 'down'
          : 'up';
        buttonDetails.innerHTML = `${text} <i class="fas fa-angle-${icon}"></i>`;
      };

      const detailsToggle = () => {
        if (timeline.classList.contains(CLASS_COLLAPSED)) {
          timeline.classList.remove('collapsed');
        } else {
          timeline.classList.add('collapsed');
        }
        updateButtonLabel();
      };

      buttonDetails.addEventListener('click', () => {
        detailsToggle();
      });

      updateButtonLabel();
    });
})();

// ************************* FIM DO timeline.js *************************
