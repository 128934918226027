(function(Cosmos) {
  const COMPONENT_NAME = 'SIEDI';
  Cosmos[COMPONENT_NAME] = {
    register: {},
  };

  const SIEDI = {
    checkpoint: {
      insert: 'afterbegin',
      updateDropdown: () => {},
    },
    iniciativa: {
      insert: 'beforeend',
      updateDropdown: () => {},
    },
  };
  const IS_TABLET = 768;

  (function() {
    const removeTagCheckpoint = (tag, nameTag, descricaoTag) => {
      const divDropdownMenu = tag.parentElement.parentElement.parentElement;
      const divCheckpointInfo = divDropdownMenu.getElementsByClassName(
        'checkpoint-info'
      )[0];
      const divCheckpointTag = divDropdownMenu.getElementsByClassName(
        'checkpoint-tag'
      )[0];
      Array.from(divCheckpointTag.children).forEach(tagFilho => {
        if (tagFilho.classList.contains('d-none')) {
          if (tagFilho.innerText === `${nameTag}${descricaoTag}`) {
            tagFilho.classList.remove('d-none');
          }
        }
      });

      const limitTagsWarning = divDropdownMenu.querySelector('.tags-warn');

      if (limitTagsWarning) {
        limitTagsWarning.classList.add('d-none');
      }

      const tagIndex = divDropdownMenu.tagsSelectedOnThisOpening.findIndex(
        t => t === tag.parentElement
      );

      if (tagIndex !== -1) {
        divDropdownMenu.tagsSelectedOnThisOpening.splice(tagIndex, 1);
      } else {
        divDropdownMenu.tagsRemovedOnThisOpening.push(tag.parentElement);
      }

      // Remove a div info-tag quando é clicado nele;
      tag.parentElement.remove();

      if (divCheckpointInfo.children.length === 1) {
        divCheckpointInfo.children[0].classList.remove('d-none');
      }
    };

    /* ----- Cria Estrutura para checkpoint info -----------------------------------------
    | Essa função cria o corpo das info tag, que seram utilizadas para mostrar
    | as tags que forem selecionadas anteriormente.
    *-------------------------------------------------------------------*/
    const criaCheckpointInfo = (nameTag, descricaoTag) => {
      const divContent = document.createElement('div');
      const buttonTag = document.createElement('button');
      const iTag = document.createElement('i');
      const pInfo = document.createElement('p');

      // Icone
      iTag.classList.add('fas', 'fa-times');

      // Botão
      buttonTag.classList.add('tag-filtro', 'darker');
      buttonTag.type = 'button';
      buttonTag.innerText = nameTag;
      buttonTag.appendChild(iTag);
      buttonTag.addEventListener('click', () => {
        removeTagCheckpoint(buttonTag, nameTag, descricaoTag);
      });

      // Paragrafo
      pInfo.innerText = descricaoTag;
      pInfo.classList.add('p3');

      // Div
      divContent.classList.add('info-tag');
      divContent.appendChild(buttonTag);
      divContent.appendChild(pInfo);
      divContent.tagCode = nameTag;

      return divContent;
    };

    const getTagsSelected = container => {
      const tags = container.querySelectorAll('.info-tag');
      return tags;
    };

    /* ----- Cria Estrutura para checkpoint tag ---------------------------------
    | Essa função cria o corpo das tags de acordo com a informação vinda da api.
    *-------------------------------------------------------------------*/
    const criaCheckpointTag = (
      dadosTag,
      maxQuantityOfTagsAllowed,
      limitTagsWarning
    ) => {
      if (!maxQuantityOfTagsAllowed || maxQuantityOfTagsAllowed < 0)
        maxQuantityOfTagsAllowed = 3;

      const buttonTag = document.createElement('button');
      const iTag = document.createElement('i');
      const spanInfo = document.createElement('span');

      // Icon
      iTag.classList.add('fas', 'fa-check');

      // Span
      spanInfo.classList.add('title');
      spanInfo.innerText = dadosTag.descricao;

      // Botão
      buttonTag.classList.add('tag-filtro', 'darker');
      buttonTag.type = 'button';
      buttonTag.innerText = dadosTag.name;
      buttonTag.tagCode = dadosTag.name;
      buttonTag.appendChild(iTag);
      buttonTag.appendChild(spanInfo);
      buttonTag.addEventListener('click', () => {
        const divDropdownMenu = buttonTag.parentElement.parentElement;
        const [divCheckpointInfo] = divDropdownMenu.getElementsByClassName(
          'checkpoint-info'
        );

        const tags = getTagsSelected(divCheckpointInfo);

        if (tags.length >= maxQuantityOfTagsAllowed) {
          limitTagsWarning.classList.remove('d-none');
          return;
        }

        const infoTag = criaCheckpointInfo(dadosTag.name, dadosTag.descricao);
        divCheckpointInfo.appendChild(infoTag);

        const tagIndex = divDropdownMenu.tagsRemovedOnThisOpening.findIndex(
          tag => tag.tagCode === infoTag.tagCode
        );

        if (tagIndex !== -1) {
          divDropdownMenu.tagsRemovedOnThisOpening.splice(tagIndex, 1);
        } else {
          divDropdownMenu.tagsSelectedOnThisOpening.push(infoTag);
        }

        if (!divCheckpointInfo.children[0].classList.contains('d-none')) {
          divCheckpointInfo.children[0].classList.add('d-none');
        }

        buttonTag.classList.add('d-none');
      });

      window.tooltipMouseMove(buttonTag);

      return buttonTag;
    };

    /* ----- Componente Vincular Tag Checkpoint ---------------------------------
    | Esse trecho de codigo cria as tags e os funcionamentos do componente vincular tag
    | checkpoint, a partir da chamada a api:
    | https://static.info.ufrn.br/current/json/vincular-tag-checkpoint-siedi-api.json
    | ou de um JSON enviado pelo componente
    *-------------------------------------------------------------------*/
    /**
     * Exemplo da Api utilizada: https://static.info.ufrn.br/current/json/vincular-tag-checkpoint-siedi-api.json
     *
     * Parâmetro name -> Indica o nome da tag;
     *
     * Parâmetro descricao -> Indica a descrição daquela tag;
     */
    const vincularTagsCheckpoints = document.querySelectorAll(
      '.action.checkpoint'
    );
    vincularTagsCheckpoints.forEach(async vincularTagcheckpoint => {
      const apiUrl = vincularTagcheckpoint.dataset.api;
      const apiJSON = vincularTagcheckpoint.dataset.json;
      const maxQuantityOfTagsAllowed =
        vincularTagcheckpoint.dataset.maxSelected;
      if (apiUrl || apiJSON) {
        let dadosApi = [];
        if (apiUrl) {
          const data = await fetch(apiUrl);
          dadosApi = await data.json();
        } else if (apiJSON) {
          dadosApi = JSON.parse(apiJSON);
        }

        const dropdownMenu = vincularTagcheckpoint.nextElementSibling;
        dropdownMenu.tagsSelectedOnThisOpening = [];
        dropdownMenu.tagsRemovedOnThisOpening = [];

        const checkpointTag = dropdownMenu.getElementsByClassName(
          'checkpoint-tag'
        )[0];
        const checkpointInfo = dropdownMenu.getElementsByClassName(
          'checkpoint-info'
        )[0];
        const checkpointButton = dropdownMenu.getElementsByClassName(
          'checkpoint-button'
        )[0];
        const [addCommentContainer] = $(vincularTagcheckpoint).parents(
          '.add-comment-container'
        );

        if (addCommentContainer) {
          const form = addCommentContainer.querySelector('form');

          if (form) {
            const resetCheckpointTagsDataState = () => {
              const checkpointButtonTags = form.querySelectorAll(
                'button[data-belongs="checkpoint"]'
              );

              checkpointButtonTags.forEach(checkpointButtonTag => {
                checkpointButtonTag.click();
              });
            };

            form.resetCheckpointTagsDataState = resetCheckpointTagsDataState;
          }
        }

        const limitTagsWarning = dropdownMenu.querySelector('.tags-warn');

        if (limitTagsWarning) {
          const limitTagsWarningQnt = limitTagsWarning.querySelector(
            '.tags-qnt'
          );
          limitTagsWarning.classList.add('d-none');
          limitTagsWarningQnt.innerText = maxQuantityOfTagsAllowed;
        }

        const removeTagOnCheckpointDropdown = ({ label, meaning }) => {
          const infoTags = checkpointInfo.querySelectorAll('.info-tag');

          infoTags.forEach(infoTag => {
            const tag = infoTag.querySelector('.tag-filtro');

            if (tag.innerText === label) {
              removeTagCheckpoint(tag, label, meaning);
            }
          });
        };

        SIEDI.checkpoint.updateDropdown = removeTagOnCheckpointDropdown;

        const emitTagsToForm = () => {
          const infoTags = Array.from(
            checkpointInfo.querySelectorAll('.info-tag')
          );

          const tagsData = infoTags.map(infoTag => ({
            label: infoTag.querySelector('.tag-filtro').innerText,
            meaning: infoTag.querySelector('p').innerText,
          }));

          // Reverter é necessário nesse caso! Pois caso contrário as tags seriam
          // inseridas de trás pra frente.
          tagsData.reverse();

          const [siediForm] = $(checkpointInfo).parents('form');

          siediForm.dispatchEvent(
            new CustomEvent('updatetags', {
              detail: {
                tags: tagsData,
                belongs: 'checkpoint',
              },
            })
          );
        };

        // Para cada dado da Api, cria-se uma tag e a põe na div certa
        dadosApi.forEach(tag => {
          const tagButton = criaCheckpointTag(
            tag,
            Number(maxQuantityOfTagsAllowed),
            limitTagsWarning
          );
          checkpointTag.appendChild(tagButton);
        });
        const buttonCancelar = checkpointButton.getElementsByClassName(
          'btn-secondary'
        )[0];
        buttonCancelar.addEventListener('click', () => {
          // Remove todos os d-nones das tags selecionadas na abertura atual do dropdown
          Array.from(checkpointTag.children).forEach(filho => {
            const wasTagSelectedOnThisOpening = dropdownMenu.tagsSelectedOnThisOpening.find(
              tag => tag.tagCode === filho.tagCode
            );

            if (
              wasTagSelectedOnThisOpening &&
              filho.classList.contains('d-none')
            ) {
              filho.classList.remove('d-none');
            }

            const wasTagRemovedOnThisOpening = dropdownMenu.tagsRemovedOnThisOpening.find(
              tag => tag.tagCode === filho.tagCode
            );

            if (
              wasTagRemovedOnThisOpening &&
              !filho.classList.contains('d-none')
            ) {
              filho.classList.add('d-none');
            }
          });

          // Remove todos as tags selecionadas na abertura atual do dropdown
          if (checkpointInfo.children.length > 1) {
            Array.from(checkpointInfo.children).forEach(filho => {
              const wasTagSelectedOnThisOpening = dropdownMenu.tagsSelectedOnThisOpening.includes(
                filho
              );

              if (wasTagSelectedOnThisOpening) {
                filho.remove();
              }
            });
          }

          checkpointInfo.append(...dropdownMenu.tagsRemovedOnThisOpening);

          const infoTitulo = checkpointInfo.querySelector('.info-titulo');
          if (checkpointInfo.children.length === 1) {
            infoTitulo.classList.remove('d-none');
          } else {
            infoTitulo.classList.add('d-none');
          }

          if (limitTagsWarning) {
            limitTagsWarning.classList.add('d-none');
          }

          dropdownMenu.tagsSelectedOnThisOpening = [];
          dropdownMenu.tagsRemovedOnThisOpening = [];

          window.closeDropdown(vincularTagcheckpoint);
        });
        const buttonVincular = checkpointButton.getElementsByClassName(
          'btn-primary'
        )[0];
        buttonVincular.addEventListener('click', () => {
          if (checkpointInfo) {
            emitTagsToForm();
          }

          if (limitTagsWarning) {
            limitTagsWarning.classList.add('d-none');
          }

          dropdownMenu.tagsSelectedOnThisOpening = [];
          dropdownMenu.tagsRemovedOnThisOpening = [];

          window.closeDropdown(vincularTagcheckpoint);
        });
      }
    });
  })();

  (function() {
    const deactivateTag = tag => {
      tag.dataset.activeted = 'false';

      const [dropdownMenu] = $(tag).parents('.dropdown-menu');
      const [pIESelecionados] = dropdownMenu.getElementsByClassName(
        'iniciativa-selecionados'
      );
      const [span] = pIESelecionados.children;

      dropdownMenu.quantidadeIESelecionadas -= 1;
      const tagIndex = dropdownMenu.tagsSelectedOnThisOpening.findIndex(
        t => t.tagCode === tag.tagCode
      );

      if (tagIndex !== -1) {
        dropdownMenu.tagsSelectedOnThisOpening.splice(tagIndex, 1);
      } else {
        dropdownMenu.tagsRemovedOnThisOpening.push(tag);
      }

      if (dropdownMenu.quantidadeIESelecionadas < 10) {
        span.innerText = `0${dropdownMenu.quantidadeIESelecionadas}`;
        if (dropdownMenu.quantidadeIESelecionadas === 0) {
          pIESelecionados.classList.add('d-none');
        }
      } else {
        span.innerText = `${dropdownMenu.quantidadeIESelecionadas}`;
      }
    };

    const updateIESelectedLabel = dropdown => {
      const [pIESelecionados] = dropdown.getElementsByClassName(
        'iniciativa-selecionados'
      );
      const [span] = pIESelecionados.children;

      if (dropdown.quantidadeIESelecionadas === 0) {
        pIESelecionados.classList.add('d-none');
        return;
      }

      pIESelecionados.classList.remove('d-none');
      if (dropdown.quantidadeIESelecionadas < 10) {
        span.innerText = `0${dropdown.quantidadeIESelecionadas}`;
      } else {
        span.innerText = `${dropdown.quantidadeIESelecionadas}`;
      }
    };

    /* ----- Cria Estrutura para tag conteudo -----------------------------------------
    | Essa função cria o corpo das tag conteudo, a partir das informações vindas da api.
    *-------------------------------------------------------------------*/
    const criaTagConteudo = (
      dadosTagConteudo,
      maxQuantityOfTagsAllowed = 2
    ) => {
      // Header
      const spanConteudoHead = document.createElement('span');
      spanConteudoHead.classList.add('tag-conteudo__head');
      const pHead = document.createElement('p');
      pHead.innerText = dadosTagConteudo.name;
      pHead.classList.add('name');
      spanConteudoHead.appendChild(pHead);

      // Body
      const spanConteudoBody = document.createElement('span');
      spanConteudoBody.classList.add('tag-conteudo__body');
      const pBody = document.createElement('p');
      pBody.innerText = dadosTagConteudo.descricao;
      pBody.classList.add('descricao');
      spanConteudoBody.appendChild(pBody);

      // Footer
      const spanConteudoFooter = document.createElement('span');
      spanConteudoFooter.classList.add('tag-conteudo__footer');
      const iconeFooter = document.createElement('i');
      iconeFooter.classList.add('fas', 'fa-check');
      spanConteudoFooter.appendChild(iconeFooter);

      // Grid container
      const spanGridContainer = document.createElement('span');
      spanGridContainer.append(
        spanConteudoHead,
        spanConteudoBody,
        spanConteudoFooter
      );

      // Botão
      const buttonTagConteudo = document.createElement('button');
      buttonTagConteudo.type = 'button';
      buttonTagConteudo.classList.add('tag-conteudo');
      buttonTagConteudo.setAttribute('data-activeted', 'false');
      buttonTagConteudo.tagCode = dadosTagConteudo.name;
      buttonTagConteudo.appendChild(spanGridContainer);

      window.tagConteudoIconChangeFeatures(buttonTagConteudo);

      buttonTagConteudo.addEventListener('click', () => {
        const [dropdownMenu] = $(buttonTagConteudo).parents('.dropdown-menu');
        const limitTagsWarning = dropdownMenu.querySelector('.tags-warn');

        if (buttonTagConteudo.getAttribute('data-activeted') === 'true') {
          if (
            dropdownMenu.quantidadeIESelecionadas >= maxQuantityOfTagsAllowed
          ) {
            limitTagsWarning.classList.remove('d-none');
            buttonTagConteudo.dataset.activeted = 'false';
            return;
          }

          dropdownMenu.quantidadeIESelecionadas += 1;

          const tagIndex = dropdownMenu.tagsRemovedOnThisOpening.findIndex(
            tag => tag.tagCode === buttonTagConteudo.tagCode
          );

          if (tagIndex !== -1) {
            dropdownMenu.tagsRemovedOnThisOpening.splice(tagIndex, 1);
          } else {
            dropdownMenu.tagsSelectedOnThisOpening.push(buttonTagConteudo);
          }

          updateIESelectedLabel(dropdownMenu);
        } else {
          limitTagsWarning.classList.add('d-none');
          deactivateTag(buttonTagConteudo);
        }
      });

      return buttonTagConteudo;
    };

    const getIniciativasEncontradasLabel = quantity => {
      if (quantity === 1) {
        return `${quantity} iniciativa estratégica`;
      }

      return `${quantity} iniciativas estratégicas`;
    };

    /* ----- Componente Vincular Iniciativa Estrategica ---------------------------------
    | Esse trecho de codigo cria as tags e os funcionamentos do componente vincular iniciativa
    | estrategica, a partir da chamada a api:
    | https://static.info.ufrn.br/current/json/vincular-iniciativa-estrategica-siedi-api.json
    | ou de um JSON enviado pelo componente
    *-------------------------------------------------------------------*/
    /**
     * Exemplo da Api utilizada: https://static.info.ufrn.br/current/json/vincular-iniciativa-estrategica-siedi-api.json
     *
     * Parametro name -> Indica o nome da tag;
     *
     * Paramentro descricao -> Indica a descrição daquela tag;
     */
    const vincularIniciativasEstrategicas = document.querySelectorAll(
      '.action.iniciativa'
    );
    vincularIniciativasEstrategicas.forEach(
      async vincularIniciativaEstrategica => {
        const apiUrl = vincularIniciativaEstrategica.dataset.api;
        const apiJSON = vincularIniciativaEstrategica.dataset.json;
        const maxQuantityOfTagsAllowed =
          vincularIniciativaEstrategica.dataset.maxSelected;

        if (apiUrl || apiJSON) {
          let dadosApi = [];
          if (apiUrl) {
            const data = await fetch(apiUrl);
            dadosApi = await data.json();
          } else if (apiJSON) {
            dadosApi = JSON.parse(apiJSON);
          }

          const dropdownMenu = vincularIniciativaEstrategica.nextElementSibling;
          dropdownMenu.quantidadeIESelecionadas = 0;
          dropdownMenu.tagsSelectedOnThisOpening = [];
          dropdownMenu.tagsRemovedOnThisOpening = [];

          const iniciativaBusca = dropdownMenu.getElementsByClassName(
            'iniciativa-busca'
          )[0];
          const iniciativaInfo = dropdownMenu.getElementsByClassName(
            'iniciativa-info'
          )[0];
          const iniciativaButton = dropdownMenu.getElementsByClassName(
            'iniciativa-button'
          )[0];
          const [addCommentContainer] = $(
            vincularIniciativaEstrategica
          ).parents('.add-comment-container');

          if (addCommentContainer) {
            const form = addCommentContainer.querySelector('form');

            if (form) {
              const resetVincularIniciativaTagsDataState = () => {
                const iniciativaButtonTags = form.querySelectorAll(
                  'button[data-belongs="iniciativa"]'
                );

                iniciativaButtonTags.forEach(iniciativaButtonTag => {
                  iniciativaButtonTag.click();
                });
              };

              form.resetVincularIniciativaTagsDataState = resetVincularIniciativaTagsDataState;
            }
          }

          const limitTagsWarning = dropdownMenu.querySelector('.tags-warn');

          if (limitTagsWarning) {
            const limitTagsWarningQnt = limitTagsWarning.querySelector(
              '.tags-qnt'
            );
            limitTagsWarning.classList.add('d-none');
            limitTagsWarningQnt.innerText = maxQuantityOfTagsAllowed;
          }

          dadosApi.forEach(infosTag => {
            iniciativaInfo.appendChild(
              criaTagConteudo(infosTag, maxQuantityOfTagsAllowed)
            );
          });

          const [
            infoTotalIniciativasEstrategica,
          ] = iniciativaBusca.getElementsByClassName('ie-total');
          infoTotalIniciativasEstrategica.innerText = getIniciativasEncontradasLabel(
            dadosApi.length
          );

          const optionsBusca = {
            valueNames: ['name', 'descricao'],
          };

          const buscaIE = new List(dropdownMenu, optionsBusca);

          buscaIE.on('searchComplete', () => {
            infoTotalIniciativasEstrategica.innerText = getIniciativasEncontradasLabel(
              buscaIE.matchingItems.length
            );
          });

          const deactivateTagOnIniciativaDropdown = ({ label }) => {
            const tagToBeDeactivated = buscaIE.items.find(
              item =>
                item.elm.querySelector('.tag-conteudo__head p').innerText ===
                label
            );

            deactivateTag(tagToBeDeactivated.elm);
          };

          SIEDI.iniciativa.updateDropdown = deactivateTagOnIniciativaDropdown;

          const emitTagsToForm = () => {
            const selectedTags = buscaIE.items
              .filter(item => item.elm.dataset.activeted === 'true')
              .map(item => item.elm);

            const [siediForm] = $(vincularIniciativaEstrategica).parents(
              'form'
            );

            const tagsData = selectedTags.map(selectedTag => ({
              label: selectedTag.querySelector('.tag-conteudo__head p')
                .innerText,
              meaning: selectedTag.querySelector('.tag-conteudo__body p')
                .innerText,
            }));

            siediForm.dispatchEvent(
              new CustomEvent('updatetags', {
                detail: {
                  tags: tagsData,
                  belongs: 'iniciativa',
                },
              })
            );
          };

          const buttonCancelar = iniciativaButton.getElementsByClassName(
            'btn-secondary'
          )[0];
          buttonCancelar.addEventListener('click', () => {
            // Limpa input de busca
            iniciativaBusca.getElementsByClassName('form-control')[0].value =
              '';

            // Limpa busca
            buscaIE.items.forEach(item => item.show());

            dropdownMenu.quantidadeIESelecionadas = 0;

            // Remove todos as tags selecionadas
            Array.from(iniciativaInfo.children).forEach(filho => {
              const wasTagSelectedOnThisOpening = dropdownMenu.tagsSelectedOnThisOpening.find(
                tag => tag.tagCode === filho.tagCode
              );
              const wasTagRemovedOnThisOpening = dropdownMenu.tagsRemovedOnThisOpening.find(
                tag => tag.tagCode === filho.tagCode
              );

              if (wasTagSelectedOnThisOpening || wasTagRemovedOnThisOpening) {
                filho.click();
              }
            });

            dropdownMenu.quantidadeIESelecionadas = dropdownMenu.querySelectorAll(
              'button[data-activeted="true"]'
            ).length;
            updateIESelectedLabel(dropdownMenu);

            if (limitTagsWarning) {
              const limitTagsWarningQnt = limitTagsWarning.querySelector(
                '.tags-qnt'
              );
              limitTagsWarning.classList.add('d-none');
              limitTagsWarningQnt.innerText = maxQuantityOfTagsAllowed;
            }

            dropdownMenu.tagsSelectedOnThisOpening = [];
            dropdownMenu.tagsRemovedOnThisOpening = [];

            infoTotalIniciativasEstrategica.innerText = getIniciativasEncontradasLabel(
              dadosApi.length
            );

            iniciativaInfo.scroll({ top: 0 });

            // Fecha o dropdown
            window.closeDropdown(vincularIniciativaEstrategica);
          });

          const buttonVincular = iniciativaButton.getElementsByClassName(
            'btn-primary'
          )[0];
          buttonVincular.addEventListener('click', () => {
            emitTagsToForm();

            if (limitTagsWarning) {
              const limitTagsWarningQnt = limitTagsWarning.querySelector(
                '.tags-qnt'
              );
              limitTagsWarning.classList.add('d-none');
              limitTagsWarningQnt.innerText = maxQuantityOfTagsAllowed;
            }

            dropdownMenu.tagsSelectedOnThisOpening = [];
            dropdownMenu.tagsRemovedOnThisOpening = [];

            infoTotalIniciativasEstrategica.innerText = getIniciativasEncontradasLabel(
              dadosApi.length
            );

            iniciativaInfo.scroll({ top: 0 });

            window.closeDropdown(vincularIniciativaEstrategica);
          });
        }
      }
    );
  })();

  (function() {
    const addCommentContainers = document.querySelectorAll(
      '.add-comment-container'
    );

    addCommentContainers.forEach(addCommentContainer => {
      const inputAddComment = addCommentContainer.querySelector(
        '#text-input-add-comment'
      );
      const buttonsContainer = addCommentContainer.querySelector(
        '.buttons-container'
      );
      const form = addCommentContainer.querySelector('form');

      const showButtonsContainer = () => {
        buttonsContainer.classList.add('show');
      };

      const hideButtonsContainer = () => {
        buttonsContainer.classList.remove('show');
      };

      const resetFormState = () => {
        inputAddComment.value = '';
        inputAddComment.classList.remove('filled');
        if (inputAddComment.updateHeight) {
          inputAddComment.updateHeight();
        }

        form.resetFilesDataState();
        form.resetCheckpointTagsDataState();
        form.resetVincularIniciativaTagsDataState();

        hideButtonsContainer();
      };

      if (inputAddComment && buttonsContainer) {
        const cancelButton = buttonsContainer.querySelector('button.cancel');
        const selectionContainer = addCommentContainer.querySelector(
          '.selections'
        );

        inputAddComment.addEventListener('focus', showButtonsContainer);

        inputAddComment.addEventListener('focusout', () => {
          if (
            !inputAddComment.value &&
            selectionContainer.children.length === 0
          ) {
            hideButtonsContainer();
          }
        });

        cancelButton.addEventListener('click', resetFormState);
      }
    });
  })();

  (function() {
    const addCommentContainers = document.querySelectorAll(
      '.add-comment-container'
    );

    addCommentContainers.forEach(addCommentContainer => {
      const labelAction = addCommentContainer.querySelector('label.action');

      if (labelAction) {
        /**
         * @type {HTMLInputElement}
         */
        const inputFile = labelAction.querySelector('input[type="file"]');
        const iconButton = labelAction.querySelector('button');
        const inputFileData = addCommentContainer.querySelector('#file-data');
        const form = addCommentContainer.querySelector('form');
        const filesData = [];

        const updateInputFileDataValue = () => {
          inputFileData.value = JSON.stringify(filesData);
        };

        const resetInputFileValue = () => {
          inputFile.value = '';
        };

        const checkIfFileSelectionsMustBeDeleted = () => {
          const selections = addCommentContainer.querySelector('.selections');
          const fileSelections = selections.querySelector('.file-selections');

          if (fileSelections && fileSelections.childElementCount === 0) {
            fileSelections.remove();
            resetInputFileValue();
          }
        };

        const attachDeleteFilesDataItemHandler = (button, paragraph) => {
          button.addEventListener('click', () => {
            const [fileSelections] = $(paragraph).parents('.file-selections');

            const paragraphs = Array.from(
              fileSelections.querySelectorAll(':scope > p')
            );

            const paragraphToBeRemovedIndex = paragraphs.findIndex(
              p => p === paragraph
            );

            filesData.splice(paragraphToBeRemovedIndex, 1);

            updateInputFileDataValue();
          });
        };

        const attachDeleteHandler = (target, elementToBeRemoved) => {
          target.addEventListener('click', () => {
            elementToBeRemoved.remove();

            checkIfFileSelectionsMustBeDeleted();
          });
        };

        const createParagraph = fileName => {
          const paragraph = document.createElement('p');

          const deleteButton = document.createElement('button');
          deleteButton.type = 'button';
          deleteButton.classList.add('link-normal', 'delete-file');
          attachDeleteFilesDataItemHandler(deleteButton, paragraph);
          attachDeleteHandler(deleteButton, paragraph);

          const trashIcon = document.createElement('i');
          trashIcon.classList.add('fas', 'fa-trash');

          deleteButton.append(trashIcon, 'Excluir');

          paragraph.append(fileName, deleteButton);

          return paragraph;
        };

        const insertFileDataOnDocument = () => {
          const selections = addCommentContainer.querySelector('.selections');

          if (!selections) return;

          let fileSelections = selections.querySelector('.file-selections');

          if (!fileSelections) {
            fileSelections = document.createElement('div');
            fileSelections.classList.add('file-selections');
            selections.append(fileSelections);
          }

          const { files } = inputFile;
          const filesArray = Array.from(files);

          // filesArrayStringifiable é necessário pois não é possível fazer
          // JSON.stringify(filesArray). Porque os objetos do tipo File não
          // possuem o método .toJSON().
          const filesArrayStringifiable = filesArray.map(file => ({
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            name: file.name,
            size: file.size,
            type: file.type,
          }));
          filesData.push(...filesArrayStringifiable);
          updateInputFileDataValue();

          const paragraphs = filesArray.map(file => createParagraph(file.name));

          if (paragraphs.length) {
            fileSelections.append(...paragraphs);
          }
        };

        const resetFilesDataState = () => {
          const selections = addCommentContainer.querySelector('.selections');
          const fileSelections = selections.querySelector('.file-selections');

          if (!fileSelections) return;

          const deleteFileButtons = fileSelections.querySelectorAll(
            'button.delete-file'
          );

          deleteFileButtons.forEach(deleteFileButton => {
            deleteFileButton.click();
          });
        };

        form.resetFilesDataState = resetFilesDataState;

        iconButton.addEventListener('click', () => {
          inputFile.click();
        });

        inputFile.addEventListener('change', () => {
          insertFileDataOnDocument();
        });
      }
    });
  })();

  (function() {
    const addCommentContainers = document.querySelectorAll(
      '.add-comment-container'
    );

    const selectionsObserver = new MutationObserver(mutations => {
      mutations.forEach(mutationRecord => {
        const { target } = mutationRecord;

        const [form] = $(target).parents('form');

        if (form) {
          const tagsInput = form.querySelector('#tags-input');

          if (target.classList.contains('tag-selections')) {
            const tags = Array.from(
              target.querySelectorAll('button.tag-filtro')
            );

            const tagsToString = tags.map(tag => tag.innerText).join(',');
            tagsInput.value = tagsToString;
          }
        }
      });
    });

    addCommentContainers.forEach(addCommentContainer => {
      const siediForm = addCommentContainer.querySelector('form');

      if (siediForm) {
        const formSelections = siediForm.querySelector('.selections');
        const tagsInput = siediForm.querySelector('#tags-input');

        if (formSelections) {
          selectionsObserver.observe(formSelections, {
            childList: true,
            subtree: true,
          });
        }

        const checkIfTagSelectionsContainerMustBeDeleted = () => {
          const tagSelectionsContainer = formSelections.querySelector(
            '.tag-selections'
          );

          if (
            tagSelectionsContainer &&
            tagSelectionsContainer.childElementCount === 0
          ) {
            tagSelectionsContainer.remove();
            tagsInput.value = '';
          }
        };

        const selfDeleteTag = ({ tag, label, meaning }) => {
          const { belongs } = tag.dataset;

          SIEDI[belongs].updateDropdown({ label, meaning });

          tag.remove();

          checkIfTagSelectionsContainerMustBeDeleted();
        };

        const createTag = ({ label, meaning, belongs }) => {
          const tag = document.createElement('button');
          tag.classList.add('tag-filtro', 'darker');
          tag.type = 'button';
          tag.dataset.belongs = belongs;

          const deleteIcon = document.createElement('i');
          deleteIcon.classList.add('fas', 'fa-times');

          const title = document.createElement('span');
          title.classList.add('title');
          title.innerText = meaning;

          tag.append(label, deleteIcon, title);

          window.tooltipMouseMove(tag);

          tag.addEventListener('click', () =>
            selfDeleteTag({ tag, label, meaning })
          );

          return tag;
        };

        const deleteTags = belongs => {
          const tags = formSelections.querySelectorAll(
            `[data-belongs=${belongs}]`
          );
          tags.forEach(tag => tag.remove());
        };

        const createTagSelectionsContainer = () => {
          const tagSelections = document.createElement('div');
          tagSelections.classList.add('tag-selections');

          return tagSelections;
        };

        const insertTagsSelections = tagsInfo => {
          deleteTags(tagsInfo.belongs);

          const tags = tagsInfo.tags.map(tag =>
            createTag({ ...tag, belongs: tagsInfo.belongs })
          );
          let tagSelections = formSelections.querySelector('.tag-selections');

          if (!tagSelections) {
            tagSelections = createTagSelectionsContainer();
            formSelections.insertAdjacentElement('afterbegin', tagSelections);
          }

          tags.forEach(tag => {
            const { belongs } = tag.dataset;
            tagSelections.insertAdjacentElement(SIEDI[belongs].insert, tag);
          });

          checkIfTagSelectionsContainerMustBeDeleted();
        };

        siediForm.addEventListener('updatetags', e => {
          insertTagsSelections(e.detail);
        });
      }
    });
  })();

  (function() {
    const siediContainer = document.querySelector('.siedi');

    if (!siediContainer) return;

    const buttonsAddGoal = siediContainer.querySelectorAll('button.add-goal');

    const buttonAddGoalHandler = buttonAddGoal => {
      const [goalInput] = $(buttonAddGoal).siblings('.goal-entry');
      const [progressValue] = $(buttonAddGoal).siblings('.progress-value');
      const [progress] = $(buttonAddGoal).siblings('.progress');
      const [progressBar] = $(progress).children('.progress-bar');
      const maxLength = Number(goalInput.dataset.maxLength) || 4;

      buttonAddGoal.addEventListener('click', () => {
        buttonAddGoal.classList.add('d-none');

        goalInput.classList.remove('d-none');
        goalInput.focus();

        if (progressValue) {
          progressValue.innerText = progressBar.style.width || '0%';
        }
      });

      goalInput.addEventListener('focusout', () => {
        const { isPercentaged } = goalInput;

        const isEmptyPercentaged = isPercentaged && goalInput.value === '%';
        const isEmptyNormal = !isPercentaged && !goalInput.value;

        if (isEmptyPercentaged || isEmptyNormal) {
          goalInput.classList.add('d-none');

          buttonAddGoal.classList.remove('d-none');
          buttonAddGoal.focus();

          if (progressValue) {
            progressValue.innerText = '';
          }
        }
      });

      goalInput.addEventListener('input', e => {
        const { isPercentaged } = goalInput;

        if (isPercentaged && e.inputType === 'deleteContentBackward') {
          // essa manipulação é necessária pois caso contrário, ao usuário dar
          // backspace, o input não "obedecerá" o comando
          goalInput.value = goalInput.value.slice(
            0,
            goalInput.value.length - 1
          );
        }

        goalInput.value = goalInput.value.replace(/(^,)|[^0-9,]/g, '');

        // Evita a adição de mais de uma vírgula
        if (goalInput.value.split(',').length > 2) {
          goalInput.value = goalInput.value.substr(
            0,
            goalInput.value.length - 1
          );
        }

        if (goalInput.value.length > maxLength) {
          goalInput.value = goalInput.value.slice(
            0,
            goalInput.value.length - 1
          );
        }
      });
    };

    buttonsAddGoal.forEach(buttonAddGoalHandler);

    Cosmos[COMPONENT_NAME].register.addGoalButton = buttonAddGoalHandler;
  })();

  (function() {
    const siediContainer = document.querySelector('.siedi');

    if (!siediContainer) return;

    const checkboxes = siediContainer.querySelectorAll(
      'input[type="checkbox"]'
    );
    const checkboxesAtividades = siediContainer.querySelectorAll(
      'input.check-atividades-sistemas[type="checkbox"]'
    );

    const checkboxHandler = checkbox => {
      const [labelSwitch] = $(checkbox).siblings('label.form-switch-label');

      if (labelSwitch) {
        checkbox.addEventListener('change', () => {
          const isChecked = checkbox.checked;

          let labelText = 'Habilitar';

          if (isChecked) {
            labelText = 'Desabilitar';
          }

          labelSwitch.innerText = `${labelText} porcentagem`;
        });
      }
    };

    const checkboxAtividadeHandler = checkbox => {
      const [labelSwitch] = $(checkbox).siblings('label.form-switch-label');

      if (labelSwitch) {
        checkbox.addEventListener('change', () => {
          const isChecked = checkbox.checked;

          let labelText = 'ocultados';

          if (isChecked) {
            labelText = 'visiveis';
          }

          labelSwitch.innerText = `Detalhes ${labelText}`;
        });
      }
    };

    checkboxes.forEach(checkboxHandler);
    checkboxesAtividades.forEach(checkboxAtividadeHandler);

    Cosmos[COMPONENT_NAME].register.percentageCheckboxLabel = checkboxHandler;
    Cosmos[
      COMPONENT_NAME
    ].register.atividadeCheckboxLabel = checkboxAtividadeHandler;
  })();

  (function() {
    const siediContainer = document.querySelector('.siedi');

    if (!siediContainer) return;

    const checkboxes = siediContainer.querySelectorAll(
      'input[type="checkbox"].switch-percentage'
    );

    const checkboxHandler = checkbox => {
      const [formCheck] = $(checkbox).parents('.form-check');
      const [cardContainer] = $(formCheck).siblings('.card-container');
      const goalInputs = cardContainer.querySelectorAll('.goal-entry');

      const addPercentage = e => {
        e.target.value = `${e.target.value}%`;
      };

      checkbox.addEventListener('change', () => {
        const isChecked = checkbox.checked;

        if (isChecked) {
          goalInputs.forEach(goalInput => {
            goalInput.isPercentaged = true;

            goalInput.value = `${goalInput.value}%`;

            goalInput.addEventListener('input', addPercentage);
          });
        } else {
          goalInputs.forEach(goalInput => {
            goalInput.isPercentaged = false;

            goalInput.value = goalInput.value.replace('%', '');

            goalInput.removeEventListener('input', addPercentage);
          });
        }
      });
    };

    checkboxes.forEach(checkboxHandler);

    Cosmos[COMPONENT_NAME].register.percentageCheckbox = checkboxHandler;
  })();

  (function() {
    const siediContainer = document.querySelector('.siedi');

    if (!siediContainer) return;

    const buttonsOpenEditor = document.querySelectorAll('button.open-editor');

    const buttonOpenEditorHandler = buttonOpenEditor => {
      const chevronIcon = buttonOpenEditor.querySelector('i');
      const [editorContainer] = $(buttonOpenEditor).siblings(
        '.editor-container'
      );
      const cancelButton = editorContainer.querySelector(
        '.editor-container__buttons button.cancel'
      );

      const toggleEditorOpening = () => {
        chevronIcon.classList.toggle('fa-flip-both');
        editorContainer.classList.toggle('show');
      };

      buttonOpenEditor.addEventListener('click', toggleEditorOpening);

      if (cancelButton) {
        cancelButton.addEventListener('click', () => {
          const formulaEditor = tinymce.get().find(editor => {
            const { targetElm } = editor;

            const [targetElmEditorContainer] = $(targetElm).parents(
              '.editor-container'
            );

            if (
              !targetElmEditorContainer ||
              targetElmEditorContainer !== editorContainer
            ) {
              return false;
            }

            return true;
          });

          formulaEditor.setContent('');
          toggleEditorOpening();
        });
      }
    };

    buttonsOpenEditor.forEach(buttonOpenEditorHandler);

    Cosmos[COMPONENT_NAME].register.openEditorButton = buttonOpenEditorHandler;
  })();

  (function() {
    const siediContainer = document.querySelector('.siedi');

    if (!siediContainer) return;

    const currentYearCheckpointDropdowns = siediContainer.querySelectorAll(
      '.card-simple.current-year .card-footer .dropdown'
    );

    const checkpointDropdownHandler = checkpointDropdown => {
      const cancelButton = checkpointDropdown.querySelector('button.cancel');
      const checkpointInputs = checkpointDropdown.querySelectorAll(
        'input.form-control'
      );
      const initialInputValues = [];

      const revertInputChanges = () => {
        checkpointInputs.forEach(checkpointInput => {
          const [initialInputValue] = initialInputValues;

          if (initialInputValue) {
            checkpointInput.classList.add('filled');
          } else {
            checkpointInput.classList.remove('filled');
          }

          checkpointInput.value = initialInputValue;

          initialInputValues.shift();
        });
      };

      $(checkpointDropdown).on('show.bs.dropdown', () => {
        checkpointInputs.forEach(checkpointInput => {
          initialInputValues.push(checkpointInput.value);
        });
      });

      checkpointInputs.forEach(checkpointInput => {
        const maxLength = Number(checkpointInput.dataset.maxLength) || 10;

        checkpointInput.addEventListener('input', e => {
          const { dataset } = checkpointInput;
          const isPercentageMode = dataset.percentage !== undefined;

          if (isPercentageMode && e.inputType === 'deleteContentBackward') {
            // essa manipulação é necessária pois caso contrário, ao usuário dar
            // backspace, o input não "obedecerá" o comando
            checkpointInput.value = checkpointInput.value.slice(
              0,
              checkpointInput.value.length - 1
            );

            if (!checkpointInput.value) {
              return;
            }
          }

          checkpointInput.value = checkpointInput.value.replace(
            /(^,)|[^0-9,-]/g,
            ''
          );

          // Evita a adição de mais de uma vírgula ou sinal de negativo
          if (
            checkpointInput.value.split(',').length > 2 ||
            checkpointInput.value.split('-').length > 2
          ) {
            checkpointInput.value = checkpointInput.value.substr(
              0,
              checkpointInput.value.length - 1
            );
          }

          if (isPercentageMode) {
            checkpointInput.value = `${checkpointInput.value}%`;
          }

          if (checkpointInput.value.length > maxLength) {
            if (isPercentageMode) {
              checkpointInput.value = `${checkpointInput.value.slice(
                0,
                checkpointInput.value.length - 2
              )}%`;
            } else {
              checkpointInput.value = checkpointInput.value.slice(
                0,
                checkpointInput.value.length - 1
              );
            }
          }
        });
      });

      cancelButton.addEventListener('click', () => {
        $(checkpointDropdown).dropdown('hide');

        revertInputChanges();
      });
    };

    currentYearCheckpointDropdowns.forEach(checkpointDropdownHandler);

    Cosmos[
      COMPONENT_NAME
    ].register.checkpointDropdown = checkpointDropdownHandler;

    const checkpointDropdowns = siediContainer.querySelectorAll(
      '.card-simple .card-footer .dropdown'
    );

    // Alinha a flecha do dropdown ao centro do seu botão acionador
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutationRecord => {
        const { target } = mutationRecord;
        const dropdownArrow = target.querySelector('.dropdown-menu-arrow');

        if (window.innerWidth <= IS_TABLET) {
          dropdownArrow.style.cssText = '';

          return;
        }

        const targetRect = target.getBoundingClientRect();
        const dropdownTrigger = document.getElementById(
          target.getAttribute('aria-labelledby')
        );
        const dropdownTriggerRect = dropdownTrigger.getBoundingClientRect();

        const middleDropdownTrigger =
          dropdownTriggerRect.left -
          targetRect.left +
          dropdownTriggerRect.width / 2;

        // Número mágico para alinhamento do dropdown mais ao centro do botão
        const ALIGNMENT_OFFSET = -6;

        dropdownArrow.style.right = '0';
        dropdownArrow.style.marginLeft = '0';
        dropdownArrow.style.marginRight = '0';
        dropdownArrow.style.left = `${middleDropdownTrigger +
          ALIGNMENT_OFFSET}px`;
      });
    });

    checkpointDropdowns.forEach(checkpointDropdown => {
      const dropdownMenu = checkpointDropdown.querySelector('.dropdown-menu');

      observer.observe(dropdownMenu, {
        attributes: true,
        attributeFilter: ['style'],
      });
    });
  })();

  (function() {
    const siediContainer = document.querySelector('.siedi');

    if (!siediContainer) return;

    const visualizationCardContainers = siediContainer.querySelectorAll(
      '.card-container.visualization'
    );

    const visualizationCardContainerHandler = visualizationCardContainer => {
      const { widthRightOffset = 0 } = visualizationCardContainer.dataset;

      const cardItems = visualizationCardContainer.querySelectorAll(
        '.card-item'
      );
      const leftButton = visualizationCardContainer.querySelector(
        '.carousel-left'
      );
      const rightButton = visualizationCardContainer.querySelector(
        '.carousel-right'
      );

      const slidesAmount = cardItems.length;
      let currentWindowWidth = visualizationCardContainer.offsetWidth;
      let currentSlide = 0;
      let currentWidthShifted = 0;
      let currentZIndex = 2;

      const updateCardItemsMargin = () => {
        if (widthRightOffset) {
          cardItems.forEach(cardItem => {
            cardItem.style.marginRight = `${widthRightOffset}px`;
          });
        }
      };

      const resetCardItemsMargin = () => {
        cardItems.forEach(cardItem => {
          cardItem.style.marginRight = '';
        });
      };

      const updateCardItemsWidth = width => {
        cardItems.forEach(cardItem => {
          cardItem.style.width = `${width}px`;
        });
      };

      const hideButton = button => {
        button.classList.add('hide');
      };

      const showButton = button => {
        button.classList.remove('hide');
      };

      const shiftButtons = width => {
        leftButton.style.transform = `translate(${width}px, -50%)`;
        rightButton.style.transform = `translate(${width}px, -50%)`;

        currentZIndex += 1;

        visualizationCardContainer.style.zIndex = currentZIndex;
      };

      const paginateRight = () => {
        const widthToBeShifted =
          currentWindowWidth * currentSlide + widthRightOffset * currentSlide;
        currentWidthShifted = widthToBeShifted;

        shiftButtons(widthToBeShifted);
        visualizationCardContainer.style.transform = `translate3d(${widthToBeShifted *
          -1}px, 0px, 0px)`;
      };

      const paginateLeft = () => {
        const widthToBeShifted =
          currentWidthShifted - currentWindowWidth - widthRightOffset;
        currentWidthShifted = widthToBeShifted;

        shiftButtons(widthToBeShifted);
        visualizationCardContainer.style.transform = `translate3d(${widthToBeShifted *
          -1}px, 0px, 0px)`;
      };

      const resetState = () => {
        resetCardItemsMargin();
        hideButton(leftButton);
        showButton(rightButton);
        currentSlide = 0;
        currentWidthShifted = 0;
        shiftButtons(0);
        visualizationCardContainer.style.transform = '';
        currentZIndex = 2;
      };

      if (window.innerWidth <= IS_TABLET) {
        updateCardItemsWidth(currentWindowWidth);
        updateCardItemsMargin();
      }

      hideButton(leftButton);

      if (slidesAmount <= 1) {
        hideButton(rightButton);
      }

      window.addEventListener('resize', () => {
        if (window.innerWidth > IS_TABLET) {
          resetState();
          return;
        }

        currentWindowWidth = visualizationCardContainer.offsetWidth;

        updateCardItemsWidth(currentWindowWidth);
        updateCardItemsMargin();
      });

      leftButton.addEventListener('click', () => {
        if (currentSlide <= 0) {
          return;
        }

        if (currentSlide === 1) {
          hideButton(leftButton);
        }

        showButton(rightButton);

        currentSlide -= 1;

        paginateLeft();
      });

      rightButton.addEventListener('click', () => {
        if (currentSlide >= slidesAmount - 1) {
          return;
        }

        if (currentSlide === slidesAmount - 2) {
          hideButton(rightButton);
        }

        showButton(leftButton);

        currentSlide += 1;

        paginateRight();
      });
    };

    visualizationCardContainers.forEach(visualizationCardContainerHandler);

    Cosmos[
      COMPONENT_NAME
    ].register.visualizationCardContainerSlider = visualizationCardContainerHandler;
  })();

  (function() {
    const siediContainer = document.querySelector('.siedi');

    if (!siediContainer) return;

    const visualizationCardContainers = siediContainer.querySelectorAll(
      '.card-container.visualization'
    );

    const visualizationCardContainerHandler = visualizationCardContainer => {
      const cardItems = Array.from(
        visualizationCardContainer.querySelectorAll('.card-item')
      );
      const cardItemsWithDropdown = cardItems.filter(cardItem =>
        cardItem.querySelector('.dropdown')
      );

      const handleIncrementZIndex = () => {
        const oldZIndex = Number(visualizationCardContainer.style.zIndex);

        visualizationCardContainer.style.zIndex = oldZIndex + 1;
      };

      const assignIncrementZIndexHandler = () => {
        cardItemsWithDropdown.forEach(cardItemWithDropdown => {
          const dropdown = cardItemWithDropdown.querySelector('.dropdown');

          $(dropdown).on('shown.bs.dropdown', handleIncrementZIndex);
        });
      };

      const deassignIncrementZIndexHandler = () => {
        cardItemsWithDropdown.forEach(cardItemWithDropdown => {
          const dropdown = cardItemWithDropdown.querySelector('.dropdown');

          $(dropdown).off('shown.bs.dropdown', handleIncrementZIndex);
        });
      };

      const alignDropdownsToCenter = () => {
        cardItemsWithDropdown.forEach(cardItemWithDropdown => {
          const dropdownMenu = cardItemWithDropdown.querySelector(
            '.dropdown-menu'
          );

          const rightAlignedDropdown = dropdownMenu.classList.contains(
            'dropdown-menu-right'
          );

          if (rightAlignedDropdown) {
            dropdownMenu.classList.remove('dropdown-menu-right');
            dropdownMenu.classList.add('dropdown-menu-right-waiting');
            dropdownMenu.classList.add('dropdown-menu-center');

            window.addDropdownToMutation(dropdownMenu);

            return;
          }

          const leftAlignedDropdown =
            dropdownMenu.classList.contains('dropdown-menu') &&
            !dropdownMenu.classList.contains('dropdown-menu-right') &&
            !dropdownMenu.classList.contains('dropdown-menu-center');

          if (leftAlignedDropdown) {
            dropdownMenu.classList.add('dropdown-menu-left-waiting');
            dropdownMenu.classList.add('dropdown-menu-center');

            window.addDropdownToMutation(dropdownMenu);
          }
        });
      };

      const alignDropdownsToOriginal = () => {
        cardItemsWithDropdown.forEach(cardItemWithDropdown => {
          const dropdownMenu = cardItemWithDropdown.querySelector(
            '.dropdown-menu'
          );

          if (dropdownMenu.classList.contains('dropdown-menu-right-waiting')) {
            dropdownMenu.classList.add('dropdown-menu-right');
            dropdownMenu.classList.remove('dropdown-menu-right-waiting');
            dropdownMenu.classList.remove('dropdown-menu-center');

            window.removeDropdownToMutation(dropdownMenu);

            return;
          }

          if (dropdownMenu.classList.contains('dropdown-menu-left-waiting')) {
            dropdownMenu.classList.remove('dropdown-menu-left-waiting');
            dropdownMenu.classList.remove('dropdown-menu-center');

            window.removeDropdownToMutation(dropdownMenu);
          }
        });
      };

      if (window.innerWidth <= IS_TABLET) {
        alignDropdownsToCenter();
        assignIncrementZIndexHandler();
      }

      window.addEventListener('resize', () => {
        if (window.innerWidth <= IS_TABLET) {
          alignDropdownsToCenter();
          assignIncrementZIndexHandler();
          return;
        }

        alignDropdownsToOriginal();
        deassignIncrementZIndexHandler();
      });
    };

    visualizationCardContainers.forEach(visualizationCardContainerHandler);

    Cosmos[
      COMPONENT_NAME
    ].register.visualizationCardContainerAlignment = visualizationCardContainerHandler;
  })();

  (function() {
    const siediContainer = document.querySelector('.siedi');

    if (!siediContainer) return;

    const criaTagsConteudo = tag => {
      const button = document.createElement('button');
      button.classList.add('tag-conteudo', 'visual', 'mb-1');
      button.setAttribute('data-activeted', 'false');

      const span = document.createElement('span');
      button.appendChild(span);

      const spanHead = document.createElement('span');
      spanHead.classList.add('tag-conteudo__head');
      span.appendChild(spanHead);
      const pHead = document.createElement('p');
      pHead.innerText = tag.sigla;
      spanHead.appendChild(pHead);

      const spanBody = document.createElement('span');
      spanBody.classList.add('tag-conteudo__body');
      span.appendChild(spanBody);
      const spanTag = document.createElement('span');
      spanTag.classList.add('tag-rotulo', 'dark');
      if (tag.tag === '') {
        spanTag.classList.add('d-none');
      } else {
        spanTag.innerText = tag.tag;
      }
      spanBody.appendChild(spanTag);
      const pBody = document.createElement('p');
      pBody.innerText = tag.conteudo;
      spanBody.appendChild(pBody);

      const spanFooter = document.createElement('span');
      spanFooter.classList.add('tag-conteudo__footer');
      span.appendChild(spanFooter);
      const i = document.createElement('i');
      i.classList.add('fas', 'fa-check');
      spanFooter.appendChild(i);

      // eslint-disable-next-line no-undef
      tagConteudoIconChangeFeatures(button);

      return button;
    };

    const buildIEWifhAPI = async iniciativaEstrategica => {
      const iEHeader = iniciativaEstrategica.getElementsByClassName(
        'ie-head'
      )[0];
      const inforQuantidadeGeral = iEHeader.children[0];
      const iEBody = iniciativaEstrategica.getElementsByClassName('ie-body')[0];
      const iEFooter = iniciativaEstrategica.getElementsByClassName(
        'ie-footer'
      )[0];
      // ForEach so funciona em arrays, neste caso, o getElementsByClassName não retorna um Array e sim um HTMLELEMENT.
      // Por isso a necessidade de trasforma em Array, para isso utilizando o Array.from();
      const buttonsNavegacoes = Array.from(
        iEHeader.getElementsByClassName('nav-link')
      );
      const buttonsEditarIE = Array.from(
        iEBody.getElementsByClassName('ie-edit')
      );
      const adicinarIe = iEFooter.getElementsByClassName('adicionar-ie')[0];
      const paginas = Array.from(iEBody.getElementsByClassName('tab-pane'));

      const cancelAddIe = Array.from(
        iEFooter.getElementsByClassName('ie-cancel')
      );

      const saveAddIe = Array.from(iEFooter.getElementsByClassName('ie-save'));

      const dataApi = iniciativaEstrategica.dataset.api;
      if (dataApi) {
        const data = await fetch(dataApi);
        const tagsApi = await data.json();
        paginas.forEach((pagina, indice) => {
          const divTags = pagina.getElementsByClassName('ie-body__tags')[0];
          const infoQuantidadeNaPagina =
            divTags.previousElementSibling.children[0];

          tagsApi.forEach(tag => {
            const tagConteudo = criaTagsConteudo(tag);

            if (indice === 0) {
              if (divTags.children[0].classList.contains('no-tags')) {
                divTags.children[0].remove();
              }
              divTags.appendChild(tagConteudo);
            } else if (pagina.getAttribute('data-pagina') === tag.ano) {
              if (divTags.children[0].classList.contains('no-tags')) {
                divTags.children[0].remove();
              }
              divTags.appendChild(tagConteudo);
            }
          });

          if (indice === 0) {
            infoQuantidadeNaPagina.innerText = `${divTags.children.length} Iniciativas estratégicas`;
            inforQuantidadeGeral.innerHTML = `Iniciativas Estratégicas (${divTags.children.length})`;
          } else if (!divTags.children[0].classList.contains('no-tags')) {
            infoQuantidadeNaPagina.innerText = `${divTags.children.length} Iniciativas estratégicas utilizadas nesse ano`;
          }
        });
      }

      // Caso mude de pagina, sem salvar ou cancelar, tudo será apagado.
      buttonsNavegacoes.forEach(buttonNavegacao => {
        buttonNavegacao.addEventListener('click', () => {
          if (!buttonNavegacao.classList.contains('active')) {
            buttonsEditarIE.forEach(buttonEditarIe => {
              if (buttonEditarIe.classList.contains('d-none')) {
                buttonEditarIe.classList.remove('d-none');
                const divBodyButton = buttonEditarIe.parentElement;
                const divBodyTags = divBodyButton.nextElementSibling;

                // Esse é so um caso possivel, caso existe outros crie essas condições;
                if (divBodyTags.children[0].classList.contains('no-tags')) {
                  divBodyTags.children[0].classList.remove('d-none');
                  divBodyButton.children[0].innerText =
                    '0 Iniciativas estratégicas utilizadas nesse ano';
                  Array.from(divBodyTags.children).forEach((filho, indice) => {
                    if (indice > 0) {
                      filho.remove();
                    }
                  });
                }
              }
            });

            if (!adicinarIe.classList.contains('d-none')) {
              adicinarIe.classList.add('d-none');
            }
          }
        });
      });

      buttonsEditarIE.forEach(buttonEditarIe => {
        buttonEditarIe.addEventListener('click', () => {
          adicinarIe.classList.remove('d-none');
          buttonEditarIe.classList.add('d-none');

          const divBodyButton = buttonEditarIe.parentElement;
          const divBodyTags = divBodyButton.nextElementSibling;
          const tags = Array.from(
            paginas[0].getElementsByClassName('ie-body__tags')[0].children
          );

          // Esse é so um caso possivel, caso existe outros crie essas condições;
          if (divBodyTags.children[0].classList.contains('no-tags')) {
            divBodyTags.children[0].classList.add('d-none');
            tags.forEach(tag => {
              const button = document.createElement('button');
              button.innerHTML = tag.innerHTML;
              button.classList = tag.classList;
              button.classList.remove('visual');
              button.setAttribute(
                'data-activeted',
                tag.getAttribute('data-activeted')
              );
              // eslint-disable-next-line no-undef
              tagConteudoIconChangeFeatures(button);
              divBodyTags.appendChild(button);
            });
          }
          divBodyButton.children[0].innerText =
            'Selecione as iniciativas que estarão presentes no ano:';
        });
      });

      cancelAddIe.forEach(cancelAdd => {
        cancelAdd.addEventListener('click', () => {
          adicinarIe.classList.add('d-none');
          buttonsEditarIE.forEach(buttonEditarIe => {
            if (buttonEditarIe.classList.contains('d-none')) {
              buttonEditarIe.classList.remove('d-none');
              const divBodyButton = buttonEditarIe.parentElement;
              const divBodyTags = divBodyButton.nextElementSibling;

              // Esse é so um caso possivel, caso existe outros crie essas condições;
              if (divBodyTags.children[0].classList.contains('no-tags')) {
                divBodyTags.children[0].classList.remove('d-none');
                divBodyButton.children[0].innerText =
                  '0 Iniciativas estratégicas utilizadas nesse ano';
                Array.from(divBodyTags.children).forEach((filho, indice) => {
                  if (indice > 0) {
                    filho.remove();
                  }
                });
              }
            }
          });
        });
      });

      saveAddIe.forEach(saveAdd => {
        saveAdd.addEventListener('click', () => {});
      });
    };

    const iniciativasEstrategicas = document.querySelectorAll('.ie');
    iniciativasEstrategicas.forEach(buildIEWifhAPI);

    Cosmos[COMPONENT_NAME].register.buildingIEWithAPI = buildIEWifhAPI;
  })();

  (function() {
    const siediContainer = document.querySelector('.siedi');

    if (!siediContainer) return;

    const atividadesContainer = document.querySelectorAll('.atividades');
    const checkboxesAtividades = siediContainer.querySelectorAll(
      'input.check-atividades-sistemas[type="checkbox"]'
    );

    /**
     * Função para construir o card comentario, de acordo com o dado vinda da API
     *
     * Exemplo da Api: https://static.info.ufrn.br/current/json/siedi-ie-tags-api.json
     * @param card objeto com informação da API
     */
    const cardsComments = card => {
      const divCardComentario = document.createElement('div');
      divCardComentario.classList.add('card-comentario', 'mb-2');
      if (card.logSistema) {
        divCardComentario.classList.add('log-sistema');
      }

      const imgHeader = document.createElement('img');
      imgHeader.setAttribute('src', card.foto);
      imgHeader.setAttribute('alt', `Foto representando ${card.nome}`);
      const img = document.createElement('img');
      img.setAttribute('src', card.foto);
      img.setAttribute('alt', `Foto representando ${card.nome}`);

      const cardHeader = document.createElement('div');
      cardHeader.classList.add('card-header');
      cardHeader.appendChild(imgHeader);
      divCardComentario.appendChild(cardHeader);

      const cardBody = document.createElement('div');
      cardBody.classList.add('card-body');
      divCardComentario.appendChild(cardBody);

      const cardLabel = document.createElement('div');
      cardLabel.classList.add('card-label');
      cardLabel.appendChild(img);
      cardBody.appendChild(cardLabel);

      const pName = document.createElement('p');
      pName.classList.add('name');
      pName.innerText = card.nome;
      cardLabel.appendChild(pName);

      const pHora = document.createElement('p');
      pHora.classList.add('hora');
      pHora.innerText = card.data;
      cardLabel.appendChild(pHora);

      card.siglas.forEach(sigla => {
        const button = document.createElement('button');
        button.classList.add('tag-filtro', 'darker');
        button.innerText = sigla.label;
        const span = document.createElement('span');
        span.classList.add('title');
        span.innerText = `Tradução: ${sigla.title}`;

        button.appendChild(span);
        cardLabel.appendChild(button);

        button.addEventListener('mousemove', e => {
          const tagRect = button.getBoundingClientRect();

          window.addEventListener('resize', () => {
            span.overflowedRight = false;
            span.style.width = '';
          });

          if (
            span.getBoundingClientRect().right >
              divCardComentario.getBoundingClientRect().right ||
            span.overflowedRight
          ) {
            const tagNewWidth =
              divCardComentario.getBoundingClientRect().width / 2;
            const tagHalfWidth = span.getBoundingClientRect().width / 2;

            if (tagNewWidth < span.getBoundingClientRect().width) {
              span.style.width = `${tagNewWidth}px`;
            }

            span.style.left = `${e.pageX - tagRect.left - tagHalfWidth}px`;
            span.overflowedRight = true;
          } else {
            span.style.left = `${e.pageX - tagRect.left}px`;
          }

          span.style.top = `${e.pageY - tagRect.top}px`;
        });
      });

      const cardConteudo = document.createElement('div');
      cardConteudo.classList.add('card-conteudo');
      const p = document.createElement('p');
      p.innerText = card.conteudo;
      cardConteudo.appendChild(p);
      cardBody.appendChild(cardConteudo);

      const cardArquivo = document.createElement('div');
      cardArquivo.classList.add('card-arquivos');
      if (card.pdf.length > 0) {
        card.pdf.forEach(arquivo => {
          const divArquivo = document.createElement('div');
          divArquivo.classList.add('arquivo');
          const a = document.createElement('a');
          a.classList.add('link-normal', 'dark');
          const i = document.createElement('i');
          i.classList.add('far', 'fa-file-pdf');
          a.appendChild(i);
          a.innerText = `${arquivo}`;
          divArquivo.appendChild(a);
          cardArquivo.appendChild(divArquivo);
        });
      }
      cardBody.appendChild(cardArquivo);

      const cardFooter = document.createElement('div');
      cardFooter.classList.add('card-footer');
      divCardComentario.appendChild(cardFooter);

      return divCardComentario;
    };

    const buildCardsCommentsWithAPI = async atividadeCardComment => {
      const topAtividades = atividadeCardComment.getElementsByClassName(
        'atividades__top'
      )[0];
      const cardAtividades = atividadeCardComment.getElementsByClassName(
        'atividades__cards'
      )[0];
      const buttonAtividades = atividadeCardComment.getElementsByClassName(
        'atividades__button'
      )[0];

      const dataApi = atividadeCardComment.dataset.api;
      if (dataApi) {
        const data = await fetch(dataApi);
        const cardsApi = await data.json();

        cardsApi.forEach(card => {
          const cardComentario = cardsComments(card);
          cardAtividades.appendChild(cardComentario);
        });
      }

      if (cardAtividades.children.length > 1) {
        cardAtividades.children[0].classList.add('d-none');
        topAtividades.children[1].classList.remove('d-none');
        buttonAtividades.classList.remove('d-none');
      } else {
        cardAtividades.children[0].classList.remove('d-none');
        topAtividades.children[1].classList.add('d-none');
        buttonAtividades.classList.add('d-none');
      }
    };

    const checkboxAtividadeHandler = checkbox => {
      const divCards =
        checkbox.parentElement.parentElement.parentElement.nextElementSibling;
      checkbox.addEventListener('change', () => {
        const isChecked = checkbox.checked;

        if (isChecked) {
          Array.from(divCards.children).forEach(filho => {
            if (filho.classList.contains('log-sistema')) {
              filho.classList.remove('d-none');
            }
          });
        } else {
          Array.from(divCards.children).forEach(filho => {
            if (filho.classList.contains('log-sistema')) {
              filho.classList.add('d-none');
            }
          });
        }
      });
    };

    atividadesContainer.forEach(buildCardsCommentsWithAPI);
    checkboxesAtividades.forEach(checkboxAtividadeHandler);

    Cosmos[
      COMPONENT_NAME
    ].register.buildingActivityCardsCommentsWithAPI = buildCardsCommentsWithAPI;
    Cosmos[
      COMPONENT_NAME
    ].register.atividadeCheckbox = checkboxAtividadeHandler;
  })();

  (function() {
    const siediContainer = document.querySelector('.siedi');

    if (!siediContainer) return;

    const pagesChefesUnidades = document.querySelectorAll('.chefe-unidade');

    const criaGraficoLinha = (lineChart, labels, line) => {
      if (lineChart) {
        const lineChartContext = lineChart.getContext('2d');
        new Chart(lineChartContext, {
          type: 'line',
          data: {
            labels,
            datasets: [
              {
                label: line.label,
                data: line.data,
              },
            ],
          },
        });
      }
    };

    const constroeGraficos = constroeGrafico => {
      const chefeUnidadeProgress = constroeGrafico.getElementsByClassName(
        'chefe-unidade__progress'
      )[0];
      const graficos = Array.from(
        chefeUnidadeProgress.getElementsByClassName('chart')
      );
      graficos.forEach((graficoLine, indice) => {
        const canvas = graficoLine.children[1];
        const labels = [
          'checkpoint 1',
          'checkpoint 2',
          'checkpoint 3',
          'checkpoint 4',
        ];
        const linha = {
          label: 'Progresso',
          data: ['10', `${indice * 10 + 35}`, `${indice * 10 + 55}`, `100`],
        };

        criaGraficoLinha(canvas, labels, linha);
      });
    };

    pagesChefesUnidades.forEach(constroeGraficos);

    Cosmos[
      COMPONENT_NAME
    ].register.buildGraficsInChefeUnidade = constroeGraficos;
  })();
})(window.Cosmos);
// ************************* FIM DO siedi_page.js *************************
