/* eslint-disable no-plusplus */
(function() {
  const paginaMemorial = document.querySelectorAll('.memorial_eternos');
  const cardList = document.querySelector(
    '.container-conteudo__memorial .container__list'
  );

  const ordenacaoCards = () => {
    const cardMemorial = cardList.querySelectorAll('.card-memorial');

    let cardHeightImpar = 0;
    let cardHeightPar = 0;
    let cardHeight = 0;

    for (let i = 0; i < cardMemorial.length; i++) {
      if (i % 2 === 0) {
        cardHeightImpar += cardMemorial[i].offsetHeight + 10;
      } else {
        cardHeightPar += cardMemorial[i].offsetHeight + 10;
      }
    }

    if (cardHeightImpar > cardHeightPar) {
      cardHeight = cardHeightImpar;
    } else {
      cardHeight = cardHeightPar;
    }

    if (window.innerWidth < 992) {
      cardHeight = cardHeightImpar + cardHeightPar - 50;
    }

    cardList.style.height = `${cardHeight + 60}px`;
  };

  if (cardList) {
    paginaMemorial.forEach(ordenacaoCards);
    window.addEventListener('resize', ordenacaoCards);
  }
})();

// ************************* FIM DO memorial_eternos_page.js *************************
