import MenuNavLibras from './MenuNavLibras';

/**
 * Controla a expansão/retração do menu
 */
export default class ToggleMenu {
  element: HTMLElement & { ToggleMenu?: ToggleMenu };
  menu: (HTMLElement & { MenuNavLibras?: MenuNavLibras }) | null;
  constructor(element: HTMLElement, target: HTMLElement | null) {
    this.element = element;
    this.element.ToggleMenu = this;
    this.menu = target;

    if (!this.menu) {
      console.error(
        `não foi possível encontrar o menu com id = "${element.dataset.toggleMenu}".\nO botão deve ter o atributo data-toggle-menu="#idDoMenu"`
      );
      return;
    }
    this.init();
  }

  init() {
    this.element.addEventListener('click', () => this.toggleMenu());
  }

  toggleMenu() {
    this.menu?.MenuNavLibras?.toggleMenu();
  }

  close() {
    this.menu?.MenuNavLibras?.close();
  }
}
