/*
 *  Este código é uma adaptação de
 *  https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
 *
 *  This content is licensed according to the W3C Software License at
 *  https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
 */
(function(Cosmos) {
  class SelectFactory {
    static createCombobox(id, labelId = '') {
      const htmlString = `
            <div class="combo">
                <button aria-controls="${id}-listbox"
                    type="button"
                    aria-expanded="false"
                    aria-haspopup="listbox"
                    id="${id}"
                    class="combo-input form-control"
                    role="combobox"
                    tabindex="0"></button>
                <div class="combo-menu custom-scroll"
                    role="listbox"
                    id="${id}-listbox"
                    aria-labelledby="${labelId}"
                    tabindex="-1">
                </div>
            </div>`;

      const parser = new DOMParser();
      return parser.parseFromString(htmlString, 'text/html').body
        .firstElementChild;
    }
  }

  // Save a list of named combobox actions, for future readability
  const SelectActions = {
    Close: 0,
    CloseSelect: 1,
    First: 2,
    Last: 3,
    Next: 4,
    Open: 5,
    PageDown: 6,
    PageUp: 7,
    Previous: 8,
    Select: 9,
    Type: 10,
  };

  /*
   * Helper functions
   */

  // filter an array of options against an input string
  // returns an array of options that begin with the filter string, case-independent
  // eslint-disable-next-line default-param-last
  function filterOptions(options = [], filter, exclude = []) {
    return options.filter(option => {
      const matches =
        option.text.toLowerCase().indexOf(filter.toLowerCase()) === 0;
      return matches && exclude.indexOf(option.text) < 0;
    });
  }

  // map a key press to an action
  function getActionFromKey(event, menuOpen) {
    const { key, altKey, ctrlKey, metaKey } = event;
    const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' ']; // all keys that will do the default open action
    // handle opening when closed
    if (!menuOpen && openKeys.includes(key)) {
      return SelectActions.Open;
    }

    // home and end move the selected option when open or closed
    if (key === 'Home') {
      return SelectActions.First;
    }
    if (key === 'End') {
      return SelectActions.Last;
    }

    // handle typing characters when open or closed
    if (
      key === 'Backspace' ||
      key === 'Clear' ||
      (key.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey)
    ) {
      return SelectActions.Type;
    }

    // handle keys when open
    if (menuOpen) {
      if (key === 'ArrowUp' && altKey) {
        return SelectActions.CloseSelect;
      }
      if (key === 'ArrowDown' && !altKey) {
        return SelectActions.Next;
      }
      if (key === 'ArrowUp') {
        return SelectActions.Previous;
      }
      if (key === 'PageUp') {
        return SelectActions.PageUp;
      }
      if (key === 'PageDown') {
        return SelectActions.PageDown;
      }
      if (key === 'Escape') {
        return SelectActions.Close;
      }
      if (key === 'Enter' || key === ' ') {
        return SelectActions.CloseSelect;
      }
    }
    return undefined;
  }

  // return the index of an option from an array of options, based on a search string
  // if the filter is multiple iterations of the same letter (e.g "aaa"), then cycle through first-letter matches
  function getIndexByLetter(options, filter, startIndex = 0) {
    const orderedOptions = [
      ...options.slice(startIndex),
      ...options.slice(0, startIndex),
    ];
    const firstMatch = filterOptions(orderedOptions, filter)[0];
    const allSameLetter = array => array.every(letter => letter === array[0]);

    // first check if there is an exact match for the typed string
    if (firstMatch) {
      return options.indexOf(firstMatch);
    }

    // if the same letter is being repeated, cycle through first-letter matches
    if (allSameLetter(filter.split(''))) {
      const matches = filterOptions(orderedOptions, filter[0]);
      return options.indexOf(matches[0]);
    }

    // if no matches, return -1
    return -1;
  }

  // get an updated option index after performing an action
  function getUpdatedIndex(currentIndex, maxIndex, action) {
    const pageSize = 10; // used for pageup/pagedown

    switch (action) {
      case SelectActions.First:
        return 0;
      case SelectActions.Last:
        return maxIndex;
      case SelectActions.Previous:
        return Math.max(0, currentIndex - 1);
      case SelectActions.Next:
        return Math.min(maxIndex, currentIndex + 1);
      case SelectActions.PageUp:
        return Math.max(0, currentIndex - pageSize);
      case SelectActions.PageDown:
        return Math.min(maxIndex, currentIndex + pageSize);
      default:
        return currentIndex;
    }
  }

  // check if element is visible in browser view port
  function isElementInView(element) {
    const bounding = element.getBoundingClientRect();

    return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  // check if an element is currently scrollable
  function isScrollable(element) {
    return element && element.clientHeight < element.scrollHeight;
  }

  // ensure a given child element is within the parent's visible scroll area
  // if the child is not visible, scroll the parent
  function maintainScrollVisibility(activeElement, scrollParent) {
    const { offsetHeight, offsetTop } = activeElement;
    const { offsetHeight: parentOffsetHeight, scrollTop } = scrollParent;

    const isAbove = offsetTop < scrollTop;
    const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;

    if (isAbove) {
      scrollParent.scrollTo(0, offsetTop);
    } else if (isBelow) {
      scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
    }
  }

  /*
   * Select Component
   * Accepts a combobox element and an array of string options
   */
  const Select = function(el, options = [], originalEl = undefined) {
    // element refs
    this.el = el;
    this.comboEl = el.querySelector('[role=combobox]');
    this.listboxEl = el.querySelector('[role=listbox]');
    this.originalEl = originalEl; // original select

    // data
    this.idBase = this.comboEl.id || 'combo';
    this.options = options;

    // state
    this.activeIndex = 0;
    this.open = false;
    this.searchString = '';
    this.searchTimeout = null;

    // init
    if (el && this.comboEl && this.listboxEl) {
      this.init();
    }
  };

  Select.prototype.init = function() {
    // adjust label "for" attribute
    const labelId = this.listboxEl.getAttribute('aria-labelledby');
    if (labelId) {
      const label = document.querySelector(`#${labelId}`);
      if (label) {
        label.setAttribute('for', this.comboEl.id);
      }
    }

    // create options
    this.options.forEach((option, index) => {
      const optionEl = this.createOption(option, index);
      this.listboxEl.appendChild(optionEl);
    });

    // disabled state
    this.updateDisabled();

    // filled state
    this.updateFilledState();

    // invalid state
    this.updateInvalidState();

    // set selected option
    this.selectOption((this.originalEl && this.originalEl.selectedIndex) || 0);
    this.onOptionChange(
      (this.originalEl && this.originalEl.selectedIndex) || 0
    );

    // add event listeners
    this.comboEl.addEventListener('blur', this.onComboBlur.bind(this));
    this.comboEl.addEventListener('focus', this.onComboFocus.bind(this));
    this.listboxEl.addEventListener('focusout', this.onComboBlur.bind(this));
    this.comboEl.addEventListener('click', this.onComboClick.bind(this));
    this.comboEl.addEventListener('keydown', this.onComboKeyDown.bind(this));

    // mutation observer for original select
    this.observer = new MutationObserver(mutations => {
      this.handleMutations(mutations);
    });
    this.observer.observe(this.originalEl, {
      attributes: true,
      childList: true,
      characterData: true,
      attributeFilter: ['disabled', 'class'],
    });

    if (this.originalEl) {
      const onChangeValue = (oldValue, newValue) => {
        if (oldValue !== newValue) {
          this.selectOption(this.originalEl.selectedIndex);
          this.onOptionChange(this.originalEl.selectedIndex);
        }
      };

      Cosmos.utils.observeElement(this.originalEl, 'value', onChangeValue);
      Cosmos.utils.observeElement(
        this.originalEl,
        'selectedIndex',
        onChangeValue
      );
    }
  };

  Select.prototype.handleMutations = function(mutations) {
    mutations.forEach(mutation => {
      if (mutation.type === 'childList' || mutation.type === 'characterData') {
        this.updateOptions();
      }
      if (mutation.type === 'attributes') {
        if (mutation.attributeName === 'disabled') {
          this.updateDisabled();
        }
        if (mutation.attributeName === 'class') {
          this.updateInvalidState();
        }
      }
    });
  };

  Select.prototype.updateInvalidState = function() {
    if (!this.originalEl) return;
    if (this.originalEl.classList.contains('is-invalid')) {
      this.el.classList.add('is-invalid');
      this.comboEl.classList.add('is-invalid');
      this.comboEl.setAttribute('aria-invalid', 'true');
    } else {
      this.el.classList.remove('is-invalid');
      this.comboEl.classList.remove('is-invalid');
      this.comboEl.removeAttribute('aria-invalid');
    }
  };

  Select.prototype.updateFilledState = function() {
    if (!this.originalEl) return;
    if (this.originalEl.value.trim() !== '') {
      this.comboEl.classList.add('filled');
    } else {
      this.comboEl.classList.remove('filled');
    }
  };

  Select.prototype.updateOptions = function() {
    if (!this.originalEl) return;
    this.listboxEl.innerHTML = '';
    this.options = [...this.originalEl.options].map(option => {
      return { text: option.innerText, value: option.value };
    });

    this.options.forEach((option, index) => {
      const optionEl = this.createOption(option, index);
      this.listboxEl.appendChild(optionEl);
    });
  };

  Select.prototype.updateDisabled = function() {
    if (!this.originalEl) return;
    if (this.originalEl.disabled) {
      this.el.classList.add('disabled');
      this.comboEl.setAttribute('disabled', 'true');
      this.comboEl.setAttribute('aria-disabled', 'true');
      this.listboxEl.setAttribute('aria-disabled', 'true');
    } else {
      this.el.classList.remove('disabled');
      this.comboEl.removeAttribute('disabled');
      this.comboEl.removeAttribute('aria-disabled');
      this.listboxEl.removeAttribute('aria-disabled');
    }
  };

  Select.prototype.addOptionHandler = function(optionEl, index) {
    optionEl.addEventListener('click', event => {
      event.stopPropagation();
      this.onOptionClick(index);
    });
    optionEl.addEventListener('mousedown', this.onOptionMouseDown.bind(this));
  };

  Select.prototype.createOption = function(option, index) {
    const optionEl = document.createElement('div');
    optionEl.setAttribute('role', 'option');
    optionEl.setAttribute('value', option.value);
    optionEl.id = `${this.idBase}-${index}`;
    optionEl.className = 'combo-option';
    optionEl.innerText = option.text;

    if (this.originalEl) {
      if (this.originalEl.options[index].hasAttribute('data-placeholder')) {
        optionEl.setAttribute('data-placeholder', 'true');
      }
      if (this.originalEl.options[index].disabled) {
        optionEl.setAttribute('disabled', 'true');
        optionEl.setAttribute('aria-disabled', 'true');
      }
    }

    this.addOptionHandler(optionEl, index);

    return optionEl;
  };

  Select.prototype.getSearchString = function(char) {
    // reset typing timeout and start new timeout
    // this allows us to make multiple-letter matches, like a native select
    if (typeof this.searchTimeout === 'number') {
      window.clearTimeout(this.searchTimeout);
    }

    this.searchTimeout = window.setTimeout(() => {
      this.searchString = '';
    }, 500);

    // add most recent letter to saved search string
    this.searchString += char;
    return this.searchString;
  };

  Select.prototype.onComboFocus = function() {
    if (this.isDisabled()) return;

    const formGroup = this.el.closest('.form-group');
    if (formGroup) formGroup.classList.add('focused');
  };

  Select.prototype.onComboBlur = function(event) {
    if (this.ignoreBlur) {
      this.ignoreBlur = false;
      return;
    }

    // do nothing if relatedTarget is contained within listboxEl
    if (this.listboxEl.contains(event.relatedTarget)) {
      return;
    }

    // select current option and close
    if (this.open) {
      if (this.shouldSelectOption(this.activeIndex)) {
        // if option is not disabled
        this.selectOption(this.activeIndex);
      }
      this.updateMenuState(false, false);
    }
    const formGroup = this.el.closest('.form-group');
    if (formGroup) formGroup.classList.remove('focused');
    this.updateFilledState();
  };

  Select.prototype.onComboClick = function(event) {
    if (!this.shouldOpenListbox()) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    this.updateMenuState(!this.open, false);
  };

  Select.prototype.onComboKeyDown = function(event) {
    if (!this.shouldOpenListbox()) {
      event.stopPropagation();
      return undefined;
    }
    const { key } = event;
    const max = this.options.length - 1;

    const action = getActionFromKey(event, this.open);

    switch (action) {
      case SelectActions.Last:
      case SelectActions.First:
        this.updateMenuState(true, false);
      // intentional fallthrough
      case SelectActions.Next:
      case SelectActions.Previous:
      case SelectActions.PageUp:
      case SelectActions.PageDown:
        event.preventDefault();
        return this.onOptionChange(
          getUpdatedIndex(this.activeIndex, max, action)
        );
      case SelectActions.CloseSelect:
        event.preventDefault();
        if (this.shouldSelectOption(this.activeIndex)) {
          this.selectOption(this.activeIndex);
          return this.updateMenuState(false, false);
        }
        break;
      // intentional fallthrough
      case SelectActions.Close:
        event.preventDefault();
        return this.updateMenuState(false);
      case SelectActions.Type:
        return this.onComboType(key);
      case SelectActions.Open:
        event.preventDefault();
        return this.updateMenuState(true);
      default:
    }
    return undefined;
  };

  Select.prototype.onComboType = function(letter) {
    // open the listbox if it is closed
    this.updateMenuState(true);

    // find the index of the first matching option
    const searchString = this.getSearchString(letter);
    const searchIndex = getIndexByLetter(
      this.options,
      searchString,
      this.activeIndex + 1
    );

    // if a match was found, go to it
    if (searchIndex >= 0) {
      this.onOptionChange(searchIndex);
    }
    // if no matches, clear the timeout and search string
    else {
      window.clearTimeout(this.searchTimeout);
      this.searchString = '';
    }
  };

  Select.prototype.updateOptionActive = function() {
    const options = this.el.querySelectorAll('[role=option]');
    [...options].forEach(optionEl => {
      optionEl.classList.remove('option-current');
    });
    options[this.activeIndex].classList.add('option-current');

    // ensure the new option is in view
    if (isScrollable(this.listboxEl)) {
      maintainScrollVisibility(options[this.activeIndex], this.listboxEl);
    }

    // ensure the new option is visible on screen
    // ensure the new option is in view
    if (!isElementInView(options[this.activeIndex])) {
      options[this.activeIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  };

  Select.prototype.onOptionChange = function(index) {
    // update state
    this.activeIndex = index;

    // update aria-activedescendant
    this.comboEl.setAttribute(
      'aria-activedescendant',
      `${this.idBase}-${index}`
    );

    // update active option styles
    this.updateOptionActive();
  };

  Select.prototype.onOptionClick = function(index) {
    if (!this.shouldSelectOption(index)) {
      this.ignoreBlur = true;
      this.comboEl.focus();
      return;
    }
    this.onOptionChange(index);
    this.selectOption(index);
    this.updateMenuState(false);
  };

  Select.prototype.onOptionMouseDown = function() {
    // Clicking an option will cause a blur event,
    // but we don't want to perform the default keyboard blur action
    this.ignoreBlur = true;
  };

  Select.prototype.selectOption = function(index) {
    // update state
    this.activeIndex = index;

    // update displayed value
    const selected = this.options[index];
    this.comboEl.innerHTML = selected.text;

    // update aria-selected and aria-label
    const options = this.el.querySelectorAll('[role=option]');
    [...options].forEach((optionEl) => {
      optionEl.setAttribute('aria-selected', 'false');

      const ariaLabel = optionEl.getAttribute('aria-label');
      if (ariaLabel && ariaLabel.startsWith('Selecionado: ')) {
        optionEl.setAttribute(
          'aria-label',
          ariaLabel.replace('Selecionado: ', '')
        );
      }
    });
    options[index].setAttribute('aria-selected', 'true');
    if (!options[index].hasAttribute('data-placeholder')) {
      if (options[index].hasAttribute('aria-label')) {
        options[index].setAttribute(
          'aria-label',
          `Selecionado: ${options[index].getAttribute('aria-label')}`
        );
      } else {
        options[index].setAttribute(
          'aria-label',
          `Selecionado: ${this.options[index].text}`
        );
      }
    }

    this.dispatchChangeEvent(selected);
  };

  Select.prototype.dispatchChangeEvent = function (selected) {
    const changeEvent = new CustomEvent('change', {
      bubbles: true,
      detail: {
        value: selected.value,
        text: selected.text,
      },
    });

    if (this.originalEl) {
      if (this.originalEl.value !== selected.value) {
        this.originalEl.value = selected.value;
        this.originalEl.dispatchEvent(changeEvent);
      }
    }
    this.comboEl.dispatchEvent(changeEvent);
  };

  Select.prototype.shouldSelectOption = function(index) {
    return !this.listboxEl
      .querySelectorAll('[role=option]')
      [index].hasAttribute('disabled');
  };

  Select.prototype.shouldOpenListbox = function() {
    return !this.isDisabled();
  };

  Select.prototype.isDisabled = function() {
    return (
      this.originalEl.hasAttribute('aria-disabled') || this.originalEl.disabled
    );
  };

  Select.prototype.updateMenuState = function(open, callFocus = true) {
    if (this.open === open) {
      return;
    }

    // update state
    this.open = open;

    // update aria-expanded and styles
    this.comboEl.setAttribute('aria-expanded', `${open}`);
    if (open) {
      this.el.classList.add('open');
      this.onOptionChange(this.activeIndex);
    } else {
      this.el.classList.remove('open');
    }

    // update activedescendant
    const activeID = open ? `${this.idBase}-${this.activeIndex}` : '';
    this.comboEl.setAttribute('aria-activedescendant', activeID);

    // move focus back to the combobox, if needed
    if (callFocus) {
      this.comboEl.focus();
    }
  };

  const initSelects = function(element) {
    const root = element instanceof HTMLElement ? element : document;
    const selectEls = root.querySelectorAll([
      'select.custom-select:not([data-cosmos])',
    ]);

    // const selectEls = document.querySelectorAll('.js-select');

    selectEls.forEach(el => {
      const id = el.id || `custom-select-${Math.floor(Math.random() * 1000)}`;
      // hide the original select element
      el.setAttribute('data-cosmos', 'true');
      el.setAttribute('hidden', 'true');
      el.setAttribute('aria-hidden', 'true');

      // pre-populate the listbox with the original select options
      const options = Array.from(el.options).map(opt => {
        return { value: opt.getAttribute('value'), text: opt.innerText };
      });

      // assing label
      const label = document.querySelector(`label[for="${id}"]`);
      if (label) {
        label.setAttribute('id', label.id || `${id}-label`);
      }

      // create the custom select
      const customSelectEl = SelectFactory.createCombobox(
        `${id}-custom`,
        label && label.id
      );
      el.insertAdjacentElement('afterend', customSelectEl);
      new Select(customSelectEl, options, el);
    });
  };

  // init select
  window.addEventListener('load', function() {
    initSelects();
  });

  const COMPONENT_NAME = 'CustomSelect';
  const methods = {
    Select,
    init: initSelects,
  };
  Cosmos[COMPONENT_NAME] = methods;
  Cosmos[COMPONENT_NAME].init();

  return methods;
})(window.Cosmos);

// ************************* FIM DO custom-select.js *************************
