/* ---------------------------- ADICIONA O ANO ATUAL NO FOOTER ----------------------------
|   Esse trecho de código realiza a adição do ano atual no span do footer através de uma   |
|   instância de Date que representa um único momento no tempo. Objetos Date são baseados  |
|   no valor de tempo que é o número de milisegundos desde 1º de Janeiro de 1970 (UTC).    |
|   O valor retornado por getFullYear() é um número absoluto e para datas entre os anos    |
|   1000 e 9999, é retornado um número de quatro dígitos, por exemplo, 1995.               |
|                                                                                          |
|   Este script faz o uso do design pattern 'Return Early' que é a maneira de escrever     |
|   funções ou métodos de forma que o resultado positivo esperado seja retornado no final  |
|   da função e o restante do código termine a execução (retornando ou lançando uma        |
|   exceção) quando as condições não forem atendidas.                                      |
*----------------------------------------------------------------------------------------*/
(() => {
  const copyrightCurrentYear = document.querySelectorAll(
    '.copyrightCurrentYear'
  );

  if (!copyrightCurrentYear || copyrightCurrentYear.length === 0) {
    return;
  }

  const year = new Date().getFullYear();
  copyrightCurrentYear.forEach(currentYear => {
    currentYear.innerHTML = year;
  });
})();

// ************************* FIM DO copyright-current-year.js *************************
