import { debounce, observeElement, hexToRgba } from './commons';

(function (Cosmos) {
  const COMPONENT_NAME = 'utils';
  const methods = {
    observeElement,
    debounce,
    hexToRgba,
  };

  Cosmos[COMPONENT_NAME] = methods;

  return methods;
})(window.Cosmos);

// ************************* FIM DO utils.js *************************
