(function(Cosmos) {
  const createLoading = () => {
    const loading = document.createElement('div');
    loading.className = 'loading-overlay';
    loading.innerHTML = `
    <div class="loading-box loading-box--bar" role="alert" aria-busy="true">
      <h1>Carregando</h1>
      <p class="loading-text" aria-live="polite">O conteúdo da tela está sendo processado...</p>
      <div class="loading-content" role="progressbar" aria-live="polite"></div>
    </div>
  `;
    return loading;
  };

  const blockLoading = element => {
    const html =
      element instanceof HTMLElement ? element : document.querySelector('body');
    html.ariaBusy = true;
    const isLoading = html.querySelector('.loading-overlay');
    if (html && !isLoading) {
      html.prepend(createLoading());
    }
    if (!element) {
      html.classList.add('position-fixed');
    }

    const destroy = function() {
      const loadingOverlay = html.querySelector('.loading-overlay');
      html.ariaBusy = false;
      if (html.tagName.toLocaleUpperCase() === 'BODY') {
        html.classList.remove('position-fixed');
      }
      loadingOverlay.querySelector('.loading-text').textContent =
        'Processamento concluido.';
      loadingOverlay.ariaBusy = false;

      setTimeout(() => {
        if (loadingOverlay) {
          loadingOverlay.remove();
        }
      }, 300);
    };
    return {
      destroy,
    };
  };

  const COMPONENT_NAME = 'BlockLoading';
  Cosmos[COMPONENT_NAME] = blockLoading;
})(window.Cosmos);

// ************************* FIM DO overlay_loading.js *************************
