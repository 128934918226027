document.documentElement.setAttribute('data-browser', navigator.userAgent);

/* ----- MUDANÇA DINÂMICA DE PALETA --------------------------------------------
|  Esse trecho de código realiza a mudança de paleta baseado na cor que está
|  setada no local storage do usuário, caso não exista, a paleta escolhida será
|  a que estiver presente no atributo "paleta" da tag html.
|  A mudança ocorre a partir da seleção de todos os elementos que possuem as
|  classes setadas no array abaixo e a concatenação da cor da paleta na classe.
|  Por exemplo, caso a paleta seja acadêmica, então qualquer elemento que possuir
|  a classe "form" passará a assumir a classe "form--academica" e assim por diante.
*-------------------------------------------------------------------*/
(function() {
  const html = document.querySelector('html');
  const mainContainer = document.querySelector('.container-principal');

  if (mainContainer) {
    const logoHeader = mainContainer.querySelector(
      '.header__logo > a > svg > defs > style'
    );

    const palettesColors = {
      academica: '#70c7cc;',
      administrativa: '#72a8e5;',
      social: '#85cc3d;',
    };

    const setClassesBackToNormal = oldPalette => {
      logoHeader.innerHTML = logoHeader.innerHTML.replace(
        `.a{fill:${palettesColors[oldPalette]}}`,
        ''
      );
    };

    const updateMetaTheme = palette => {
      const head = document.querySelector('head');
      let metaThemeColor = head.querySelector('meta[name="theme-color"]');

      if (!metaThemeColor) {
        metaThemeColor = document.createElement('meta');
        metaThemeColor.name = 'theme-color';
        head.append(metaThemeColor);
      }

      metaThemeColor.content = palettesColors[palette];
    };

    const htmlAttrChanged = ({ detail }) => {
      const palette = detail ? detail.palette : html.getAttribute('paleta');
      const oldPalette = detail && detail.oldPalette;

      if (palette === oldPalette) {
        return;
      }

      if (oldPalette) {
        setClassesBackToNormal(oldPalette);
      }

      updateMetaTheme(palette);
      html.setAttribute('paleta', palette);

      if (
        logoHeader &&
        !logoHeader.innerHTML.includes(`.a{fill:${palettesColors[palette]}}`)
      ) {
        logoHeader.innerHTML += `.a{fill:${palettesColors[palette]}}`;
      }
    };

    if (!html.getAttribute('paleta')) {
      // caso não tenha setado a paleta no html
      html.setAttribute('paleta', 'administrativa');
    }

    htmlAttrChanged({ detail: { palette: html.getAttribute('paleta') } });

    html.addEventListener('paletteChanged', htmlAttrChanged);
  }
})();

/* ----- MUDANÇA PARA PALETA DE ALTO CONTRASTE ---------------------------------
|  Esse trecho de código realiza a alternância entre as paletas de alto contraste
|  e a paleta padrão. Há duas formas de ocorrer a mudança:
|  1. Se já estiver setado no local storage;
|  2. Caso ocorra o clique de algum dos botões de alto contraste.
|  Todo o código é fundamento a partir do objeto "paletteAcInfo", o qual contém
|  todos os detalhes de cada uma das paletas de alto contraste, e do array "elements"
|  o qual contém todos os elementos ou classes que deverão ser alterados para
|  ativação do alto contraste.
*-------------------------------------------------------------------*/
(function() {
  const dropdownAc = document.querySelector('div.dropdown-menu.ac');

  if (dropdownAc && !dropdownAc.dataset.testMode) {
    const html = document.querySelector('html');
    const { body } = document;
    const buttonDefault = dropdownAc.querySelector('ul > li > button');
    const buttonWhiteBlue = dropdownAc.querySelector('button.branco-azul');
    const buttonBlueYellow = dropdownAc.querySelector('button.azul-amarelo');
    const buttonBlackYellow = dropdownAc.querySelector('button.preto-amarelo');
    const buttonBlackBlue = dropdownAc.querySelector('button.preto-azul');
    const logoHeader = document.querySelector(
      '.header__logo > a > svg > defs > style'
    );
    const logoLogin = document.querySelector(
      '#login-form > .login-system-logo > svg > defs > style'
    ); // Somente utilizada na tela de login de autenticação integrada
    const logoFooter = document.querySelector('.rodape__logo > a > svg > g');
    let paleta = html.getAttribute('paleta');

    const footerColor = '#a4b2c8';
    const paletteAcInfo = {
      'branco-azul': {
        bodyBg: 'white',
        font: 'preta',
        bg: 'branco',
        headerColor: '#3737e2',
        footerColor: '#000000',
        robotColor: 'azul_branco',
      },
      'azul-amarelo': {
        bodyBg: 'blue',
        font: 'branca',
        bg: 'azul',
        headerColor: '#ffff00',
        footerColor: '#ffffff',
        robotColor: 'amarelo',
      },
      'preto-amarelo': {
        bodyBg: 'black',
        font: 'branca',
        bg: 'preto',
        headerColor: '#ffff00',
        footerColor: '#ffffff',
        robotColor: 'amarelo',
      },
      'preto-azul': {
        bodyBg: 'black',
        font: 'branca',
        bg: 'preto',
        headerColor: '#00f3f3',
        footerColor: '#ffffff',
        robotColor: 'azul',
      },
    };

    const acMode = () => {
      const currentAcMode = localStorage.getItem('acMode');

      if (currentAcMode === 'false' || !currentAcMode) {
        return 'off';
      }

      return currentAcMode;
    };

    const setAcMode = ac => {
      localStorage.setItem('acMode', ac);
      html.dispatchEvent(new CustomEvent('contrastChange', { detail: { ac } }));
    };

    const removeSelectedClass = () => {
      buttonDefault.classList.remove('selected');
      buttonWhiteBlue.classList.remove('selected');
      buttonBlueYellow.classList.remove('selected');
      buttonBlackYellow.classList.remove('selected');
      buttonBlackBlue.classList.remove('selected');
    };

    const changeStylesOn = paletteAc => {
      if (html.getAttribute('paleta') !== paleta) {
        paleta = html.getAttribute('paleta');
      }
      removeSelectedClass();
      switch (paletteAc) {
        case 'branco-azul':
          buttonWhiteBlue.classList.add('selected');
          break;
        case 'azul-amarelo':
          buttonBlueYellow.classList.add('selected');
          break;
        case 'preto-amarelo':
          buttonBlackYellow.classList.add('selected');
          break;
        case 'preto-azul':
          buttonBlackBlue.classList.add('selected');
          break;

        default:
          break;
      }
      body.classList.add(`bg-${paletteAcInfo[paletteAc].bodyBg}`);
      body.setAttribute('data-ac-activated', paletteAc);

      if (logoHeader) {
        const styleSvg = `.a,.b{fill:${paletteAcInfo[paletteAc].headerColor}}`;

        if (
          document.getElementById('logo-ufrn') &&
          !logoHeader.innerHTML.includes(
            `.a-ufrn,.b-ufrn,.c-ufrn,.d-ufrn{fill:${paletteAcInfo[paletteAc].footerColor}}`
          )
        ) {
          logoHeader.innerHTML += `.a-ufrn,.b-ufrn,.c-ufrn,.d-ufrn{fill:${paletteAcInfo[paletteAc].footerColor}}`;
        } else if (!logoHeader.innerHTML.includes(styleSvg)) {
          logoHeader.innerHTML += styleSvg;
        }
      }

      if (logoLogin) {
        const styleSvg = `.a,.b{fill:${paletteAcInfo[paletteAc].headerColor}}`;

        if (!logoLogin.innerHTML.includes(styleSvg)) {
          logoLogin.innerHTML += styleSvg;
        }
      }

      if (logoFooter) {
        logoFooter.innerHTML = logoFooter.innerHTML.replace(
          new RegExp(footerColor, 'g'),
          paletteAcInfo[paletteAc].footerColor
        );
      }
    };

    const changeStylesOff = previousPalette => {
      if (previousPalette !== 'off') {
        removeSelectedClass();
        buttonDefault.classList.add('selected');
        // Remove todas as classes do body que iniciam com bg-
        body.className = body.className.replace(/bg-\w+/g, '').trim();
        body.removeAttribute('data-ac-activated');

        if (logoHeader) {
          if (document.getElementById('logo-ufrn')) {
            logoHeader.innerHTML = logoHeader.innerHTML.replace(
              `.a-ufrn,.b-ufrn,.c-ufrn,.d-ufrn{fill:${paletteAcInfo[previousPalette].footerColor}}`,
              ''
            );
          } else {
            logoHeader.innerHTML = logoHeader.innerHTML.replace(
              `.a,.b{fill:${paletteAcInfo[previousPalette].headerColor}}`,
              ''
            );
          }
        }

        if (logoLogin) {
          logoLogin.innerHTML = logoLogin.innerHTML.replace(
            `.a,.b{fill:${paletteAcInfo[previousPalette].headerColor}}`,
            ''
          );
        }

        if (logoFooter) {
          logoFooter.innerHTML = logoFooter.innerHTML.replace(
            new RegExp(paletteAcInfo[previousPalette].footerColor, 'g'),
            footerColor
          );
        }
      }
    };

    const handleStylesOn = () => {
      const { acActivated } = document.body.dataset;
      changeStylesOn(acActivated);
    };

    buttonDefault.addEventListener('click', () => {
      changeStylesOff(acMode());
      setAcMode('off');
      window.closeDropdown(buttonDefault);
    });

    buttonWhiteBlue.addEventListener('click', () => {
      const palette = 'branco-azul';

      if (palette === acMode()) {
        return;
      }

      changeStylesOff(acMode());
      setAcMode(palette);
      changeStylesOn(palette);
      window.closeDropdown(buttonWhiteBlue);
    });

    buttonBlueYellow.addEventListener('click', () => {
      const palette = 'azul-amarelo';

      if (palette === acMode()) {
        return;
      }

      changeStylesOff(acMode());
      setAcMode(palette);
      changeStylesOn(palette);
      window.closeDropdown(buttonBlueYellow);
    });

    buttonBlackYellow.addEventListener('click', () => {
      const palette = 'preto-amarelo';

      if (palette === acMode()) {
        return;
      }

      changeStylesOff(acMode());
      setAcMode(palette);
      changeStylesOn(palette);
      window.closeDropdown(buttonBlackYellow);
    });

    buttonBlackBlue.addEventListener('click', () => {
      const palette = 'preto-azul';

      if (palette === acMode()) {
        return;
      }

      changeStylesOff(acMode());
      setAcMode(palette);
      changeStylesOn(palette);
      window.closeDropdown(buttonBlackBlue);
    });

    const paletteAc = acMode();
    if (paletteAc !== 'off') {
      changeStylesOn(paletteAc);
    } else {
      buttonDefault.classList.add('selected');
    }

    html.addEventListener('acChange', handleStylesOn);
  }
})();

window.emitChangePalette = () => {
  const html = document.querySelector('html');

  html.dispatchEvent(new CustomEvent('paletteChanged'));

  const { acActivated } = document.body.dataset;

  if (acActivated) {
    html.dispatchEvent(new CustomEvent('acChange'));
  }
};

// ************************* FIM DO paleta.js *************************
