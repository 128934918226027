(function(Cosmos) {
  function saveConsent(consentKeyName, policyVersion, expireAt) {
    if (Number.isInteger(expireAt)) {
      const date = new Date();
      date.setDate(date.getDate() + expireAt);
      expireAt = date.toISOString();
    }
    if (expireAt instanceof Date) expireAt = expireAt.toISOString();

    const consentValues = {
      consent_date: new Date(),
      policy_version: policyVersion,
      expire_at: expireAt,
    };

    localStorage.setItem(consentKeyName, JSON.stringify(consentValues));
  }

  function loadConsent(consentKeyName) {
    const consentData = JSON.parse(localStorage.getItem(consentKeyName));
    if (!consentData) return {};
    return {
      consentDate: new Date(consentData.consent_date),
      policyVersion: consentData.policy_version,
      expireAt: consentData.expire_at ? new Date(consentData.expire_at) : null,
    };
  }

  function resetConsent(consentKeyName) {
    localStorage.removeItem(consentKeyName);
  }

  function createDialog(content, confirmCallback) {
    const dialog = document.createElement('div');
    Object.assign(dialog, {
      role: 'dialog',
      className: 'cookies-consent-dialog',
      ariaLabel: 'termo de consentimento do uso de cookies',
      tabIndex: '-1',
    });

    /*
    dialog.dataset.expiresAt =
      typeof expirationDate === 'string'
        ? new Date(expirationDate)
        : expirationDate;
    dialog.dataset.policyVersion = policyVersion;
    */
    dialog.innerHTML = `<div class="container"><p>${content}</p></div>`;

    const consentButton = document.createElement('button');
    Object.assign(consentButton, {
      className: 'btn-quaternary consent-button',
      innerHTML: 'Ciente',
    });

    dialog.append(consentButton);
    document.body.prepend(dialog);

    dialog.focus();

    consentButton.addEventListener('click', function() {
      confirmCallback();
      dialog.remove();
    });

    return { dialog, consentButton };
  }

  const hasConsentValid = (name, options) => {
    const localConsent = loadConsent(name);
    if (!localConsent) return false;
    if (localConsent.expireAt && new Date() > localConsent.expireAt)
      return false;
    if (localConsent.policyVersion !== options.policyVersion) return false;
    return true;
  };

  function initCookiesConsentDialog(consentKey, options) {
    if (!consentKey) consentKey = 'cookies-consent';
    const defaultOptions = {
      policyVersion: '1.0.0',
      expiration: null,
      consentText: `Nós usamos cookies para melhorar sua experiência de navegação no portal. Ao utilizar o sistema,
      você concorda com a política de monitoramento de cookies. Para ter mais informações sobre como isso é feito e como remover,
      acesse a <a href="https://ufrn.br/institucional/politica-de-cookies" class="link-normal"> Política de Cookies</a>.
      Para saber como a UFRN trata os dados, acesse a
      <a href="https://ufrn.br/institucional/politica-de-privacidade-e-termos-de-uso" class="link-normal">Política de Privacidade</a>.
      Se você concorda, clique em "Ciente".`,
    };
    options = { ...defaultOptions, ...options };

    if (hasConsentValid(consentKey, options)) return;

    resetConsent(consentKey);
    createDialog(options.consentText, function() {
      saveConsent(consentKey, options.policyVersion, options.expiration);
    });
  }

  const COMPONENT_NAME = 'consentDialog';
  const methods = {
    init: initCookiesConsentDialog,
  };
  Cosmos[COMPONENT_NAME] = methods;
})(window.Cosmos);

// ************************* FIM DO cookies-consent-dialog.js *************************
