(function() {
  document.querySelectorAll('.skip-menu a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function(e) {
      e.preventDefault();
      const target = document.querySelector(anchor.getAttribute('href'));
      if (!target) return;
      window.location.href =
        window.location.origin +
        window.location.pathname +
        anchor.getAttribute('href');
      target.scrollIntoView({
        behavior: 'smooth',
      });
      target.focus({ preventScroll: true });
    });
  });
})();
// ************************* FIM DO skip-menu.js *************************
