(function () {
  const wizard = document.querySelector('.wizard');
  if (!wizard) {
    return;
  }
  const wizardToggle = document.querySelector('.wizard-toggle');
  const backButton = document.querySelector('.wizard-btn-back');
  const nextButton = document.querySelector('.wizard-btn-next');
  const contents = document.querySelectorAll('.wizard-body-content');
  const wizardHeader = document.querySelector('.wizard-header');
  const wizardDeskVert = wizard.querySelector('.wizard-desktop-vertical');

  // Larguras máximas para a troca de layout (horizontal/vertical)
  const LARGURA_TELA_MAX_HORIZONTAL = 576;
  const LARGURA_TELA_MAX_VERTICAL = 768;

  // Aplica a classe step-vertical no wizard caso ele esteja no modo desktop vertical
  function aplicarStepVertical() {
    const step = wizard.querySelector('.step');

    if (wizardDeskVert) step.classList.add('step-vertical');
  }

  // Ajusta os estilos do wizard com base na largura da tela
  function ajustarLarguraTela() {
    const step = wizard.querySelector('.step');
    const LARGURA_TELA = document.documentElement.clientWidth;

    if (
      (LARGURA_TELA < LARGURA_TELA_MAX_VERTICAL && wizardDeskVert) ||
      LARGURA_TELA < LARGURA_TELA_MAX_HORIZONTAL
    ) {
      aplicarEstilosMobile(wizard, step);
    } else {
      aplicarEstilosDesktop(wizard, step);
    }

    aplicarStepVertical();
  }

  // Aplica os estilos de mobile
  function aplicarEstilosMobile(wizard, step) {
    wizard.classList.add('wizard-vertical');
    wizardToggle.style.display = 'flex';
    wizardHeader.style.display = 'none';
    backButton.innerHTML = '<i class="fas fa-chevron-left"></i>';
    nextButton.innerHTML = '<i class="fas fa-chevron-right"></i>';
    atualizarBotaoEstilos('btn-secondary', 'btn-secondary-icon');
    atualizarBotaoEstilos('btn-primary', 'btn-primary-icon');
  }

  // Aplica os estilos de desktop
  function aplicarEstilosDesktop(wizard, step) {
    wizard.classList.remove('wizard-vertical');
    wizardToggle.style.display = 'none';
    if (wizardToggle.classList.contains('wizard-toggle-expanded')) {
      resetarBotaoToggle();
    }
    wizardHeader.style.display = 'block';
    backButton.innerHTML = 'Voltar';
    nextButton.innerHTML = 'Avançar';
    atualizarBotaoEstilos('btn-secondary-icon', 'btn-secondary');
    atualizarBotaoEstilos('btn-primary-icon', 'btn-primary');
  }

  // Atualiza as classes dos botões
  function atualizarBotaoEstilos(removedClass, addedClass) {
    backButton.classList.replace(removedClass, addedClass);
    nextButton.classList.replace(removedClass, addedClass);
  }

  // Reseta o botão de toggle para o estado inicial
  function resetarBotaoToggle() {
    wizardToggle.classList.remove('wizard-toggle-expanded');
    wizardToggle.setAttribute('aria-expanded', 'false');
    wizardToggle
      .querySelector('i')
      .classList.replace('fa-chevron-up', 'fa-chevron-down');
    wizardHeader.style.display = 'none';
  }

  // Atualiza as classes do status do toggle do wizard com base no estado atual
  function atualizarWizardItemClasses() {
    const activeStep = document.querySelector('.step-progress-item.active');
    const wizardItem = document.querySelector('.wizard-status');
    if (activeStep && wizardItem) {
      resetarClasses(wizardItem);
      adicionarClassesBaseadasNoStatus(activeStep, wizardItem);

      // Atualiza o número do step apenas se o wizard for do tipo ordenado
      const stepContainer = wizard.querySelector('.step-progress ');
      if (stepContainer && stepContainer.classList.contains('step-ordered')) {
        atualizarStepNum(activeStep);
      }
    }
  }

  // Atualiza o número do step no status do wizard
  function atualizarStepNum(activeStep) {
    const wizardStatus = document.querySelector('.wizard-status.wizard-item');
    if (activeStep && wizardStatus) {
      const stepNum = activeStep.getAttribute('step-num');
      wizardStatus.setAttribute('step-num', stepNum);
    }
  }

  // Reseta as classes do status do toggle do wizard
  function resetarClasses(element) {
    element.className = 'wizard-status';
  }

  // Adiciona classes no status do toggle do wizard com base no status do step ativo
  function adicionarClassesBaseadasNoStatus(activeStep, wizardItem) {
    const stepIcon = activeStep.querySelector('.step-icon');
    if (stepIcon) {
      stepIcon.classList.forEach((cls) => {
        wizardItem.classList.add(cls);
        wizardItem.classList.remove('step-icon');
      });
    } else {
      const stepContainer = wizard.querySelector('.step-progress ');
      if (stepContainer && stepContainer.classList.contains('step-ordered')) {
        wizardItem.classList.add('wizard-ordered');
      } else {
        wizardItem.classList.add('wizard-bullet');
      }
    }

    if (activeStep.classList.contains('step-success')) {
      wizardItem.classList.add('wizard-success');
    } else if (activeStep.classList.contains('step-warning')) {
      wizardItem.classList.add('wizard-warning');
    } else if (activeStep.classList.contains('step-danger')) {
      wizardItem.classList.add('wizard-danger');
    }

    if (
      wizardItem.classList.contains('wizard-success') ||
      wizardItem.classList.contains('wizard-warning') ||
      wizardItem.classList.contains('wizard-danger') ||
      wizardItem.classList.contains('wizard-bullet') ||
      wizardItem.classList.contains('wizard-ordered')
    ) {
      wizardItem.classList.add('wizard-item');
    }
  }

  // Atualiza o texto do toggle do wizard baseado no step ativo
  function atualizarWizardTexto() {
    const activeStep = document.querySelector(
      '.step-progress-item.active .step-label'
    );
    const wizardText = document.querySelector('.wizard-text');
    if (activeStep && wizardText) {
      wizardText.textContent = activeStep.textContent;
    }
  }

  // Observer para monitorar mudanças de classes nos steps
  function configurarObservadorDeMutacoes() {
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'class'
        ) {
          const activeContentIndex = Array.from(contents).findIndex((content) =>
            content.classList.contains('active')
          );
          if (activeContentIndex !== -1) {
            atualizarWizardTexto();
            atualizarWizardItemClasses();
          }

          if (
            mutation.target.classList.contains('step-progress-item') &&
            mutation.target.classList.contains('active')
          ) {
            if (mutation.target.classList.contains('step-danger')) {
              const wizardItem = document.querySelector('.wizard-status');
              adicionarClassesBaseadasNoStatus(mutation.target, wizardItem);
            }
          }
        }
      }
    });

    contents.forEach((content) => {
      observer.observe(content, { attributes: true });
    });

    const stepItems = document.querySelectorAll('.step-progress-item');
    stepItems.forEach((stepItem) => {
      observer.observe(stepItem, { attributes: true });
    });
  }

  // Configura o evento do botão de toggle para alternar a exibição do cabeçalho
  function configurarEventoToggle() {
    if (!wizardToggle) return;
    wizardToggle.addEventListener('click', (event) => {
      event.stopPropagation();
      const isExpanded = wizardToggle.classList.toggle(
        'wizard-toggle-expanded'
      );
      wizardToggle.setAttribute('aria-expanded', isExpanded);
      wizardHeader.style.display = isExpanded ? 'block' : 'none';
      atualizarIconeToggle(isExpanded);
    });

    document.addEventListener('click', () => {
      if (wizardToggle.classList.contains('wizard-toggle-expanded')) {
        resetarBotaoToggle();
      }
    });
  }

  // Atualiza o ícone do botão de toggle com base no estado expandido/colapsado
  function atualizarIconeToggle(isExpanded) {
    if (isExpanded) {
      wizardToggle
        .querySelector('i')
        .classList.replace('fa-chevron-down', 'fa-chevron-up');
    } else {
      wizardToggle
        .querySelector('i')
        .classList.replace('fa-chevron-up', 'fa-chevron-down');
    }
  }

  // Scrolla a tela para baixo ao abrir um select
  function configurarRolagemSelect() {
    const comboButtons = wizard.querySelectorAll('.combo-input');
    const wizardBody = wizard.querySelector('.wizard-body');
    const wizardBodyStyle = getComputedStyle(wizardBody);
    const marginBottom = parseFloat(wizardBodyStyle.marginBottom);

    comboButtons.forEach(function (comboButton) {
      comboButton.addEventListener('click', function () {
        const comboPosition = comboButton.getBoundingClientRect();
        const wizardBodyPosition = wizardBody.getBoundingClientRect();

        const offset =
          comboPosition.top - wizardBodyPosition.top + wizardBody.scrollTop;

        wizardBody.scrollTo({
          top: offset - wizardBody.clientHeight / 2 + marginBottom,
          behavior: 'smooth',
        });
      });
    });
  }

  ajustarLarguraTela();
  atualizarWizardItemClasses();
  atualizarWizardTexto();
  configurarObservadorDeMutacoes();
  configurarEventoToggle();
  configurarRolagemSelect();

  window.addEventListener('resize', ajustarLarguraTela);
})();
