/* eslint-disable no-unused-vars */
(function() {
  const galeriasImagens = document.querySelectorAll('.galeria-imagens');
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const mountDropdown = (itemActions, dataAlbum, galeriaImagem) => {
    const dropdown = document.createElement('div');
    dropdown.classList.add('dropdown');
    const buttonDropdownToggle = document.createElement('button');
    const faEllipsis = document.createElement('i');
    const dropdownMenu = document.createElement('div');
    const dropMenuArrow = document.createElement('div');

    buttonDropdownToggle.classList.add('icon', 'dropdown-toggle');
    buttonDropdownToggle.setAttribute('id', `dropdown-album-${dataAlbum.id}`);
    buttonDropdownToggle.setAttribute('data-toggle', 'dropdown');
    buttonDropdownToggle.setAttribute('aria-haspopup', 'true');
    buttonDropdownToggle.setAttribute('aria-expanded', 'false');
    buttonDropdownToggle.setAttribute('aria-label', 'ações para a imagem');

    faEllipsis.classList.add('fas', 'fa-ellipsis-h');
    buttonDropdownToggle.appendChild(faEllipsis);
    dropdown.appendChild(buttonDropdownToggle);
    dropdownMenu.classList.add('dropdown-menu', 'dropdown-menu-right');
    dropdownMenu.setAttribute('aria-label', 'ações para o album');
    dropdown.appendChild(dropdownMenu);

    dropMenuArrow.classList.add('dropdown-menu-arrow');
    dropdownMenu.appendChild(dropMenuArrow);

    Object.keys(itemActions).forEach(item => {
      const dropdownItem = document.createElement('a');
      dropdownItem.classList.add('dropdown-item');
      dropdownItem.setAttribute('href', '#');
      dropdownItem.textContent = itemActions[item];
      dropdownItem.setAttribute('id', `dropdown-item-${item}`);
      dropdownMenu.appendChild(dropdownItem);

      const dropdownAlbumEvent = new CustomEvent(
        `galeria-imagem:item${capitalizeFirstLetter(item)}`,
        {
          detail: {
            id: dataAlbum.id,
          },
        }
      );

      dropdownItem.addEventListener('click', e => {
        e.preventDefault();
        galeriaImagem.dispatchEvent(dropdownAlbumEvent);
      });
    });
    return dropdown;
  };

  const mountTitleAlbum = nameAlbum => {
    const nameAlbumEl = document.createElement('h3');
    nameAlbumEl.classList.add('name-album');
    nameAlbumEl.innerText = nameAlbum;
    return nameAlbumEl;
  };

  const mountImage = dadosAlbum => {
    const imgEl = document.createElement('img');
    imgEl.classList.add('img-fluid');

    dadosAlbum.album[0].photos.forEach(photo => {
      if (photo.id === dadosAlbum.album[0].capa) {
        imgEl.setAttribute('src', photo.src);
        imgEl.setAttribute('title', photo.titulo);
        imgEl.setAttribute('alt', photo.titulo);
      }
    });
    return imgEl;
  };

  const mountAlbum = (dadosAlbum, itemActions, galeriaImagem) => {
    const figureEl = document.createElement('figure');
    const titleAlbumEl = mountTitleAlbum(dadosAlbum.album[0].name);
    const imageCapaEl = mountImage(dadosAlbum);

    figureEl.classList.add('galeria-imagens__show-album');
    figureEl.setAttribute('data-album', '');
    figureEl.appendChild(imageCapaEl);
    figureEl.appendChild(titleAlbumEl);

    if (Object.keys(itemActions).length !== 0) {
      const dropdownElement = mountDropdown(
        itemActions,
        dadosAlbum,
        galeriaImagem
      );
      figureEl.appendChild(dropdownElement);
    }
    return figureEl;
  };

  const carregaApi = () => {
    const galeriasImagens2 = document.querySelectorAll('.galeria-imagens');
    galeriasImagens2.forEach(async galeriaImagem => {
      const apiUrl = galeriaImagem.dataset.api;
      let dadosApi = [];
      const dataItemActions = galeriaImagem.dataset.itemActions
        ? JSON.parse(galeriaImagem.dataset.itemActions)
        : {};
      if (apiUrl) {
        const data = await fetch(apiUrl)
          .then(response => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response;
          }) // eslint-disable-next-line no-use-before-define
          .catch(() => console.log('erro'));
        dadosApi = await data.json();
      }

      dadosApi.forEach(album => {
        const containerGaleriaSelector = document.createElement('div');
        containerGaleriaSelector.classList.add('galeria-imagens__album');
        const showAlbum = mountAlbum(album, dataItemActions, galeriaImagem);
        containerGaleriaSelector.appendChild(showAlbum);
        galeriaImagem.appendChild(containerGaleriaSelector);
      });
    });
  };
  const addActiveNaFoto = e => {
    const mascara = e.closest('.edit-photo');
    // eslint-disable-next-line no-unused-expressions
    e.checked
      ? mascara.classList.add('active')
      : mascara.classList.remove('active');
  };
  carregaApi();

  const inpusCheckboxEl = document.querySelectorAll(
    '.galeria-imagens__album input[type="checkbox"]'
  );
  inpusCheckboxEl.forEach(item => {
    item.addEventListener('click', () => addActiveNaFoto(item));
  });
})();

//   galeriasImagens.forEach(async galeriaImagem => {
//     /* ----- Show Album -------------------------
//     |  Esse trecho de código cria o esqueleto da div show album.
//     *-------------------------------------------------------------------*/

//     const criaShowAlbum = () => {
//       const showAlbum = document.createElement('div');
//       showAlbum.innerHTML = `
//         <img class="img-fluid w-215px h-100px" src="" alt="" title="" />
//         <div class="mascara w-215px h-100px" title="" tabindex = "0" onclick="criaGaleriaComFotos(this, true)"></div>
//         <h3 class="name-album"></h3>
//         <div class="dropdown position-relative">
//           <button class="icon dropdown-toggle" id="dropdown-album-imagem" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//             <i class="fas fa-ellipsis-h"></i>
//           </button>
//           <div class="dropdown-menu" aria-labelledby="dropdown-album-imagem">
//             <div class="dropdown-menu__triangle"></div>
//             <button class="dropdown-item">Renomear</button>
//             <button class="dropdown-item" onClick="criaGaleriaComFotos(this,false)">Editar</button>
//             <button class="dropdown-item">Excluir</button>
//           </div>
//         </div>`;
//       showAlbum.classList.add('galeria-imagens__show-album');
//       showAlbum.setAttribute('data-album', '');
//       const dropdownMenu = showAlbum.querySelector('.dropdown-menu');
//       window.addDropdownToMutation(dropdownMenu);
//       return showAlbum;
//     };
//     /* ----- Pega os albuns da API-------------------------
//     |  Esse trecho de código gera os albuns da api encontradas no caminho
//     |  https://static.info.ufrn.br/current/json/galeria-imagens-api.json.
//     *-------------------------------------------------------------------*/
//     /**
//      * name é o nome do album,
//      * capa é o id da photo com o attr isAlbumCapa = true,
//      * photo array de imagens do album,
//      * titulo é a legenda da imagem,
//      * src é a link para a imagem utilizada,
//      * isAlbumCapa é o atributo que defini se aquela imagem é a capa do album,
//      * Renomear os dados das funções para ficarem de acordo com os dados da
//      * API a ser utilizada.
//      *
//      * Modelo da API utilizada no exemplo: https://static.info.ufrn.br/current/json/galeria-imagens-api.json
//      */
//     const apiUrl = galeriaImagem.dataset.api;
//     let dadosApi = [];
//     if (apiUrl) {
//       const data = await fetch(apiUrl);
//       dadosApi = await data.json();
//       const showAlbunsAndGalerias = Array.from(
//         galeriaImagem.getElementsByClassName('galeria-imagens__album')
//       );
//       showAlbunsAndGalerias.forEach(showAlbumAndGaleria => {
//         dadosApi.forEach(dadoApi => {
//           // - Cria Album
//           const showAlbum = criaShowAlbum();
//           showAlbum.dataset.album = `album-fotos-${dadoApi.id}`;
//           showAlbum.setAttribute('id', dadoApi.id);
//           const img = showAlbum.querySelector('img');
//           const mascara = showAlbum.getElementsByClassName('mascara')[0];
//           const nameAlbum = showAlbum.getElementsByClassName('name-album')[0];
//           nameAlbum.innerText = dadoApi.album[0].name;
//           if (showAlbumAndGaleria.dataset.public === 'true') {
//             nameAlbum.nextElementSibling.remove();
//           }
//           dadoApi.album[0].photos.forEach(photo => {
//             if (photo.id === dadoApi.album[0].capa) {
//               img.setAttribute('src', photo.src);
//               img.setAttribute('title', photo.titulo);
//               img.setAttribute('alt', photo.titulo);
//               mascara.setAttribute('title', photo.titulo);
//             }
//           });
//           showAlbumAndGaleria.appendChild(showAlbum);
//         });
//       });
//     }
//   });
// })();
// /* ----- Show Photo -------------------------
// |  Esse trecho de código cria o esqueleto da div show photo.
// *-------------------------------------------------------------------*/
// function criaShowPhoto() {
//   const showPhoto = document.createElement('div');
//   showPhoto.innerHTML = `
//       <img class="img-fluid w-215px h-100px" src="" alt="" title="" tabindex = "0" />
//       <div class="edit-photo">
//         <div class="mascara w-215px h-100px" title=""></div>
//         <div class="form-check">
//           <input type="checkbox" id="check-photo" onclick="addActiveNaFoto(this)" />
//           <label class="form-check-label" for="check-photo"></label>
//         </div>
//       </div>`;
//   showPhoto.classList.add('show-photo');
//   return showPhoto;
// }

// /* ----- Altera Breadcrumb-------------------------
//   |  Função Auxiliar para a função Abre Galeria Imagens.
//   |  Ele altera os nomes que estão na migalha, adicionando a proxima pagina.
//   *-------------------------------------------------------------------*/
// function alteraBreadcrumb(ehExibicaoDeGaleria) {
//   const migalha = document.getElementsByClassName('breadcrumb')[0];
//   if (migalha) {
//     const filhoActivo = Array.from(migalha.children).find(element =>
//       element.classList.contains('active')
//     );
//     const li = document.createElement('li');
//     li.innerHTML = filhoActivo.previousElementSibling.innerHTML;
//     li.classList.add('breadcrumb-item');
//     li.children[0].setAttribute('href', 'galeria_page_imagem_exemplo');
//     li.children[0].innerText = 'Exibição de Album';
//     migalha.insertBefore(li, filhoActivo);
//     if (ehExibicaoDeGaleria) {
//       filhoActivo.children[0].innerText = 'Exibição de Galeria';
//     } else {
//       filhoActivo.children[0].innerText = 'Edição de Galeria';
//     }
//   }
// }

// /* ----- Abre Galeria de Imagens -------------------------
//   |  Esse trecho de código ira abrir a geleria de acordo com o
//   |  album dele.
//   *-------------------------------------------------------------------*/
// /**
//  * Faz a busca na api procurando o id do album correspondente para o album clicado;
//  * Se encontrar, você é redirecionado para a nova pagina, onde estará presente a
//  * galeria daquela respectivo album.
//  *
//  * Parametros:
//  * e -> é o elemento clicado 'this' do elemento clicado no html;
//  * ehExibicaoDeGaleria -> Caso seja para exibir galeria, virar como true;
//  *
//  * Modelo da API utilizada no exemplo: https://static.info.ufrn.br/current/json/galeria-imagens-api.json
//  */
// async function criaGaleriaComFotos(e, ehExibicaoDeGaleria) {
//   let mascara = e.parentElement.parentElement.parentElement.getElementsByClassName(
//     'mascara'
//   )[0];
//   if (ehExibicaoDeGaleria) {
//     mascara = e;
//   }
//   const showAlbum = mascara.parentElement;
//   const id = showAlbum.getAttribute('id');
//   const data = await fetch(
//     `https://static.info.ufrn.br/current/json/galeria-imagens-api.json`
//   );
//   const album = Array.from(await data.json()).find(
//     element => element.id === id
//   );
//   const showGaleria = document.createElement('div');
//   showGaleria.classList.add('show-galeria');
//   showGaleria.setAttribute('data-galeria', `album-fotos-${album.id}`);
//   document.getElementsByClassName('cria-album')[0].remove();
//   const showAlbumAndGaleria = showAlbum.parentElement;
//   showAlbumAndGaleria.innerHTML = '';
//   album.album[0].photos.forEach(photo => {
//     const showPhoto = criaShowPhoto();
//     const img = showPhoto.querySelector('img');
//     img.setAttribute('src', photo.src);
//     img.setAttribute('title', photo.titulo);
//     img.setAttribute('alt', photo.titulo);
//     img.setAttribute('id', photo.id);
//     const edit = showPhoto.getElementsByClassName('edit-photo')[0];
//     const label = edit.getElementsByClassName('form-check-label')[0];
//     const input = label.previousElementSibling;
//     if (ehExibicaoDeGaleria) {
//       edit.classList.add('d-none');
//     }
//     label.setAttribute('for', `check-photo-${photo.id}`);
//     input.setAttribute('id', `check-photo-${photo.id}`);
//     showGaleria.appendChild(showPhoto);
//   });
//   showAlbumAndGaleria.appendChild(showGaleria);
//   alteraBreadcrumb(ehExibicaoDeGaleria);
// }

// /* ----- Active na foto -------------------------
//   |  Esse trecho de código adiciona a classe .active quando o input estiver
//   | :checked;
//   *-------------------------------------------------------------------*/

// ************************* FIM DO galeria-imagem.js *************************
