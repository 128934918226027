/**
 * Este script realiza a funcionalidade de reduzir os elementos da barra de aplicação (header)
 *
 * Move todos as ações do dropdown do usuário
 * e todos o botões contidos em `.header__right` que possuem a classe `.header-mob-out`
 * para um menu lateral.
 */

import Overlay from '../overlay';
import { BREAKPOINT_LG, installGlobal } from '../../global';

const NAV_RIGHT_SELECTOR = '.container-conteudo__nav_right';
const NAV_HEADER_MOBILE_SELECTOR = 'nav.header-mobile';

const getNavRight = () =>
  document.querySelector<HTMLElement>(NAV_RIGHT_SELECTOR);

const getNavHeaderMobile = () =>
  document.querySelector<HTMLElement>(NAV_HEADER_MOBILE_SELECTOR);

export const closeNavRightMenu = () => {
  getNavRight()?.classList.remove('nav-open');
  Overlay.hide();
};

/* ----- EXPANDE OU CONTRAI O MENU LATERAL ----------------------------
  |  Esse trecho de código realiza a funcionalidade do botão presente no header
  |  para abertura ou fechamento do menu lateral.
  *-------------------------------------------------------------------*/

/**
 * Closes the main menu by hiding the sidebar menu and closing the lateral menu.
 *
 * @return {void}
 */
const closeMainMenu = () => {
  const mainMenu = document.querySelector('.sidebar .menu');
  const menuLibras = document.querySelector(
    '.menu[data-libras]'
  ) as HTMLElement & {
    MenuNavLibras: any;
  };

  if (menuLibras) {
    menuLibras.MenuNavLibras.close();
  }

  if (mainMenu) {
    window.Cosmos.MenuNavLateral.closeMenu();
  }
};

class ToggleButtonRightMenu {
  element: HTMLElement;
  ToggleButtonRightMenu: ToggleButtonRightMenu;
  expanded: boolean;

  constructor(element: HTMLElement) {
    this.element = element;
    this.ToggleButtonRightMenu = this;
    this.expanded = false;

    this.init();
  }

  init() {
    this.element.addEventListener('click', () => {
      const windowWidth = window.innerWidth;

      closeMainMenu();

      const navHeaderMobile = getNavHeaderMobile();
      if (!navHeaderMobile) {
        console.error(`${NAV_HEADER_MOBILE_SELECTOR} not found`);
        return;
      }

      const containerNavRight = getNavRight();

      const itensExpanded = navHeaderMobile.querySelectorAll('[aria-expanded]');
      const ulPrincipalHeader = navHeaderMobile.querySelector(
        '.header-mobile__item'
      );
      const linkSubMenuActive = navHeaderMobile.querySelectorAll(
        'ul.submenu_itens a.active'
      );

      if (windowWidth > BREAKPOINT_LG) {
        if (containerNavRight?.classList.contains('nav-open')) {
          containerNavRight.classList.remove('nav-open');
          containerNavRight.classList.add('d-block');
        }

        // Verifica se tem algum item no submenu ativo, caso tenha e o usuario esteja em um submenu.
        // ao clicar no icone do header, o menu volta ao menu principal.
        if (
          linkSubMenuActive.length > 0 &&
          ulPrincipalHeader?.classList.contains('nav-go-right')
        ) {
          const li = Array.from(ulPrincipalHeader.children).find(
            (item) => !item.classList.contains('d-none')
          );
          let ul = Array.from(
            li?.getElementsByClassName('submenu_itens') || []
          ).find((item) => !item.classList.contains('nav-go-right'));
          let buttonBack = ul?.getElementsByClassName('back-menu')[0];
          itensExpanded.forEach((item) => {
            item.setAttribute('aria-expanded', 'false');
          });

          while (ul !== ulPrincipalHeader) {
            (buttonBack as HTMLButtonElement)?.click();
            ul = ul?.parentElement?.parentElement?.parentElement || undefined;
            [buttonBack] = ul?.getElementsByClassName('back-menu') || [];
          }
        } else {
          // Caso não tenha submenu ativo ou esteja no menu principal, funciona normalmente expandindo ou contraido o menu
          itensExpanded.forEach((item) => {
            const attrExpanded = item.getAttribute('aria-expanded');
            if (attrExpanded === 'true') {
              item.setAttribute('aria-expanded', 'false');
            } else {
              item.setAttribute('aria-expanded', 'true');
            }
          });
        }
      } else {
        itensExpanded.forEach((item) => {
          item.setAttribute('aria-expanded', 'false');
        });

        if (containerNavRight?.classList.contains('nav-open')) {
          this.close();
        } else {
          this.open();
        }
      }
    });

    window.addEventListener('resize', () => {
      this.handleResizeWindow();
    });
  }

  toggle() {
    if (this.expanded) {
      this.close();
    } else {
      this.open();
    }
  }

  close() {
    this.expanded = false;
    const nav = getNavRight();
    if (!nav) return;

    nav?.classList.remove('nav-open');
    nav?.classList.add('d-none');

    const buttonSubmenuOpened = document.querySelector<HTMLButtonElement>(
      '.submenu__content.nav-go-center .back-menu'
    );
    if (buttonSubmenuOpened) {
      buttonSubmenuOpened.click();
    }

    Overlay.hide();
  }

  open() {
    this.expanded = true;
    const nav = getNavRight();
    if (!nav) return;
    nav.classList.add('nav-open');
    nav.classList.remove('d-none');
    this.showOverlay();
  }

  handleResizeWindow() {
    const windowWidth = window.innerWidth;
    if (windowWidth > BREAKPOINT_LG) {
      Overlay.hide();
      return;
    }
    if (this.expanded === true) {
      this.showOverlay();
    }
  }

  showOverlay() {
    const overlayEl = Overlay.show();
    overlayEl.addEventListener('click', () => {
      this.close();
    });
  }
}

const initButtonToggleRightMenu = () => {
  const buttonToggleRightMenu = document.querySelector<HTMLElement>(
    'button#toggle-header-mobile'
  );
  if (buttonToggleRightMenu) {
    new ToggleButtonRightMenu(buttonToggleRightMenu);
  }
};

initButtonToggleRightMenu();

/* ----- TROCA DE ABAS ENTRE MENUS E SUBMENUS ----------------------------
  |  Esse trecho de código faz as trocas entre as abas de submenus e menus
  *------------------------------------------------------------------------*/
const setSubmenusHandlers = (menu: HTMLElement) => {
  const menuPrincipal = menu.getElementsByClassName('header-mobile__item')[0];

  // IDA PARA PROXIMA ABA
  const buttonsSubMenus = menuPrincipal.querySelectorAll('button.submenu');
  buttonsSubMenus.forEach((button) => {
    // Faz a passagem do menu para outros niveis.
    button.addEventListener('click', () => {
      const ul = button.parentElement?.parentElement;
      const li = button.parentElement;
      const subMenu = button.nextElementSibling;

      // Realiza a transição
      ul?.classList.add('nav-go-right');
      subMenu?.classList.add('nav-go-center');
      if (!menuPrincipal.classList.contains('w-268px')) {
        menuPrincipal.classList.add('w-268px');
      }
      // ---------------------

      // Auxilia na navegação por TAB
      setTimeout(() => {
        button.setAttribute('disabled', 'disabled');
        button.setAttribute('aria-expanded', 'true');
        Array.from(ul?.children || []).forEach((filho) => {
          if (!(filho === li)) {
            filho.classList.add('d-none');
          }
        });
      }, 200);
      // -------------------------
    });
  });

  // VOLTA PARA ABA ANTERIOR
  const buttonsBackMenus = menuPrincipal.querySelectorAll('button.back-menu');
  buttonsBackMenus.forEach((button) => {
    // Faz a volta do menu para niveis anteriores
    button.addEventListener('click', () => {
      const ul = button.closest('.header-mobile__item');
      const subMenu = button.closest('.submenu__content');
      const buttonSubMenu = subMenu?.previousElementSibling;

      // Auxilia na navegação por TAB
      Array.from(ul?.children || []).forEach((filho) => {
        filho.classList.remove('d-none');
      });
      buttonSubMenu?.removeAttribute('disabled');
      buttonSubMenu?.setAttribute('aria-expanded', 'false');
      // -------------------------

      // Realiza a transição
      if (ul?.classList.contains('menu-lateral__item')) {
        menuPrincipal.classList.remove('w-268px');
      }
      subMenu?.classList.add('d-none');
      subMenu?.classList.remove('nav-go-center');
      ul?.classList.remove('nav-go-right');
      subMenu?.classList.remove('d-none');
      // ---------------------
    });
  });

  // ATIVA OS LIS E AS ABAS ANTERIORES;
  const linkClicado = menuPrincipal.querySelectorAll('a.nav-link');
  linkClicado.forEach((link) => {
    link.addEventListener('click', () => {
      let sair = false;
      let li: HTMLElement | null | undefined = link.parentElement;
      let ul = li?.parentElement;

      if (!link.classList.contains('active')) {
        // Remove todos as classes activas dentro do menu lateral
        const activos = menuPrincipal.querySelectorAll('.active');
        activos.forEach((activo) => {
          activo.classList.remove('active');
        });

        while (!sair) {
          li?.children[0].classList.add('active');
          if (ul?.classList.contains('header-mobile__item')) {
            sair = true;
          } else {
            li = ul?.parentElement?.parentElement;
            ul = li?.parentElement;
          }
        }
      }
    });
  });
};

const headerMobile = getNavHeaderMobile();
if (headerMobile) {
  setSubmenusHandlers(headerMobile);
}

/* ----- ADIÇÃO AUTOMÁTICA DO ACTIVE BASEADO NA URL ----------------------------
  |  Esse trecho de código realiza a adição da classe active nos <li> que abrigam
  |  os links que referenciam uma URL qualquer. Se a URL atual for igual à URL
  |  que o <a> referencia, então o <li> recebe a classe active.
  *-------------------------------------------------------------------*/
const currentUrl = window.location.href;
const navContainer = getNavHeaderMobile();

if (navContainer?.closest('container-conteudo__nav_right')) {
  const links = navContainer.querySelectorAll<HTMLAnchorElement>('a.nav-link');
  links.forEach((link) => {
    if (link.href === currentUrl) {
      let sair = false;
      let li: HTMLElement | null | undefined = link.parentElement;
      let ul = li?.parentElement;

      // Remove todos as classes activas dentro do menu lateral
      const activos = navContainer.querySelectorAll('.active');
      activos.forEach((activo) => {
        activo.classList.remove('active');
      });

      while (!sair) {
        li?.children[0].classList.add('active');
        if (ul?.classList.contains('header-mobile__item')) {
          sair = true;
        } else {
          li = ul?.parentElement?.parentElement;
          ul = li?.parentElement;
        }
      }
    }
  });

  // Caminha até o submenu desejado
  const activos = navContainer.querySelectorAll<HTMLElement>('.active');
  activos.forEach((activo, indice) => {
    if (indice < activos.length - 1) {
      activo.click();
    } else {
      setTimeout(() => {
        const targetOffset = $(activo).offset()?.top || 0;
        const scrollContainer = navContainer.getElementsByClassName(
          'header-mobile__content'
        )[0];
        const subTarget =
          targetOffset - ($(scrollContainer).offset()?.top || 0);
        $(scrollContainer).animate({ scrollTop: subTarget }, 1000);
      }, 500);
    }
  });
}

const COMPONENT_NAME = 'MenuNavRight';
const methods = {
  closeMenu: closeNavRightMenu,
};

installGlobal(COMPONENT_NAME, methods);
