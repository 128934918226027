(function (Cosmos) {
  const renderForm = () => {
    // init custom selects
    if (Cosmos && Cosmos.CustomSelect) {
      Cosmos.CustomSelect.init();
    }

    const textInputs = document.querySelectorAll([
      '.form-control:not([data-cosmos])',
    ]);
    const textInputsWithMaxLen = document.querySelectorAll(
      '[data-max-size]:not([data-cosmos])'
    );

    const dateInputsCustomizados = document.querySelectorAll(
      '.form-calendar:not([data-cosmos])'
    );
    const disabledDateInputsCustomizados = document.querySelectorAll(
      '.form-calendar[disabled]:not([data-cosmos])'
    );

    const dateButtonsCustomizados =
      document.querySelectorAll('.input-group-addon');

    const fileInputs = document.querySelectorAll(
      'input.custom-file-input[type="file"]:not([data-cosmos])'
    );
    const disabledFileInputs = document.querySelectorAll(
      'input[type="file"]:disabled:not([data-cosmos])'
    );
    const invalidFileInputs = document.querySelectorAll(
      'input[type="file"].is-invalid:not([data-cosmos])'
    );

    const selectsPicker = document.querySelectorAll(
      'select.selectpicker:not([data-cosmos])'
    );

    const textareas = document.querySelectorAll('textarea:not([data-cosmos])');
    const textareaHeightCustom = document.querySelectorAll(
      'textarea:not([data-cosmos])[data-max-height]'
    );

    const textInputsAutocompleteSimples = document.querySelectorAll([
      '.form-autocomplete[data-type="simples"]:not([data-cosmos])',
    ]);

    const textInputsAutocompleteCatgs = document.querySelectorAll([
      '.form-autocomplete[data-type="catg"]:not([data-cosmos])',
    ]);

    const tagsInputsSelector = '.tags input[type="text"]:not([data-cosmos])';

    const tagsInputs = document.querySelectorAll(tagsInputsSelector);

    const invalidTagsInputs = document.querySelectorAll([
      '.tags input[type="text"].is-invalid:not([data-cosmos])',
      '.tags input[type="text"]:invalid:not([data-cosmos])',
    ]);
    const disabledTagsInputs = document.querySelectorAll(
      '.tags input[type="text"]:disabled:not([data-cosmos])'
    );

    const formChecks = document.querySelectorAll(
      '.form-check:not([data-cosmos])'
    );

    /**
     * ATENÇÃO: CASO CRIE ALGUM OUTRO SELETOR, ADICIONAR SEUS ELEMENTOS NESSE ARRAY
     * Isso é necessário para evitar que o JS seja aplicado mais de uma vez em
     * um mesmo elemento
     */
    const elementsToBeModified = [
      ...textInputs,
      ...textInputsWithMaxLen,
      ...dateInputsCustomizados,
      ...disabledDateInputsCustomizados,
      ...fileInputs,
      ...disabledFileInputs,
      ...invalidFileInputs,
      ...selectsPicker,
      ...textareas,
      ...textareaHeightCustom,
      ...textInputsAutocompleteSimples,
      ...textInputsAutocompleteCatgs,
      ...tagsInputs,
      ...invalidTagsInputs,
      ...disabledTagsInputs,
      ...formChecks,
    ];

    const addDisabledToParent = (element) => {
      element.parentElement.setAttribute('disabled', 'disabled');
    };
    const addErrorToParent = (element) => {
      element.parentElement.classList.add('is-invalid');
    };

    /* -------------------------- FOCUS -----------------------------*
    |  Este trecho de código adiciona e retira a class FOCUSED nos   |
    |  inputs que tem form-control                                   |
    *---------------------------------------------------------------*/

    textInputs.forEach((input) => {
      input.addEventListener('focus', () => {
        const formGroup = input.closest('.form-group');
        if (formGroup) formGroup.classList.add('focused');
      });
      input.addEventListener('blur', () => {
        const formGroup = input.closest('.form-group');
        if (formGroup) formGroup.classList.remove('focused');
      });
    });

    /* ------------------------- FORM CHECK --------------------------------------*
    |  Este trecho de código adiciona classes específicas às labels de checkboxes |
    |  e radios, e adiciona event listeners e um obsever para detectar mudanças   |
    |  nos inputs e atualizar dinamicamente as classes do form-check.             |
    *----------------------------------------------------------------------------*/

    function removeCheckedClassFromRadioLabels(inputElement) {
      if (inputElement.type !== 'radio') return;

      const fieldset = inputElement.closest('fieldset');
      if (!fieldset) return;

      const formChecksRadio = fieldset.querySelectorAll('.form-check');
      formChecksRadio.forEach((formCheckRadio) => {
        formCheckRadio.classList.remove('checked');
      });
    }

    function updateCheckedClass(inputElement, formCheckElement) {
      if (inputElement.checked) {
        formCheckElement.classList.add('checked');
      } else {
        formCheckElement.classList.remove('checked');
      }
    }

    function changeCheckedClass(inputElement, formCheckElement) {
      if (inputElement.checked) {
        formCheckElement.classList.add('checked');
      }

      inputElement.addEventListener('change', () => {
        removeCheckedClassFromRadioLabels(inputElement);
        updateCheckedClass(inputElement, formCheckElement);
      });
    }

    function updateDisabledClass(inputElement, formCheckElement) {
      const isDisabled =
        inputElement.disabled || inputElement.closest('[disabled]');
      if (isDisabled) {
        formCheckElement.classList.add('disabled');
      } else {
        formCheckElement.classList.remove('disabled');
      }
    }

    function updateInvalidClass(inputElement, formCheckElement) {
      const isInvalid = inputElement.classList.contains('is-invalid');
      if (isInvalid) {
        formCheckElement.classList.add('is-invalid');
      } else {
        formCheckElement.classList.remove('is-invalid');
      }
    }

    function updateFocusedClass(inputElement, formCheckElement) {
      inputElement.addEventListener('focus', () => {
        formCheckElement.classList.add('focused');
      });

      inputElement.addEventListener('blur', () => {
        formCheckElement.classList.remove('focused');
      });
    }

    function observeInputAttributes(inputElement, formCheckElement) {
      const onChangeChecked = () => {
        updateCheckedClass(inputElement, formCheckElement);
        updateDisabledClass(inputElement, formCheckElement);
        updateInvalidClass(inputElement, formCheckElement);
      };

      const observer = new MutationObserver((records) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const record of records) {
          if (record.attributeName === 'checked') {
            inputElement.checked =
              inputElement.getAttribute('checked') !== null;
          }
        }
        onChangeChecked();
      });

      observer.observe(inputElement, {
        attributes: true,
        attributeFilters: ['checked', 'disabled', 'class'],
      });

      Cosmos.utils.observeElement(inputElement, 'checked', onChangeChecked);
    }

    formChecks.forEach((formCheck) => {
      const label = formCheck.querySelector('label');

      const input = formCheck.querySelector(
        'input[type="checkbox"], input[type="radio"]'
      );
      if (!input) return;

      if (input.type === 'checkbox') {
        label.classList.add('label-checkbox');
      } else if (input.type === 'radio') {
        label.classList.add('label-radio');
      }

      changeCheckedClass(input, formCheck);
      updateDisabledClass(input, formCheck);
      updateInvalidClass(input, formCheck);
      updateFocusedClass(input, formCheck);
      observeInputAttributes(input, formCheck);
    });

    /* ----- CONTADOR DE CARACTERES PARA CAMPOS DE TEXTO COM TAMANHO LIMITADO ----
    |  Esse trecho de código cria dinamicamente um h3 que guardará dois span.
    |  O primeiro span (current) será responsável por guardar a quantidade de
    |  caracteres no campo de texto. O segundo span (total) será responsável por
    |  guardar a quantidade de caracteres total permitido.
    *-------------------------------------------------------------------*/
    const stringLength = function (text) {
      if (typeof Intl.Segmenter !== 'undefined') {
        const segmenter = new Intl.Segmenter('pt-br', {
          granularity: 'grapheme',
        });
        return Array.from(segmenter.segment(text)).length;
      }

      if (typeof GraphemeSplitter !== 'undefined') {
        const splitter = new GraphemeSplitter();
        return splitter.splitGraphemes(text).length;
      }
      console.warn(
        'ATENÇÃO: Para contabilizar corretamente os caracteres de emoji é necessário adicionar a biblioteca Grapheme. \nConsulte a documentação do Design System.'
      );
      return text.length; // forma padrão
    };

    /* ----- ACESSIBILIDADE DO CONTADOR DE CARACTERES------------------------------
    |  Esse trecho de código realiza a contagem de caracteres e informa ao usuário a quantidade
    de caracteres digitados no input com contador de caracteres de forma mais explicativa
    *-------------------------------------------------------------------*/

    const getCounter = (input) => {
      const element = input.parentElement.querySelector(
        '.form__label--counter'
      );
      if (element) return element;
      const uuid = crypto.randomUUID();
      const counter = document.createElement('span');
      counter.classList.add('form__label--counter');
      counter.setAttribute('aria-describedby', uuid);
      counter.setAttribute('aria-hidden', true);
      input.insertAdjacentElement('afterend', counter);
      const a11y = document.createElement('div');
      a11y.id = uuid;
      a11y.classList.add('sr-only');
      a11y.role = 'status';
      a11y.ariaLive = 'polite';

      input.insertAdjacentElement('afterend', a11y);
      return counter;
    };

    const updateCounter = (input) => {
      const counter = getCounter(input);
      const screenReaderCounter =
        counter.parentElement.querySelector('[role=status]');
      const maxSize = input.getAttribute('data-max-size');
      const currentSize = stringLength(input.value);
      counter.innerText = `${currentSize}/${maxSize}`;
      const charactersRemaining = maxSize - currentSize;
      let msg = `Você tem ${charactersRemaining} de ${maxSize} caracteres restantes`;
      if (currentSize > maxSize)
        msg = `Você excedeu em ${Math.abs(
          charactersRemaining
        )} de ${maxSize} caracteres permitidos`;
      screenReaderCounter.innerHTML = msg;
    };

    textInputsWithMaxLen.forEach((textInput) => {
      function resetValue(oldValue, newValue) {
        const wasChanged = oldValue !== newValue;
        const isEmpty = newValue === '';
        if (isEmpty && wasChanged) this.reset();
      }

      Cosmos.utils.observeElement(textInput, 'value', resetValue);

      updateCounter(textInput);

      const removeExceeded = () => {
        const counter = getCounter(textInput);
        counter.style.display = 'block';
        window.deactivateError(textInput);
      };

      textInput.reset = () => {
        textInput.value = '';
        updateCounter(textInput);
        removeExceeded();
        if (textInput.tagName === 'TEXTAREA' && textInput.originalHeight) {
          textInput.style.height = `${textInput.originalHeight}px`;
        }
      };

      const exceeded = () => {
        const counter = getCounter(textInput);
        const maxSize = textInput.getAttribute('data-max-size');
        counter.style.display = 'none';
        window.activateError(
          textInput,
          `Limite de caracteres excedido: ${maxSize}`,
          true
        );
      };

      $(textInput).on('keyup input', () => {
        const strLenght = stringLength(textInput.value);
        const maxSize = textInput.getAttribute('data-max-size');
        if (strLenght > maxSize) {
          exceeded();
        } else {
          // current.innerText = strLenght;
          removeExceeded();
        }
        updateCounter(textInput);
      });
    });

    /* ----- AUMENTO DINÂMICO DE ALTURA DO TEXTAREA ------------------------------
    |  Esse trecho de código realiza a reconfiguração constante da altura do textarea
    |  à medida que o usuário digita no campo.
    *-------------------------------------------------------------------*/
    textareas.forEach((textarea) => {
      if (!textarea.attributes.rows) {
        textarea.rows = 1;
      }
      const offset = textarea.offsetHeight - textarea.clientHeight;
      textarea.originalHeight = textarea.offsetHeight;
      const { form } = textarea;

      // Caso o textarea esteja dentro de um collapse, seta a altura original
      // para quando o collapse estiver totalmente aberto, caso contrário, a altura
      // original será 0, pois o textarea não estará visivel.
      $(form).one('shown.bs.collapse', () => {
        textarea.originalHeight = textarea.offsetHeight;
      });

      function resizeTextarea(el) {
        el.style.height = el.rows === 1 ? '1.8rem' : 'auto'; // Necessário devido ao Firefox (windows) deixar altura dupla quando rows = 1
        let newHeight = el.scrollHeight + offset;

        if (newHeight > textarea.originalHeight) {
          const { maxHeight } = textarea.dataset;
          if (maxHeight) {
            textarea.classList.add('custom-scroll');
            if (newHeight > maxHeight) {
              newHeight = maxHeight;
              textarea.style.overflowY = 'auto';
            } else {
              textarea.style.overflowY = 'hidden';
            }
          }
          el.style.height = `${newHeight}px`;
        }
      }

      textarea.updateHeight = () => resizeTextarea(textarea);

      $(textarea).on('keyup input', () => resizeTextarea(textarea));
      resizeTextarea(textarea);
    });

    /* ----- CONFIGURAÇÃO PARA ESTILIZAÇÃO DO CAMPO DE TEXTO --------------------
    |  Esse trecho de código simplesmente realiza a adição da classe "filled" se
    |  o campo de texto possuir algum caractere.
    *-------------------------------------------------------------------*/
    textInputs.forEach((textInput) => {
      function toggleFilledBasedOnValue() {
        if (textInput.value) {
          textInput.classList.add('filled');
        } else {
          textInput.classList.remove('filled');
        }
      }

      $(textInput).on('keyup input', toggleFilledBasedOnValue);
    });

    function observeElement(element, property, callback, delay = 0) {
      const elementPrototype = Object.getPrototypeOf(element);
      // eslint-disable-next-line no-prototype-builtins
      if (elementPrototype.hasOwnProperty(property)) {
        const descriptor = Object.getOwnPropertyDescriptor(
          elementPrototype,
          property
        );
        Object.defineProperty(element, property, {
          get() {
            // eslint-disable-next-line prefer-rest-params
            return descriptor.get.apply(this, arguments);
          },
          set() {
            const oldValue = this[property];
            // eslint-disable-next-line prefer-rest-params
            descriptor.set.apply(this, arguments);
            const newValue = this[property];
            if (typeof callback === 'function') {
              setTimeout(callback.bind(this, oldValue, newValue), delay);
            }
          },
        });
      }
    }

    selectsPicker.forEach(async (selectPicker) => {
      const parent = selectPicker.parentElement;
      const apiUrl = selectPicker.dataset.api;
      const dataValue = selectPicker.dataset.value || 'id';
      const dataText = selectPicker.dataset.text || 'option';

      if (apiUrl) {
        const data = await fetch(apiUrl);
        const options = await data.json();

        /**
         * Renomear "id" e "option" para ficarem de acordo com os dados da
         * API a ser utilizada.
         *
         * Obs.: O primeiro argumento será o value daquela option, já o segundo
         * argumento será a label. Nesse exemplo, a API que foi utilizada tem como
         * campo o "id" representando o value e o campo "option" representando a label.
         * Modelo da API utilizada no exemplo: https://static.info.ufrn.br/current/json/select-api.json
         */
        const optionElements = options.map(
          ({ [dataValue]: value, [dataText]: label }) => {
            const newOption = document.createElement('option');
            newOption.value = value;
            newOption.innerText = label;

            return newOption;
          }
        );

        selectPicker.append(...optionElements);

        $(selectPicker).selectpicker('refresh');
      }

      function addSelectedClassToButton() {
        const dropItems = parent.querySelectorAll(
          '.dropdown.bootstrap-select > .dropdown-menu > .inner > .dropdown-menu.inner > li'
        );
        dropItems.forEach((dropItem) => {
          $(dropItem).on('keyup click', () => {
            const selectButton = parent.querySelector(
              'button.btn.dropdown-toggle'
            );

            selectButton.classList.add('selected');
          });
        });
      }

      $(selectPicker).one('shown.bs.select', addSelectedClassToButton);
    });

    /* ----- ADIÇÃO DO DISABLED EM INPUT DATE COSTUMIZADO -----------------
    |  Esse trecho de código percorre todos os campos de de data costumizado que possuem o
    |  atributo "disabled" e simplesmente adiciona esse atributo ao button que o acompanha.
    *-------------------------------------------------------------------*/
    disabledDateInputsCustomizados.forEach((disabledDateInputCostumizado) => {
      const button = disabledDateInputCostumizado.nextElementSibling;
      button.setAttribute('disabled', 'disabled');
    });

    disabledFileInputs.forEach(addDisabledToParent);
    invalidFileInputs.forEach(addErrorToParent);
    invalidTagsInputs.forEach(addErrorToParent);
    disabledTagsInputs.forEach(addDisabledToParent);

    /* ----- ADIÇÃO MASCARA AO CAMPO DE TEXTO DE DATA COSTUMIZADO  -----------
    |  Esse trecho de código percorre todos os buttons com a classe
    |  "form-calendar" e adiciona uma mask ao input que esta com ele
    *-------------------------------------------------------------------*/
    dateInputsCustomizados.forEach((dateInput) => {
      $(dateInput).mask('00/00/0000');
    });

    /* ---------- FECHA O CALANDÁRIO DO DATA COSTUMIZADO ----------------
    |  Esse trecho de código fecha os calendários após perder o focus
    |  do botão do calendário e mantém no máximo um aberto na página
    |  quando o focus está no input do calendário
    *------------------------------------------------------------------*/
    let timeout;

    const closeCalendar = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const calendars = document.querySelectorAll(
          '.datepicker.datepicker-dropdown.dropdown-menu'
        );
        calendars.forEach((calendar) => {
          calendar.remove();
        });
      }, 100);
    };

    dateButtonsCustomizados.forEach((dateButton) => {
      dateButton.addEventListener('blur', closeCalendar);
      dateButton.addEventListener('mousedown', (event) => {
        event.preventDefault();
        clearTimeout(timeout);
      });
    });

    dateInputsCustomizados.forEach((dateInput) => {
      dateInput.addEventListener('focus', () => {
        clearTimeout(timeout);
        const calendars = document.querySelectorAll(
          '.datepicker.datepicker-dropdown.dropdown-menu'
        );
        if (calendars.length > 1) {
          calendars[0].remove();
        }
      });
    });

    // INICIO DO AUTOCOMPLETE
    /* ----- ADIÇÃO DO AUTOCOMPLETE AO CAMPO TEXTO -------------------------
    |  Esse trecho de código percorre todos os inputs que possuem a name
    |  "AutocompleteSimples" ou "AutocompleteCatg" e adiciona um
    |  autocomplete neles.
    *-------------------------------------------------------------------*/

    const FactoryAutocomplete = (() => {
      function createEmptyResult() {
        const result = document.createElement('li');
        result.setAttribute('class', 'no_result');
        result.setAttribute('style', 'cursor:default');
        result.setAttribute('tabindex', '1');
        result.innerHTML = 'Não foi encontrado nenhum resultado';

        return result;
      }

      function createSpinner() {
        const spinner = document.createElement('div');
        spinner.classList.add('spinner-border');
        spinner.classList.add('spinner-border-sm');
        spinner.classList.add('ml-auto');
        return spinner;
      }

      return {
        createEmptyResult,
        createSpinner,
      };
    })();

    class AutocompleteManagerSimples {
      constructor() {
        this.listaAutocomplete = [];
      }

      get(element) {
        let listaElementos = [element];

        if (typeof element === 'string') {
          listaElementos = document.querySelector(element);
        }

        return this.listaAutocomplete.filter((e) => {
          return (
            Array.from(listaElementos).findIndex(
              (input) => input === e.input
            ) !== -1
          );
        });
      }

      add(autocompleteElement) {
        this.listaAutocomplete.push(autocompleteElement);
      }

      remove(element) {
        const autocompleteElement = this.get(element);

        this.listaAutocomplete = this.listaAutocomplete.filter((e) => {
          const existe = Array.from(autocompleteElement).findIndex(
            (autocomplete) => autocomplete === e
          );
          if (existe === -1) return true;
          e.unInit();
          return false;
        });
      }

      update(element) {
        this.remove(element);
        const listUl =
          element.parentElement.querySelector('.autocomplete_list');
        if (listUl) listUl.remove();
        this.iniciar(element);
      }

      getSpinner(textInput) {
        return (
          textInput.closest('.formGroup') || textInput.parentElement
        ).querySelector('.spinner-border');
      }

      removeSpinner(textInput) {
        const spinner = this.getSpinner(textInput);
        if (spinner) spinner.remove();
      }

      iniciar(textInputAutocompleteSimple) {
        if (typeof textInputAutocompleteSimple === 'string') {
          textInputAutocompleteSimple = document.querySelector(
            textInputAutocompleteSimple
          );
        }

        /**
         * Modelo da API utilizada no exemplo:
         * https://static.info.ufrn.br/current/json/autocomplete-simple-api.json
         */
        const minTamParaBusca =
          textInputAutocompleteSimple.dataset.minSize === undefined
            ? 3
            : textInputAutocompleteSimple.dataset.minSize;
        let apiUrl = textInputAutocompleteSimple.dataset.api;
        const keys = textInputAutocompleteSimple
          .getAttribute('data-keys')
          .split(',')[0];

        if (apiUrl) {
          let controller = new AbortController();
          // eslint-disable-next-line new-cap
          const autocomplete = new autoComplete({
            selector: () => {
              return textInputAutocompleteSimple;
            },
            data: {
              src: async (query) => {
                controller.abort();
                controller = new AbortController();
                try {
                  // Fetch Data from external Source
                  apiUrl = textInputAutocompleteSimple.dataset.api;

                  const source =
                    // eslint-disable-next-line eqeqeq
                    textInputAutocompleteSimple.dataset.externa == 'true'
                      ? await fetch(apiUrl + query, {
                          signal: controller.signal,
                        })
                      : await fetch(apiUrl, {
                          signal: controller.signal,
                        });
                  // Data is array of `Objects` | `Strings`
                  const responseData = await source.json();

                  responseData.sort(function (a, b) {
                    if (a[query] > b[query]) {
                      return 1;
                    }
                    if (a[query] < b[query]) {
                      return -1;
                    }
                    return 0;
                  });
                  return responseData;
                } catch (error) {
                  return error;
                }
              },
              // Data 'Object' key to be searched
              keys: [keys],
              cache: false,
            },
            threshold: minTamParaBusca > 0 ? minTamParaBusca : 0,
            debounce: 1000,
            searchEngine: 'strict',
            diacritics: true,
            resultsList: {
              tag: 'ul',
              tabSelect: true,
              class: 'autocomplete_list list_categorias',
              position: 'afterend',
              maxResults:
                textInputAutocompleteSimple.dataset.elements === undefined
                  ? 6
                  : parseInt(textInputAutocompleteSimple.dataset.elements, 10),
              render: true,
              element: (source, data) => {
                source.removeAttribute('id');

                if (data.results.length === 0) {
                  const resultElement = FactoryAutocomplete.createEmptyResult();
                  source.appendChild(resultElement);
                }

                this.removeSpinner(textInputAutocompleteSimple);
              },
              noResults: true,
            },
            resultItem: {
              tag: 'li',
              class: 'autoComplete_result',
              element: (source, data) => {
                source.innerHTML = data.match;
              },
              highlight: 'autoComplete_highlighted',
            },
            events: {
              input: {
                selection: (event) => {
                  const feedback = event.detail;
                  if (!feedback.results || feedback.results.length === 0)
                    return;
                  const selection =
                    feedback.selection.value[feedback.selection.key];
                  textInputAutocompleteSimple.value = selection;
                },
              },
            },
            wrapper: false,
          });

          textInputAutocompleteSimple.addEventListener(
            'input',
            (event) => {
              const possuiLoading = !!this.getSpinner(
                textInputAutocompleteSimple
              );

              if (
                event.target.value.length >= minTamParaBusca &&
                !possuiLoading
              ) {
                const spinner = FactoryAutocomplete.createSpinner();
                textInputAutocompleteSimple.after(spinner);
                textInputAutocompleteSimple.classList.add('pr-4');
                autocomplete.close();
              }

              if (
                event.target.value.length < minTamParaBusca &&
                possuiLoading
              ) {
                this.removeSpinner(textInputAutocompleteSimple);
                autocomplete.close();
              }
            },
            false
          );

          this.add(autocomplete);
        }
      }
    }

    Cosmos.autocompleteManagerSimples = new AutocompleteManagerSimples();

    // Codigo para autocomplete simples, sem categorias;
    textInputsAutocompleteSimples.forEach((textInputAutocompleteSimple) => {
      Cosmos.autocompleteManagerSimples.iniciar(textInputAutocompleteSimple);
    });

    class AutocompleteCategoriasManager {
      constructor() {
        this.listaAutocompleteCatg = [];
      }

      get(element) {
        let listaElementos = [element];

        if (typeof element === 'string') {
          listaElementos = document.querySelector(element);
        }

        return this.listaAutocompleteCatg.filter((e) => {
          return (
            Array.from(listaElementos).findIndex(
              (input) => input === e.input
            ) !== -1
          );
        });
      }

      add(autocompleteElement) {
        this.listaAutocompleteCatg.push(autocompleteElement);
      }

      remove(element) {
        const autocompleteElement = this.get(element);

        this.listaAutocompleteCatg = this.listaAutocompleteCatg.filter((e) => {
          const existe = Array.from(autocompleteElement).findIndex(
            (autocomplete) => autocomplete === e
          );
          if (existe === -1) return true;
          e.unInit();
          return false;
        });
      }

      update(element) {
        this.remove(element);
        const listUl =
          element.parentElement.querySelector('.autocomplete_list');
        if (listUl) listUl.remove();
        this.iniciar(element);
      }

      getSpinner(textInput) {
        return (
          textInput.closest('.formGroup') || textInput.parentElement
        ).querySelector('.spinner-border');
      }

      removeSpinner(textInput) {
        const spinner = this.getSpinner(textInput);
        if (spinner) spinner.remove();
      }

      iniciar(textInputAutocompleteCatg) {
        /**
         * As keys precisam ser bem especificas pois são elas que definem as categorias.
         * Ex: Catg Nomes vem da Key name
         * Obs.2: Lembre a quantiade de keys define o tamanho e a quantidade de categorias que você terá.
         * As posições precisam coincidir tanto em categorias como em keys.
         * Ex: keys = ['name','frutas'] dará categorias = ["Nomes", "Frutas"].
         * Modelo da API utilizada no exemplo:
         * https://static.info.ufrn.br/current/json/autocomplete-catg-api.json
         */
        const minTamParaBusca =
          textInputAutocompleteCatg.dataset.minSize === undefined
            ? 3
            : textInputAutocompleteCatg.dataset.minSize;
        const keys = textInputAutocompleteCatg
          .getAttribute('data-keys')
          .split(',');
        const categorias = textInputAutocompleteCatg
          .getAttribute('data-categorias')
          .split(',');
        const apiUrl = textInputAutocompleteCatg.dataset.api;

        if (apiUrl) {
          let controller = new AbortController();
          // eslint-disable-next-line
          const autoCompleteCategorias = new autoComplete({
            selector: () => {
              return textInputAutocompleteCatg;
            },
            data: {
              src: async (query) => {
                try {
                  controller.abort();
                  controller = new AbortController();
                  const source =
                    // eslint-disable-next-line eqeqeq
                    textInputAutocompleteCatg.dataset.externa == 'true'
                      ? await fetch(apiUrl + query, {
                          signal: controller.signal,
                        })
                      : await fetch(apiUrl, {
                          signal: controller.signal,
                        });
                  const data = await source.json();
                  data.sort(function (a, b) {
                    if (a[query] > b[query]) {
                      return 1;
                    }
                    if (a[query] < b[query]) {
                      return -1;
                    }
                    return 0;
                  });
                  return data;
                } catch (error) {
                  return error;
                }
              },
              keys,
              cache: false,
            },
            threshold: minTamParaBusca > 0 ? minTamParaBusca : 0,
            debounce: 1000,
            searchEngine: 'strict',
            diacritics: true,
            resultsList: {
              tag: 'ul',
              class: 'autocomplete_list list_categorias',
              position: 'afterend',
              maxResults:
                textInputAutocompleteCatg.dataset.elements === undefined
                  ? 6
                  : parseInt(textInputAutocompleteCatg.dataset.elements, 10),
              render: true,
              element: (source, data) => {
                source.removeAttribute('id');

                if (data.results.length === 0) {
                  const result = FactoryAutocomplete.createEmptyResult();
                  source.appendChild(result);
                }

                this.removeSpinner(textInputAutocompleteCatg);
              },
              noResults: true,
            },
            wrapper: false,
            resultItem: {
              tag: 'li',
              class: 'autoComplete_result',
              element: (source, data) => {
                source.innerHTML = data.match;
                source.setAttribute('name', data.key);
              },
              highlight: 'autoComplete_highlighted',
            },
            events: {
              input: {
                selection: (event) => {
                  if (
                    event.detail.event.target.className ===
                    'autoComplete_result'
                  ) {
                    const selection = event.detail.event.target.innerText;
                    textInputAutocompleteCatg.value = selection;
                  }
                },
                /* ----------------------- ESCUTADOR DO INPUT  -----------------------
              |  Esse trecho de código fica escutando o input do AutoComplete,
              |  quando o mesmo, tiver uma largura igual ou maior que mimTamParaBusca
              |  ele chamara a função para atualizar as listas.
              *-------------------------------------------------------------------*/
                results: (event) => {
                  const listaCategoria =
                    textInputAutocompleteCatg.parentElement.getElementsByClassName(
                      'list_categorias'
                    )[0];
                  listaCategoria.innerHTML = '';
                  if (
                    event.detail.query.length >= minTamParaBusca > 0
                      ? minTamParaBusca
                      : 0
                  ) {
                    setTimeout(() => {
                      const listaAutoComplete =
                        textInputAutocompleteCatg.nextElementSibling;
                      listaAutoComplete.style.visibility = 'visible';
                      if (
                        listaAutoComplete.children[0] &&
                        !listaAutoComplete.children[0].classList.contains(
                          'no_result'
                        )
                      ) {
                        // eslint-disable-next-line no-use-before-define
                        atualizaUl(listaCategoria, listaAutoComplete);
                      }
                    }, 0);
                  }
                },
              },
            },
          });

          /* ----------------------- ATUALIZAÇÃO DA LISTA  -----------------------
            |  Esse trecho de código pega as duas listas existentes no input,
            |  e concate a Lista Antiga na Lista Catg, desse modo, os itens listado
            |  ficaram em ordem e categorias especificas.
            *-------------------------------------------------------------------*/
          const atualizaUl = (ulCatg, ulAutoComplete) => {
            const autoCompleteLista = ulAutoComplete;
            const catgLista = ulCatg;

            keys.forEach((key, i) => {
              let ahCatg = false;
              let liCatg = '';
              Array.from(autoCompleteLista.children)
                .reverse()
                .forEach((liAutoComplete) => {
                  if (key === liAutoComplete.getAttribute('name')) {
                    if (!ahCatg) {
                      liCatg = document.createElement('li');
                      liCatg.classList.add('autoComplete_catg');
                      liCatg.innerHTML = `-- ${categorias[i]} --`;
                      liCatg.setAttribute('disabled', 'disabled');
                      liCatg.id = key;
                      catgLista.appendChild(liCatg);
                      liCatg.insertAdjacentElement('afterend', liAutoComplete);
                      ahCatg = true;
                    } else {
                      liCatg.insertAdjacentElement('afterend', liAutoComplete);
                    }
                  }
                });
            });
          };

          textInputAutocompleteCatg.addEventListener(
            'input',
            (event) => {
              const possuiLoading = !!this.getSpinner(
                textInputAutocompleteCatg
              );

              if (
                event.target.value.length >= minTamParaBusca &&
                !possuiLoading
              ) {
                const spinner = FactoryAutocomplete.createSpinner();
                textInputAutocompleteCatg.after(spinner);
                textInputAutocompleteCatg.classList.add('pr-4');
                autoCompleteCategorias.close();
              }

              if (
                event.target.value.length < minTamParaBusca &&
                possuiLoading
              ) {
                this.removeSpinner(textInputAutocompleteCatg);
                autoCompleteCategorias.close();
              }
            },
            false
          );
          this.add(autoCompleteCategorias);
        }
      }
    }

    Cosmos.autocompleteCategoriasManager = new AutocompleteCategoriasManager();

    textInputsAutocompleteCatgs.forEach((textInputAutocompleteCatg) => {
      Cosmos.autocompleteCategoriasManager.iniciar(textInputAutocompleteCatg);
    });

    // FIM DO AUTOCOMPLETE

    const handleEmptyFileInput = (fileInput, labelText) => {
      const label = fileInput.parentElement.querySelector('.custom-file-label');
      label.classList.remove('filled');
      label.innerText = labelText;
    };

    const fileInputObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutationRecord) => {
        const { target } = mutationRecord;
        const isDisabled = target.disabled;
        const [customFileContainer] = $(target).parents('.custom-file');

        if (isDisabled) {
          customFileContainer.setAttribute('disabled', '');
          return;
        }

        if (target.files.length === 0) {
          target.classList.remove('filled');
        } else {
          target.classList.add('filled');
        }

        customFileContainer.removeAttribute('disabled');
      });
    });

    fileInputs.forEach((fileInput) => {
      const label = fileInput.parentElement.querySelector('.custom-file-label');
      const labelText = label.innerText;
      const formGroup = fileInput.closest('.form-group');

      const addFileName = () => {
        label.classList.add('filled');
        fileInput.classList.add('filled');

        const [firstFile, ...files] = fileInput.files;

        label.innerText = firstFile.name;

        files.forEach((file) => {
          const br = document.createElement('br');
          label.append(br);
          label.append(file.name);
        });
      };

      const removeFileName = () => {
        fileInput.classList.remove('filled');
        label.classList.remove('filled');
        label.innerText = labelText;
      };

      function toggleFileName() {
        if (fileInput.files.length) {
          addFileName();
        } else {
          removeFileName();
        }
      }

      /* ------------------- Cria botão de reset input file  ------------------- /*
      |  Esse trecho de código cria um botão que quando clicado limpa o conteúdo  |
      |  do input file. O botão é adicionado ao componente somente quando o input |
      |  está preenchido com um ou mais arquivos, e é removido após o clique.     |
      \* ----------------------------------------------------------------------- */

      const createResetButton = () => {
        const resetIcon = document.createElement('i');
        resetIcon.classList.add('fa-circle-xmark');
        resetIcon.setAttribute('aria-hidden', 'true');

        const resetInput = document.createElement('button');
        resetInput.type = 'button';
        resetInput.classList.add('reset-input');
        resetInput.setAttribute('title', 'Limpar campo');
        resetInput.setAttribute('aria-label', 'Clique aqui para limpar');
        resetInput.appendChild(resetIcon);

        return resetInput;
      };

      const resetInputButton = createResetButton();
      const showResetButton = fileInput.getAttribute('data-reset') === 'true';

      if (showResetButton) {
        fileInput.addEventListener('change', () => {
          if (fileInput.files.length > 0) {
            label.insertAdjacentElement('afterend', resetInputButton);

            resetInputButton.addEventListener(
              'click',
              () => {
                fileInput.value = '';
                resetInputButton.remove();
              },
              { once: true }
            );
          }
        });
      }

      fileInput.addEventListener('change', toggleFileName);

      Cosmos.utils.observeElement(fileInput, 'value', (_, newValue) => {
        if (!newValue) {
          handleEmptyFileInput(fileInput, labelText);
        }
      });

      fileInputObserver.observe(fileInput, {
        attributes: true,
        attributeFilter: ['disabled'],
      });

      const fileInputFocus = () => {
        if (formGroup) {
          formGroup.classList.add('focused');
        }
      };

      const fileInputBlur = () => {
        if (formGroup) {
          formGroup.classList.remove('focused');
        }
      };

      fileInput.addEventListener('focus', fileInputFocus);
      fileInput.addEventListener('blur', fileInputBlur);
    });

    // Script de adicionar TAGS
    const initTags = (element) => {
      const html = element instanceof HTMLElement ? element : document;
      html.querySelectorAll(tagsInputsSelector).forEach((tagInput) => {
        const [tagContainer] = $(tagInput).parents('.tags');
        const tagsValueInput = tagContainer.querySelector('input.tags-value');

        if (tagsValueInput) {
          tagsValueInput.classList.add('d-none');
          tagsValueInput.setAttribute('type', 'text');
          tagsValueInput.setAttribute('aria-hidden', 'true');
        }
        const getOrCreateTagsContainer = () => {
          const container =
            tagInput.parentElement.querySelector('.tags-container');

          if (container) return container;

          const div = document.createElement('div');
          div.classList.add('tags-container');
          tagInput.insertAdjacentElement('beforebegin', div);
          return div;
        };
        const generateUniqueId = () => {
          const characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          let uniqueId = '';
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < 4; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            uniqueId += characters[randomIndex];
          }
          return uniqueId;
        };

        const createTag = (tagLabel) => {
          const tag = document.createElement('button');
          tag.classList.add('tag-filtro', 'darker');
          tag.type = 'button';
          tag.innerText = tagLabel;

          const icon = document.createElement('i');
          icon.classList.add('fas', 'fa-times');
          const uniqueId = generateUniqueId();
          tag.id = uniqueId;
          tag.append(icon);
          return tag;
        };

        const checkIfAnyTagExist = () => {
          if (tagsValueInput.value.trim() === '') {
            tagContainer.classList.remove('filled');
          } else {
            tagContainer.classList.add('filled');
          }
        };

        const renderTags = () => {
          const container = getOrCreateTagsContainer();
          container.innerHTML = '';

          if (tagsValueInput.value.trim() !== '') {
            const tags = tagsValueInput.value.split(',');

            const uniqueTags = tags.filter(
              (item, index) => tags.indexOf(item) === index
            );
            tagsValueInput.value = uniqueTags.join(',');
            // eslint-disable-next-line no-use-before-define
            uniqueTags.map((tag) => createAndAddTag(tag));
          }
        };

        const onTagsUpdated = () => {
          checkIfAnyTagExist();
          renderTags();
        };

        const attachSelfDelete = (tagEl) => {
          tagEl.addEventListener('click', () => {
            const tags = tagsValueInput.value.split(',');
            tagsValueInput.value = tags
              .filter((tag) => tag !== tagEl.innerText)
              .join(',');
            onTagsUpdated();
          });
        };

        const createAndAddTag = (tagLabel) => {
          const tag = createTag(tagLabel);
          attachSelfDelete(tag);
          const container = getOrCreateTagsContainer();
          container.appendChild(tag);
        };

        const backspaceDeleteLastTag = (e) => {
          if (e.keyCode === 8 && !tagInput.value) {
            e.preventDefault();
            const tags = tagsValueInput.value.split(',');
            const tagRemoved = tags.pop();
            const tagsNotRemoveds = tags.filter((tag) => tag !== tagRemoved);
            tagsValueInput.value = tagsNotRemoveds.join(',');
            onTagsUpdated();
          }
        };

        async function handleFuncValidateTag(tag, func) {
          try {
            const result = await Promise.resolve(func(tag));
            // qualquer coisa que não seja true, indica que houve erro de validação.
            if (result !== true) {
              const errorMessage =
                typeof result === 'string' ? result : 'Tag inválida';
              throw new Error(errorMessage);
            }
            return true;
          } catch (e) {
            const errorMessage = e.message || e;
            return errorMessage;
          }
        }

        function getValidateFunction(el) {
          return el.validate || window[el.dataset.validate];
        }

        async function handleInputKeyPress(e) {
          if (e.keyCode === 13) {
            e.preventDefault();
            const functionValidate = getValidateFunction(e.target);
            if (functionValidate) {
              const validate = await handleFuncValidateTag(
                e.target.value,
                functionValidate
              );

              if (validate !== true) {
                window.activateError(e.target, validate);
                return;
              }
            }
            const inputValueTrimmed = tagInput.value.trim();
            window.deactivateError(e.target);

            if (inputValueTrimmed) {
              if (tagsValueInput.value === '') {
                tagsValueInput.value = inputValueTrimmed;
              } else {
                const tags = tagsValueInput.value.split(',');
                if (!tags.includes(inputValueTrimmed)) {
                  tags.push(inputValueTrimmed);
                  tagsValueInput.value = tags.join(',');
                }
              }
              onTagsUpdated();
            }
            tagInput.value = '';
          }
        }

        onTagsUpdated();

        let lastValue = tagInput.value;
        $(tagInput).on('keyup', (e) => {
          if (e.keyCode === 8 && !tagInput.value && !lastValue) {
            $(tagInput).one('keyup', backspaceDeleteLastTag);
          }
          lastValue = tagInput.value;
        });

        $(tagInput).keypress(handleInputKeyPress);

        tagsValueInput.addTags = (...tagsLabel) => {
          tagsLabel.forEach((tagLabel) => createAndAddTag(tagLabel));
        };
      });
    };

    Cosmos.initInputTags = initTags;
    Cosmos.initInputTags();

    // ATENÇÃO: ESSE TRECHO DE CÓDIGO PRECISA SER O ÚLTIMO A SER INVOCADO
    elementsToBeModified.forEach((elementToBeModified) => {
      elementToBeModified.dataset.cosmos = '';
    });
  };

  renderForm();

  window.renderForm = renderForm;

  const elementModifierUp = (element) => {
    if (
      element.type === 'file' ||
      element.parentElement.className.includes('tags')
    ) {
      element.parentElement.classList.add('is-invalid');
    }
  };

  const elementModifierDown = (element) => {
    if (
      element.type === 'file' ||
      element.parentElement.className.includes('tags')
    ) {
      element.parentElement.classList.remove('is-invalid');
    }
  };

  const insertFeedbackBy = (element, position, feedback) => {
    element.insertAdjacentElement(position, feedback);
  };

  const getFieldParent = (field) => {
    if (field.parentElement.className.includes('tags')) {
      return field.parentElement.parentElement;
    }

    const formGroup = field.closest('.form-group');

    return formGroup || field.parentElement;
  };

  const templateInvalidMessage = document.createElement('template');
  templateInvalidMessage.innerHTML = `
    <div class="invalid-feedback">
      <span class="sr-only">Mensagem de erro: </span>
      <span class="feedback-message"></span>
    </div>
  `;

  const createInvalidFeedback = (message) => {
    const invalidFeedback = templateInvalidMessage.content
      .cloneNode(true)
      .querySelector('.invalid-feedback');

    const uniqueId = crypto.randomUUID();
    invalidFeedback.id = uniqueId;

    invalidFeedback.querySelector('.feedback-message').innerHTML = message;

    return invalidFeedback;
  };

  const setInvalidFieldMessage = (field, message) => {
    const fieldParent = getFieldParent(field);
    let invalidFeedback = fieldParent.querySelector('.invalid-feedback');

    if (invalidFeedback) invalidFeedback.remove();

    invalidFeedback = createInvalidFeedback(message);

    if (field.classList.contains('custom-select')) {
      const customSelectButton = fieldParent.querySelector('.combo-input');
      if (customSelectButton) {
        customSelectButton.setAttribute('aria-describedby', invalidFeedback.id);
      }
    } else {
      field.setAttribute('aria-describedby', invalidFeedback.id);
    }

    const formTipText = fieldParent.querySelector('.form-text');

    if (formTipText) {
      insertFeedbackBy(formTipText, 'beforebegin', invalidFeedback);
      return;
    }

    const tagsContainer = fieldParent.querySelector('.tags');

    if (tagsContainer) {
      insertFeedbackBy(tagsContainer, 'afterend', invalidFeedback);
      return;
    }

    insertFeedbackBy(fieldParent, 'beforeend', invalidFeedback);
  };

  const handlerActiveFieldsetGroupError = (field, message) => {
    field.classList.add('is-invalid');
    field.querySelectorAll('input').forEach((element) => {
      element.classList.add('is-invalid');
    });
    setInvalidFieldMessage(field, message);
  };

  const handlerDeactivateFieldsetGroupError = (field) => {
    field.classList.remove('is-invalid');
    field.querySelectorAll('input').forEach((element) => {
      element.classList.remove('is-invalid');
    });
    const invalidFeedback = field.querySelector('.invalid-feedback');
    if (invalidFeedback) {
      invalidFeedback.remove();
    }
  };

  const toggleInputActionErrorState = (field, isInvalid) => {
    const formInputGroup = field.closest('.form-input-group');
    if (!formInputGroup) { return; }

    const inputAction = formInputGroup.querySelector('.input-action > button');
    if (!inputAction) { return; }

    if (isInvalid) {
      inputAction.classList.add('btn-danger');
    } else {
      inputAction.classList.remove('btn-danger');
    }
  }

  /**
   * @param {HTMLElement} field - Elemento alvo da validação.
   * @param {String} [message] - Mensagem de erro a ser apresentada.
   */
  const activateError = (field, message, customError = false) => {
    const DEFAULT_MESSAGE = 'Campo não preenchido';
    const DEFAULT_MESSAGE_SELECT = 'Campo não selecionado';

    message = !message ? DEFAULT_MESSAGE : message;

    if (field.className.includes('fieldset-group')) {
      return handlerActiveFieldsetGroupError(field, message);
    }

    if (field.classList.contains('tags-value')) {
      field = field.parentNode.querySelector('.form-control');
    }

    const formGroup = field.closest('.form-group');
    if (field.className.includes('custom-select')) {
      if (formGroup) {
        const customSelect = formGroup.querySelector('.combo');
        if (customSelect) {
          customSelect.classList.add('is-invalid');
        }
        const customSelectButton = formGroup.querySelector('.combo-input');
        if (customSelectButton) {
          customSelectButton.classList.add('is-invalid');
          customSelectButton.setAttribute('aria-invalid', 'true');
        }
      }

      if (message === DEFAULT_MESSAGE) {
        message = DEFAULT_MESSAGE_SELECT;
      }
    }

    if (field.activateError) {
      field.activateError();
    }

    field.classList.add('is-invalid');
    field.setAttribute('aria-invalid', 'true');

    if (customError) {
      field.setCustomValidity(message);
    }

    if (formGroup) {
      formGroup.classList.add('is-invalid');
      const tooltipGroup = formGroup.querySelector('.tooltip-group');

      if (tooltipGroup) {
        tooltipGroup.classList.add('is-invalid');
      }
    }

    elementModifierUp(field);
    setInvalidFieldMessage(field, message);
    toggleInputActionErrorState(field, true);

    return 0;
  };

  /**
   * @param {HTMLElement} field - Elemento alvo da validação.
   */
  const deactivateError = (field) => {
    if (
      field.className.includes('fieldset-group') ||
      field.className.includes('form-check')
    ) {
      handlerDeactivateFieldsetGroupError(field);
    }

    const formGroup = field.closest('.form-group');
    if (field.className.includes('custom-select')) {
      if (formGroup) {
        const customSelect = formGroup.querySelector('.combo');
        if (customSelect) {
          customSelect.classList.remove('is-invalid');
        }
        const customSelectButton = formGroup.querySelector('.combo-input');
        if (customSelectButton) {
          customSelectButton.classList.remove('is-invalid');
          customSelectButton.removeAttribute('aria-invalid', 'true');
        }
      }
    }

    if (field.deactivateError) {
      field.deactivateError();
    }

    field.classList.remove('is-invalid');
    field.removeAttribute('aria-invalid');
    field.setCustomValidity('');

    if (formGroup) {
      formGroup.classList.remove('is-invalid');
      const tooltipGroup = formGroup.querySelector('.tooltip-group');

      if (tooltipGroup) {
        tooltipGroup.classList.remove('is-invalid');
      }

      const invalidFeedback = formGroup.querySelector('.invalid-feedback');
      if (invalidFeedback) {
        invalidFeedback.remove();
      }
    }

    elementModifierDown(field);
    toggleInputActionErrorState(field, false);
  };

  window.activateError = activateError;
  window.deactivateError = deactivateError;
})(window.Cosmos);

window.updateComponents = () => {
  // 'emitChangePalette' será depreciado em versões futuras
  if (window.emitChangePalette) window.emitChangePalette();
  window.renderForm();
};

/* ------------------ Custom Multiple Select  -------------------------
|   Esse trecho de código cria e estiliza uma ul que simula            |
|   o comportamento de um multiselect. Essa criação foi necessária     |
|   porque a estilização padrão da tag select é muito limitada         |
*---------------------------------------------------------------------*/

$(function () {
  $('.customMultipleSelect li').CustomMultipleSelect({});
  $('.sizedCustomMultipleSelect li').CustomMultipleSelect();
  /*  Para a criação de um Multiple Select com 'size' é necessário
  fazer a chamada da função 'reSize', passando como parâmetro o
  ID do Multiple Select em questão. */
  $().reSize('sizedMS1');
});

(function ($) {
  $.fn.reSize = function (ID) {
    /* Função responsável pelo redimencionamento de um Multiple Select com
  parâmetro size. Ocorre a alteração da propriedade 'height' do componente
  de acordo com a quantidade desejada de opções visíveis para o usuário */

    const multipleSelectContainer = $(`#${ID}`);
    const optionSize = 1.375; // Mais ou menos a altura padrão de uma opção
    const qtdOptions = multipleSelectContainer.children().length;
    let size = multipleSelectContainer.attr('size');
    if (size) {
      if (size <= 0) {
        size = 1;
      }
      //  Calcula a altura de acordo com o valor escolhido
      let newHeight = 0.6 + size * optionSize;
      if (size >= qtdOptions) {
        newHeight = 0.6 + qtdOptions * optionSize;
      }
      //  Insere a altura no css do componente
      const newStyle = `height:${newHeight}rem !important`;
      $(`.sized-customMS#${ID}`).attr('style', newStyle);
    }
  };

  $.fn.CustomMultipleSelect = function () {
    // Variável de controle que determina se o container do MS deve estar ou não com o estilo de foco;
    let qtdSelected = 0;

    //  Permite que uma opção seja selecionada ao utilizar a tecla 'enter'
    $(this).keyup(function (ev) {
      if (ev.keyCode === 13) {
        $(this).click();
      }
    });
    //  Adiciona uma classe que determina se uma opção foi selecionada ou não;
    $(this).click(function () {
      if ($(this).hasClass('active-multiple-select') === true) {
        $(this).removeClass('active-multiple-select');
        qtdSelected -= 1;
        if (qtdSelected <= 0) {
          //  Se não houver nenhuma opção selecionada, retira a classe 'isOnFocus' do container do MS
          $(this).parent().parent().removeClass('isOnFocus');
        }
      } else {
        $(this).addClass('active-multiple-select');
        qtdSelected += 1;
        $(this).parent().parent().addClass('isOnFocus');
      }
    });
  };
})(jQuery);

// ************************* FIM DO form.js *************************
