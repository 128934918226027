import { AnyFunction } from '../types/types';

/**
 * Observes a specified property of an element and invokes a callback when the property changes.
 *
 * @param {Object} element - The element to observe.
 * @param {string} property - The name of the property to observe.
 * @param {Function} callback - The callback function to invoke when the property changes.
 * @param {number} [delay=0] - The optional delay in milliseconds before invoking the callback after the property changes.
 */
function observeElement(
  element: HTMLElement,
  property: string,
  callback: AnyFunction,
  delay = 0
) {
  const elementPrototype = Object.getPrototypeOf(element);
  if (Object.prototype.hasOwnProperty.call(elementPrototype, property)) {
    const descriptor = Object.getOwnPropertyDescriptor(
      elementPrototype,
      property
    );
    Object.defineProperty(element, property, {
      get(...args) {
        return descriptor && descriptor.get?.apply(this, args);
      },
      set(...args) {
        const oldValue = this[property];
        descriptor && descriptor.set?.apply(this, args);
        const newValue = this[property];
        if (typeof callback === 'function') {
          setTimeout(callback.bind(this, oldValue, newValue), delay);
        }
      },
    });
  }
}

export default observeElement;
