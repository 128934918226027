import { installGlobal } from '../../global';
import MenuLibras, { MenuLibrasElement } from './MenuNavLibras';
import ToggleMenu from './ToggleMenu';
import { COMPONENT_NAME } from './constants';

function initMenuLibras() {
  const menus = document.querySelectorAll('.menu[data-libras]');
  menus.forEach((sideBarLibras) => {
    if ((sideBarLibras as MenuLibrasElement).MenuNavLibras) return;
    new MenuLibras(sideBarLibras as HTMLElement);
  });
}

function initButtonToggleMenuLibras() {
  const buttonsToggleMenu = document.querySelectorAll<HTMLElement>(
    '.toggle-menu-libras, [data-toggle-menu]'
  );

  buttonsToggleMenu.forEach((button) => {
    let menu = null;
    if (button.dataset.toggleMenu) {
      menu = document.querySelector<HTMLElement>(button.dataset.toggleMenu);
    } else {
      menu = document.querySelector<HTMLElement>('.menu');
    }
    new ToggleMenu(button, menu);
  });
}

export function init() {
  initMenuLibras();
  initButtonToggleMenuLibras();
}

const methods = {
  init,
  ToggleMenu,
};

installGlobal(COMPONENT_NAME, methods);

export default methods;
