(function(Cosmos) {
  const tooltipObserver = new MutationObserver(mutations => {
    mutations.forEach(mutationRecord => {
      const tooltipIcon = mutationRecord.target.previousSibling;
      $(tooltipIcon).tooltip('hide');
    });
  });

  const handlerKeypress = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      $(event.target).tooltip('toggle');
    }
  };

  const handlerFocusOut = event => {
    $(event.target).tooltip('hide');
  };

  // Função que renderiza e define os atributos do tooltip
  const renderTooltip = (el, _options) => {
    const html = el || document;
    $(html)
      .find('[data-toggle="tooltip"]')
      .each(function(idx, elTootip) {
        const isASmallResolution = $(window).width() <= 768;
        const data = elTootip.dataset;
        const { container } = data;
        const options = {
          container: container || elTootip.parentElement,
          html: true,
          placement: isASmallResolution ? 'bottom' : data.placement || 'right',
          boundary: 'window',
          ..._options,
        };

        const $elTooltip = $(elTootip);
        if (elTootip.hasAttribute('data-type')) {
          options.template = `<div class="tooltip ${$elTooltip.attr(
            'data-type'
          )} ${container ? 'container-used' : ''}" role="tooltip">
          	<div class="arrow"></div>
          	<div class="tooltip-inner"></div>
          </div>`;
        }

        if (!container) {
          $elTooltip.on('shown.bs.tooltip', () => {
            const tooltipInfo = this.nextElementSibling;
            tooltipObserver.observe(tooltipInfo, {
              attributes: true,
              attributeFilter: ['x-out-of-boundaries'],
            });
          });
        }

        $elTooltip.tooltip(options);
        // necessário remover os eventos devido a possibilidade do render ser chamado mais de uma vez
        $elTooltip.off('keypress', handlerKeypress);
        $elTooltip.off('focusout', handlerFocusOut);
        $elTooltip.on('keypress', handlerKeypress);
        $elTooltip.on('focusout', handlerFocusOut);
      });
  };

  // Roda a função novamente caso a tela mude de tamanho
  window.addEventListener('resize', function() {
    // Esconde e destroi o elemento tooltip.
    $('[data-toggle="tooltip"]').tooltip('dispose');
    // Renderiza novamente para o novo tamnho de tela
    renderTooltip();
  });

  window.document.body.addEventListener('scroll', () => {
    $('[data-toggle="tooltip"][data-container]').tooltip('hide');
  });

  Cosmos.initTooltip = renderTooltip;

  Cosmos.initTooltip();
})(window.Cosmos);

// ************************* FIM DO tooltip.js *************************
