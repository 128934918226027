export const DS_NAME = 'Cosmos';

window[DS_NAME] = window[DS_NAME] ?? {};

/**
 * Cosmos.urlBase is used to resolve relative paths in assets design system.
 * 
  If you want to change it, just set window.Cosmos.urlBase before importing main script of design system.
 */
if (typeof window[DS_NAME].urlBase === 'undefined') {
  const DS_URL = 'https://static.info.ufrn.br/release/';
  console.warn(`${DS_NAME}.urlBase not defined, defaulting to ${DS_URL}`);
  window[DS_NAME].urlBase = DS_URL;
}

/** Breakpoints from bootstrap (https://getbootstrap.com/docs/4.6/layout/overview/#containers) */
export const BREAKPOINT_SM = 576;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 992;
export const BREAKPOINT_XL = 1200;
export const BREAKPOINT_XXL = 1400;

export const PREFIX = 'ds';

export function installGlobal<T>(name: string | null, methods: T) {
  if (name) {
    window[DS_NAME][name] = methods;
    return;
  }
  window[DS_NAME] = Object.assign(window[DS_NAME], methods);
}

export const DSGlobal = window[DS_NAME];
