import { PREFIX } from '../../global';

export const COMPONENT_NAME = 'ModeLibras';

export const MODE_LIBRAS_CHANGE_EVENT = `${PREFIX}.${COMPONENT_NAME}.change`;

export const MODE_LIBRAS_ON_TITLE = 'Desativar modo libras';
export const MODE_LIBRAS_ON_ARIALABEL = 'Modo libras ativado';
export const MODE_LIBRAS_OFF_TITLE = 'Ativar modo libras';
export const MODE_LIBRAS_OFF_ARIALABEL = 'Modo libras desativado';
