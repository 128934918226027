(async function() {
  // Esta função deixa a primeira letra de uma string MAIÚSCULA
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function mountDropdown(itemActions, dadosVideo, galeriaVideo) {
    const dropdown = document.createElement('div');
    dropdown.classList.add('dropdown');

    const buttonDropdownToggle = document.createElement('button');
    buttonDropdownToggle.classList.add('btn-tertiary-icon', 'dropdown-toggle');
    buttonDropdownToggle.setAttribute('id', `dropdown-video-${dadosVideo.id}`);
    buttonDropdownToggle.setAttribute('data-toggle', 'dropdown');
    buttonDropdownToggle.setAttribute('aria-haspopup', 'true');
    buttonDropdownToggle.setAttribute('aria-expanded', 'false');
    buttonDropdownToggle.setAttribute('aria-label', 'ações para o vídeo');

    const faEllipsis = document.createElement('i');
    faEllipsis.classList.add('fas', 'fa-ellipsis-h');

    buttonDropdownToggle.appendChild(faEllipsis);
    dropdown.appendChild(buttonDropdownToggle);

    const dropdownMenu = document.createElement('div');
    dropdownMenu.classList.add('dropdown-menu', 'dropdown-menu-right');
    dropdownMenu.setAttribute('aria-label', 'ações para o vídeo');
    dropdown.appendChild(dropdownMenu);

    const dropMenuArrow = document.createElement('div');
    dropMenuArrow.classList.add('dropdown-menu-arrow');
    dropdownMenu.appendChild(dropMenuArrow);

    Object.keys(itemActions).forEach(item => {
      const dropdownItem = document.createElement('a');
      dropdownItem.classList.add('dropdown-item');
      dropdownItem.setAttribute('href', '#');
      dropdownItem.textContent = itemActions[item];
      dropdownItem.setAttribute('id', `dropdown-item-${item}`);

      dropdownMenu.appendChild(dropdownItem);

      const dropdownVideoEvent = new CustomEvent(
        `galeria:item${capitalizeFirstLetter(item)}`,
        {
          detail: {
            id: dadosVideo.id,
          },
        }
      );

      dropdownItem.addEventListener('click', e => {
        e.preventDefault();
        galeriaVideo.dispatchEvent(dropdownVideoEvent);
      });
    });

    return dropdown;
  }

  function mountExternalVideo(dadosVideo) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute(
      'allow',
      'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
    );
    iframe.setAttribute('src', dadosVideo.src);
    iframe.setAttribute('title', dadosVideo.titulo);
    iframe.setAttribute('allowfullscreen', 'allowfullscreen');
    return iframe;
  }

  function mountTitleVideo(dadosVideo) {
    const nameVideo = document.createElement('h3');
    nameVideo.classList.add('name-video');
    nameVideo.innerText = dadosVideo.titulo;

    return nameVideo;
  }

  function mountInternalVideo(dadosVideo) {
    const video = document.createElement('video');
    video.setAttribute('controls', 'controls');

    const source = document.createElement('source');
    source.setAttribute('src', dadosVideo.src);
    source.setAttribute('title', dadosVideo.titulo);
    source.setAttribute('type', `video/${dadosVideo.src.substr(-3)}`);
    video.appendChild(source);

    return video;
  }

  /* ----- Show Video -------------------------
  |  Esse trecho de código cria o esqueleto da div show video.
  *-------------------------------------------------------------------*/
  const criaShowVideo = (dadosVideo, itemActions, galeriaVideo) => {
    const containerVideoEl = document.createElement('div');
    containerVideoEl.classList.add('show-video');
    containerVideoEl.setAttribute('id', `video-${dadosVideo.id}`);

    const infoVideoEl = document.createElement('div');
    infoVideoEl.classList.add('info-video');

    const titleElement = mountTitleVideo(dadosVideo);
    infoVideoEl.appendChild(titleElement);

    if (Object.keys(itemActions).length !== 0) {
      const dropdownElement = mountDropdown(
        itemActions,
        dadosVideo,
        galeriaVideo
      );
      infoVideoEl.appendChild(dropdownElement);
    }

    const videoElement =
      dadosVideo.ehLinkExterno === true
        ? mountExternalVideo(dadosVideo)
        : mountInternalVideo(dadosVideo);

    containerVideoEl.appendChild(videoElement);
    containerVideoEl.appendChild(infoVideoEl);

    return containerVideoEl;
  };

  function carregaApi() {
    const galeriaVideos = document.querySelectorAll('.galeria-videos');

    galeriaVideos.forEach(async galeriaVideo => {
      /* ----- Pega os videos da API-------------------------
      |  Esse trecho de código gera os albuns da api encontradas no caminho
      |  https://static.info.ufrn.br/current/json/galeria-videos-api.json.
      *-------------------------------------------------------------------*/
      /**
       * id -> identificador
       * link-externo -> define se o video sera um link externo(youtube) ou interno(api);
       * src -> caminho do video;
       * titulo -> Nome dado ao video;
       * Renomear os dados das funções para ficarem de acordo com os dados da
       * API a ser utilizada.
       *
       * Modelo da API utilizada no exemplo: https://static.info.ufrn.br/current/json/galeria-videos-api.json
       */
      const dataApi = galeriaVideo.dataset.api;
      const dataItemActions = galeriaVideo.dataset.itemActions
        ? JSON.parse(galeriaVideo.dataset.itemActions)
        : {};

      let dadosApi = [];
      if (dataApi) {
        const data = await fetch(dataApi)
          .then(response => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response;
          }) // eslint-disable-next-line no-use-before-define
          .catch(() => telaErroApi());
        dadosApi = await data.json();
      }
      const containVideosEl = document.createElement('div');
      containVideosEl.classList.add('contain-videos');
      galeriaVideo.appendChild(containVideosEl);

      dadosApi.forEach(video => {
        const divShowVideo = criaShowVideo(
          video,
          dataItemActions,
          galeriaVideo
        );
        containVideosEl.appendChild(divShowVideo);
      });
    });
  }

  carregaApi();

  function telaErroApi() {
    const containerEl = document.createElement('div');
    containerEl.classList.add(
      'd-flex',
      'w-100',
      'justify-content-center',
      'flex-column',
      'align-items-center'
    );

    const avisoErroEL = document.createElement('h2');
    avisoErroEL.classList.add('my-3');
    avisoErroEL.textContent = 'Ops! Não foi possível carregar os dados.';

    containerEl.appendChild(avisoErroEL);

    const btnRecarregarEl = document.createElement('a');
    btnRecarregarEl.classList.add('btn-primary');
    btnRecarregarEl.textContent = 'Tentar novamente';
    btnRecarregarEl.addEventListener('click', e => {
      e.preventDefault();
      carregaApi();
    });

    containerEl.appendChild(btnRecarregarEl);

    const conteudoPrincipal = document.querySelector(
      '.conteudo-principal__content'
    );
    const galeria = document.querySelector('.galeria');
    galeria.remove();
    conteudoPrincipal.appendChild(containerEl);
  }
})();

// ************************* FIM DO galeria-video.js *************************
