/* ------------------- ADICIONA FUNÇÃO DE CLICK AOS BOTÕES ------------------- *\
 | Esse trecho de código pecorre por cada item do menu/lista e adiciona a cada   |
 | button a função de click, no qual irá desativar e ativar os itens desejados.  |
 \* --------------------------------------------------------------------------- */

(() => {
  const navigation = document.querySelectorAll('.nvg');

  if (!navigation) return;

  const navigationHandler = item => {
    const navigationMenuList = Array.from(
      item.querySelectorAll('.nvg-menu-list')
    );

    if (!navigationMenuList) return;

    const navigationMenuButton = item.querySelectorAll('.nvg-menu-button');

    if (!navigationMenuButton) return;

    const navigationMenuHandler = (button, index) => {
      button.addEventListener('focus', () => {
        if (Array.isArray(window.mySwiper)) {
          window.mySwiper.forEach(mySw => {
            mySw.el.scrollLeft = 0;
            mySw.slideTo(index);
          });
        } else {
          window.mySwiper.scrollLeft = 0;
          window.mySwiper.slideTo(index);
        }
      });

      button.addEventListener('click', () => {
        const hrefAttribute = button.getAttribute('href');
        const navigationContentTab = item.querySelector('.nvg-content-tab');

        if (!navigationContentTab) return;

        // Desabilita o conteudo atual
        navigationContentTab
          .querySelector(':scope > .tab-pane.fade.show.active')
          .classList.remove('show', 'active');
        // Habilita o conteudo referente ao botão clicado
        navigationContentTab
          .querySelector(hrefAttribute)
          .classList.add('show', 'active');

        item.dispatchEvent(
          new CustomEvent('navigate', {
            bubbles: true,
            detail: hrefAttribute,
          })
        );
      });
    };

    navigationMenuButton.forEach(navigationMenuHandler);
  };

  navigation.forEach(navigationHandler);
})();

/* ----------------------- ADICIONA FUNÇÃO DE SLIDER ----------------------- *\
 | Swiper é o mais moderno mobile toucher slider gratuito com transições       |
 | aceleradas por hardware e comportamento nativo incrível. Destina-se a ser   |
 | usado em sites, aplicativos web e aplicativos nativos / híbridos móveis.    |
 | https://swiperjs.com/                                                       |
 \* ------------------------------------------------------------------------- */

(() => {
  function clearSwiperStyle() {
    const swiperWrapper = document.querySelectorAll('.swiper-wrapper');

    swiperWrapper.forEach(list => {
      list.removeAttribute('style');

      [...list.children].forEach(item => {
        item.removeAttribute('style');
      });
    });
  }

  function handleStatus(swiper) {
    /*
     ** translateTo(): Anima o valor de conversão da transformação css3 personalizada para o wrapper swiper
     ** translate - number - valor (em px)
     ** speed - number - duração (em ms)
     ** runCallbacks - boolean - Defina como falso (por padrão é verdadeiro) e a transição não produzirá eventos de transição
     ** translateBounds - boolean - Defina-o como falso (por padrão é verdadeiro) e o valor de transição pode se estender além da tradução mínima e máxima
     */

    /*
     ** swiper.enable(): Ativar Swiper (se estiver desativado)
     ** swiper.disable(): Desative o Swiper (se estiver ativado). Quando o Swiper estiver desativado, ele ocultará
     ** todos os elementos de navegação e não responderá a nenhum evento e interação
     */

    if (Array.isArray(swiper)) {
      swiper.forEach(sw => {
        sw.translateTo(32, 0);
        sw.enable();
        if (window.innerWidth > 991.98) {
          sw.disable();

          clearSwiperStyle();
        }
      });
    } else {
      swiper.translateTo(32, 0);

      swiper.enable();

      if (window.innerWidth > 991.98) {
        swiper.disable();

        clearSwiperStyle();
      }
    }
  }

  if (!window.Swiper) {
    return;
  }

  const swiper = new window.Swiper('.swiper', {
    on: {
      resize: () => {
        handleStatus(swiper);
      },
    },
    /* Parâmetros padrão */
    /* Ativa mensagem para leitores de tela */
    a11y: {
      prevSlideMessage: 'Anterior', // Mensagem para leitores de tela para o botão anterior.
      nextSlideMessage: 'Próximo', // Mensagem para leitores de tela para o próximo botão.
    },
    centerInsufficientSlides: true,
    centeredSlides: true, // O item ativo será centralizado (nem sempre no lado esquerdo).
    centeredSlidesBounds: true, // O item ativo será centralizado sem adicionar lacunas no início e no final (Required centeredSlides: true).
    direction: 'horizontal', // Pode ser 'horizontal' ou 'vertical'.
    /* Ativa a funcionalidade do modo livre */
    freeMode: {
      enabled: false, // Habilita ou desabilita as configurações padrão.
    },
    /* Permite a navegação por itens usando o teclado */
    keyboard: {
      enabled: true, // Habilita o controle do teclado.
      onlyInViewport: true, // Gerencia os controles deslizantes que estão atualmente na janela de visualização.
      pageUpDown: true, // Permite a navegação do teclado pelas teclas Page Up e Page Down.
    },
    /* Permite a navegação por itens usando a roda do mouse */
    mousewheel: {
      invert: true, // Inverte a direção de deslizamento.
    },
    navigation: {
      nextEl: '.swiper-button-next', // Funciona como o botão "próximo" após clicar nele.
      prevEl: '.swiper-button-prev', // Funciona como o botão "anterior" após clicar nele.
    },
    preventClicks: true, // Evita cliques indesejados acidentais em links durante o deslizamento.
    scrollbar: {
      el: '.swiper-scrollbar', // Contêiner com barra de rolagem.
      draggable: true, // Permite que a barra de rolagem seja arrastável, o que permite que você controle a posição do controle deslizante.
      hide: false, // Oculta barra de rolagem automaticamente após a interação do usuário.
    },
    slidesOffsetAfter: 32, // Adicionar (em px) deslocamento de item adicional no final do contêiner (após todos os slides).
    slidesOffsetBefore: 32, // Adicionar (em px) deslocamento de item adicional no início do contêiner (antes de todos os slides).
    slidesPerView: 'auto', // Número de itens visíveis ao mesmo tempo no contêiner (padrão).
    slidesPerGroup: 1, // Defina o número de itens para definir e habilitar o deslizamento do grupo. Útil para usar com slidesPerView> 1.
    spaceBetween: 0, // Distância em px entre os itens.
    watchOverflow: true, // Desativa e oculta os botões de navegação caso não haja itens suficientes para deslizar.
  });

  window.mySwiper = swiper;

  handleStatus(swiper);
})();
// ************************* FIM DO navegacao_responsiva.js *************************
