import Overlay from '../overlay';
import { closeNavRightMenu } from '../header-mobile';
import ElementMenuFactory from './ElementMenuFactory';
import MenuNavLibras from './MenuNavLibras';
import { AutoactivateOptions } from './constants';
import StateModeLibras from '../toggle-mode-libras/state';

export type MenuLibrasElement = HTMLElement & { MenuNavLibras?: MenuNavLibras };

export default class MenuLibras {
  element: MenuLibrasElement;
  menuAux: Element[] = [];
  menuItem?: NodeListOf<HTMLElement>;
  expanded: boolean = false;
  buttonsNextSubMenus?: NodeListOf<HTMLElement>;
  itemList?: NodeListOf<HTMLElement>;
  links?: NodeListOf<HTMLElement>;
  menuItemsLinks?: NodeListOf<HTMLElement>;
  selectedItemIndex: number = 0;

  constructor(element: MenuLibrasElement) {
    this.element = element;
    if (this.element.MenuNavLibras) return; // already initialized

    this.element.MenuNavLibras = this;
    this.menuAux = Array.from(
      this.element.querySelector('.menu__list:not(submenu)')?.children || []
    );
    this.menuItem = this.element.querySelectorAll('.menu__item');
    this.expanded = false;
    this.buttonsNextSubMenus = this.element.querySelectorAll('.open-submenu');
    this.itemList = this.element.querySelectorAll('.menu__item');
    this.links = this.element.querySelectorAll('a.menu__link');
    this.menuItemsLinks = this.element.querySelectorAll('.menu__link');
    this.selectedItemIndex = 0;

    this.init();
  }

  showOverlay() {
    Overlay.show().addEventListener('click', () => {
      this.toggleMenu();
    });
  }

  // Cria o botão de voltar do menu automáticamente
  createButtonBackMenu() {
    const li = document.createElement('li');
    const buttonBack = document.createElement('button');
    const span = document.createElement('span');
    const i = document.createElement('i');
    li.classList.add('menu__item');
    li.setAttribute('role', 'none');
    span.textContent = 'Voltar ao menu';
    i.classList.add('fas', 'fa-chevron-left');
    buttonBack.classList.add('link-normal', 'back-menu', 'menu__link');
    buttonBack.appendChild(i);
    buttonBack.appendChild(span);
    buttonBack.ariaLabel = 'Voltar para o menu anterior';
    li.appendChild(buttonBack);
    buttonBack.addEventListener('click', (e) => this.handleSubMenuPrev(e));

    return li;
  }

  addTabindexInLinks() {
    this.menuItemsLinks?.forEach((item) => {
      item.setAttribute('tabindex', '-1');
    });
    this.menuItemsLinks![0].setAttribute('tabindex', '0');
  }

  loadingHandler(video: HTMLElement) {
    const loadingDiv = document.createElement('div');
    loadingDiv.classList.add('loading-video');
    loadingDiv.innerHTML = '<div class="spinner-border" role="status"></div>';

    const videoParent = video.closest('.menu__video');
    video.onwaiting = function () {
      if (!document.querySelector('.loading-video')) {
        videoParent?.insertBefore(loadingDiv, video);
      }
    };
    video.oncanplay = function () {
      document.querySelector('.loading-video')?.remove();
    };
  }

  handlerPlayVideo(url: string) {
    const video = this.element.querySelector('video');

    if (!video) return;

    if (url) {
      video.src = url;

      video.play().catch(() => {
        console.error('Não foi possível exibir o video');
      });
    }
    this.loadingHandler(video);
  }

  // Cria o ícone de libras presente nos items do menu
  createIconHands() {
    const i = document.createElement('i');
    i.classList.add('menu__item__icon');
    i.setAttribute('aria-hidden', 'true');
    return i;
  }

  // Cria o título do menu automáticamente
  createTitleSubmenu(buttonSubmenu: HTMLElement) {
    const li = document.createElement('li');
    li.classList.add('menu__item--goback');
    li.setAttribute('role', 'none');

    const span = document.createElement('span');
    span.classList.add('menu__title');
    span.textContent = buttonSubmenu.textContent;
    span.setAttribute('aria-hidden', 'false');

    li.appendChild(span);

    if (this.element.dataset.libras === 'true') {
      const iconHand = this.createIconHands();
      iconHand.setAttribute(
        'data-src',
        buttonSubmenu.getAttribute('data-src') || ''
      );
      li.prepend(iconHand);
    }

    return li;
  }

  removeClassActive() {
    const lisActive = this.element.querySelectorAll('.active');
    lisActive.forEach((item) => {
      item.classList.remove('active');
      item.removeAttribute('aria-current');
    });
  }

  // Esse método verica se possui algum item ativo no submenu
  handleLiSubmenuActive(submenu: HTMLElement, buttonOpen: HTMLElement) {
    // const buttonOpenSubmenu = buttonOpen.closest('.menu__item').querySelector('.open-submenu')
    const linksActive = submenu.querySelectorAll('.active');
    buttonOpen.focus();
    if (linksActive.length > 0) {
      buttonOpen.classList.add('active');
      buttonOpen.closest('li')?.classList.add('active');
    }
  }

  // Adiciona a classe active na li e no link quando clicar nele
  handleLinkActive(link: HTMLAnchorElement) {
    const li = link.closest('li');
    if (
      link.getAttribute('data-autoactive') &&
      !li?.classList.contains('active')
    ) {
      this.removeClassActive();
      this.updateAttributeAutoActivate(link);
      return;
    }
    if (!li?.classList.contains('active')) {
      this.removeClassActive();
      li?.classList.add('active');
      link.classList.add('active');
      link.setAttribute('aria-current', 'page');
    }
  }

  activeElement(link: HTMLElement) {
    link.classList.add('active');

    if (link.parentElement && (link.role != 'menu' || !link.dataset.libras)) {
      this.activeElement(link.parentElement);
    }
  }

  updateAttributeAutoActivate(link: HTMLAnchorElement) {
    if (link.tagName !== 'A') return;

    let autoActive =
      link.getAttribute('data-autoactive') || AutoactivateOptions.EXACT;
    const currentUrl = window.location.href;

    if (
      autoActive &&
      !Object.values(AutoactivateOptions).includes(autoActive)
    ) {
      console.warn(
        `Valor inválido para data-autoactive: ${autoActive}. Será assumido o valor padrão: 'exact'`
      );
      autoActive = AutoactivateOptions.EXACT;
    }

    if (autoActive === AutoactivateOptions.FALSE) {
      return;
    }

    if (autoActive === AutoactivateOptions.PATH) {
      const { pathname: pathnameLink } = new URL(link.href);
      const { pathname: pathnameCurrentPage } = new URL(currentUrl);

      if (pathnameLink === pathnameCurrentPage) {
        this.activeElement(link);
      }
      return;
    }

    if (autoActive === AutoactivateOptions.START) {
      const {
        pathname: pathnameLink,
        hash: hashLink,
        searchParams: searchParamsLink,
      } = new URL(link.href);
      const {
        pathname: pathnameCurrentPage,
        hash: hashCurrentPage,
        searchParams: searchParamsCurrentPage,
      } = new URL(currentUrl);

      const includesAll = (arr: any, values: any) =>
        arr.every((v: any) => values.includes(v));

      if (
        pathnameLink === pathnameCurrentPage &&
        (hashLink === '' || hashLink === hashCurrentPage) &&
        includesAll(
          Array.from(searchParamsLink.values()),
          Array.from(searchParamsCurrentPage.values())
        )
      ) {
        this.activeElement(link);
      }
      return;
    }

    if (autoActive === AutoactivateOptions.EXACT && link.href === currentUrl) {
      this.activeElement(link);
    }
  }

  handleActiveCurrentPage() {
    const itemList = this.element.querySelectorAll('.menu__item');
    itemList.forEach((li) => {
      const link = li.querySelector('.menu__link') as HTMLAnchorElement;
      this.updateAttributeAutoActivate(link);
    });
  }

  // Destrói o menu mobile caso ele esteja ativo
  handleNavRight() {
    const navRight = document.querySelector(
      '.container-conteudo__nav_right.nav-open'
    );
    const overlayNavRight = document.querySelector('.overlay-header.d-block');
    if (navRight && overlayNavRight) {
      navRight.classList.remove('nav-open');
      overlayNavRight.classList.remove('d-block');
    }
  }

  // Abre/fecha o menu
  toggleMenu() {
    this.expanded ? this.close() : this.open();
  }

  open() {
    // Se houver menu lateral direito expandido, recolhe-o.
    closeNavRightMenu();

    this.expanded = true;
    const sidebar = this.element.closest('.sidebar');
    if (!sidebar) return;
    sidebar.classList.add('expanded');
    (sidebar as HTMLElement).focus({ preventScroll: true });
    this.showOverlay();
  }

  close() {
    if (!this.expanded) return;
    this.expanded = false;
    Overlay.hide();
    const sidebar = this.element.closest('.sidebar');
    sidebar?.classList.remove('expanded');
  }

  /* Este trecho de código destrói o efeito overlay criado
     quando o dispositivo estiver com uma largura maior que 992px
    */
  handleResizeWindow() {
    const windowWidth = window.innerWidth;
    if (windowWidth > 992) {
      Overlay.hide();
      return;
    }
    if (this.expanded) {
      this.showOverlay();
    }
  }

  // Navega entre as abas do menu indo para os submenu
  handleSubMenuNext(button: HTMLElement, forceFocus = true) {
    const parentMenu = button.closest('.menu__list')!;
    button.setAttribute('aria-expanded', 'true');
    parentMenu.classList.add('menu__list--expanded');
    const submenu = button.parentElement?.querySelector('ul.submenu');

    if (parentMenu.getAttribute('aria-expanded') === 'true') {
      parentMenu.setAttribute('aria-expanded', 'false');
      button.setAttribute('tabindex', '-1');
    }
    submenu?.classList.add('menu__list--expanded');
    submenu?.setAttribute('aria-expanded', 'true');

    if (submenu?.querySelector('li .menu__title') === null) {
      const titleSubmenu = this.createTitleSubmenu(button);
      submenu.prepend(titleSubmenu);
      titleSubmenu.addEventListener('click', ({ target }: any) => {
        this.handlerPlayVideo(target?.dataset.src);
      });
    }

    // Verifica se ainda não existe um botão de voltar criado, caso não tenha ele cria.
    if (submenu?.querySelector('li .back-menu') === null) {
      const menuItemButtonBack = this.createButtonBackMenu();
      const buttonBack = menuItemButtonBack.querySelector('button');
      submenu.prepend(menuItemButtonBack);

      setTimeout(() => {
        if (forceFocus) {
          buttonBack?.focus();
          buttonBack?.setAttribute('tabindex', '0');
        }
      }, 250);
      if (button?.closest('.menu__list')?.classList.contains('submenu')) {
        buttonBack?.setAttribute(
          'aria-label',
          `Voltar para o menu de ${button.textContent}`
        );
      } else {
        buttonBack?.setAttribute('aria-label', 'Voltar para o menu principal');
      }
    }

    // Trecho de código criado para auxiliar na navegação com setas no menu,
    // no qual faz referência à cada menu e não ao menu e submenu inteiro
    this.menuAux = Array.from(submenu?.children || []).filter((item) => {
      if (item.classList.contains('menu__item')) {
        return item;
      }
      return false;
    });
    this.selectedItemIndex = 0;
  }

  // Navega entre as abas do menu voltando os submenu
  handleSubMenuPrev(event: Event) {
    const button = event.target as HTMLElement;
    const subMenu = button.closest('.menu__list') as HTMLElement;
    if (!subMenu) return;
    subMenu.classList.remove('menu__list--expanded');
    subMenu.setAttribute('aria-expanded', 'false');
    const subMenuPrev = subMenu.closest('.menu__list.menu__list--expanded');
    if (!subMenuPrev) return;

    if (subMenuPrev && subMenuPrev.classList.contains('submenu')) {
      subMenuPrev.setAttribute('aria-expanded', 'true');
      button.setAttribute('tabindex', '-1');
    } else {
      subMenuPrev.classList.remove('menu__list--expanded');
      button.setAttribute('tabindex', '-1');
    }
    const parentUl = button?.closest('.submenu');
    if (!parentUl) return;
    // Botão que foi responsável por acionar o submenu
    const buttonOpenSubmenu = parentUl
      .closest('.menu__item')
      ?.querySelector<HTMLButtonElement>('.open-submenu');
    if (!buttonOpenSubmenu) return;
    buttonOpenSubmenu.setAttribute('aria-expanded', 'false');
    buttonOpenSubmenu.setAttribute('tabindex', '0');

    this.handleLiSubmenuActive(subMenu, buttonOpenSubmenu as HTMLElement);

    // Destrói o botão de voltar
    button.closest('.menu__item')?.remove();
    button.remove();
    buttonOpenSubmenu.focus();

    // Trecho de código criado para auxiliar na navegação com setas no menu,
    // no qual faz referência à cada menu e não ao menu e submenu inteiro
    this.menuAux = Array.from(subMenuPrev.children).filter((item) => {
      if (item.classList.contains('menu__item')) {
        return item;
      }
      return false;
    });
    // Navegação por teclado, pega o índice em que está o foco atualmente.
    this.selectedItemIndex = this.menuAux.length - 1;
  }

  navigateArrow(type: 'up' | 'down') {
    const menuItem = this.menuAux;
    if (type === 'up') {
      this.selectedItemIndex -= 1;
      let nextItemIndex = this.selectedItemIndex + 1;
      if (this.selectedItemIndex < 0) {
        this.selectedItemIndex = menuItem.length - 1;
        nextItemIndex = 0;
      }
      const menuLink =
        menuItem[this.selectedItemIndex].querySelector('.menu__link');
      const nextMenuItem = menuItem[nextItemIndex].querySelector('.menu__link');
      if (menuLink) {
        menuLink.setAttribute('tabindex', '0');
        (nextMenuItem as HTMLElement).setAttribute('tabindex', '-1');
        (menuLink as HTMLElement).focus();
      }
    }

    if (type === 'down') {
      this.selectedItemIndex += 1;
      let previousItemIndex = this.selectedItemIndex - 1;
      if (this.selectedItemIndex >= menuItem.length) {
        this.selectedItemIndex = 0;
        previousItemIndex = menuItem.length - 1;
      }
      const menuLink =
        menuItem[this.selectedItemIndex].querySelector('.menu__link');
      const previousMenuItem =
        menuItem[previousItemIndex].querySelector('.menu__link');
      if (menuLink) {
        menuLink.setAttribute('tabindex', '0');
        (previousMenuItem as HTMLElement).setAttribute('tabindex', '-1');
        (menuLink as HTMLElement).focus();
      }
    }
  }

  observerAttributes() {
    const config = { attributes: true };

    const callback = (mutationList: any) => {
      mutationList.forEach((mutation: any) => {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-libras'
        ) {
          if (this.element.getAttribute(mutation.attributeName) === 'false') {
            this.deactivateLibras();
            return;
          }
          this.activateLibras();
        }
      });
    };

    const observer = new MutationObserver(callback);
    observer.observe(this.element, config);
  }

  deactivateLibras() {
    const videoEl = this.element.querySelector('.menu__video');
    if (videoEl) videoEl.remove();
    this.element.querySelectorAll('.menu__item__icon').forEach((e) => {
      e.remove();
    });
  }

  activateLibras() {
    const videoEl = this.element.querySelector('.menu__video');
    if (!videoEl) {
      this.element
        .querySelector('.menu__content')
        ?.prepend(
          ElementMenuFactory.createVideo(
            this.element.dataset.librasDefault || ''
          )
        );

      const videoEl = this.element.querySelector(
        'video'
      ) as HTMLMediaElement | null;
      videoEl?.play().catch(() => {
        console.error('Não foi possível exibir o video');
      });
    }

    this.itemList?.forEach((item) => {
      const iconHands = this.createIconHands();
      iconHands.setAttribute(
        'data-src',
        item.querySelector('.menu__link')?.getAttribute('data-src') || ''
      );

      if (item.querySelector('.menu__item__icon')) {
        item.querySelector('.menu__item__icon')?.remove();
      }

      item.prepend(iconHands.cloneNode(true));

      const iconAdded = item.querySelector('.menu__item__icon');

      iconAdded?.addEventListener('click', () => {
        const source = iconAdded.getAttribute('data-src');
        if (!source) return;
        this.handlerPlayVideo(source);
      });
    });
  }

  checkActiveSubmenu() {
    let button = this.element.querySelector(
      '.menu__list.active > .menu__item.active  .open-submenu[aria-expanded="false"]'
    ) as HTMLElement | null | undefined;

    // expande menus abertos
    while (button) {
      this.handleSubMenuNext(button, false);
      button = button.parentElement?.querySelector(
        '.menu__list.submenu > .menu__item.active  .open-submenu[aria-expanded="false"]'
      );
    }
  }

  isLibraActive() {
    return this.element.dataset.libras === 'true';
  }

  toggleLibras() {
    if (this.isLibraActive()) {
      this.disableModeLibras();
    } else {
      this.enableModeLibras();
    }
  }

  enableModeLibras() {
    this.element.dataset.libras = 'true';
  }

  disableModeLibras() {
    this.element.dataset.libras = 'false';
  }

  init() {
    StateModeLibras.subscribe((state) => {
      state.mode === 'on' ? this.enableModeLibras() : this.disableModeLibras();
    });

    this.observerAttributes();

    this.buttonsNextSubMenus?.forEach((button) => {
      button.addEventListener('click', () => this.handleSubMenuNext(button));
    });
    const lists = this.menuAux;

    lists.forEach((li) => {
      li.addEventListener('keydown', (event: Event) => {
        if ((event as KeyboardEvent).key === 'ArrowDown') {
          this.navigateArrow('down');
          event.preventDefault();
        }

        if ((event as KeyboardEvent).key === 'ArrowUp') {
          this.navigateArrow('up');
          event.preventDefault();
        }
      });
    });

    this.links?.forEach((link: HTMLElement) => {
      link.addEventListener('click', (e) => {
        this.handleLinkActive(link as HTMLAnchorElement);
        Array.from(
          (e.target as HTMLElement).closest('.menu__list')?.children || []
        )
          .filter((item) => item.classList.contains('menu__item'))
          .forEach((item, idx) => {
            if (item.classList.contains('active')) {
              this.selectedItemIndex = idx;
            }
          });
      });
    });

    if (this.element.dataset.libras === 'true') {
      this.activateLibras();
    } else {
      this.deactivateLibras();
    }

    this.handleActiveCurrentPage();
    window.addEventListener('resize', () => this.handleResizeWindow());
    this.addTabindexInLinks();
    this.checkActiveSubmenu();
  }
}
