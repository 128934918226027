import {
  MODE_LIBRAS_CHANGE_EVENT,
  MODE_LIBRAS_OFF_ARIALABEL,
  MODE_LIBRAS_OFF_TITLE,
  MODE_LIBRAS_ON_ARIALABEL,
  MODE_LIBRAS_ON_TITLE,
} from './constants';
import { ModeLibrasState } from './types';

type Subject = (v: ModeLibrasState) => void;

const state: ModeLibrasState = {
  mode: 'off',
  title: MODE_LIBRAS_OFF_TITLE,
  label: MODE_LIBRAS_OFF_ARIALABEL,
};

const subscribers: Subject[] = [];

const subscribe = (subject: Subject) => subscribers.push(subject);

const unsubscribe = (subject: Subject) =>
  subscribers.splice(subscribers.indexOf(subject), 1);

const notify = () => {
  subscribers.forEach((subscriber) => {
    subscriber(state);
  });
  document.dispatchEvent(
    new CustomEvent(MODE_LIBRAS_CHANGE_EVENT, {
      detail: {
        active: isEnabled(),
        ...state,
      },
    })
  );
};

const setMode = (newMode: 'on' | 'off') => {
  state.mode = newMode;
  state.title = isEnabled() ? MODE_LIBRAS_ON_TITLE : MODE_LIBRAS_OFF_TITLE;
  state.label = isEnabled()
    ? MODE_LIBRAS_ON_ARIALABEL
    : MODE_LIBRAS_OFF_ARIALABEL;
  notify();
};

const enable = () => setMode('on');
const disable = () => setMode('off');

const toggle = () => (state.mode === 'on' ? disable() : enable());

const isEnabled = () => state.mode === 'on';

export type ToggleModeLibras = {
  value: () => ModeLibrasState;
  isEnabled: () => boolean;
  enable: () => void;
  disable: () => void;
  toggle: () => void;
  subscribe: (callback: Subject) => void;
  unsubscribe: (callback: Subject) => void;
};

export default {
  value: () => state,
  toggle,
  isEnabled,
  subscribe,
  unsubscribe,
  enable,
  disable,
} as ToggleModeLibras;
